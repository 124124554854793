import React, { Component } from 'react';
import { connect } from 'react-redux';
import Auth from "./auth";
import TopBar from './TopBar';
import { withRouter } from 'react-router-dom';
import { withStyles, Grid, MenuItem, DialogContent } from "@material-ui/core";
import { Field, reduxForm, getFormValues } from 'redux-form';
import muiStyles from "./MuiStyles";
import { Editor } from "felix-ui";
import SettingMenu from './SettingMenu';
import { SecondaryButton, EnhancedTable, StyledFormControl, StyledSelect, PrimaryButton, BorderPrimaryButton, StyledDialog, CloseButton, PlayArrowButton, StopButton, GrayButton, KeywordDeleteButton, StyledFormControlMd, SearchTextField } from './AppParts';
import ErrorMessage from './errorMessage';
import UpdateMessage from './updateMessage';
import { formatDate } from './utilities/utils';
import { listFaceDetectionPhrases, beginEditFaceDetectionPhrase, getFaceDetectionPhraseMorphRequest, editFaceDetectionPhrase, faceDetectionPhraseSynthesizeRequest, deleteFaceDetectionPhrase, getFaceDetectionPhraseUrl, allCopyExecute, searchFaceDetectionPhrase, setSynthesizedFaceDetectionPhrase, faceDetectionPhraseExists } from './actions/faceDetectionPhrases';
import { onLoading, offLoading } from './actions/loading';
import { updateMessage, appRemoveError, appError } from './actions/appAction';
import { renderSimpleSelectField, renderSimpleTextField } from './formRenderer';
import { required } from './validator';
import * as d3 from 'd3';
import ModalWrapper from './ModalWrapper';

// import { login } from "./actions/login";
// import { mainCompany } from "./actions/main";

export const PHRASE_TYPE_ORGANIZATION = { key: "organization", text: "組織名" };
export const PHRASE_TYPE_NAME = { key: "name", text: "氏名" };
export const PHRASE_TYPE_TITLE = { key: "title", text: "敬称" };
const PHRASE_TYPES = [PHRASE_TYPE_ORGANIZATION, PHRASE_TYPE_NAME, PHRASE_TYPE_TITLE]
const PHRASE_TYPE_KV = {
  organization: PHRASE_TYPE_ORGANIZATION.text,
  name: PHRASE_TYPE_NAME.text,
  title: PHRASE_TYPE_TITLE.text,
}

export class FaceDetectionPhrases extends Component {

  state = {
    isEditing: false,
    testPlay: false,
    requestingSynthesize: false,
    isDeleteConfirmDialog: false,
    deleteId: null,
    deleteTitle: null,
    message: null,
    add: false,
    isCopyDialog: false,
    originalModel: '',
    targetModel: '',
    executing: false,
  }

  constructor(props) {
    super(props);
    this.isTestPlayMode = false; // 再生のみか再生と保存かの判定用
    this.synthesizerRef = React.createRef();
  }

  async componentDidMount() {
    // TODO: デバッグ用
    // console.log("componentDidMount", this.props);
    // if(!this.props.user){
    //   const cid = this.props.match.params && this.props.match.params.cid;
    //   await login(this.props.dispatch, "test@test.com", "sskkotdotd", cid);
    // }
    // デバッグ用
    if (this.props.user) {
      getFaceDetectionPhraseUrl(this.props.dispatch, null);
      onLoading(this.props.dispatch);
      await listFaceDetectionPhrases(this.props.dispatch, this.props.user.TenantId);
      offLoading(this.props.dispatch);
    }
  }

  async componentDidUpdate() {
    // TODO: デバッグ用
    // console.log("componentDidUpdate", this.props);
    // if(!this.props.company && this.props.user){
    //   await mainCompany(this.props.dispatch, this.props.user.TenantId);
    // }
    // if(this.props.company && this.props.list.length === 0){
    //   await listFaceDetectionPhrases(this.props.dispatch, this.props.user.TenantId);
    // }
    // デバッグ用

    if(this.props.isRefresh && !this.props.loading){
      onLoading(this.props.dispatch);
      await listFaceDetectionPhrases(this.props.dispatch, this.props.user.TenantId);
      offLoading(this.props.dispatch);
      if (this.state.message) {
        updateMessage(this.props.dispatch, true, this.state.message);
        this.setState({ message: null });
      }
    }
    if(this.state.message === "copy"){
      updateMessage(this.props.dispatch, true, this.state.message);
      this.setState({ message: null });
    }

    // felixコンポーネントの見た目を強引に変更
    // 最初に選択されているタブのcolorを白にする
    d3.selectAll("li.react-tabs__tab--selected")
      .attr("style", "color: white !important");
    // 選択されたタブのcolorを白に、他のを通常に戻す
    const tabs = d3.selectAll("li.react-tabs__tab");
    tabs.on("click", (data, idx, elem) => {
      elem.forEach((e, i) => {
        if(idx === i){
          d3.select(e).attr("style", "color: white !important");
        } else {
          d3.select(e).attr("style", "");
        }
      })
    });
    // 読みのボタンの中身を変更
    d3.selectAll("button.mode-btn")
      .text("")
      .append("img")
      .attr("src", "/images/right_arrow_sm.png")
      .attr("alt", ">");
  }

  onSearch = async (e) => {
    e.preventDefault();
    searchFaceDetectionPhrase(this.props.dispatch, e.target.value, this.props.searchModel, this.props.searchPhraseType);
  }

  onBeginEdit = (e, item) => {
    e.preventDefault();
    appRemoveError(this.props.dispatch);
    beginEditFaceDetectionPhrase(this.props.dispatch, item ? item.faceDetectionPhrase : null, this.props.searchModel, this.props.searchPhraseType);
    this.setState({
      isEditing: true,
      deleteId: null,
      deleteTitle: null,
      add: item ? false : true,
    })
  }

  onModelChange = (e) => {
    searchFaceDetectionPhrase(this.props.dispatch, this.props.search, e.target.value, this.props.searchPhraseType);
    this.setState({
      searchModel: e.target.value,
    });
  }

  onSearchPhraseType = (phraseType) => {
    searchFaceDetectionPhrase(this.props.dispatch, this.props.search, this.props.searchModel, phraseType);
    this.setState({
      searchPhraseType: phraseType,
    });
  }

  onCloseEdit = async (e) => {
    // console.log(e.target);
    appRemoveError(this.props.dispatch);
    setSynthesizedFaceDetectionPhrase(this.props.dispatch, null);
    this.setState({
      isEditing: false,
      testPlay: false,
    });
  }

  onChange = (e) => {
    const editing = { ...this.props.editing, ...this.props.formValues };
    editing[e.target.name] = e.target.value;
    // if(e.target.name === "phraseTitle"){
    //   editing.interlanguageTitle = e.target.value;
    // }
    editFaceDetectionPhrase(this.props.dispatch, editing, e.target.name === "phraseTitle");
  }

  getMorphRequestHook = async (path, method, responseParser, options) => {
    return await getFaceDetectionPhraseMorphRequest(this.props.dispatch, this.props.user.TenantId, path, method, responseParser, options, this.props.editing.interlanguage, this.props.isFirstMorphRequest);
  };

  synthesizeRequestHook = async (path, method, responseParser, payload) => {
    // console.log("synthesizeRequestHook");
    const editing = { ...this.props.editing, ...this.props.formValues };
    let phrase_title = payload.morph.map(x => x[0]).join("");
    if(payload.morph.length <= 1 && phrase_title === "。"){
      appError(this.props.dispatch, "フレーズを入力してください");
      this.setState({ requestingSynthesize: false });
      return;
    }
    let phrase_yomi = payload.morph.filter(x => x[1] !== "記号").map(x => x[9]).join("");
    if(phrase_yomi === ""){
      appError(this.props.dispatch, "読みを入力してください");
      this.setState({ requestingSynthesize: false });
      return;
    }
    const data = await faceDetectionPhraseSynthesizeRequest(this.props.dispatch, this.props.user.TenantId, path, method, responseParser, payload, this.isTestPlayMode, editing);
    if (!data) return;
    this.setState({ message: this.state.add ? "register" : "update" });
    return data;
  };

  onUpdate = async () => {
    if(!this.props.formValues.modelRef){
      appError(this.props.dispatch, "モデルを選択してください");
      return;
    }
    if(!this.props.formValues.phraseType){
      appError(this.props.dispatch, "タイプを選択してください");
      return;
    }
    if(!this.props.formValues.phraseTitle){
      appError(this.props.dispatch, "フレーズ名を入力してください");
      return;
    }
    if (this.props.editing.faceDetectionPhrase) {
      const exists = await faceDetectionPhraseExists(this.props.dispatch, this.props.user.TenantId, this.props.editing.faceDetectionPhrase);
      if (!exists) return;
    }
    appRemoveError(this.props.dispatch);
    this.isTestPlayMode = false;
    this.setState({
      requestingSynthesize: true, // ボタン無効化用
    });
    if (this.synthesizerRef.current) {
      this.synthesizerRef.current.synthesize();
    }
  }

  onPlaySynthesizedPhrase = () => {
    setSynthesizedFaceDetectionPhrase(this.props.dispatch, null);
    this.isTestPlayMode = true;
    this.setState({
      testPlay: true, // テストプレイの有効化
      requestingSynthesize: true, // ボタン無効化用
    });
    if (this.synthesizerRef.current) {
      this.synthesizerRef.current.synthesize(); // 音声合成
    }
  }

  onStopSynthesizedPhrase = () => {
    this.setState({
      testPlay: false, // テストプレイの中断
    });
    this.synthesizerRef.current.cancel();
  }

  onDeleteConfirm = (e, phraseItem) => {
    e.preventDefault();
    this.setState({
      isDeleteConfirmDialog: true,
      deleteId: phraseItem.faceDetectionPhrase,
      deleteTitle: phraseItem.phraseTitle,
    });
  }

  onDeleteExecute = async (e) => {
    e.preventDefault();
    if (!this.state.deleteTitle) return;
    onLoading(this.props.dispatch);
    await deleteFaceDetectionPhrase(this.props.dispatch, this.props.user.TenantId, this.state.deleteId);
    offLoading(this.props.dispatch);
    this.setState({
      isDeleteConfirmDialog: false,
      deleteId: null,
      deleteTitle: null,
    });
  }

  onPlayPhrase = async (e, item) => {
    e.preventDefault();
    await getFaceDetectionPhraseUrl(this.props.dispatch, null);
    await getFaceDetectionPhraseUrl(this.props.dispatch, item);
  }

  allPhrasesCopy = async (e, item) =>{
    e.preventDefault();
    this.setState({
      isCopyDialog: true,
      originalModel: this.props.searchModel,
      targetModel: "",
    })
  }

  onCopyModelChange = (e) => {
    e.preventDefault();
    const s = this.state;
    s[e.target.name] = e.target.value;
    this.setState(s);
  }

  onAllCopyExecute = async () => {
    // console.log(this.state);
    if(!this.state.targetModel){
      appError(this.props.dispatch, "コピー先のモデルが指定されていません。");
      return;
    }
    if(this.state.originalModel === this.state.targetModel){
      appError(this.props.dispatch, "コピー元とコピー先に同じモデルが指定されています。");
      return;
    }
    onLoading(this.props.dispatch);
    this.setState({
      executing: true
    }, async () => {
      await allCopyExecute(this.props.dispatch, this.props.user.TenantId, this.state.originalModel, this.state.targetModel)
      offLoading(this.props.dispatch);
      this.setState({
        isCopyDialog: false,
        executing: false,
      })
    });
  }

  onTagClick = (e, tag) => {
    e.preventDefault();
    const searchInput = document.querySelector('#searchFaceDetection');
    if (searchInput) {
      searchInput.value = tag;
    }
    searchFaceDetectionPhrase(this.props.dispatch, tag, this.props.searchModel, this.props.searchPhraseType);
  }

  render() {
    const { handleSubmit, classes } = this.props;
    const headCells = [
      { id: 'phraseType', label: 'タイプ', isKV: true, kv: PHRASE_TYPE_KV },
      { id: 'phraseTitle', label: 'フレーズ名' },
      { id: 'createdAt', label: '作成日時', modFunc: formatDate },
      { id: 'updatedAt', label: '更新日時', modFunc: formatDate },
    ];
    let OrganizationButton = BorderPrimaryButton;
    let NameButton = BorderPrimaryButton;
    let TitleButton = BorderPrimaryButton;
    switch(this.props.searchPhraseType){
      case PHRASE_TYPE_ORGANIZATION.key:
        OrganizationButton = PrimaryButton;
        break;
      case PHRASE_TYPE_NAME.key:
        NameButton = PrimaryButton;
        break;
      case PHRASE_TYPE_TITLE.key:
        TitleButton = PrimaryButton;
        break;
      default:
        break;
    }
    return (
      <React.Fragment>
        <Auth />
        {this.props.user &&
        <React.Fragment>
          <TopBar />
          <div className={classes.content}>
            <SettingMenu />
            <div className={classes.main}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div className="titleText">顔認証音声フレーズ管理</div>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  <SearchTextField id="searchFaceDetection" name="search" style={{ width: "280px" }} defaultValue={this.props.search} onChange={this.onSearch} />
                  {(this.props.user && this.props.user.isManager()) &&
                    <SecondaryButton onClick={(e) => this.onBeginEdit(e)} disabled={this.props.loading} style={{ marginLeft: 5 }}>新規登録</SecondaryButton>
                  }
                  <audio src={this.props.playPhraseUrl} autoPlay />
                </Grid>
              </Grid>
              <ErrorMessage />
              <UpdateMessage />
              <Grid container spacing={2} style={{ marginTop: 15 }}>
                <Grid item xs={12} className="verticalCenter">
                  <span>モデル</span>
                  <React.Fragment>
                    <StyledFormControl style={{ width: 200, marginLeft: 10, textAlign: "center" }}>
                      <StyledSelect name="searchModel" value={this.props.searchModel} onChange={this.onModelChange} disabled={this.props.loading}>
                        { this.props.models.map(item => {
                          return(
                            <MenuItem key={item.model} value={item.model}>{ item.title }</MenuItem>
                          )
                        }) }
                      </StyledSelect>
                    </StyledFormControl>
                    <KeywordDeleteButton className="ml12" onClick={(e) => this.allPhrasesCopy(e)} disabled={this.props.loading}>
                      {(this.props.user && this.props.user.isManager()) &&
                        <span className="primaryText f16">[ 他モデルへコピー ]</span>
                      }
                    </KeywordDeleteButton>
                  </React.Fragment>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginTop: 15 }}>
                <Grid item xs={10} className="verticalCenter">
                  <OrganizationButton width={160} style={{ width: 160, marginLeft: 0 }} onClick={() => this.onSearchPhraseType(PHRASE_TYPE_ORGANIZATION.key)} disabled={this.props.loading}><span className="f14">{PHRASE_TYPE_ORGANIZATION.text} {this.props.listCount.organization}件</span></OrganizationButton>
                  <NameButton width={160} style={{ width: 160,marginLeft: 10 }} onClick={() => this.onSearchPhraseType(PHRASE_TYPE_NAME.key)} disabled={this.props.loading}><span className="f14">{PHRASE_TYPE_NAME.text} {this.props.listCount.name}件</span></NameButton>
                  <TitleButton width={160} style={{ width: 160,marginLeft: 10 }} onClick={() => this.onSearchPhraseType(PHRASE_TYPE_TITLE.key)} disabled={this.props.loading}><span className="f14">{PHRASE_TYPE_TITLE.text} {this.props.listCount.title}件</span></TitleButton>
                </Grid>
                {/* <Grid item xs={2} style={{ textAlign: "right" }}>
                  <PrimaryButton onClick={this.onReload} disabled={this.props.loading}>リロード</PrimaryButton>
                </Grid> */}
              </Grid>
              <div className={classes.listContainer} style={{ marginTop: 15, height: "calc(100vh - 56px - 48px - 215px)" }}>
              <EnhancedTable list={this.props.searchedList} classes={classes} operationMinWidth={120}
                  headCells={headCells} editCallback={(this.props.user && this.props.user.isManager()) ? this.onBeginEdit : null} playCallback={this.onPlayPhrase}
                  deleteCallback={(this.props.user && this.props.user.isManager()) ? this.onDeleteConfirm : null} displayTag tagClickCallback={this.onTagClick} />
              </div>
            </div>
          </div>
          { this.props.editing &&
          <StyledDialog open={this.state.isEditing} onClose={this.onCloseEdit} PaperProps={{ style: { width: 1152, minHeight: 786 } }}>
            <DialogContent className={classes.dialogContent} style={{ alignItems: "baseline", padding: "8px 0" }}>
              <form onSubmit={handleSubmit((values) => this.onCloseEdit)}style={{ width: "100%" }}>
                <div className={classes.closeButton}>
                  <CloseButton onClick={this.onCloseEdit}/>
                </div>
                <div className="alignCenter mb15" style={{ marginTop: 20 }}>
                  <span className="f28">顔認証音声フレーズ管理 {this.state.add ? "新規登録" : "編集"}</span>
                </div>
                <div className="alignCenter verticalCenter mb15">
                  <span className="f16" style={{ marginRight: 10, width: 100, textAlign: "right" }}>モデル:</span>
                  <Field name="modelRef" component={renderSimpleSelectField} validate={[required]} onChange={this.onChange} width={348} disabled={!this.state.add}>
                    {this.props.models.map(model => (
                      <MenuItem key={model.title} value={model.model}>{model.title}</MenuItem>
                    ))}
                  </Field>
                </div>
                <div className="alignCenter verticalCenter mb15">
                  <span className="f16" style={{ marginRight: 10, width: 100, textAlign: "right" }}>タイプ:</span>
                  <Field name="phraseType" component={renderSimpleSelectField} validate={[required]} onChange={this.onChange} width={348} disabled={!this.state.add}>
                    { PHRASE_TYPES.map(t => (
                      <MenuItem key={t.key} value={t.key}>{ t.text }</MenuItem>
                    )) }
                  </Field>
                </div>
                <div className="alignCenter verticalCenter mb15">
                  <span className="f16" style={{ marginRight: 10, width: 100, textAlign: "right" }}>フレーズ名:</span>
                  <Field name="phraseTitle" uncontrolled component={renderSimpleTextField} onChange={this.onChange} style={{ width: 348 }} />
                </div>
                <div className="alignCenter verticalCenter mb15">
                  <span className="f16" style={{ marginRight: 10, width: 100, textAlign: "right" }}>タグ(任意):</span>
                  <Field name="tag" uncontrolled component={renderSimpleTextField} placeholder="#タグ1 #タグ2" onChange={this.onChange} style={{ width: 348 }} />
                </div>
                <Editor
                  text={this.props.interlanguageTitle}
                  ref={this.synthesizerRef}
                  modelID={this.props.editing.modelID}
                  speakerID={this.props.editing.speakerID}
                  onSynthesizeRequestResolved={() => {
                    // 合成処理終了トリガー
                    this.setState({ requestingSynthesize: false }); // ボタン無効化用
                  }}
                  onSynthesized={(base64EncodedWav) => {
                    if (this.isTestPlayMode) {
                      setSynthesizedFaceDetectionPhrase(this.props.dispatch, "data:audio/wav;base64," + base64EncodedWav);
                    }
                  }}
                  onSynthesizeError={(e) => {
                    console.log(e)
                    this.setState({
                      requestingSynthesize: false, // ボタン無効化用
                      message: null,
                     });
                  }}
                  // APIリクエストのフック
                  getMorphRequestHook={this.getMorphRequestHook}
                  synthesizeRequestHook={this.synthesizeRequestHook}
                />
                { this.state.testPlay && <audio src={this.props.synthesizedPhrase} autoPlay onEnded={() => {
                  this.setState({
                    testPlay: false,
                    requestingSynthesize: false, // ボタン無効化用
                  });
                }} />}
                <div className="alignCenter" style={{ marginBottom: 20, marginRight: 50, justifyContent: "flex-end" }}>
                  <div style={{ marginRight: 50 }}>
                    <PlayArrowButton style={{ marginRight: 10 }}
                      disabled={this.state.requestingSynthesize || this.state.testPlay}
                      onClick={this.onPlaySynthesizedPhrase}
                    />
                    <StopButton variant="contained" color="default" onClick={this.onStopSynthesizedPhrase}>停止</StopButton>
                  </div>
                  <div>
                    <GrayButton width={120} height={32} onClick={this.onCloseEdit} style={{ marginRight: 15 }}>キャンセル</GrayButton>
                    <PrimaryButton width={120} height={32} disabled={this.state.requestingSynthesize || this.state.testPlay} onClick={this.onUpdate}>{this.state.add ? "登録する" : "更新する"}</PrimaryButton>
                  </div>
                </div>
              </form>
            </DialogContent>
          </StyledDialog>
          }
          <StyledDialog open={this.state.isDeleteConfirmDialog} onClose={() => this.setState({ isDeleteConfirmDialog: false, deleteId: null })}>
            <DialogContent className={classes.dialogContent}>
              <div className={classes.closeButton}>
                <CloseButton onClick={() => this.setState({ isDeleteConfirmDialog: false, deleteId: null })}/>
              </div>
              <div className="deletePopupContentText">
                <div>
                  <div className="wordKeepAll">「{this.state.deleteTitle}</div>
                  <div className="wordKeepAllClose">」</div>
                  を削除しますか？
                </div>
                <div style={{ marginTop: 36 }}>
                  <PrimaryButton width={160} height={48} onClick={(e) => this.onDeleteExecute(e)} disabled={this.props.loading}>削除する</PrimaryButton>
                  <GrayButton width={160} height={48} onClick={() => this.setState({ isDeleteConfirmDialog: false, deleteId: null })} style={{ marginLeft: 20 }}>キャンセル</GrayButton>
                </div>
              </div>
            </DialogContent>
          </StyledDialog>
          <StyledDialog open={this.state.isCopyDialog} onClose={() => this.setState({ isCopyDialog: false })}>
            <DialogContent className={classes.dialogContent}>
              <div className={classes.closeButton}>
                <CloseButton onClick={() => this.setState({ isCopyDialog: false })}/>
              </div>
              <div className="popupContentText" style={{ width: "100%" }}>
                <div className="popupTitleText alignCenter mb20">顔認証音声フレーズ</div>
                <div className="popupTitleText alignCenter mb20">他モデルへコピー</div>
                <div className="alignCenter mb20 f16" style={{ textAlign: "center" }}>
                  ※ 顔認証音声フレーズ名が一致しない<br/>
                  「組織名」「氏名」「敬称」をコピーします。
                </div>
                <Grid container spacing={2} className="mb15">
                  <Grid item xs={2} className="verticalCenter" style={{ justifyContent: "flex-end" }}>
                    <span>コピー元</span>
                  </Grid>
                  <Grid item xs={8} className="verticalCenter alignCenter">
                    <StyledFormControlMd style={{ width: 316, marginLeft: 10, textAlign: "center" }}>
                      <StyledSelect name="originalModel" value={this.state.originalModel} onChange={this.onCopyModelChange}>
                        { this.props.models.map(item => {
                          return(
                            <MenuItem key={item.model} value={item.model}><span className="f16">{ item.title }</span></MenuItem>
                          )
                        }) }
                      </StyledSelect>
                    </StyledFormControlMd>
                  </Grid>
                  <Grid item xs={2}>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={2} className="verticalCenter" style={{ justifyContent: "flex-end" }}>
                    <span>コピー先</span>
                  </Grid>
                  <Grid item xs={8} className="verticalCenter alignCenter">
                    <StyledFormControlMd style={{ width: 316, marginLeft: 10, textAlign: "center" }}>
                      <StyledSelect name="targetModel" value={this.state.targetModel} onChange={this.onCopyModelChange}>
                        { this.props.models.map(item => {
                          return(
                            <MenuItem key={item.model} value={item.model}><span className="f16">{ item.title }</span></MenuItem>
                          )
                        }) }
                      </StyledSelect>
                    </StyledFormControlMd>
                  </Grid>
                  <Grid item xs={2}>
                  </Grid>
                </Grid>
                <p style={{ marginTop: 36 }}>
                  <PrimaryButton width={160} height={48} onClick={this.onAllCopyExecute} disabled={this.props.loading}>コピーする</PrimaryButton>
                </p>
              </div>
            </DialogContent>
          </StyledDialog>
          <ModalWrapper
            open={this.state.executing}
            noCloseButton={true}
            content={
              <p className={classes.registering}>コピー実行中…</p>
            } />
        </React.Fragment>
        }
      </React.Fragment>
    )
  }
}

export default withRouter(withStyles(muiStyles)(
  connect(state => ({
    user: state.user && state.user.user,
    company: state.main && state.main.company,
    list: (state.faceDetectionPhrases && state.faceDetectionPhrases.list) || [],
    searchedList: (state.faceDetectionPhrases && state.faceDetectionPhrases.searchedList) || [],
    search: state.faceDetectionPhrases && state.faceDetectionPhrases.search,
    searchModel: state.faceDetectionPhrases && state.faceDetectionPhrases.searchModel,
    searchPhraseType: state.faceDetectionPhrases && state.faceDetectionPhrases.searchPhraseType,
    editing: state.faceDetectionPhrases && state.faceDetectionPhrases.editing,
    models: (state.faceDetectionPhrases && state.faceDetectionPhrases.models) || [],
    isFirstMorphRequest: state.faceDetectionPhrases && state.faceDetectionPhrases.isFirstMorphRequest,
    isRefresh: state.faceDetectionPhrases && state.faceDetectionPhrases.isRefresh,
    playPhraseUrl: state.faceDetectionPhrases && state.faceDetectionPhrases.playPhraseUrl,
    synthesizedPhrase: state.faceDetectionPhrases && state.faceDetectionPhrases.synthesizedPhrase,
    interlanguageTitle: state.faceDetectionPhrases && state.faceDetectionPhrases.interlanguageTitle,
    listCount: state.faceDetectionPhrases && state.faceDetectionPhrases.listCount,
    loading: state.loading && state.loading.loading,
    initialValues: state.faceDetectionPhrases && state.faceDetectionPhrases.editing,
    formValues: getFormValues("faceDetectionPhraseForm")(state),
  }))(reduxForm({ form: 'faceDetectionPhraseForm', enableReinitialize: true })(FaceDetectionPhrases))
));
