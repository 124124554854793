import db, { getDocData, getDocsData } from "../utilities/db";
import firebase from 'firebase/app';
import { appErrorAction, libraryErrorAction, offLineErrorAction } from "./appAction";
import { rejectCall, callStop } from "./voiceCalling";
import functions, { isFunctionsSdkInternalError, isTokenRevokedError } from "../utilities/functions";
import { redirectDeletedUser, redirectUnauthorizeUser } from "./login";

const LOCAL_SELECTED_LABEL = "localSelectedLabel";

export async function mainCompany(dispatch, cid) {
  try {
    // console.log("mainCompany");
    const companyDoc = db().collection("companies").doc(cid);
    const company = await getDocData(companyDoc, "cid");
    dispatch(mainCompanyAction(company));
  } catch (e) {
    if (e && e.name === "FirebaseError" && e.code === "permission-denied") {
      redirectUnauthorizeUser(dispatch, cid);
      return;
    }
    dispatch(appErrorAction(e));
  }
}

export function mainLablesListener(dispatch, cid, listeners){
  try{
    // console.log("mainLablesListener");
    // リスナー登録済みの場合は何もしない
    if(listeners.labels) return;
    // console.log("mainLablesListener", listeners);
    const companyDoc = db().collection("companies").doc(cid);
    const unsub = companyDoc.collection("labels").onSnapshot(labelsSnapshot => {
      // console.log("labels listen");
      const labels = labelsSnapshot.docs.map(doc => {
        return{
          label: doc.id,
          ...doc.data(),
          ref: doc.ref
        }
      });
      labels.sort(function(a, b) {
        return (a.labelName < b.labelName) ? -1 : 1;
      });
      const localSelectedLabel = localStorage.getItem(LOCAL_SELECTED_LABEL);

      let selectedLabel = null;
      if(localSelectedLabel){
        selectedLabel = labels.find(x => x.label === localSelectedLabel);
      }
      if(!selectedLabel){
        // ラベルが見つからない場合は一番最初のやつ
        selectedLabel = labels.length > 0 && labels[0];
        if(selectedLabel){
          localStorage.setItem(LOCAL_SELECTED_LABEL, selectedLabel.label);
        }
      }
      dispatch(mainLabelsListenAction(labels, selectedLabel));
    }, error => {
      if (error && error.name === "FirebaseError" && error.code === "permission-denied") {
        redirectUnauthorizeUser(dispatch, cid);
        return;
      }
      dispatch(appErrorAction(error));
    });
    dispatch(mainListerAdded("labels", unsub));
  } catch(e){
    dispatch(appErrorAction(e));
  }
}

export function currentUserListener(dispatch, cid, uid, role, listeners) {
  try {
    if(listeners.currentUser) return;
    const collectionName = role === "tool-manager" ? "tool-managers" : "tool-users";
    const companyDoc = db().collection("companies").doc(cid);
    const userDoc = companyDoc.collection(collectionName).doc(uid);
    const unsub = userDoc.onSnapshot(snapshot => {
      if (!snapshot.exists) {
        // 削除されたら
        redirectDeletedUser(dispatch, cid);
      }
    }, error => {
      if (error && error.name === "FirebaseError" && error.code === "permission-denied") {
        redirectUnauthorizeUser(dispatch, cid);
        return;
      }
      dispatch(appErrorAction(error));
    });
    dispatch(mainListerAdded("currentUser", unsub));
  } catch(e) {
    dispatch(appErrorAction(e));
  }
}

export function mainVguardsListener(dispatch, cid, label, listeners){
  try{
    // console.log("mainVguardsListener");
    if(listeners.vguards) {
      listeners.vguards();
    }
    ///companies/labels/vguards 特に書かれていないが、vguardsに紐づくデータの取得のためにリスナー登録している
    const unsub = db().collection("companies").doc(cid).collection("labels").doc(label).collection("vguards").orderBy("createdAt", "asc").onSnapshot(async snapshot => {
      const vguards = await Promise.all(snapshot.docs.map(async doc => {
        if(doc.data().settings){
          const characterRef = doc.data().settings.characterRef;
          try {
            const character = await db().collection("companies").doc(cid).collection("labels").doc(label).collection("characters").doc(characterRef.id).get()
            return{
              vguard: doc.id,
              ...doc.data(),
              ref: doc.ref,
              character: {
                character: character.id,
                ...character.data(),
              }
            }
          } catch (e) {
            if (e && e.name === "FirebaseError" && e.code === "permission-denied") {
              redirectUnauthorizeUser(dispatch, cid);
              return;
            }
            dispatch(appErrorAction(e));
          }
        } else {
          return{
            vguard: doc.id,
            ...doc.data(),
            ref: doc.ref,
          }
        }
      }));
      dispatch(mainVguardsListenAction(vguards));
    }, error => {
      if (error && error.name === "FirebaseError" && error.code === "permission-denied") {
        redirectUnauthorizeUser(dispatch, cid);
        return;
      }
      dispatch(appErrorAction(error));
    });
    dispatch(mainListerAdded("vguards", unsub));
  } catch(e){
    dispatch(appErrorAction(e));
  }
}

export function mainCharactersListener(dispatch, cid, label, listeners){
  try{
    // console.log("mainCharactersListener");
    if(listeners.characters) {
      listeners.characters();
    }
    ///companies/labels/vguards 特に書かれていないが、vguardsに紐づくデータの取得のためにリスナー登録している
    const unsub = db().collection("companies").doc(cid).collection("labels").doc(label).collection("characters").onSnapshot(async snapshot => {
      snapshot.docChanges().forEach(async change => {
        if(change.type === "modified"){
          // console.log(change.doc, change.doc.data());
          dispatch({
            type: "MAIN_UPDATE_VGUARD_CHARACTER",
            character: {
              character: change.doc.id,
              ...change.doc.data(),
            },
          })
        }
      });
    }, error => {
      if (error && error.name === "FirebaseError" && error.code === "permission-denied") {
        redirectUnauthorizeUser(dispatch, cid);
        return;
      }
      dispatch(appErrorAction(error));
    });
    dispatch(mainListerAdded("characters", unsub));
  } catch(e){
    dispatch(appErrorAction(e));
  }
}

export function mainAfterVguardListenerInitialized(dispatch, cid, label, vguards, listeners){
  // console.log("mainAfterVguardListenerInitialized");
  vguards.forEach(item => {
    mainHeartbeatsListener(dispatch, cid, label, item.vguard, listeners);
    if(item.settings && item.settings.characterRef) mainManualCommandsListener(dispatch, cid, label, item.settings.characterRef.id, listeners);
  });
  dispatch({
    type: "AFTER_VGUARDS_LISTENER"
  });
}

function mainHeartbeatsListener(dispatch, cid, label, vguard, listeners){
  try{
    // console.log("mainHeartbeatsListener");
    if(listeners.heartbeats && listeners.heartbeats[vguard]){
      // console.log("stop heartbeat listen", vguard);
      listeners.heartbeats[vguard]();
    }
    const unsub = db().collection("companies").doc(cid).collection("labels").doc(label).collection("vguards").doc(vguard).collection("heartbeats").onSnapshot(snapshot => {
      // console.log("heartbeats listen", vguard, snapshot, snapshot.docChanges());
      if(snapshot.size <= 1){
        dispatch({
          type: "MAIN_HEARTBEATS_LISTEN",
          payload: {
            vguard: vguard,
            heartbeatCount: 0,
          }
        });
      } else {
        dispatch({
          type: "MAIN_HEARTBEATS_LISTEN",
          payload: {
            vguard: vguard,
            heartbeatCount: 2,
          }
        });
      }
    }, error => {
      if (error && error.name === "FirebaseError" && error.code === "permission-denied") {
        redirectUnauthorizeUser(dispatch, cid);
        return;
      }
      dispatch(appErrorAction(error));
    });
    // 連続して更新するため引数のlistenersも変えておく
    if(!listeners.heartbeats) listeners.heartbeats = {};
    listeners.heartbeats[vguard] = unsub;
    dispatch(mainListerAdded("heartbeats", unsub, vguard));
  } catch(e){
    dispatch(appErrorAction(e));
  }
}

function mainManualCommandsListener(dispatch, cid, label, character, listeners){
  try{
    // console.log("mainManualCommandsListener", character, listeners);
    if(!character) return;
    if(listeners.manualCommands && listeners.manualCommands[character]){
      // console.log("stop manual commands listen", character);
      listeners.manualCommands[character]();
    }
    const unsub = db().collection("companies").doc(cid).collection("labels").doc(label).collection("characters").doc(character).collection("manualCommands").onSnapshot(async snapshot => {
      // console.log("manualCommands listen", character);
      const manualCommands = snapshot.docs.map(doc => {
        return {
          manualCommand: doc.id,
          ...doc.data(),
          ref: doc.ref
        }
      });
      manualCommands.sort((a, b) => {
        if(a.title > b.title) return 1;
        return -1;
      })
      try {
        const commandGroups = await getDocsData(db().collection("companies").doc(cid).collection("manualCommandsGroups"), "manualCommandGroup")
        manualCommands.forEach(item => {
          if(item.manualCommandGroupRef) item.manualCommandGroupRef = commandGroups.find(x => x.manualCommandGroup === item.manualCommandGroupRef.id);
        });
        dispatch(mainManualCommandsListenerAction(character, manualCommands));
      } catch (e) {
        if (e && e.name === "FirebaseError" && e.code === "permission-denied") {
          redirectUnauthorizeUser(dispatch, cid);
          return;
        }
        dispatch(appErrorAction(e));
      }
    }, error => {
      if (error && error.name === "FirebaseError" && error.code === "permission-denied") {
        redirectUnauthorizeUser(dispatch, cid);
        return;
      }
      dispatch(appErrorAction(error));
    });
    // 連続して更新するため引数のlistenersも変えておく
    if(!listeners.manualCommands) listeners.manualCommands = {};
    listeners.manualCommands[character] = unsub;
    dispatch(mainListerAdded("manualCommands", unsub, character));
  } catch(e){
    dispatch(appErrorAction(e));
  }
}

export function mainCommandsListener(dispatch, cid, label, listeners){
  try{
    // console.log("mainCommandsListener")
    if(listeners.commands) {
      listeners.commands();
    }
    const companyDoc = db().collection("companies").doc(cid);
    const labelDoc = companyDoc.collection("labels").doc(label);
    const unsub = labelDoc.collection("commands").orderBy("createdAt", "desc").endBefore(new Date()).onSnapshot(snapshot => {
      if(snapshot.empty) return;
      snapshot.docChanges().forEach(change => {
        if(change.type === "added"){
          const command = {
            command: change.doc.id,
            ...change.doc.data(),
            ref: change.doc.ref,
          };
          // console.log(command);
          dispatch({
            type: "MAIN_COMMAND_LISTEN",
            payload: {
              command: command
            }
          });
          if(command.commandType === "call" && command.commandMap.callMode === "start"){
            console.log("音声通話開始コマンド受信", command);
            setVguardCallType(dispatch, command.reporterRef.id, "callFromVguard");
          } else if(command.commandType === "emergency" && command.commandMap.callMode === "start"){
            console.log("緊急通話開始コマンド受信", command);
            setVguardCallType(dispatch, command.reporterRef.id, "callFromVguard");
          } else if(command.commandMap.callMode === "fail_timeout"){
            // main.jsのcomponentDidUpdateで停止処理
            // タイムアウトの場合はダイアログ表示
            dispatch(appErrorAction(["タイムアウトにより音声通話が切断されました。"]));
            setVguardCallType(dispatch, command.reporterRef.id, null);
          } else if(command.commandMap.callMode === "stop" || command.commandMap.callMode === "fail"){
            // main.jsのcomponentDidUpdateで停止処理
          }
        }
      });
    }, error => {
      if (error && error.name === "FirebaseError" && error.code === "permission-denied") {
        redirectUnauthorizeUser(dispatch, cid);
        return;
      }
      dispatch(appErrorAction(error));
    });
    dispatch(mainListerAdded("commands", unsub));
  } catch(e){
    dispatch(appErrorAction(e));
  }
}

export function mainCommandDelete(dispatch, vguard, commandType){
  dispatch({
    type: "MAIN_COMMAND_DELETE",
    payload: {
      vguard: vguard,
      commandType: commandType,
    }
  })
}

export function setVguardCallType(dispatch, vguard, callType) {
  dispatch ({
    type: "SET_VGUARD_CALL_TYPE",
    payload: {
      vguard: vguard,
      callType: callType,
    }
  });
}

export function mainEventLogsListener(dispatch, cid, label, listeners){
  try{
    // console.log("mainEventLogsListener", listeners);
    if(listeners.eventLogs) {
      listeners.eventLogs();
    }
    const labelDoc = db().collection("companies").doc(cid).collection("labels").doc(label);
    const unsub = labelDoc.collection("event-logs").where("level", "==", "INFO").orderBy("createdAt", "desc").endBefore(new Date()).onSnapshot(snapshot => {
      if (snapshot.empty) return;
      snapshot.docChanges().forEach(change => {
        // if (change.doc.metadata.hasPendingWrites) console.log("pending: ", change.doc.data())
        if (change.type === "added" || change.type === "modified") {
          if (!change.doc.metadata.hasPendingWrites) {
            // ログに追加または修正されたときでかつ書き込み遅延がない場合
            const eventLog = {
              log: change.doc.id,
              ...change.doc.data(),
              ref: change.doc.ref,
            }
            // console.log(eventLog);
            dispatch({
              type: "MAIN_EVENT_LOGS_LISTEN",
              payload: {
                eventLog: eventLog
              }
            });
          }
        }
      });
    }, error => {
      if (error && error.name === "FirebaseError" && error.code === "permission-denied") {
        redirectUnauthorizeUser(dispatch, cid);
        return;
      }
      dispatch(appErrorAction(error));
    });
    dispatch(mainListerAdded("eventLogs", unsub));
  } catch(e){
    dispatch(appErrorAction(e));
  }
}

export async function selectedLabelChange(dispatch, cid, label, listeners){
  // 選択されたラベルをlocalに保持
  // console.log("selectedLabelChange");
  localStorage.setItem(LOCAL_SELECTED_LABEL, label);
  dispatch({
    type: "SELECTED_LABEL_CHANGE",
    label: label
  });

  // リスナーの再登録
  mainVguardsListener(dispatch, cid, label, listeners);
  mainCommandsListener(dispatch, cid, label, listeners);
  mainEventLogsListener(dispatch, cid, label, listeners);
  mainCharactersListener(dispatch, cid, label, listeners);
}

export async function listEventLogs(dispatch, cid, label){
  try{
    // console.log("listEventLogs");
    // const eventLogs = await getDocsData(db().collection("companies").doc(cid).collection("labels").doc(label).collection("event-logs").where("level", "==", "INFO").orderBy("createdAt", "desc").limit(50), "log");
    const dt = new Date();
    dt.setDate(dt.getDate() - 3);
    // const eventLogs = await getDocsData(db().collection("companies").doc(cid).collection("labels").doc(label).collection("event-logs").where("level", "==", "INFO").orderBy("createdAt", "desc").endBefore(dt), "log");
    // const eventLogs = await getDocsData(db().collection("companies").doc(cid).collection("labels").doc(label).collection("event-logs").where("level", "==", "INFO").orderBy("createdAt", "asc").startAfter(dt), "log");
    let eventLogs = await getDocsData(db().collection("companies").doc(cid).collection("labels").doc(label).collection("event-logs").where("level", "==", "INFO").orderBy("createdAt", "desc").limit(180), "log");
    eventLogs = eventLogs.reverse();
    dispatch({
      type: "MAIN_LIST_EVENT_LOGS",
      payload: {
        eventLogs: eventLogs,
      }
    })
  } catch(e) {
    if (e && e.name === "FirebaseError" && e.code === "permission-denied") {
      redirectUnauthorizeUser(dispatch, cid);
      return;
    }
    dispatch(appErrorAction(e));
  }
}

export async function cancelManualVguardCommand(dispatch, user, selectedVguard, label){
  try{
    await (functions().httpsCallable('addManualVguardCommand'))({
      cid: user.TenantId,
      label: label,
      uid: user.uid,
      isManager: user.isManager(),
      vguardId: selectedVguard.vguard,
      type: "cancel",
    });
  } catch(e) {
    if (isTokenRevokedError(e)) {
      redirectUnauthorizeUser(dispatch, user.TenantId);
      return;
    }
    if (!navigator.onLine) {
      dispatch(offLineErrorAction("手動操作コマンドの送信"));
      return;
    }
    if (isFunctionsSdkInternalError(e)) {
      dispatch(libraryErrorAction(e.code));
      return;
    }
    dispatch(appErrorAction(e));
  }
}

export async function eventLogScrollDownChange(dispatch, scrollDown){
  dispatch({ type: "MAIN_SCROLL_DOWN_CHANGE", scrollDown: scrollDown });
}

export function eventLogsScrollChange(dispatch, scroll){
  dispatch({
    type: "MAIN_EVENT_LOGS_SCROLL_CHANGED",
    scroll: scroll,
  })
}

export async function addManualVguardCommand(dispatch, user, selectedVguard, manualCommandData, label, vguardStatus, callingData, vguardCommand){
  try {
    // 呼出中のときは通話を停止する
    const status = vguardStatus[selectedVguard.vguard];
    // console.log(status, callingData);
    if(status){
      if(callingData.mode){
        // 監視ツールから呼出中または通話中の場合
        console.log("監視ツールから呼出中または通話中の場合");
        await callStop(dispatch, user, label, selectedVguard, callingData);
      } else if(status.callType === "callFromVguard"){
        // クライアントから呼出中
        console.log("クライアントから呼出中");
        await rejectCall(dispatch, user, label, selectedVguard, status.colorStatus, vguardCommand);
      }
    }

    // コマンドを送信
    await (functions().httpsCallable('addManualVguardCommand'))({
      cid: user.TenantId,
      label: label,
      uid: user.uid,
      isManager: user.isManager(),
      vguardId: selectedVguard.vguard,
      manualCommandData: {
        manualCommand: manualCommandData.manualCommand,
        motionId: manualCommandData.motionRef ? manualCommandData.motionRef.id : null,
        phraseId: manualCommandData.phraseRef ? manualCommandData.phraseRef.id : null,
        pictureId: manualCommandData.pictureRef ? manualCommandData.pictureRef.id : null,
        title: manualCommandData.title,
        digitalOut: manualCommandData.digitalOut,
        digitalOutOneShotTime: manualCommandData.digitalOutOneShotTime,
        timeoutType: manualCommandData.timeoutType,
        timeoutSec: manualCommandData.timeoutSec,
        playType: manualCommandData.playType,
        updatedAt: manualCommandData.updatedAt.toDate().getTime(), // タイムスタンプ型のまま送るとfunctionsに正常なデータが送れない
      },
      manualCommandGroupData: {
        manualCommandGroup: manualCommandData.manualCommandGroupRef.manualCommandGroup,
        title: manualCommandData.manualCommandGroupRef.title,
      },
      type: "execute",
    });
  } catch(e) {
    if (isTokenRevokedError(e)) {
      redirectUnauthorizeUser(dispatch, user.TenantId);
      return;
    }
    if (!navigator.onLine) {
      dispatch(offLineErrorAction("手動操作コマンドの送信"));
      return;
    }
    if (isFunctionsSdkInternalError(e)) {
      dispatch(libraryErrorAction(e.code));
      return;
    }
    dispatch(appErrorAction(e));
  }
}

export function searchLog(dispatch, name, value){
  dispatch({
    type: "MAIN_SEARCH_LOG",
    payload: {
      name: name,
      value: value,
    }
  });
}

export function changeSearchMode(dispatch, searchMode){
  dispatch({
    type: "MAIN_CHANGE_SEARCH_MODE",
    payload: {
      logSearchMode: searchMode,
    }
  });
}

export function unsubAllMainListeners(dispatch) {
  dispatch({
    type: "UNSUB_MAIN_ALL_LISTENER",
  });
}

export function removeAllMainListeners(dispatch) {
  dispatch({
    type: "REMOVE_MAIN_ALL_LISTENER",
  });
}

export function updateSelectedVguard(dispatch, index, manualCommandGroup) {
  dispatch({
    type: "MAIN_UPDATE_SELECTED_VGAURD",
    payload: {
      selectedVguardIndex: index,
      selectedManualCommandGroup: manualCommandGroup,
    }
  });
}

export function updateSelectedManualCommandGroup(dispatch, manualCommandGroup){
  dispatch({
    type: "MAIN_UPDATE_SELECTED_MANUAL_COMMAND_GROUP",
    payload: {
      selectedManualCommandGroup: manualCommandGroup,
    }
  });
}

export function updateSelectedManualCommand(dispatch, manualCommand){
  dispatch({
    type: "MAIN_UPDATE_SELECTED_MANUAL_COMMAND",
    payload: {
      selectedManualCommand: manualCommand,
    }
  });
}

export function updateCallingControl(dispatch, callingControl){
  dispatch({
    type: "MAIN_UPDATE_CALLING_CONTROL",
    payload: {
      callingControl: callingControl,
    }
  });
}

function mainCompanyAction(company) {
  return {
    type: "MAIN_COMPANY",
    payload: {
      company: company
    }
  };
}

function mainLabelsListenAction(labels, selectedLabel){
  return {
    type: "MAIN_LABELS_LISTEN",
    payload: {
      labels: labels,
      selectedLabel: selectedLabel,
    }
  };
}

function mainListerAdded(key, unsub, nestId){
  return {
    type: "MAIN_LISTENER_ADDED",
    payload: {
      key: key,
      unsub: unsub,
      nestId: nestId,
    }
  };
}

function mainVguardsListenAction(vguards){
  return {
    type: "MAIN_VGUARDS_LISTEN",
    payload: {
      vguards: vguards
    }
  };
}

function mainManualCommandsListenerAction(character, manualCommands){
  return {
    type: "MAIN_MANUAL_COMMANDS_LISTEN",
    payload: {
      character: character,
      manualCommands: manualCommands
    }
  };
}

export async function vguardEventExecDummySample(cid, label, vguardItem, eventValue, user){
  console.log("vguardEventExecDummySample")
  const batch = db().batch();
  const companyDoc = db().collection("companies").doc(cid);
  const labelDoc = companyDoc.collection("labels").doc(label);
  for(let i = 0; i < 100; i++){
    const data = {
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      executorRole: "vguard",
      executorUserRef: labelDoc.collection("vguard").doc(vguardItem.vguard),
      executorUserDisplayName: vguardItem.displayName,
      executorProcess: "client",
      relatedVguardRef: null,
      relatedVguardDisplayName: null,
      level: "INFO",
      primaryType: "nearZoneDetection",
      secondaryType: "secondaryType",
      title: "title",
      state: "done",
      motionMap: null,
      phraseMap: null,
      pictureMap: null,
      keywords: null,
      keywordFull: null,
      note: null,
    }
    if(i % 2 === 0){
      data.secondaryType = "enter";
      data.title = "近距離検知-Enter";
    } else {
      data.secondaryType = "exit";
      data.title = "近距離検知-Exit";
    }
    batch.set(labelDoc.collection("event-logs").doc(), data);
    // labelDoc.collection("event-logs").doc().set(data);
  }
  await batch.commit();
}

export async function vguardEventExecDummy(cid, label, vguardItem, eventValue, user){
  // console.log(vguardItem, eventValue);
  if(!eventValue) return;
  let [primaryType, secondaryType, state, title, payload] = eventValue.split(",");
  const companyDoc = db().collection("companies").doc(cid);
  const labelDoc = companyDoc.collection("labels").doc(label);
  switch(primaryType){
    case "vguardReceivedManualCommand":
    case "vguardReceivedCallCommand":
    case "nearZoneDetection":
    case "nearZoneGreeting":
    case "midZoneGreeting":
    case "commandToMonitoringTool":
      break;
    case "content":
      break;
    default:
      break;
  }
  const data = {
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    executorRole: "vguard",
    executorUserRef: labelDoc.collection("vguard").doc(vguardItem.vguard),
    executorUserDisplayName: vguardItem.displayName,
    executorProcess: "client",
    relatedVguardRef: null,
    relatedVguardDisplayName: null,
    level: "INFO",
    primaryType: primaryType,
    secondaryType: secondaryType,
    title: title,
    state: state,
    motionMap: null,
    phraseMap: null,
    pictureMap: null,
    keywords: null,
    keywordFull: null,
    note: null,
  }
  if(primaryType !== "onlyVguardCommand" && primaryType !== "onlyMonitoringCommand"){
    await labelDoc.collection("event-logs").doc().set(data);
  }
  if(primaryType === "vguardSendCallCommand" || primaryType === "onlyVguardCommand" || (primaryType === "callFunction" && secondaryType === "failTimeoutStartCall")){
    // /companies/labels/commandsにデータ入れる
    let commandType, commandMap;
    switch(secondaryType){
      case "sendStartCall":
        commandType = "call";
        commandMap = { callMode: "start" };
        break;
      case "sendStartEmergencyCall":
        commandType = "emergency";
        commandMap = { callMode: "start" };
        break;
      case "stop":
      case "fail":
        commandType = state;
        commandMap = { callMode: secondaryType };
        break;
      case "failTimeoutStartCall":
        if (payload && payload === "emergency") {
          commandType = "emergency";
        } else {
          commandType = "call";
        }
        commandMap = { callMode: "fail_timeout" };
        break;
      default:
        break;
    }
    const command = {
      reporterRef: labelDoc.collection("vguard").doc(vguardItem.vguard),
      commandType: commandType,
      commandMap: commandMap,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    }
    await labelDoc.collection("commands").doc().set(command);
  } else if(primaryType === "onlyMonitoringCommand"){
    let commandType, commandMap;
    switch(secondaryType){
      case "stop":
      case "fail":
      case "reject":
        commandType = state;
        commandMap = { callMode: secondaryType, agoraAccessToken: "" };
        break;
      default:
        break;
    }
    const userRef = user.isManager() ? companyDoc.collection("tool-managers").doc(user.uid) : companyDoc.collection("tool-users").doc(user.uid);
    const command = {
      toolRef: userRef,
      commandType: commandType,
      commandMap: commandMap,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    }
    await labelDoc.collection("vguards").doc(vguardItem.vguard).collection("commands").doc().set(command);
  }
}
