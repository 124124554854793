const defaultState = {
  requests: [],
};

function defaultRequests() {
  return {
    uid: '',
    role: '',
    email: '',
    displayName: '',
    createdAt: '',
  }
}

const approvalRequests = (state = defaultState, action) => {
  let requests = null;
  switch (action.type) {
    case 'REQUESTS':
      return {
        ...state,
        requests: action.payload.requests || defaultRequests(),
      };
    case 'APPROVE_REQUEST':
      requests = state.requests.concat();
      requests.splice(requests.findIndex(({uid}) => uid === action.payload.uid), 1);
      return {
        ...state,
        requests: requests,
      };
    case 'REJECT_REQUEST':
      requests = state.requests.concat();
      requests.splice(requests.findIndex(({uid}) => uid === action.payload.uid), 1);
      return {
        ...state,
        requests: requests,
      };
    default:
      return state;
  }
};

export default approvalRequests;