import React, { Component } from 'react';
import { connect } from 'react-redux';
import Auth from "./auth";
import TopBar from './TopBar';
import { withRouter } from 'react-router-dom';
import { withStyles, MenuItem, DialogContent } from "@material-ui/core";
import { Field, reduxForm, getFormValues, change } from 'redux-form';
import muiStyles from "./MuiStyles";
import { SecondaryButton, EnhancedTable, PrimaryButton, BorderPrimaryButton, StyledDialog, CloseButton, GrayButton, SearchTextField, Delete24Button, BorderBackButton, PlusButton, KeywordDeleteButton, EditDialogButton } from './AppParts';
import ErrorMessage from './errorMessage';
import UpdateMessage from './updateMessage';
import { formatDate } from './utilities/utils';
import { onLoading, offLoading } from './actions/loading';
import { updateMessage, appRemoveError, appError } from './actions/appAction';
import { renderSimpleSelectField, renderSimpleTextField, renderSimpleSwitch } from './formRenderer';
import { required } from './validator';
import CharacterTopBar from './CharacterTopBar';
import { listExternalCommands, beginEditExternalCommand, cancelExternalCommand, changeExternalCommand, updateExternalCommand, deleteExternalCommandExec, closeExternalCommand, searchExternalCommand } from './actions/externalCommands';
import { updateCharactersExternalCommands, characterExists } from './actions/characters';
import SelectDialog from './selectDialog';

// import { login } from "./actions/login";
// import { mainCompany, mainLablesListener } from "./actions/main";
// import { listCharacters } from "./actions/characters";

export const EXTERNAL_COMMAND_MODE_FACE = { key: "faceDetection", text: "顔認証" };
export const EXTERNAL_COMMAND_MODE_DIGITAL = { key: "digitalInput", text: "接点入力" };
export const EXTERNAL_COMMAND_MODES = [
  EXTERNAL_COMMAND_MODE_FACE,
  EXTERNAL_COMMAND_MODE_DIGITAL
];

const faceDetectionHeadCells = [
  { id: 'faceDetectionType', label: 'コマンドタイプ' },
  { id: 'faceDetectionPhraseMaps', label: 'セリフ一覧', isPhraseMaps: true, sortDisabled: true, },
  { id: 'createdAt', label: '作成日時', modFunc: formatDate },
  { id: 'updatedAt', label: '更新日時', modFunc: formatDate },
];
const editFaceDetectionHeadCells = [
  { id: 'faceDetectionType', label: 'コマンドタイプ' },
  { id: 'faceDetectionPhraseMaps', label: 'セリフ一覧', isPhraseMaps: true, sortDisabled: true, },
];

const digitalInputHeadCells = [
  { id: 'title', label: 'コマンド名' },
  { id: 'motionRef.title', label: 'モーション', isNest: true },
  { id: 'phraseRef.phraseTitle', label: 'フレーズ', isNest: true },
  { id: 'updatedAt', label: '更新日時', modFunc: formatDate },
]
const editDigitalInputHeadCells = [
  { id: 'title', label: 'コマンド名' },
  { id: 'motionRef.title', label: 'モーション', isNest: true },
  { id: 'phraseRef.phraseTitle', label: 'フレーズ', isNest: true },
]

export const callItems = [
  { id: "callSuccess", label: "・音声通話「成功」時再生項目" },
  { id: "callFail", label: "・音声通話「失敗」時再生項目" },
  { id: "callReject", label: "・音声通話「拒否」時再生項目" },
]

const validate = values => {
  const errors = {};
  if (values.digitalOutOneShotTime && Number(values.digitalOutOneShotTime.toString().replace(/,/g, "")) > 3600) {
    errors.digitalOutOneShotTime = "3,600以下";
  }
  if (values.timeoutSec && Number(values.timeoutSec.toString().replace(/,/g, "")) > 40000000) {
    errors.timeoutSec = "40,000,000以下";
  }
  callItems.forEach(callItem => {
    errors[callItem.id] = {};
    if(values[callItem.id] && values[callItem.id].digitalOutOneShotTime && Number(values[callItem.id].digitalOutOneShotTime.toString().replace(/,/g, "")) > 3600) {
      errors[callItem.id].digitalOutOneShotTime = "3,600以下";
    }
    if(values[callItem.id] && values[callItem.id].timeoutSec && Number(values[callItem.id].timeoutSec.toString().replace(/,/g, "")) > 40000000) {
      errors[callItem.id].timeoutSec = "40,000,000以下";
    }
  });
  return errors;
}

export class ExternalCommands extends Component {

  state ={
    editingOriginalTitle: null,
    list: [],
    isEditing: false,
    isDeleteConfirmDialog: false,
    deleteId: null,
    deleteExternalCommandTitle: null,
    isDeletePhraseMapConfirmDialog: false,
    deletePhraseMapIndex: null,
    message: null,
    add: false,
    editingDialog: {},
  }

  async componentDidMount() {
    // TODO: デバッグ用
    // console.log("componentDidMount", this.props);
    // if(!this.props.user){
    //   const cid = this.props.match.params && this.props.match.params.cid;
    //   login(this.props.dispatch, "test@test.com", "sskkotdotd", cid);
    // }
    // デバッグ用

    if(this.props.company && this.props.selectedLabel){
      const character = this.props.match.params && this.props.match.params.character;
      onLoading(this.props.dispatch);
      const exists = await characterExists(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, character);
      if (!exists) {
        this.backToCharacterPage();
        offLoading(this.props.dispatch);
        return;
      }
      await listExternalCommands(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, character, this.backToCharacterPage, true);
      offLoading(this.props.dispatch);
    }
  }

  async componentDidUpdate() {
    // TODO: デバッグ用
    // console.log("componentDidMount", this.props);
    // if(!this.props.company && this.props.user){
    //   await mainCompany(this.props.dispatch, this.props.user.TenantId);
    // }
    // if(this.props.company && !this.props.selectedLabel.label){
    //   await mainLablesListener(this.props.dispatch, this.props.user.TenantId, this.props.listeners);
    // }
    // if(this.props.company && this.props.selectedLabel.label && this.props.characters.length === 0){
    //   await listCharacters(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label);
    // }
    // if(this.props.company && this.props.selectedLabel && this.props.characters.length > 0 && !this.props.list.length){
    //   const character = this.props.match.params && this.props.match.params.character;
    //   await listExternalCommands(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, character, this.backToCharacterPage);
    // }
    // デバッグ用

    if(this.props.isRefresh && !this.props.loading){
      const character = this.props.match.params && this.props.match.params.character;
      onLoading(this.props.dispatch);
      await listExternalCommands(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, character, this.backToCharacterPage, false);
      offLoading(this.props.dispatch);
    }
    if (this.state.message) {
      updateMessage(this.props.dispatch, true, this.state.message);
      this.setState({ message: null });
    }
  }

  onSearch = (e) => {
    e.preventDefault();
    const search = e.target.value;
    searchExternalCommand(this.props.dispatch, search, this.props.searchMode);
  }

  onSearchMode = (searchMode) => {
    if (this.props.editing && this.props.editing.mode !== searchMode) {
      if(this.isFormChanged()) return;
      closeExternalCommand(this.props.dispatch);
    }
    searchExternalCommand(this.props.dispatch, this.props.search, searchMode);
  }

  onBeginEdit = (e, item) => {
    e.preventDefault();
    // console.log(this.props.editing);
    if(this.isFormChanged()) return;
    appRemoveError(this.props.dispatch);
    const newItem = Object.keys(item).length !== 0 ? false : true;
    const character = this.props.match.params && this.props.match.params.character;
    beginEditExternalCommand(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, character, newItem ? null : item.externalCommand, this.props.searchMode);
    const title = item.mode === EXTERNAL_COMMAND_MODE_FACE.key ? item.faceDetectionType : item.title;
    this.setState({
      editingOriginalTitle: newItem ? null : title,
      add: newItem ? true : false,
    });
  }

  onBeginDialogEdit = (e, name) =>{
    e.preventDefault();
    this.setState({
      editingDialog: {
        [name]: true,
      },
    });
  }

  onCloseDialogEdit = (e) =>{
    e.preventDefault();
    this.setState({
      editingDialog: {},
    });
  }

  onRegisterDialog = (name, value, change) => {
    const editing = { ...this.props.formValues };
    if (name === "phrase") {
      editing["phraseRef"] = value;
      if (change) {
        editing.isPhraseModelDiff = false;
      }
    } else if (name === "picture") {
      editing["pictureRef"] = value;
    }
    changeExternalCommand(this.props.dispatch, editing);
  }

  onRegisterFaceDetectionPhraseDialog = (name, value, change, index) => {
    const editing = { ...this.props.formValues };
    if (name === "phrase") {
      editing["phraseRef" + index] = value;
      editing["faceDetectionPhraseMaps"][index]["phraseRef"] = value;
      if (change) {
        editing["isPhraseModelDiff" + index] = false;
      }
    }
    changeExternalCommand(this.props.dispatch, editing);
  }

  onRegisterCallItemDialog = (name, value, change, callItem) => {
    const editing = { ...this.props.formValues };
    editing[callItem] = { ...editing[callItem] };
    if (name === "phrase") {
      editing[callItem]["phraseRef"] = value;
      if(change){
        editing[callItem].isPhraseModelDiff = false;
      }
    } else if (name === "picture") {
      editing[callItem]["pictureRef"] = value;
    }
    changeExternalCommand(this.props.dispatch, editing);
  }

  onEditingChange = (e) =>{
    e.preventDefault();
    const editing = { ...this.props.formValues };
    editing[e.target.name] = e.target.value;
    if(e.target.name === "motionRef"){
      editing.isMotionModelDiff = false;
    } else if (e.target.name === "phraseRef") {
      editing.isPhraseModelDiff = false;
    } else if (e.target.name === "callFlag") {
      editing[e.target.name] = e.target.checked;
      editing.timeoutType = "Instantly";
      editing.timeoutSec = 0;
      if(!e.target.value) editing["emergencyFlag"] = false;
    } else if (e.target.name === "emergencyFlag") {
      editing[e.target.name] = e.target.checked;
    }
    changeExternalCommand(this.props.dispatch, editing);
  }

  onClose = () => {
    if (this.isFormChanged()) return;
    closeExternalCommand(this.props.dispatch);
    this.setState({
      editingOriginalTitle: null,
    });
  }

  onCancel = () => {
    cancelExternalCommand(this.props.dispatch);
  }

  onUpdateExec = async (values) => {
    const exists = await characterExists(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, this.props.characterData.character);
    if (!exists) {
      this.backToCharacterPage();
      return;
    }
    const editing = { ...values }
    if (editing.mode === EXTERNAL_COMMAND_MODE_DIGITAL.key && this.props.list.find(x => x.externalCommand !== editing.externalCommand && x.title === editing.title)) {
      appError(this.props.dispatch, "同じ外部コマンドがすでにあります。");
      return;
    }
    if (editing.mode === EXTERNAL_COMMAND_MODE_FACE.key && this.props.list.find(x => x.externalCommand !== editing.externalCommand && x.faceDetectionType === editing.faceDetectionType)) {
      appError(this.props.dispatch, "同じコマンドタイプがすでにあります。");
      return;
    }
    if (editing.isMotionModelDiff) {
      console.log("1111")
      appError(this.props.dispatch, "モーションに異なるモデルのデータが指定されています。");
      return;
    }
    if (editing.mode === EXTERNAL_COMMAND_MODE_DIGITAL.key && editing.isPhraseModelDiff) {
      console.log("222")
      appError(this.props.dispatch, "フレーズに異なるモデルのデータが指定されています。");
      return;
    }
    if (editing.mode === EXTERNAL_COMMAND_MODE_FACE.key && editing.faceDetectionPhraseMaps) {
      for (let [index, map] of editing.faceDetectionPhraseMaps.entries()) {
        if (map.phraseType === "phrase" && editing["isPhraseModelDiff" + index]) {
          console.log("555")
          appError(this.props.dispatch, "フレーズに異なるモデルのデータが指定されています。");
          return;
        }
      }
    }
    if (editing.callFlag) {
      if ((editing.callSuccess && editing.callSuccess.isMotionModelDiff) || (editing.callFail && editing.callFail.isMotionModelDiff) || (editing.callReject && editing.callReject.isMotionModelDiff)) {
        console.log("333")
        appError(this.props.dispatch, "モーションに異なるモデルのデータが指定されています。");
        return;
      }
      if ((editing.callSuccess && editing.callSuccess.isPhraseModelDiff) || (editing.callFail && editing.callFail.isPhraseModelDiff) || (editing.callReject && editing.callReject.isPhraseModelDiff)) {
        console.log("444")
        appError(this.props.dispatch, "フレーズに異なるモデルのデータが指定されています。");
        return;
      }
    }
    const motion = this.props.motionMenuItems.find(x => x.motion === editing.motionRef);
    if (motion && motion.title && motion.title.indexOf("緊急地震速報") !== -1) {
      if (editing.timeoutType === "Instantly") {
        appError(this.props.dispatch, ["モーションに緊急地震速報が設定されている場合、", "タイムアウトタイプにInstantlyは設定できません。"]);
        return;
      }
    }
    if (editing.callFlag) {
      const error = ["callSuccess", "callFail", "callReject"].some(callItem => {
        const callItemMotion = this.props.motionMenuItems.find(x => x.motion === editing[callItem].motionRef);
        if (editing[callItem].timeoutType === "Instantly") {
          if (callItemMotion && callItemMotion.title && callItemMotion.title.indexOf("緊急地震速報") !== -1) {
            appError(this.props.dispatch, ["モーションに緊急地震速報が設定されている場合、", "タイムアウトタイプにInstantlyは設定できません。"]);
            return true;
          }
        }
        return false;
      });
      if (error) return;
    }
    let i = 0;
    while (editing["phraseType" + i]) {
      if (editing["phraseType" + i] === "phrase" && !editing["phraseRef" + i]) {
        appError(this.props.dispatch, "セリフ一覧でフレーズが選択されていますが、フレーズが[再生しない]になっています。");
        return;
      }
      i++;
    }
    appRemoveError(this.props.dispatch);
    onLoading(this.props.dispatch);
    const success = await updateExternalCommand(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, this.props.match.params.character, editing, this.backToCharacterPage);
    if (editing.mode === EXTERNAL_COMMAND_MODE_DIGITAL.key) {
      await updateCharactersExternalCommands(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, this.props.match.params.character);
    }
    offLoading(this.props.dispatch);
    if (!success) return;
    this.setState({
      editingOriginalTitle: editing.mode === EXTERNAL_COMMAND_MODE_FACE.key ? editing.faceDetectionType : editing.title,
      message: this.state.add ? "register" : "update",
      add: false,
    });
  }

  onAddPhaseMaps = (e) => {
    e.preventDefault();
    const editing = { ...this.props.formValues };
    editing.faceDetectionPhraseMaps = [...editing.faceDetectionPhraseMaps];
    editing.faceDetectionPhraseMaps.push({ phraseType: "", phraseRef: null });
    changeExternalCommand(this.props.dispatch, editing);
  }

  onChangePhraseMaps = (e, i, target) => {
    const editing = { ...this.props.formValues };
    editing.faceDetectionPhraseMaps = [...editing.faceDetectionPhraseMaps];
    const map = { ...editing.faceDetectionPhraseMaps[i] };
    map[target] = e.target.value;
    if (map.phraseType !== "phrase") {
      map.phraseRef = null;
    }
    editing.faceDetectionPhraseMaps[i] = map;
    editing[e.target.name] = e.target.value;
    if (e.target.value !== "phrase") {
      editing["phraseRef" + i] = null;
    }
    changeExternalCommand(this.props.dispatch, editing);
  }

  onChangeCallItem = (e, target, name) => {
    const editing = { ...this.props.formValues };
    editing[target] = { ...editing[target] };
    editing[target][name] = e.target.value;
    if(name === "motionRef"){
      editing[target].isMotionModelDiff = false;
    } else if(name === "phraseRef"){
      editing[target].isPhraseModelDiff = false;
    }
    changeExternalCommand(this.props.dispatch, editing);
  }

  onDeletePhraseMapConfirm = (e, index) => {
    e.preventDefault();
    this.setState({
      isDeletePhraseMapConfirmDialog: true,
      deletePhraseMapIndex: index,
    });
  }

  onDeletePhraseMap = (e) => {
    // console.log(index);
    e.preventDefault();
    if(this.state.deletePhraseMapIndex >= 0){
      const editing = { ...this.props.formValues };
      editing.faceDetectionPhraseMaps = [...editing.faceDetectionPhraseMaps];
      let isPhraseModeDiffs =[];
      // いったん初期化
      editing.faceDetectionPhraseMaps.forEach((x, i) => {
        isPhraseModeDiffs[i] = editing["isPhraseModelDiff" + i];
        editing["phraseType" + i] = "";
        editing["phraseRef" + i] = "";
        editing["isPhraseModelDiff" + i] = "";
      });
      editing.faceDetectionPhraseMaps = editing.faceDetectionPhraseMaps.filter((x, i) => i !== this.state.deletePhraseMapIndex);
      isPhraseModeDiffs.splice(this.state.deletePhraseMapIndex, 1);
      // 値を振りなおす
      editing.faceDetectionPhraseMaps.forEach((x, i) => {
        editing["phraseType" + i] = x.phraseType;
        editing["phraseRef" + i] = x.phraseRef;
        editing["isPhraseModelDiff" + i] = isPhraseModeDiffs[i];
      });
      changeExternalCommand(this.props.dispatch, editing);
    }
    this.setState({
      isDeletePhraseMapConfirmDialog: false,
      deletePhraseMapIndex: null,
    });
  }

  onDeleteConfirm = (e, item) => {
    e.preventDefault();
    const title =  item.mode === EXTERNAL_COMMAND_MODE_FACE.key ? item.faceDetectionType : item.title;
    this.setState({
      isDeleteConfirmDialog: true,
      deleteId: item.externalCommand,
      deleteExternalCommandTitle: this.state.editingOriginalTitle || title,
    });
  }

  onDeleteExecute = async (e) =>{
    e.preventDefault();
    const exists = await characterExists(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, this.props.characterData.character);
    if (!exists) {
      this.backToCharacterPage();
      return;
    }
    if(!this.state.deleteId) return;
    appRemoveError(this.props.dispatch);
    onLoading(this.props.dispatch);
    await deleteExternalCommandExec(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, this.props.match.params.character, this.state.deleteId);
    if (this.props.searchMode === EXTERNAL_COMMAND_MODE_DIGITAL.key) {
      await updateCharactersExternalCommands(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, this.props.match.params.character);
    }
    offLoading(this.props.dispatch);
    this.setState({
      isDeleteConfirmDialog: false,
      deleteId: null,
      deleteExternalCommandTitle: null,
    });
  }

  getMenuItems = (items, addData) => {
    if(!addData) return items;
    const result = [...items];
    result.push({ ...addData, disabled: true });
    return result;
  }

  getPhraseItems = (items, editing) => {
    let result = [...items];
    let i = 0;
    while (editing["phraseType" + i] !== undefined) {
      let addData = editing["phraseData" + i];
      if (addData) {
        result = this.getMenuItems(result, addData);
      }
      i++;
    }
    return result;
  }

  getVariableArray = (key) =>{
    const a = this.props.editing[key] || [];
    return a.length ? a : [''];
  }

  onChangeTimeoutType = (e) => {
    const name = (e.target.name).split('.');
    if (e.target.value === "Instantly") {
      if (name[0] === "timeoutType") {
        this.props.dispatch(change("externalCommandForm", "timeoutSec", "0"));
      } else if (name[1] === "timeoutType") {
        // コールアイテム
        const callId = name[0];
        this.props.dispatch(change("externalCommandForm", callId + ".timeoutSec", "0"));
      }
    }
  }

  isFormChanged = () => {
    if(this.props.editing && (this.props.pristine === false || this.props.editing.isEditingUpdate === true)){
      this.setState({ message: this.props.editing.externalCommand ? "changing_edit" : "changing_new" });
      return true;
    }
    return false;
  }

  backToCharacterPage = () => {
    this.props.history.push("/" + this.props.user.TenantId + "/characters");
  }

  onTagClick = (e, tag) => {
    e.preventDefault();
    const searchInput = document.querySelector('#searchExternalCommand');
    if (searchInput) {
      searchInput.value = tag;
    }
    searchExternalCommand(this.props.dispatch, tag, this.props.searchMode);
  }

  render(){
    const { handleSubmit, submitting, classes } = this.props;
    let FaceDetectionButton = BorderPrimaryButton;
    let DigitalInputButton = BorderPrimaryButton;
    switch(this.props.searchMode){
      case EXTERNAL_COMMAND_MODE_FACE.key:
        FaceDetectionButton = PrimaryButton;
        break;
      case EXTERNAL_COMMAND_MODE_DIGITAL.key:
        DigitalInputButton = PrimaryButton;
        break;
      default:
        break;
    }
    let headCells = this.props.editing ? editFaceDetectionHeadCells : faceDetectionHeadCells;
    if(this.props.searchMode === EXTERNAL_COMMAND_MODE_DIGITAL.key){
      headCells = this.props.editing ? editDigitalInputHeadCells : digitalInputHeadCells;
    }
    const textRight = { style: { textAlign: "right" }};
    let timeoutSecDisabled = true;
    if((this.props.formValues && this.props.formValues.timeoutType !== "Instantly")){
      timeoutSecDisabled = false;
    }
    return(
      <React.Fragment>
        <Auth />
        <React.Fragment>
          <TopBar isCharacterEdit={true} />
          <div className={classes.content}>
            <div className={classes.characterMain}>
              <CharacterTopBar />
              <ErrorMessage />
              <UpdateMessage />
              <div className={classes.characerForm}>
                <div className="verticalCenter" style={{ justifyContent: "space-between", marginBottom: 15 }}>
                  <div>
                    <FaceDetectionButton width={160} onClick={() => this.onSearchMode(EXTERNAL_COMMAND_MODE_FACE.key)}><span className="f14">顔認証</span></FaceDetectionButton>
                    <DigitalInputButton width={160} style={{ marginLeft: 5 }} onClick={() => this.onSearchMode(EXTERNAL_COMMAND_MODE_DIGITAL.key)}><span className="f14">接点入力</span></DigitalInputButton>
                  </div>
                  <div>
                    <SearchTextField id="searchExternalCommand" name="search" style={{ width: "280px" }} defaultValue={this.props.search} onChange={this.onSearch} />
                    {/* <SearchButton onClick={this.onSearch} style={{ marginLeft: 5 }} /> */}
                    {(this.props.user && this.props.user.isManager()) &&
                      <SecondaryButton onClick={(e) => this.onBeginEdit(e, {})} style={{ marginLeft: 5 }} disabled={this.props.loading}>新規登録</SecondaryButton>
                    }
                  </div>
                </div>
                { !this.props.editing &&
                <div className={classes.characterListContainer}>
                  <EnhancedTable list={this.props.searchedExternalCommand} classes={classes} operationMinWidth={80}
                    headCells={headCells} editCallback={(this.props.user && this.props.user.isManager()) ? this.onBeginEdit : null} deleteCallback={(this.props.user && this.props.user.isManager()) ? this.onDeleteConfirm : null}
                    displayTag tagClickCallback={this.onTagClick} />
                </div>
                }
                <form onSubmit={handleSubmit(this.onUpdateExec)}>
                { (this.props.editing && this.props.formValues) &&
                  <div className={classes.listEditContainer}>
                    <div className={classes.listEditList} style={{ width: "65%", maxWidth: this.props.editing.mode === EXTERNAL_COMMAND_MODE_FACE.key ? 573 : 787 }}>
                      <EnhancedTable list={this.props.searchedExternalCommand} classes={classes} selectCallback={(this.props.user && this.props.user.isManager()) ? this.onBeginEdit : null}
                        headCells={headCells} selectItem={this.props.editing} selectKey="externalCommand" focusItem={this.props.editing} focusKey="externalCommand" isOperationHidden={true}
                        displayTag tagClickCallback={this.onTagClick} />
                    </div>
                    <div className={classes.listEditEdit} style={{ minWidth: this.props.editing.mode === EXTERNAL_COMMAND_MODE_FACE.key ? 643 : 429, maxWidth: this.props.editing.mode === EXTERNAL_COMMAND_MODE_FACE.key ? 643 : 429}}>
                      <div className={classes.arrowAndSideMenuContainer}>
                        <div className={classes.listEditSpace}>
                          <img src="/images/left_arrow.png" alt="<" />
                        </div>
                        <div className={classes.insideEditContainer} style={{ width: this.props.editing.mode === EXTERNAL_COMMAND_MODE_FACE.key ? 613 : 399 }}>
                          <div className="verticalCenter" style={{ justifyContent: "space-between" }}>
                            <div className="verticalCenter">
                              <BorderBackButton onClick={this.onClose}/>
                              <span className="f16 subText ml8">外部コマンド（{ this.props.editing.mode === EXTERNAL_COMMAND_MODE_FACE.key ? "顔認証" : "接点入力" }）{this.state.add ? "新規登録" : "編集"}</span>
                            </div>
                            { (this.props.editing.externalCommand && this.props.user && this.props.user.isManager()) &&
                              <Delete24Button onClick={(e) => this.onDeleteConfirm(e, this.props.editing)} />
                            }
                          </div>
                          { this.props.editing.mode === EXTERNAL_COMMAND_MODE_FACE.key &&
                          <div className="verticalCenter mb15" style={{ marginTop: 15 }}>
                            <span className="contentEditLabel">コマンドタイプ</span>
                            <Field name="faceDetectionType" style={{ width: "100%", marginLeft: 4 }} component={renderSimpleTextField} validate={[required]} />
                          </div>
                          }
                          { this.props.editing.mode === EXTERNAL_COMMAND_MODE_DIGITAL.key &&
                          <div className="verticalCenter mb15" style={{ marginTop: 15 }}>
                            <span className="contentEditLabel80">タイトル</span>
                            <Field name="title" style={{ width: "100%", marginLeft: 4 }} component={renderSimpleTextField} validate={[required]} />
                          </div>
                          }
                          <div className="verticalCenter mb15" style={{ marginTop: 15 }}>
                            <span className={this.props.editing.mode === EXTERNAL_COMMAND_MODE_FACE.key ? "contentEditLabel" : "contentEditLabel80"}>タグ(任意)</span>
                            <Field name="tag" style={{ width: "100%", marginLeft: 4 }} component={renderSimpleTextField} placeholder="#タグ1 #タグ2" />
                          </div>
                          <div className={classes.characterInsideEditScroll} style={{ overflowX: "hidden", maxHeight: "calc(100vh - 56px - 48px - 170px - 257px)" }}>
                            <div className="verticalCenter mb15">
                              <span className="contentEditLabel80 f12">モーション</span>
                              <Field name="motionRef" component={renderSimpleSelectField} size={"sm"} style={{ width: 252, marginRight: 10 }} displayEmpty textcolor={this.props.editing.isMotionModelDiff ? "#F8431C" : "#444444"} onChange={this.onEditingChange}>
                                <MenuItem value="">[状態維持]</MenuItem>
                                { this.getMenuItems(this.props.motionMenuItems, this.props.editing.motionData).map((item, i) => (
                                  <MenuItem key={i} value={item.motion} style={{display: item.disabled && "none"}}>{ item.title }</MenuItem>
                                )) }
                              </Field>
                            </div>
                            { this.props.editing.mode === EXTERNAL_COMMAND_MODE_FACE.key &&
                            <div className="verticalCenter mb15" style={{ alignItems: "baseline" }}>
                              <span className="contentEditLabel80 f12">セリフ一覧</span>
                              <div style={{ width: "100%" }}>
                                { this.getVariableArray("faceDetectionPhraseMaps").map((phrases, i, array) => (
                                <div className="verticalCenter" style={{ justifyContent: "space-between", marginTop: i === 0 ? 0 : 16 }} key={i}>
                                  <div>
                                    <Field name={"phraseType" + i} component={renderSimpleSelectField} size={"sm"} displayEmpty
                                      onChange={(e) => this.onChangePhraseMaps(e, i, "phraseType")} width={124} style={{ marginRight: 10 }}>
                                      <MenuItem value={""}>設定しない</MenuItem>
                                      <MenuItem value={"organization"}>組織名</MenuItem>
                                      <MenuItem value={"name"}>氏名</MenuItem>
                                      <MenuItem value={"title"}>敬称</MenuItem>
                                      <MenuItem value={"phrase"}>フレーズ</MenuItem>
                                    </Field>
                                    { this.props.editing["phraseType" + i] === "phrase" &&
                                      <React.Fragment>
                                        <Field name={"phraseRef" + i} component={renderSimpleSelectField} size={"sm"} displayEmpty width={300} style={{ marginRight: 10 }} SelectDisplayProps={{ style: {paddingRight: 45} }}
                                          textcolor={this.props.editing["isPhraseModelDiff" + i] ? "#F8431C" : "#444444"} disabled IconComponent={() => (
                                            <EditDialogButton onClick={(e) => this.onBeginDialogEdit(e, "faceDetectionPhrase" + i)}>[編集]</EditDialogButton>
                                            )}
                                          >
                                          <MenuItem value="">[再生しない]</MenuItem>
                                          { this.getPhraseItems(this.props.phraseMenuItems, this.props.editing).map((item, i) => (
                                            <MenuItem key={i} value={item.phrase}>{ item.phraseTitle }</MenuItem>
                                          )) }
                                        </Field>
                                        <SelectDialog
                                          open={this.state.editingDialog["faceDetectionPhrase" + i] || false}
                                          name="phrase"
                                          type="table"
                                          list={this.getPhraseItems(this.props.phraseMenuItems, this.props.editing)}
                                          initialSelected={this.props.editing["phraseRef" + i] || undefined}
                                          saveOption={{saveType: "faceDetection", position: i}}
                                          isDiff={this.props.editing["isPhraseModelDiff" + i]}
                                          onRegister={this.onRegisterFaceDetectionPhraseDialog}
                                          isCreate={!this.props.editing.createdAt}
                                          onClose={this.onCloseDialogEdit} />
                                      </React.Fragment>
                                    }
                                  </div>
                                  <div>
                                    { this.props.editing.faceDetectionPhraseMaps.length > 1 &&
                                      <KeywordDeleteButton onClick={(e) => this.onDeletePhraseMapConfirm(e, i)}><span className="primaryText f10">[ 削除 ]</span></KeywordDeleteButton>
                                    }
                                  </div>
                                </div>
                                )) }
                                <div style={{ textAlign: "right", marginRight: 15, marginTop: 11 }}>
                                  <PlusButton onClick={(e) => this.onAddPhaseMaps(e)} />
                                </div>
                              </div>
                            </div>
                            }
                            { this.props.editing.mode === EXTERNAL_COMMAND_MODE_DIGITAL.key &&
                            <div className="verticalCenter mb15">
                              <span className="contentEditLabel80 f12">音声フレーズ</span>
                              <Field name="phraseRef" component={renderSimpleSelectField} size={"sm"} style={{ width: 252, marginRight: 10 }} displayEmpty SelectDisplayProps={{ style: {paddingRight: 45} }}
                                textcolor={this.props.editing.isPhraseModelDiff ? "#F8431C" : "#444444"} disabled IconComponent={() => (
                                  <EditDialogButton onClick={(e) => this.onBeginDialogEdit(e, "phrase")}>[編集]</EditDialogButton>
                                  )}
                                >
                                <MenuItem value="">[再生しない]</MenuItem>
                                {this.getMenuItems(this.props.phraseMenuItems, this.props.editing.phraseData).map((item, i) => (
                                  <MenuItem key={i} value={item.phrase}>{item.phraseTitle}</MenuItem>
                                ))}
                              </Field>
                              <SelectDialog
                                open={this.state.editingDialog.phrase || false}
                                name="phrase"
                                type="table"
                                list={[{phrase: '', phraseTitle: '[再生しない]'}].concat(this.getMenuItems(this.props.phraseMenuItems, this.props.editing.phraseData))}
                                initialSelected={this.props.editing.phraseRef}
                                isDiff={this.props.editing.isPhraseModelDiff}
                                onRegister={this.onRegisterDialog}
                                isCreate={!this.props.editing.createdAt}
                                onClose={this.onCloseDialogEdit} />
                            </div>
                            }
                            <div className="verticalCenter mb15">
                              <span className="contentEditLabel80 f12">再生タイプ</span>
                              <Field name="playType" component={renderSimpleSelectField} size={"sm"} width={252}>
                                <MenuItem value="SyncSoundAndMotion">音声・モーション同時再生</MenuItem>
                                <MenuItem value="SoundAfterMotion">音声再生のあとにモーション再生</MenuItem>
                                <MenuItem value="MotionAfterSound">モーション再生のあとに音声再生</MenuItem>
                              </Field>
                            </div>
                            <div className="verticalCenter mb15">
                              <span className="contentEditLabel80 f12">案内画像</span>
                              <Field name="pictureRef" component={renderSimpleSelectField} size={"sm"} width={252} displayEmpty SelectDisplayProps={{ style: {paddingRight: 45} }}
                                textcolor="#444444" disabled IconComponent={() => (
                                  <EditDialogButton onClick={(e) => this.onBeginDialogEdit(e, "picture")}>[編集]</EditDialogButton>)}
                                >
                                <MenuItem value="">[非表示]</MenuItem>
                                { this.props.pictures.map((item, i) => (
                                  <MenuItem key={i} value={item.picture}>{ item.title }</MenuItem>
                                )) }
                              </Field>
                              <SelectDialog
                                open={this.state.editingDialog.picture || false}
                                name="picture"
                                type="table"
                                list={[{picture: '', title: '[非表示]'}].concat(this.props.pictures)}
                                initialSelected={this.props.editing.pictureRef}
                                onRegister={this.onRegisterDialog}
                                isCreate={!this.props.editing.createdAt}
                                onClose={this.onCloseDialogEdit} />
                            </div>
                            <div className="mb15" style={{ display: "flex", alignItems: "baseline" }}>
                              <span className="contentEditLabel80 f12">接点出力</span>
                              <Field name="digitalOut" component={renderSimpleSelectField} SelectDisplayProps={{ style: { minWidth: 32 } }} size={"sm"} width={124} style={{ marginRight: 10 }} displayEmpty>
                                <MenuItem value="">[なし]</MenuItem>
                                { this.props.digitalOutItems.map(item => (
                                  <MenuItem key={item} value={item}>{item}</MenuItem>
                                )) }
                              </Field>
                              <span className="contentEditLabel f12">ワンショット時間</span>
                              <Field name="digitalOutOneShotTime" type="text" numberFormat={true} inputProps={{ ...textRight }} className="timeoutSecField" style={{ width: 64 }}
                                component={renderSimpleTextField} validate={[required]} size={"sm"} />
                              <span className="f12 ml6">s</span>
                            </div>
                            <div className="verticalCenter mb15">
                              <span className="contentEditLabel120 f12" >タイムアウトタイプ</span>
                              <Field name="timeoutType" className="timeoutTypeField" component={renderSimpleSelectField} size={"sm"} onChange={this.onChangeTimeoutType} disabled={this.props.formValues.callFlag}>
                                { this.props.timeoutTypeItems.map(item => (
                                  <MenuItem key={item} value={item}>{item}</MenuItem>
                                )) }
                              </Field>
                            </div>
                            <div className="mb15" style={{ marginTop: 15, display: "flex", alignItems: "baseline" }}>
                              <span className="contentEditLabel120 f12">タイムアウト秒数</span>
                              <Field name="timeoutSec" type="text" numberFormat={true} inputProps={{ ...textRight }} className="timeoutSecField" style={{ width: 64 }}
                                component={renderSimpleTextField} validate={[required]} size={"sm"} disabled={timeoutSecDisabled || this.props.formValues.callFlag} />
                              <span className="contentEditLabel f12" style={{ marginLeft: 4 }}>s</span>
                            </div>
                            <div className="verticalCenter mb15">
                              <span className="f12" style={{ marginRight: 10 }}>音声通話</span>
                              <Field name="callFlag" component={renderSimpleSwitch} onChange={this.onEditingChange} />
                              <span className="f12" style={{ marginRight: 10, marginLeft: 20 }}>緊急</span>
                              <Field name="emergencyFlag" component={renderSimpleSwitch} disabled={this.props.formValues.callFlag === false} style={{ marginLeft: 15 }} onChange={this.onEditingChange} />
                            </div>
                            { this.props.formValues.callFlag &&
                            <React.Fragment>
                              { callItems.map((callItem, i) => (
                                <RenderCallItem key={i} classes={classes} callItem={callItem} parentProps={this.props} getMenuItems={this.getMenuItems} onChangeCallItem={this.onChangeCallItem}
                                  onBeginDialogEdit={this.onBeginDialogEdit} onCloseDialogEdit={this.onCloseDialogEdit} editingDialog={this.state.editingDialog} onChangeTimeoutType={this.onChangeTimeoutType} onRegisterCallItemDialog={this.onRegisterCallItemDialog} />
                              )) }
                            </React.Fragment>
                            }
                            <div className="verticalCenter mb15">
                              <span className="contentEditLabel80 f12">作成日時</span>
                              <span className="f12">{ formatDate(this.props.editing.createdAt) }</span>
                            </div>
                            <div className="verticalCenter mb15">
                              <span className="contentEditLabel80 f12">更新日時</span>
                              <span className="f12">{ formatDate(this.props.editing.updatedAt) }</span>
                            </div>
                          </div>
                          <div className="verticalCenter alignCenter" style={{ marginTop: 25 }}>
                            <GrayButton width={120} height={32} onClick={this.onCancel} style={{ marginRight: 20 }}>リセット</GrayButton>
                            <PrimaryButton type="submit" width={120} height={32} disabled={submitting}>{this.state.add ? "登録する" : "更新する"}</PrimaryButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                </form>
              </div>
            </div>
            <StyledDialog open={this.state.isDeleteConfirmDialog} onClose={() => this.setState({ isDeleteConfirmDialog: false, deleteId: null })}>
              <DialogContent className={classes.dialogContent}>
                <div className={classes.closeButton}>
                  <CloseButton onClick={() => this.setState({ isDeleteConfirmDialog: false, deleteId: null })}/>
                </div>
                <div className="deletePopupContentText">
                  <div>
                    <div className="wordKeepAll">「{this.state.deleteExternalCommandTitle}</div>
                    <div className="wordKeepAllClose">」</div>
                    を削除しますか？
                  </div>
                  <div style={{ marginTop: 36 }}>
                    <PrimaryButton width={160} height={48} onClick={(e) => this.onDeleteExecute(e)} disabled={this.props.loading}>削除する</PrimaryButton>
                    <GrayButton width={160} height={48} onClick={() => this.setState({ isDeleteConfirmDialog: false, deleteId: null })} style={{ marginLeft: 20 }}>キャンセル</GrayButton>
                  </div>
                </div>
              </DialogContent>
            </StyledDialog>
            <StyledDialog open={this.state.isDeletePhraseMapConfirmDialog} onClose={() => this.setState({ isDeletePhraseMapConfirmDialog: false, deletePhraseMapIndex: null })}>
              <DialogContent className={classes.dialogContent}>
                <div className={classes.closeButton}>
                  <CloseButton onClick={() => this.setState({ isDeletePhraseMapConfirmDialog: false, deletePhraseMapIndex: null })}/>
                </div>
                <div className="deletePopupContentText">
                  <div>削除しますか？</div>
                  <div style={{ marginTop: 36 }}>
                    <PrimaryButton width={160} height={48} onClick={(e) => this.onDeletePhraseMap(e)} disabled={this.props.loading}>削除する</PrimaryButton>
                    <GrayButton width={160} height={48} onClick={() => this.setState({ isDeletePhraseMapConfirmDialog: false, deletePhraseMapIndex: null })} style={{ marginLeft: 20 }}>キャンセル</GrayButton>
                  </div>
                </div>
              </DialogContent>
            </StyledDialog>
          </div>
        </React.Fragment>
      </React.Fragment>
    )
  }
}

function RenderCallItem(props){
  const { callItem, parentProps, getMenuItems, onChangeCallItem, onBeginDialogEdit, onCloseDialogEdit, editingDialog, onChangeTimeoutType, onRegisterCallItemDialog } = props;
  const textRight = { style: { textAlign: "right" }};
  let timeoutSecDisabled = true;
  // console.log(parentProps.formValues, callItem.id);
  if(parentProps.formValues[callItem.id] && parentProps.formValues[callItem.id]["timeoutType"] !== "Instantly"){
    timeoutSecDisabled = false;
  }
  const item = parentProps.editing[callItem.id];
  let width_sub = 0;
  if(parentProps.editing.mode === EXTERNAL_COMMAND_MODE_DIGITAL.key) width_sub = 62;
  return(
    <div style={{ marginTop: 40 }}>
      <div className="mb15">
        <span className="f14">{ callItem.label }</span>
      </div>
      <div className="verticalCenter mb15">
        <span className="contentEditLabel80 f12">モーション</span>
        <Field name={callItem.id + ".motionRef"} component={renderSimpleSelectField} size={"sm"} width={314 - width_sub} onChange={(e) => onChangeCallItem(e, callItem.id, "motionRef")}
          displayEmpty textcolor={item.isMotionModelDiff ? "#F8431C" : "#444444"}>
          <MenuItem value="">[状態維持]</MenuItem>
          { getMenuItems(parentProps.motionMenuItems, item.motionData).map((item, i) => (
            <MenuItem key={i} value={item.motion} style={{display: item.disabled && "none"}}>{ item.title }</MenuItem>
          )) }
        </Field>
      </div>
      <div className="verticalCenter mb15">
        <span className="contentEditLabel80 f12">音声フレーズ</span>
        <Field name={callItem.id + ".phraseRef"} component={renderSimpleSelectField} size={"sm"} width={314 - width_sub} displayEmpty SelectDisplayProps={{ style: {paddingRight: 45} }}
          textcolor={item.isPhraseModelDiff ? "#F8431C" : "#444444"} disabled IconComponent={() => (
            <EditDialogButton onClick={(e) => onBeginDialogEdit(e, callItem.id + ".phrase")}>[編集]</EditDialogButton>
            )}
          >
          <MenuItem value="">[再生しない]</MenuItem>
          { getMenuItems(parentProps.phraseMenuItems, item.phraseData).map((item, i) => (
            <MenuItem key={i} value={item.phrase}>{ item.phraseTitle }</MenuItem>
          )) }
        </Field>
        <SelectDialog
          open={editingDialog[callItem.id + ".phrase"] || false}
          name="phrase"
          type="table"
          list={[{phrase: '', phraseTitle: '[再生しない]'}].concat(getMenuItems(parentProps.phraseMenuItems, item.phraseData))}
          initialSelected={item.phraseRef}
          isDiff={item.isPhraseModelDiff}
          saveOption={{saveType: "callItem", position: callItem.id}}
          onRegister={onRegisterCallItemDialog}
          isCreate={!parentProps.editing.createdAt}
          onClose={onCloseDialogEdit} />
      </div>
      <div className="verticalCenter mb15">
        <span className="contentEditLabel80 f12">再生タイプ</span>
        <Field name={callItem.id + ".playType"} component={renderSimpleSelectField} size={"sm"} width={314 - width_sub}>
          <MenuItem value="SyncSoundAndMotion">音声・モーション同時再生</MenuItem>
          <MenuItem value="SoundAfterMotion">音声再生のあとにモーション再生</MenuItem>
          <MenuItem value="MotionAfterSound">モーション再生のあとに音声再生</MenuItem>
        </Field>
      </div>
      <div className="verticalCenter mb15">
        <span className="contentEditLabel80 f12">案内画像</span>
        <Field name={callItem.id + ".pictureRef"} component={renderSimpleSelectField} size={"sm"} width={314 - width_sub} displayEmpty SelectDisplayProps={{ style: {paddingRight: 45} }}
          textcolor="#444444" disabled IconComponent={() => (
            <EditDialogButton onClick={(e) => onBeginDialogEdit(e, callItem.id + ".picture")}>[編集]</EditDialogButton>)}
          >
          <MenuItem value="">[非表示]</MenuItem>
          { parentProps.pictures.map((item, i) => (
            <MenuItem key={i} value={item.picture}>{ item.title }</MenuItem>
          )) }
        </Field>
        <SelectDialog
          open={editingDialog[callItem.id + ".picture"] || false}
          name="picture"
          type="table"
          list={[{picture: '', title: '[非表示]'}].concat(parentProps.pictures)}
          initialSelected={item.pictureRef}
          saveOption={{saveType: "callItem", position: callItem.id}}
          onRegister={onRegisterCallItemDialog}
          isCreate={!parentProps.editing.createdAt}
          onClose={onCloseDialogEdit} />
      </div>
      <div className="mb15" style={{ display: "flex", alignItems: "baseline" }}>
        <span className="contentEditLabel80 f12">接点出力</span>
        <Field name={callItem.id + ".digitalOut"} component={renderSimpleSelectField} SelectDisplayProps={{ style: { minWidth: 32 } }} size={"sm"} width={124 - (width_sub/2)} style={{ marginRight: 10 }} displayEmpty>
          <MenuItem value="">[なし]</MenuItem>
          { parentProps.digitalOutItems.map(item => (
            <MenuItem key={item} value={item}>{item}</MenuItem>
          )) }
        </Field>
        <span className="contentEditLabel f12">ワンショット時間</span>
        <Field name={callItem.id + ".digitalOutOneShotTime"} type="text" numberFormat={true} inputProps={{ ...textRight }} className="timeoutSecField" style={{ width: 64 }}
          component={renderSimpleTextField} validate={[required]} size={"sm"} />
        <span className="f12 ml6">s</span>
      </div>
      <div className="verticalCenter mb15">
        <span className="contentEditLabel120 f12" >タイムアウトタイプ</span>
        <Field name={callItem.id + ".timeoutType"} className="timeoutTypeField" component={renderSimpleSelectField} width={252 - width_sub} size={"sm"} onChange={onChangeTimeoutType}>
          { parentProps.timeoutTypeItems.map(item => (
            <MenuItem key={item} value={item}>{item}</MenuItem>
          )) }
        </Field>
      </div>
      <div className="mb15" style={{ marginTop: 15, display: "flex", alignItems: "baseline" }}>
        <span className="contentEditLabel120 f12">タイムアウト秒数</span>
        <Field name={callItem.id + ".timeoutSec"} type="text" numberFormat={true} inputProps={{ ...textRight }} className="timeoutSecField" style={{ width: 64 }}
          component={renderSimpleTextField} validate={[required]} size={"sm"} disabled={timeoutSecDisabled} />
        <span className="contentEditLabel f12" style={{ marginLeft: 4 }}>s</span>
      </div>
    </div>
  );
}

export default withRouter(withStyles(muiStyles)(
  connect(state => ({
    user: state.user && state.user.user,
    listeners: state.main && state.main.listeners,
    company: state.main && state.main.company,
    selectedLabel: state.main && state.main.selectedLabel,
    characters: (state.characters && state.characters.characters) || [],
    characterData: state.externalCommands && state.externalCommands.characterData,
    list: (state.externalCommands && state.externalCommands.list) || [],
    searchedExternalCommand: (state.externalCommands && state.externalCommands.searchedExternalCommand) || [],
    search: state.externalCommands && state.externalCommands.search,
    searchMode: state.externalCommands && state.externalCommands.searchMode,
    phraseMenuItems: (state.externalCommands && state.externalCommands.phraseMenuItems) || [],
    motionMenuItems: (state.externalCommands && state.externalCommands.motionMenuItems) || [],
    pictures:  (state.externalCommands && state.externalCommands.pictures) || [],
    editing: state.externalCommands && state.externalCommands.editing,
    timeoutTypeItems: (state.externalCommands && state.externalCommands.timeoutTypeItems) || [],
    digitalOutItems: (state.externalCommands && state.externalCommands.digitalOutItems) || [],
    isRefresh: state.externalCommands && state.externalCommands.isRefresh,
    loading: state.loading && state.loading.loading,
    initialValues: (state.externalCommands && state.externalCommands.editing) || {},
    formValues: getFormValues('externalCommandForm')(state),
  }))(reduxForm({ form: 'externalCommandForm', validate: validate , enableReinitialize: true })(ExternalCommands))
));
