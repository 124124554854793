//import firebase from 'firebase/app';
import 'firebase/firestore';
import db, { getDocsOrderBy } from '../utilities/db';
import { appErrorAction } from './appAction';

export async function accounts(dispatch, cid) {
  try {
    const compnayDoc = db().collection("companies").doc(cid);
    const [managers, users] = await Promise.all([
      getDocsOrderBy(compnayDoc.collection("tool-managers"), "uid", [{ column: "createdAt", type: "asc" }]),
      getDocsOrderBy(compnayDoc.collection("tool-users"), "uid", [{ column: "createdAt", type: "asc" }])
    ]);
    dispatch(accountsAction(managers, users));
  } catch (e) {
    dispatch(appErrorAction(e));
  }
}

export async function deleteAccount(dispatch, cid, uid, role) {
  try{
    const company = db().collection("companies").doc(cid);
    // const manager = company.collection("tool-managers").doc(uid);
    // const user = company.collection("tool-users").doc(uid);
    if (role === "tool-manager") {
      const manager = await company.collection("tool-managers").doc(uid).get();
      if (!manager.exists) {
        dispatch(appErrorAction(["指定された監視ツール管理者アカウントは存在しません。","他のアカウントにより削除された可能性があります。"]));
        accounts(dispatch, cid);
        return;
      }
      const managerDoc = company.collection("tool-managers").doc(uid);
      await managerDoc.delete();
      dispatch(deleteManagerAction(uid));
    }
    if (role === "tool-user") {
      const user = await company.collection("tool-users").doc(uid).get();
      if (!user.exists) {
        dispatch(appErrorAction(["指定された監視ツール利用者アカウントは存在しません。","他のアカウントにより削除された可能性があります。"]));
        accounts(dispatch, cid);
        return;
      }
      const userDoc = company.collection("tool-users").doc(uid);
      await userDoc.delete();
      dispatch(deleteUserAction(uid));
    }
  } catch (e) {
    dispatch(appErrorAction(e));
  }
}

function accountsAction(toolManagers, toolUsers) {
  return {
    type: 'ACCOUNTS',
    payload: {
      toolManagers: toolManagers,
      toolUsers: toolUsers,
    }
  }
}

function deleteManagerAction(uid) {
  return {
    type: 'DELETE_MANAGER',
    payload: {
      uid: uid,
    }
  }
}

function deleteUserAction(uid) {
  return {
    type: 'DELETE_USER',
    payload: {
      uid: uid,
    }
  }
}
