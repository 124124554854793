import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles, MenuItem, Grid } from "@material-ui/core";
import Auth from './auth';
import TopBar from './TopBar';
import { withRouter } from 'react-router-dom';
import { motionManagement, searchMotions } from './actions/motions';
import { formatDate } from './utilities/utils';
import ErrorMessage from './errorMessage';
import muiStyles from "./MuiStyles";
import SettingMenu from './SettingMenu';
import { EnhancedTable, StyledFormControl, StyledSelect } from './AppParts';
import { onLoading, offLoading } from './actions/loading';
// import { login } from "./actions/login";
// import { mainCompany } from "./actions/main";

const headCells = [
  { id: 'title', label: ['モーション名'] },
  // { id: 'code', label: 'モーションコード' },
  { id: 'createdAt', label: '作成日時', modFunc: formatDate },
  { id: 'updatedAt', label: '更新日時', modFunc: formatDate },
]

export class Motion extends Component {
  state = {
    order: null,
    orderBy: null,
  }

  async componentDidMount() {
    // TODO: デバッグ用
    // console.log("componentDidMount", this.props);
    // if(!this.props.user){
    //   const cid = this.props.match.params && this.props.match.params.cid;
    //   await login(this.props.dispatch, "test@test.com", "sskkotdotd", cid);
    // }
    // デバッグ用

    if (this.props.user) {
      onLoading(this.props.dispatch);
      await motionManagement(this.props.dispatch, this.props.user.TenantId);
      offLoading(this.props.dispatch);
    }
  }

  async componentDidUpdate() {
    // TODO: デバッグ用
    // console.log("componentDidUpdate", this.props);
    // if(!this.props.company && this.props.user){
    //   await mainCompany(this.props.dispatch, this.props.user.TenantId);
    // }
    // if(this.props.company && this.props.motions.length === 0){
    //   await motionManagement(this.props.dispatch, this.props.user.TenantId);
    // }
    // デバッグ用
  }

  setOrder(order, orderBy) {
    this.setState({
      order: order,
      orderBy: orderBy,
    });
  }

  onModelChange = (e) => {
    const searchModel = e.target.value;
    // console.log(searchModel);
    searchMotions(this.props.dispatch, searchModel);
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Auth />
        <React.Fragment>
          <TopBar />
          <div className={classes.content}>
            <SettingMenu />
            <div className={classes.main}>
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  <div className="titleText">モーション管理</div>
                </Grid>
              </Grid>
              <ErrorMessage />
              <Grid container spacing={2} style={{ marginTop: 15 }}>
                <Grid item xs={12} className="verticalCenter">
                  <span>モデル</span>
                  <StyledFormControl style={{ width: 200, marginLeft: 10, textAlign: "center" }}>
                    <StyledSelect name="searchModel" value={this.props.searchModel} onChange={this.onModelChange}>
                      { this.props.models.map(item => {
                        return(
                          <MenuItem key={item.model} value={item.model}>{ item.title }</MenuItem>
                        )
                      }) }
                    </StyledSelect>
                  </StyledFormControl>
                </Grid>
              </Grid>
              <div className={classes.listContainer} style={{ height: "calc(100vh - 56px - 48px - 90px - 55px)" }}>
                <EnhancedTable list={this.props.searchedMotions} classes={classes}
                  headCells={headCells} />
              </div>
            </div>
          </div>
        </React.Fragment>
      </React.Fragment>
    );
  }
}

//Main.contextTypes = {
//  router: PropTypes.object
//};

export default withRouter(withStyles(muiStyles)(
  connect(state => ({
    user: state.user && state.user.user,
    company: state.main && state.main.company,
    motions: (state.motions && state.motions.motions) || [],
    searchedMotions: (state.motions && state.motions.searchedMotions) || [],
    models: (state.motions && state.motions.models) || [],
    searchModel: state.motions && state.motions.searchModel,
  }))(Motion)
));
