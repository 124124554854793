import firebase from 'firebase/app';
import 'firebase/auth';

export default class AuthorizerFirebase {

  _cid = null;

  constructor(cid) {
    this._auth = null;
    this._cid = cid;
  }

  auth() {
    if (!this._auth) {
      this._auth = firebase.auth();
      this._auth.tenantId = this._cid;
    }
    return this._auth;
  }

  async authorize(email, password) {
    await this.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
    return this.auth().signInWithEmailAndPassword(email, password).catch(e => undefined);
  }

  async createUser(email, password){
    await this.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
    const authorized　= await this.auth().createUserWithEmailAndPassword(email, password);
    return authorized;
  }

  async getUser(authorized) {
    if (!authorized || !authorized.user) {
      return null;
    }
    const token = await authorized.user.getIdTokenResult();
    if (!token || !token.claims) {
      this.auth().signOut();
      return null;
    }
    return {
      uid: authorized.user.uid,
      email: authorized.user.email,
      displayName: authorized.user.displayName,
      TenantId: authorized.user.tenantId,
      role: token.claims.role,
      isManager: function(){
        if(token.claims.role === "tool-manager"){
          return true;
        }
        return false;
      }
    };
  }

  async isAuthorized() {
    return new Promise((resolve, reject) => {
      const unsub = this.auth().onAuthStateChanged((user) => {
        unsub();
        resolve(!!user);
      }, error => {
        reject(error);
      });
    });
  }

  async unauthorize() {
    return this.auth().signOut();
  }
}
