import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';

let _db;

export default function db() {
  if (!_db) {
    _db = firebase.firestore();
    const host = process.env.REACT_APP_FIRESTORE_EMULATOR_HOST;
    if (host) {
      _db.settings({
        host: host,
        ssl: false
      });
    }
    _db.enablePersistence().then(() => {
      console.log("enablePersistence synchronizeTabs success");
    }).catch(e => {
      if (e && e.name === "FirebaseError" && e.code === "failed-precondition") {
        console.log("enablePersistence synchronizeTabs failed due to open multi tabs");
      }
    });
  }
  return _db;
}

export async function getDocData(documentRef, idName){
  if(!documentRef) return null;
  //const data = (await documentRef.get()).data();
  const d = await documentRef.get();
  const data = d.data();
  if(!data) return null;
  data[idName] = documentRef.id;
  return data;
}

export async function getDocsData(collectionRef, idName){
  const docs = await collectionRef.get();
  return docs.docs.map(doc => {
    let d = { ...doc.data() };
    d[idName] = doc.id;
    d.id = doc.id;
    return d;
  });
}

export async function getDocsOrderBy(collectionRef, idName, orders=[{ column: null, type: "desc" }], currentList=[], listMoreColumnName="", limit=0){
  // let query = collectionRef.orderBy(orderName, orderType);
  let query = collectionRef;
  orders.forEach(order => {
    if(order.column && order.type){
      query = query.orderBy(order.column, order.type);
    }
  });
  if(currentList.length){
    const last = currentList[currentList.length - 1];
    query = query.startAfter(last[listMoreColumnName]);
  }
  if(limit){
    query = query.limit(limit);
  }
  return currentList.concat(await getDocsData(query, idName));
}

export async function getModelsData(companyDoc) {
  const models = await getDocsOrderBy(companyDoc.collection("models"), "model", [{ column: "title", type: "asc" }]);
  return models;
}

export async function getMotionsData(companyDoc, excludes=[]) {
  let motions = await getDocsOrderBy(companyDoc.collection("motions"), "motion", [{ column: "title", type: "asc" }]);
  if(excludes.length){
    excludes.forEach(exclude => {
      motions = motions.filter(x => x.motion.indexOf(exclude) < 0);
    });
  }
  return motions;
}

export async function getMotionsDataExcludeNod(companyDoc){
  return await getMotionsData(companyDoc, ["_103"]);
}

export async function getPhrasesData(companyDoc) {
  const phrases = await getDocsOrderBy(companyDoc.collection("phrases"), "phrase", [{ column: "phraseTitle", type: "asc" }]);
  return phrases;
}

export async function getManualCommandGroupsData(companyDoc) {
  const manualCommandGroups = await getDocsOrderBy(companyDoc.collection("manualCommandsGroups"), "manualCommandsGroup", [{ column: "title", type: "asc" }]);
  return manualCommandGroups;
}

export async function getPicturesData(labelDoc) {
  const stay = await getDocData(labelDoc.collection("guide-pictures").doc("stay"), "picture");
  const pictures = await getDocsOrderBy(labelDoc.collection("guide-pictures"), "picture", [{ column: "title", type: "asc" }]);
  if(stay){
    return [stay, ...pictures.filter(x => x.picture !== "stay")];
  }
  return pictures;
}

export async function getVguardsData(labelDoc) {
  const vguards = await getDocsOrderBy(labelDoc.collection("vguards"), "vguard", [{ column: "createdAt", type: "asc" }]);
  return vguards;
}

export async function getCharactersData(labelDoc) {
  const characters = await getDocsOrderBy(labelDoc.collection("characters"), "character", [{ column: "name", type: "asc" }]);
  return characters;
}

export async function getManualCommandsData(characterDoc) {
  const manualCommands = await getDocsOrderBy(characterDoc.collection("manualCommands"), "manualCommand", [{ column: "title", type: "asc" }]);
  return manualCommands;
}

export async function getDigitalInputExternalCommandsData(characterDoc) {
  const manualCommands = await getDocsOrderBy(characterDoc.collection("externalCommands").where("mode", "==", "digitalInput"), "externalCommand", [{ column: "title", type: "asc" }]);
  return manualCommands;
}

export async function getKeywordGroupsData(parserDoc) {
  return await getDocsOrderBy(parserDoc.collection("keywordGroups"), "keywordGroup", [{ column: "title", type: "asc" }]);
}

// https://firebase.google.com/docs/firestore/manage-data/delete-data?hl=ja
export function deleteCollection(db, collectionRef, batchSize=100) {
  let query = collectionRef.orderBy('__name__').limit(batchSize);
  return new Promise((resolve, reject) => {
    deleteQueryBatch(db, query, batchSize, resolve, reject);
  });
}

async function deleteQueryBatch(db, query, batchSize, resolve, reject) {
  try{
    const snapshot = await query.get();
    if(snapshot.size === 0){
      resolve();
      return;
    }
    let batch = db.batch();
    snapshot.docs.forEach((doc) => {
      batch.delete(doc.ref);
    });
    await batch.commit();
    process.nextTick(() => {
      deleteQueryBatch(db, query, batchSize, resolve, reject);
    });
  } catch(e){
    reject(e);
  }
}
