import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles, Grid, Button, DialogContent, ListItem, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import Auth from "./auth";
import TopBar from "./TopBar";
import { onLoading, offLoading } from "./actions/loading";
import { listKeywordGroups, beginEditKeywordGroup, updateKeywordGroup, changeSearchSynonym, changeSearchMorpheme, changeMorphemeSeleted, closeEditKeywordGroup, cancelEditKeywordGroup, addKeywordAndUpdate, deleteKeywordGroup, changeEditingKeywordGroup, beginEditKeywords, searchKeywordGroup, } from "./actions/keywordGroups";
import { formatDate } from "./utilities/utils";
import { Field, getFormValues, reduxForm } from "redux-form";
import { renderSimpleTextField } from "./formRenderer";
import { required } from "./validator";
import ErrorMessage from './errorMessage';
import UpdateMessage from './updateMessage';
import { appError, appRemoveError, updateMessage } from './actions/appAction';
import muiStyles from "./MuiStyles";
import SettingMenu from "./SettingMenu";
import { EnhancedTable, SecondaryButton, GrayButton, PrimaryButton, CloseButton, StyledDialog, BorderBackButton, KeywordDeleteButton, EditButton, Delete24Button, SearchTextField } from "./AppParts";

// import { login } from "./actions/login";
// import { mainCompany } from "./actions/main";


const SynonymButton = withStyles({
  root: {
    minWidth: 0,
  },
  text: {
    padding: 3
  }
})(Button);

const MorphButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white.main,
    borderRadius: 12,
    height: 24,
    // width: props.width ? props.width : 120,
    '&:hover': {
      backgroundColor: theme.palette.white.dark,
    },
    minWidth: "initial",
    justifyContent: "start",
  },
  text: {
    padding: "0 8px"
  },
  label: {
    color: theme.palette.primary.main,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  }
}))(Button);

const MorphSelectedButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 12,
    height: 24,
    // width: props.width ? props.width : 120,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    minWidth: "initial",
    justifyContent: "start",
  },
  text: {
    padding: "0 8px"
  },
  label: {
    color: theme.palette.white.main,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  }
}))(Button);

const headCells = [
  { id: 'title', label: 'グループ名', width: 140 },
  { id: 'keywords', label: 'キーワード一覧', isKeywords: true, sortDisabled: true, width: 230 },
  { id: 'createdAt', label: '作成日時', modFunc: formatDate },
  { id: 'updatedAt', label: '更新日時', modFunc: formatDate },
]

const editHeadCells = [
  { id: 'title', label: 'グループ名', width: 140 },
  { id: 'keywords', label: 'キーワード一覧', isKeywords: true, sortDisabled: true, width: 230 },
]

export class KeywordGroups extends Component {

  state ={
    editingOriginalTitle: null,
    isDeleteConfirmDialog: false,
    isDeleteKeywordConfirmDialog: false,
    deleteItem: null,
    deleteTitle: null,
    deleteKeywordIndex: null,
    deleteKeywordMode: null,
    message: null,
    add: false,
    editingKeywordDialog: false,
    editingKeywordList: [],
    preSynonymInput: null,
  }

  async componentDidMount() {
    // TODO: デバッグ用
    // console.log("componentDidMount", this.props);
    // if(!this.props.user){
    //   const cid = this.props.match.params && this.props.match.params.cid;
    //   await login(this.props.dispatch, "test@test.com", "sskkotdotd", cid);
    // }
    // デバッグ用

    if (this.props.user) {
      onLoading(this.props.dispatch);
      await listKeywordGroups(this.props.dispatch, this.props.user.TenantId, true);
      offLoading(this.props.dispatch);
    }
  }

  async componentDidUpdate() {
    // TODO: デバッグ用
    // console.log("componentDidMount", this.props);
    // if(!this.props.company && this.props.user){
    //   await mainCompany(this.props.dispatch, this.props.user.TenantId);
    // }
    // if(this.props.company && this.props.keywordGroups.length === 0){
    //   await listKeywordGroups(this.props.dispatch, this.props.user.TenantId);
    // }
    // デバッグ用

    if (this.props.isRefresh && !this.props.loading) {
      onLoading(this.props.dispatch);
      await listKeywordGroups(this.props.dispatch, this.props.user.TenantId, false);
      offLoading(this.props.dispatch);
    }
    if (this.state.message) {
      updateMessage(this.props.dispatch, true, this.state.message);
      this.setState({ message: null });
    }
  }

  onSearch = (e) => {
    e.preventDefault();
    searchKeywordGroup(this.props.dispatch, e.target.value);
  }

  onBeginEdit = async (e, item) => {
    e.preventDefault();
    if (this.isFormChanged()) return;
    const keywordGroup = item.keywordGroup;
    const newItem = Object.keys(item).length !== 0 ? false : true;
    // appRemoveError(this.props.dispatch);
    // onLoading(this.props.dispatch);
    // await listKeywordGroups(this.props.dispatch, this.props.user.TenantId);
    // offLoading(this.props.dispatch);
    beginEditKeywordGroup(this.props.dispatch, keywordGroup);
    this.setState({
      editingOriginalTitle: newItem ? null : item.title,
      add: newItem ? true : false,
    });
  }

  onCloseEdit = (e) => {
    e.preventDefault();
    appRemoveError(this.props.dispatch);
  };

  onCancel = () => {
    cancelEditKeywordGroup(this.props.dispatch);
  }

  pattern = /^.*['’].*$/;

  onTitleChange = (e) => {
    e.preventDefault();
    if (e.target.value.match(this.pattern)){
      appError(this.props.dispatch, "シングルクォーテーションは入力できません");
      return;
    }
    const data = { ...this.props.formValues };
    data[e.target.name] = e.target.value;
    changeEditingKeywordGroup(this.props.dispatch, data, true);
  }

  onSynonymChange = (e) => {
    e.preventDefault();
    if(e.target.value.match(this.pattern)){
      const synonymInput = document.querySelector('#searchSynonym');
      if (synonymInput) {
        synonymInput.value = this.state.preSynonymInput;
      }
      appError(this.props.dispatch, "シングルクォーテーションは入力できません");
      return;
    }
    const data = {...this.props.formValues};
    data.searchSynonym = e.target.value;
    this.setState({ preSynonymInput: e.target.value });
    changeSearchSynonym(this.props.dispatch, this.props.user.TenantId, data);
    changeEditingKeywordGroup(this.props.dispatch, data, true);
  }

  onSynonymClick = (e, word) => {
    const morphemeInput = document.querySelector('#searchMorpheme');
    if (morphemeInput) {
      morphemeInput.value = word;
    }
    this.onMorphemeChange(e, word);
  }

  onMorphemeChange = (e, word) => {
    e.preventDefault();
    let target = e.target.value;
    if(word) target = word;
    const editingKeywordGroup = {...this.props.formValues};
    editingKeywordGroup.searchMorpheme = target;
    changeSearchMorpheme(this.props.dispatch, this.props.user.TenantId, editingKeywordGroup)
    changeEditingKeywordGroup(this.props.dispatch, editingKeywordGroup, true);
  }

  onMorphemeSelectedChange = (e, index, item) =>{
    e.preventDefault();
    const morphemeList = [...this.props.morphemeList];
    morphemeList[index] = { ...item, selected: !item.selected };
    console.log(morphemeList);
    changeMorphemeSeleted(this.props.dispatch, morphemeList);
  }

  onUpdateExec = async (values) => {
    const editingKeywordGroup = {...values};
    if(this.props.keywordGroups.find(x => x.keywordGroup !== editingKeywordGroup.keywordGroup && x.title === editingKeywordGroup.title)){
      appError(this.props.dispatch, "同じ音声認識キーワードグループ名がすでにあります。");
      return;
    }
    onLoading(this.props.dispatch);
    const success = await addKeywordAndUpdate(this.props.dispatch, this.props.user.TenantId, editingKeywordGroup);
    offLoading(this.props.dispatch);
    if(!success) return;
    this.setState({
      editingOriginalTitle: editingKeywordGroup.title,
      message: this.state.add ? "register" : "update",
      add: false,
    });
  }

  onDeleteKeywordConfirm = (e, item, index, mode) => {
    e.preventDefault();
    this.setState({
      isDeleteKeywordConfirmDialog: true,
      deleteItem: item,
      deleteKeywordIndex: index,
      deleteKeywordMode: mode,
    });
  }

  onDeleteKeyword = async (e) => {
    e.preventDefault();
    if(!this.state.deleteItem) return;
    const item = this.state.deleteItem;
    const index = this.state.deleteKeywordIndex;
    const mode = this.state.deleteKeywordMode;
    if (index >= 0) {
      if (mode === "keyword-in-side-menu") {
        const editingKeywordGroup = { ...item };
        const keywords = [ ...item.keywords ];
        keywords.splice(index, 1);
        changeEditingKeywordGroup(this.props.dispatch, { ...editingKeywordGroup, keywords: keywords }, true);
      } else if (mode === "keyword-in-dialog") {
        item.splice(index, 1);
      }
    }
    this.setState({
      isDeleteKeywordConfirmDialog: false,
      deleteItem: null,
      deleteKeywordIndex: null,
      deleteKeywordMode: null,
    });
  }

  onDeleteConfirm = (e, item) => {
    e.preventDefault();
    this.setState({
      isDeleteConfirmDialog: true,
      deleteItem: item,
      deleteTitle: this.state.editingOriginalTitle || item.title,
    });
  }

  onDeleteExecute = async (e) => {
    e.preventDefault();
    if(!this.state.deleteItem) return;
    onLoading(this.props.dispatch);
    await deleteKeywordGroup(this.props.dispatch, this.props.user.TenantId, this.state.deleteItem);
    offLoading(this.props.dispatch);
    this.setState({
      isDeleteConfirmDialog: false,
      deleteItem: null,
      deleteTitle: null,
    });
  }

  onEndEdit = async (values, save) => {
    if (this.isFormChanged()) return;
    if (save) {
      const editingKeywordGroup = {...values};
      if(!editingKeywordGroup.keywordGroup && this.props.keywordGroups.find(x => x.title === editingKeywordGroup.title)){
        appError(this.props.dispatch, "同じ音声認識キーワードグループ名がすでにあります。");
        return;
      }
      await updateKeywordGroup(this.props.dispatch, this.props.user.TenantId, editingKeywordGroup);
    } else {
      closeEditKeywordGroup(this.props.dispatch);
    }
    this.setState({
      editingOriginalTitle: null,
    });
  }

  onOpenKeywordListDialog = (e) => {
    e.preventDefault();
    const editingKeywordGroup = { ...this.props.formValues };
    editingKeywordGroup.searchSynonym = editingKeywordGroup.title;
    editingKeywordGroup.searchMorpheme = editingKeywordGroup.title;
    beginEditKeywords(this.props.dispatch, editingKeywordGroup);
    changeSearchSynonym(this.props.dispatch, this.props.user.TenantId, editingKeywordGroup);
    changeSearchMorpheme(this.props.dispatch, this.props.user.TenantId, editingKeywordGroup);
    this.setState({
      editingKeywordDialog: true,
      editingKeywordList: [ ...this.props.editingKeywordGroup.keywords ],
      preSynonymInput: editingKeywordGroup.title,
    });
  }

  onCloseKeywordListDialog = (e) => {
    e.preventDefault();
    const editingKeywordGroup = { ...this.props.editingKeywordGroup };
    editingKeywordGroup.searchSynonym = null;
    editingKeywordGroup.searchMorpheme = null;
    changeEditingKeywordGroup(this.props.dispatch, editingKeywordGroup, true);
    this.setState({
      editingKeywordDialog: false,
      preSynonymInput: null,
    });
  }

  onApplyKeyword = (e) => {
    e.preventDefault();
    const editingKeywordGroup = { ...this.props.formValues, keywords: this.state.editingKeywordList };
    editingKeywordGroup.searchSynonym = null;
    editingKeywordGroup.searchMorpheme = null;
    changeEditingKeywordGroup(this.props.dispatch, editingKeywordGroup, true);
    this.setState({
      editingKeywordDialog: false,
    });
  }

  onAddKeyword = () => {
    let tmpIndex = -1;
    for(let [i, m] of this.props.morphemeList.entries()){
      //console.log(i, m);
      if(m.selected){
        // console.log(i - tmpIndex);
        if(tmpIndex >= 0 && i - tmpIndex > 1){
          // console.log("連続していない");
          appError(this.props.dispatch, "連続していない要素が選択されているので追加できません");
          return;
        }
        tmpIndex = i;
      }
    }
    const morphemeList = [ ...this.props.morphemeList.filter(x => x.selected) ];
    // バリデーション
    for (let keywords of this.state.editingKeywordList) {
      if (keywords.length !== morphemeList.length) continue;
      let exists = true;
      morphemeList.forEach((token, i) => {
        let keyword = keywords[i];
        if (token.word !== keyword.word ||
            token.pos  !== keyword.pos  ||
            token.pos1 !== keyword.pos1 ||
            token.pos2 !== keyword.pos2 ||
            token.pos3 !== keyword.pos3) {
              exists = false;
              return;
        }
        exists = exists && true;
        return;
      });
      if (exists) {
        appError(this.props.dispatch, "同じキーワードが既に追加されています");
        return;
      }
    }
    this.setState({
      editingKeywordList: [ ...this.state.editingKeywordList, morphemeList ],
    });
  }

  onResetKeyword = async (e) => {
    e.preventDefault();
    const editingKeywordGroup = {...this.props.formValues };
    editingKeywordGroup.searchSynonym = editingKeywordGroup.title;
    editingKeywordGroup.searchMorpheme = editingKeywordGroup.title;
    const synonymInput = document.querySelector('#searchSynonym');
    const morphemeInput = document.querySelector('#searchMorpheme');
    if (synonymInput) {
      synonymInput.value = editingKeywordGroup.title;
    }
    if (morphemeInput) {
      morphemeInput.value = editingKeywordGroup.title;
    }
    onLoading(this.props.dispatch);
    await changeEditingKeywordGroup(this.props.dispatch, editingKeywordGroup, false);
    await changeSearchSynonym(this.props.dispatch, this.props.user.TenantId, editingKeywordGroup);
    await changeSearchMorpheme(this.props.dispatch, this.props.user.TenantId, editingKeywordGroup);
    offLoading(this.props.dispatch);
  }

  posArray = (item) => {
    if(!item) return [];
    return [item.pos, item.pos1, item.pos2, item.pos3];
  }

  isFormChanged = () => {
    if(this.props.editingKeywordGroup && (this.props.pristine === false || this.props.editingKeywordGroup.isEditingUpdate === true)){
      this.setState({ message: this.state.add ? "changing_new" : "changing_edit" });
      return true;
    }
    return false;
  }

  onTagClick = (e, tag) => {
    e.preventDefault();
    const searchInput = document.querySelector('#searchKeywordGroup');
    if (searchInput) {
      searchInput.value = tag;
    }
    searchKeywordGroup(this.props.dispatch, tag);
  }

  render() {
    const { handleSubmit, submitting, classes } = this.props;
    let addBtnDisabled = true;
    if(this.props.morphemeList && this.props.morphemeList.length){
      if(this.props.morphemeList.find(x => x.selected)){
        addBtnDisabled = false;
      }
    }
    return (
      <React.Fragment>
        <Auth />
        <React.Fragment>
          <TopBar />
          <div className={classes.content}>
            <SettingMenu />
            <div className={classes.main}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div className="titleText">音声認識キーワードグループ管理</div>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  <SearchTextField id="searchKeywordGroup" name="search" style={{ width: "280px" }} defaultValue={this.props.search} onChange={this.onSearch} />
                  {(this.props.user && this.props.user.isManager()) &&
                    <SecondaryButton onClick={(e) => this.onBeginEdit(e, {})} style={{ marginLeft: 5 }}>新規登録</SecondaryButton>
                  }
                </Grid>
              </Grid>
              <ErrorMessage />
              <UpdateMessage />
              { this.props.editingKeywordGroup === null &&
              <div className={classes.listContainer}>
                <EnhancedTable list={this.props.searchedKeywordGroups} classes={classes} selectKey="keywordGroup" editedId={this.props.editedId} operationMinWidth={80}
                  headCells={headCells} editCallback={(this.props.user && this.props.user.isManager()) ? this.onBeginEdit : null} deleteCallback={(this.props.user && this.props.user.isManager()) ? this.onDeleteConfirm : null}
                  displayTag tagClickCallback={this.onTagClick} />
              </div>
              }
              { this.props.editingKeywordGroup &&
              <form onSubmit={handleSubmit((values) => this.onUpdateExec(values))}>
              <div className={classes.listKeywordGroupEditContainer}>
                <div className={classes.listEditList} style={{ maxWidth: 439 }}>
                  <EnhancedTable list={this.props.searchedKeywordGroups} classes={classes} selectCallback={(this.props.user && this.props.user.isManager()) ? this.onBeginEdit : null}
                    headCells={editHeadCells} selectItem={this.props.editingKeywordGroup} selectKey="keywordGroup" focusItem={this.props.editingKeywordGroup} focusKey="keywordGroup" isOperationHidden={true}
                    displayTag tagClickCallback={this.onTagClick} />
                </div>
                <div className={classes.listEditEdit}>
                  <div className={classes.arrowAndSideMenuContainer}>
                    <div className={classes.listEditSpace}>
                      <img src="/images/left_arrow.png" alt="<" />
                    </div>
                    <div className={classes.insideEditContainer} style={{ width: 430 }}>
                      <div className="verticalCenter" style={{ justifyContent: "space-between" }}>
                        <div className="verticalCenter">
                          <BorderBackButton onClick={this.onEndEdit}/>
                          <span className="f16 subText ml8">音声認識キーワードグループ { this.state.add ? "新規登録" : "編集" }</span>
                        </div>
                        { (this.props.editingKeywordGroup.keywordGroup && this.props.user && this.props.user.isManager()) &&
                          <Delete24Button onClick={(e) => this.onDeleteConfirm(e, this.props.editingKeywordGroup)} />
                        }
                      </div>
                      <div className="verticalCenter mb15" style={{ marginTop: 15 }}>
                        <span className="contentEditLabel">グループ名</span>
                        <Field name="title" style={{ width: "100%" }} component={renderSimpleTextField} validate={[required]} onChange={this.onTitleChange} />
                      </div>
                      <div className="verticalCenter mb15" style={{ marginTop: 15 }}>
                        <span className="contentEditLabel">タグ(任意)</span>
                        <Field name="tag" style={{ width: "100%" }} component={renderSimpleTextField} placeholder="#タグ1 #タグ2" />
                      </div>
                      <div className={classes.keywordGroupInsideEditScroll + " verticalCenter mb15"} style={{ alignItems: "baseline" }}>
                        <div className="contentEditLabel">
                          <div className="f12">キーワード一覧</div>
                        </div>
                        <div style={{ width: "100%" }}>
                          { this.props.editingKeywordGroup && this.props.editingKeywordGroup.keywords.map((keyword, keywordsIndex, keywords) => {
                            return(
                              <div key={keywordsIndex} className={classes.keywordListContainer}>
                                <div style={{ margin: "3px 0" }}>
                                  <span className="keywordNumber">{ keywordsIndex + 1 }.</span>
                                </div>
                                <div className={classes.flexKeywordList}>
                                  <div>
                                    { keyword.map((word, wordIndex) => {
                                      return (
                                        <Tooltip key={wordIndex} placement="bottom" title={<span style={{ fontSize: "14px" }}>{this.posArray(word).join(", ")}</span>}>
                                          <span key={wordIndex} className={classes.keyword}>{ word && word.word }</span>
                                        </Tooltip>
                                      )
                                    }) }
                                  </div>
                                  { keywords.length > 1 &&
                                    <div style={{ minWidth: 60 }}>
                                      <KeywordDeleteButton onClick={(e) => this.onDeleteKeywordConfirm(e, this.props.editingKeywordGroup, keywordsIndex, 'keyword-in-side-menu')}>
                                        <span className="primaryText f10">[ 削除 ]</span>
                                      </KeywordDeleteButton>
                                    </div>
                                  }
                                </div>
                              </div>
                            )
                          }) }
                        <div style={{ textAlign: "right", marginRight: 15 }}>
                            <EditButton onClick={(e) => this.onOpenKeywordListDialog(e)} />
                          </div>
                        </div>
                      </div>
                      <div className="verticalCenter alignCenter" style={{ marginTop: 25 }}>
                        <GrayButton width={120} height={32} onClick={this.onCancel} style={{ marginRight: 20 }}>リセット</GrayButton>
                        <PrimaryButton type="submit" width={120} height={32} disabled={submitting}>{ this.state.add ? "登録する" : "更新する" }</PrimaryButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </form>
              }
            </div>
          </div>
          <StyledDialog open={this.state.isDeleteKeywordConfirmDialog} onClose={() => this.setState({ isDeleteKeywordConfirmDialog: false, deleteItem: null, deleteKeywordIndex: null, deleteKeywordMode: null })}>
            <DialogContent className={classes.dialogContent}>
              <div className={classes.closeButton}>
                <CloseButton onClick={() => this.setState({ isDeleteKeywordConfirmDialog: false, deleteItem: null, deleteKeywordIndex: null, deleteKeywordMode: null })}/>
              </div>
              <div className="deletePopupContentText">
                <div>削除しますか？</div>
                <div style={{ marginTop: 36 }}>
                  <PrimaryButton width={160} height={48} onClick={(e) => this.onDeleteKeyword(e)} disabled={this.props.loading}>削除する</PrimaryButton>
                  <GrayButton width={160} height={48} onClick={() => this.setState({ isDeleteKeywordConfirmDialog: false, deleteItem: null, deleteKeywordIndex: null, deleteKeywordMode: null })} style={{ marginLeft: 20 }}>キャンセル</GrayButton>
                </div>
              </div>
            </DialogContent>
          </StyledDialog>
          <StyledDialog open={this.state.isDeleteConfirmDialog} onClose={() => this.setState({ isDeleteConfirmDialog: false, deleteItem: null })}>
            <DialogContent className={classes.dialogContent}>
              <div className={classes.closeButton}>
                <CloseButton onClick={() => this.setState({ isDeleteConfirmDialog: false, deleteItem: null })}/>
              </div>
              <div className="deletePopupContentText">
                <div>
                  <div className="wordKeepAll">「{this.state.deleteTitle}</div>
                  <div className="wordKeepAllClose">」</div>
                  を削除しますか？
                </div>
                <div style={{ marginTop: 36 }}>
                  <PrimaryButton width={160} height={48} onClick={(e) => this.onDeleteExecute(e)} disabled={this.props.loading}>削除する</PrimaryButton>
                  <GrayButton width={160} height={48} onClick={() => this.setState({ isDeleteConfirmDialog: false, deleteItem: null })} style={{ marginLeft: 20 }}>キャンセル</GrayButton>
                </div>
              </div>
            </DialogContent>
          </StyledDialog>
          <StyledDialog open={this.state.editingKeywordDialog} onClose={this.onCloseKeywordListDialog} PaperProps={{ style: { width: 1152, minHeight: 776 } }}>
            <DialogContent className={classes.dialogContent}>
              <div className={classes.closeButton}>
                <CloseButton onClick={this.onCloseKeywordListDialog}/>
              </div>
              <div>
                <div style={{ width: "100%", textAlign: "center" }}>
                  <span className="f28">キーワード一覧 編集</span>
                </div>
                <div className={classes.insideEditContainer + " mb20"} style={{ width: 1024, height: 576, padding: "24px", display: "flex" }}>
                  <div style={{ flex: 3 }}>
                    <div className="verticalCenter mb15">
                      <span className="f24 subText">キーワード一覧</span>
                    </div>
                    <div style={{ maxHeight: "calc(100% - 51px)", overflowY: "auto" }}>
                      { this.state.editingKeywordList.map((keyword, keywordsIndex, keywords) => {
                        return (
                        <ListItem key={keywordsIndex} className={classes.keywordListContainer} style={{ alignItems: "center" }} divider>
                          <div>
                            <span className="f18L27 keywordNumber">{ keywordsIndex + 1 }.</span>
                          </div>
                          <div className={classes.flexKeywordList}>
                            <div>
                              { keyword.map((word, wordIndex) => {
                                return (
                                  <Tooltip key={wordIndex} placement="bottom" title={<span style={{ fontSize: "14px" }}>{this.posArray(word).join(", ")}</span>}>
                                    <span key={wordIndex} className={classes.keyword + " " + classes.keywordInsideDialog}>{ word && word.word }</span>
                                  </Tooltip>
                                )
                              }) }
                            </div>
                            { keywords.length > 1 &&
                              <div style={{ minWidth: 65 }}>
                                <KeywordDeleteButton onClick={(e) => this.onDeleteKeywordConfirm(e, this.state.editingKeywordList, keywordsIndex, 'keyword-in-dialog')}>
                                  <span className="primaryText f14">[ 削除 ]</span>
                                </KeywordDeleteButton>
                              </div>
                            }
                          </div>
                        </ListItem>
                        )
                      })}
                    </div>
                  </div>
                  <div className={classes.listEditSpace} style={{ marginTop: 0 }}>
                    <img src="/images/left_arrow.png" alt="<" />
                  </div>
                  <div style={{ flex: 2, backgroundColor: "#F2F2F2", borderRadius: 8, padding: 18, maxWidth: 418 }}>
                    <div className="verticalCenter mb15">
                      <span className="f16 subText">キーワード登録ツール</span>
                    </div>
                    <Grid container spacing={2} style={{marginTop: 15}}>
                      <Grid item xs={4} className="formLabel"><span>類義語検索</span></Grid>
                      <Grid item xs={8}>
                        <Field id="searchSynonym" name="searchSynonym" uncontrolled style={{ width: "100%" }} component={renderSimpleTextField} onChange={(e) => this.onSynonymChange(e)} InputProps={{ style: { backgroundColor: "#fff" }}} />
                      </Grid>
                      <Grid container spacing={2} className={classes.keywordGroupInsideEditScroll} style={{ marginLeft: 0, marginTop: 8, height: "350px" }}>
                        <Grid item xs={4} className="formLabel" style={{ display: "flex", alignItems: "baseline" }}><span className="synonymText">類義語一覧</span></Grid>
                        <Grid item xs={8}>
                          <div style={{ maxHeight: 240, overflowY: "auto" }}>
                            { this.props.synonymList.map((item, i) => {
                              return(
                                <React.Fragment key={i}>
                                  <SynonymButton onClick={(e) => this.onSynonymClick(e, item)}>
                                    <span className="primaryText" style={{ fontSize: 14, lineHeight: "30px", textDecoration: "underline" }}>{ item }</span>
                                  </SynonymButton>
                                  { this.props.synonymList.length - 1 > i &&
                                  <span>/</span>
                                  }
                                </React.Fragment>
                              )
                            }) }
                          </div>
                        </Grid>
                        <Grid item xs={4} className="formLabel"><span className="synonymText">形態素解析実行</span></Grid>
                        <Grid item xs={8}>
                          <Field id="searchMorpheme" name="searchMorpheme" uncontrolled style={{ width: "100%" }} component={renderSimpleTextField} onChange={(e) => this.onMorphemeChange(e)} InputProps={{ style: { backgroundColor: "#fff" }}} />
                        </Grid>
                        <Grid item xs={4} className="formLabel"><span className="synonymText">形態素解析結果</span></Grid>
                        <Grid item xs={8}></Grid>
                        <Grid item xs={12}>
                          <div>
                            { this.props.morphemeList.map((item, i) => {
                              let ComponentName = MorphButton;
                              if(item.selected){
                                ComponentName = MorphSelectedButton;
                              }
                              return(
                                <div key={i} style={{ marginTop: i === 0 ? 0 : 5 }}>
                                  <ComponentName style={{ width: "100%" }} onClick={(e) => this.onMorphemeSelectedChange(e, i, item)}>
                                    <span className={classes.keyword} style={{ height: 14 }}>{ item.word }</span>
                                    <span className={classes.keywordToken}>{ this.posArray(item).join(", ") }</span>
                                  </ComponentName>
                                </div>
                              )
                            }) }
                          </div>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: "15px", textAlign: "center" }}>
                          <div><span className="f12">上記結果を選択したものをAND条件で</span></div>
                          <div><span className="f12">音声認識キーワード一覧へ</span></div>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: "15px", textAlign: "center" }}>
                        <GrayButton width={120} height={32} onClick={this.onResetKeyword} style={{ marginRight: 20 }}>リセット</GrayButton>
                        <PrimaryButton width={120} height={32} onClick={this.onAddKeyword} disabled={this.props.loading || addBtnDisabled}>追加する</PrimaryButton>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <div className="alignCenter" >
                  <GrayButton width={120} height={32} onClick={this.onCloseKeywordListDialog}>キャンセル</GrayButton>
                  <PrimaryButton width={120} height={32} onClick={this.onApplyKeyword} style={{ marginLeft: 20 }}>更新する</PrimaryButton>
                </div>
              </div>
            </DialogContent>
          </StyledDialog>
        </React.Fragment>
      </React.Fragment>
    )
  }
}


export default withRouter(withStyles(muiStyles)(
  connect(state => ({
    user: state.user && state.user.user,
    company: state.main && state.main.company,
    keywordGroups: state.keywordGroups && state.keywordGroups.keywordGroups,
    searchedKeywordGroups: state.keywordGroups && state.keywordGroups.searchedKeywordGroups,
    search: state.keywordGroups && state.keywordGroups.search,
    editingKeywordGroup: state.keywordGroups && state.keywordGroups.editingKeywordGroup,
    editedId: state.keywordGroups && state.keywordGroups.editedId,
    synonymList: (state.keywordGroups && state.keywordGroups.synonymList) || [],
    morphemeList: (state.keywordGroups && state.keywordGroups.morphemeList) || [],
    initialValues: state.keywordGroups && state.keywordGroups.editingKeywordGroup,
    formValues: getFormValues("keywordGroupForm")(state),
    isRefresh: state.keywordGroups && state.keywordGroups.isRefresh,
    loading: state.loading && state.loading.loading,
  }))(reduxForm({ form: "keywordGroupForm", enableReinitialize: true })(KeywordGroups))
));
