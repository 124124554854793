import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import firebase from 'firebase/app';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { green, red, blue } from '@material-ui/core/colors';
import Login from './login';
import Main from './main';
import GuidePicture from './guide';
import Phrases from './phrases';
import FaceDetectionPhrases from './faceDetectionPhrases';
import Motion from './motions';
import Model from './models';
import CommandGroups from './commandsGroups';
import createFinalStore from './store';
import Characters from './characters';
import CharacterForm from './characterForm';
import Account from './accounts';
import ManualCommands from './manualCommands';
import Greetings from './greetings';
import ApprovalRequests from './approvalRequests';
import Vguards from './vguards';
import AddAccount from './addAccount';
import KeywordGroups from './keywordGroups';
import Contents from './contents';
import ExternalCommands from './externalCommands';
import env from './utilities/env';
import { NotFound } from './notFound';

export default class App extends Component {

  constructor(props) {
    super(props);
    this.store = createFinalStore();
    this.theme = createMuiTheme({
      palette: {
        primary: {
          main: "#38496D",
          dark: "#4360AA",
        },
        sub: {
          main: "#6781B8",
          dark: "#597DDA",
        },
        secondary: {
          main: "#FFB72C",
          dark: "#FFE456"
        },
        white: {
          main: "#fff",
          dark: "#cccccc"
        },
        gray: {
          main: "#aaaaaa",
          dark: "#cccccc",
          light: "#eeeeee",
        },
        green: {
          main: "#58D16D",
          dark: "#38B14D"
        },
        red: {
          main: "#E14B43",
          dark: "#C11B13",
        },
        action: {
          disabledBackground: "#aaaaaa",
        },
        text: {
          main: "#444444"
        },
        disabled: {
          light: "#E0E0E0",
          main: "#D3D3D3",
        },

        info: {
          light: blue[100],
          main: blue[400],
        },
        success: {
          light: green[100],
          main: green[400],
        },
        warning: {
          main: "#ffc400",
        },
        error: {
          main: red[600],
        },
      },
      typography: {
        fontSize: 12,
        button: {
            textTransform: "none"
        }
      },
      props: {
        MuiFormControl: {
          variant: "outlined",
          size: "small",
        },
      },
      overrides: {
        MuiButton: {
          root: {
            '&:hover.Mui-disabled': {
              backgroundColor: "#D3D3D3 !important", // theme.pallete.disabled.mainと同じ
            },
          },
        },
        MuiTableCell: {
          root: {
            color: "inherit",
            borderBottom: "none",
            fontFamily: "inherit",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: 12,
            lineHeight: "18px",
          },
          body: {
            color: "inherit",
          },
          head: {
            color: "inherit",
          }
        },
        MuiPaper: {
          root: {
            color: "#444"
          }
        },
        MuiFormHelperText: {
          root: {
            '&.Mui-error': {
              fontWeight: 700,
            },
          },
          contained: {
            margin: "2px 14px 0",
          },
        },
      }
    });
    const firebaseConfig = {
      apiKey: env('API_KEY'),
      authDomain: env('AUTH_DOMAIN'),
      databaseURL: env('DATABASE_URL'),
      projectId: env('PROJECT_ID'),
      storageBucket: env('STORAGE_BUCKET'),
      messagingSenderId: env('MESSAGING_SENDER_ID'),
      appId: env('APP_ID')
    };
    firebase.initializeApp(firebaseConfig);
  }

  render() {
    return (
      <ThemeProvider theme={this.theme}>
        <Provider store={this.store}>
          <BrowserRouter>
            <Switch>
              <Route exact path='/:cid/login'>
                <Login />
              </Route>
              <Route exact path="/:cid">
                <Main />
              </Route>
              <Route exact path='/:cid/characters'>
                <Characters />
              </Route>
              <Route exact path='/:cid/characterForm'>
                <CharacterForm />
              </Route>
              <Route exact path='/:cid/characterForm/:character'>
                <CharacterForm />
              </Route>
              <Route exact path="/:cid/guild-pictures">
                <GuidePicture />
              </Route>
              <Route exact path="/:cid/phrases">
                <Phrases />
              </Route>
              <Route exact path="/:cid/faceDetectionPhrases">
                <FaceDetectionPhrases />
              </Route>
              <Route exact path="/:cid/motions">
                <Motion />
              </Route>
              <Route exact path="/:cid/models">
                <Model />
              </Route>
              <Route exact path="/:cid/manualCommandsGroups">
                <CommandGroups />
              </Route>
              <Route exact path="/:cid/accounts">
                <Account />
              </Route>
              <Route exact path="/:cid/approvalRequests">
                <ApprovalRequests />
              </Route>
              <Route exact path='/:cid/manualCommands/:character'>
                <ManualCommands />
              </Route>
              <Route exact path='/:cid/greetings/:character'>
                <Greetings />
              </Route>
              <Route exact path='/:cid/externalCommands/:character'>
                <ExternalCommands />
              </Route>
              <Route exact path='/:cid/contents/:character'>
                <Contents />
              </Route>
              <Route exact path='/:cid/vguards'>
                <Vguards />
              </Route>
              <Route exact path='/:cid/addAccount'>
                <AddAccount />
              </Route>
              <Route exact path='/:cid/keywordGroups'>
                <KeywordGroups />
              </Route>
              <Route>
                <NotFound />
              </Route>
            </Switch>
          </BrowserRouter>
        </Provider>
      </ThemeProvider>
    )
  }

}
