import { searchByTag } from "../utilities/utils";
import { MODEL_SPEAKERS } from "./phrases";
import { PHRASE_TYPE_ORGANIZATION, PHRASE_TYPE_NAME, PHRASE_TYPE_TITLE } from '../faceDetectionPhrases';

const defaultState = {
  list: [],
  searchedList: [],
  search: "",
  searchModel: "1",
  searchPhraseType: PHRASE_TYPE_ORGANIZATION.key,
  editing: {},
  isFirstMorphRequest: true,
  isRefresh: false,
  interlanguageTitle: "",
  playPhraseUrl: "",
  synthesizedPhrase: null,
  listCount: { organization: 0, name: 0, title: 0 },
};

function defaultItem(){
  return {
    faceDetectionPhrase: null,
    phraseType: 'organization',
    phraseTitle: '',
    interlanguage: [],
    // interlanguageTitle: '',
    phraseLanguage: "ja",
    phraseMd5: null,
    modelRef: null,
    phrasePath: null,
    createdAt: '',
    updatedAt: '',
    modelID: "all_adapt",
    speakerID: null,
  }
}

function searchList(list, search, model, phraseType) {
  let searchedList = [ ...list ];
  searchedList = searchedList.filter(x => x.modelRef.model === model && x.phraseType === phraseType);
  if (search) {
    searchedList = searchByTag(searchedList, 'phraseTitle', search);
  }
  return searchedList;
}

function searchListCount(list, model) {
  const listCount = {
    organization: list.filter(x => x.modelRef.model === model && x.phraseType === PHRASE_TYPE_ORGANIZATION.key).length,
    name: list.filter(x => x.modelRef.model === model && x.phraseType === PHRASE_TYPE_NAME.key).length,
    title: list.filter(x => x.modelRef.model === model && x.phraseType === PHRASE_TYPE_TITLE.key).length,
  }
  return listCount;
}

const faceDetectionPhrases = (state = defaultState, action) => {
  let editing;
  switch (action.type) {
    case 'FACE_DETECTION_LIST':
      return {
        ...state,
        list: action.list,
        searchedList: searchList(action.list, state.search, state.searchModel, state.searchPhraseType),
        models: action.models,
        editing: null,
        isRefresh: false,
        listCount: searchListCount(action.list, state.searchModel),
      }
    case 'SEARCH_FACE_DETECTION_PHRASE':
      return {
        ...state,
        search: action.payload.search,
        searchModel: action.payload.searchModel,
        searchPhraseType: action.payload.phraseType,
        searchedList: searchList(state.list, action.payload.search, action.payload.searchModel, action.payload.phraseType),
        listCount: searchListCount(state.list, action.payload.searchModel),
      };
    case 'FACE_DETECTION_BEGIN_EDIT':
      editing = state.list.find((item) => (
        item.faceDetectionPhrase === action.itemId
      ));
      if(editing){
        editing = {...defaultItem(), ...editing};
        // 先にspeakerIDとmodelIDをセットする
        editing.speakerID = editing.modelRef && editing.modelRef.ttsSpeakerId;
        editing.modelID = editing.modelRef && editing.modelRef.ttsModelId;
        editing.modelRef = editing.modelRef && editing.modelRef.model;
        if(!editing.speakerID){
          // 古いデータ用補正
          editing.speakerID = MODEL_SPEAKERS[editing.modelRef].speakerID;
          editing.modelID = MODEL_SPEAKERS[editing.modelRef].modelID;
        }
      } else {
        editing = defaultItem();
        editing.modelRef = action.model;
        const model = state.models.find(m => (
          m.model === editing.modelRef
        ));
        editing.speakerID = model.ttsSpeakerId;
        editing.modelID = model.ttsModelId;
        editing.phraseType = action.phraseType;
        if(!editing.speakerID){
          // 古いデータ用補正
          editing.speakerID = MODEL_SPEAKERS[editing.modelRef].speakerID;
          editing.modelID = MODEL_SPEAKERS[editing.modelRef].modelID;
        }
      }
      // console.log(editing)
      return {
        ...state,
        editing: editing,
        isFirstMorphRequest: true,
        interlanguageTitle: editing.interlanguage.map(x => x[0]).join(""),
      };
    case 'FACE_DETECTION_EDIT':
      editing = {...action.editing};
      const modelData = state.models.find((m) => (
        m.model === editing.modelRef
      ));
      editing.speakerID = modelData && modelData.ttsSpeakerId;
      editing.modelID = modelData && modelData.ttsModelId;
      if(!editing.speakerID){
        // 古いデータ用補正
        editing.speakerID = MODEL_SPEAKERS[editing.modelRef].speakerID;
        editing.modelID = MODEL_SPEAKERS[editing.modelRef].modelID;
      }
      return {
        ...state,
        editing: editing,
        interlanguageTitle: action.isChangeTitle ? editing.phraseTitle : state.interlanguageTitle,
        isRefresh: false,
      };
    case "FACE_DETECTION_PHRASE_GET_MORPH":
      return {
        ...state,
        isFirstMorphRequest: false,
      }
    case "FACE_DETECTION_PHRASE_SYNTHESIZE":
      return {
        ...state,
        editing: null,
        isRefresh: true,
      }
    case "FACE_DETECTION_PHRASE_DELETED":
      return {
        ...state,
        isRefresh: true,
      }
    case "FACE_DETECTION_PHRASE_PLAY_URL":
      return {
        ...state,
        playPhraseUrl: action.playPhraseUrl,
      }
    case "FACE_SYNTHESIZED_FACE_DETECTION_PHRASE":
      return {
        ...state,
        synthesizedPhrase: action.synthesizedPhrase,
      }
    case "FACE_DETECTION_PHRASE_ALL_COPY":
      return {
        ...state,
        isRefresh: true,
      }
    case "FACE_DETECTION_PHRASE_REFRESH":
      return {
        ...state,
        editing: null,
        isRefresh: true,
      }
    default:
      return state;
  }
}

export default faceDetectionPhrases;
