import db, { getKeywordGroupsData, getPhrasesData, getPicturesData, getDocData, getDocsOrderBy, getMotionsDataExcludeNod, getModelsData } from "../utilities/db";
import firebase from 'firebase/app';
import { appErrorAction, dbSetDataFailedErrorAction } from './appAction';
import { getDocsData } from '../utilities/db';
import { MORPH_PARSER } from "./keywordGroups";
import * as d3 from 'd3';
import { checkModel, formatTag } from "../utilities/utils";
import { characterExists, characterNotFoundErrorAction } from "./characters";

export const MOTION_SHAGAMU = "しゃがむ";
export const CONTENT_TYPE_SUCCESS = "success";
export const CONTENT_TYPE_SILENT = "silent";
export const CONTENT_TYPE_REJECT = "reject";
export const CONTENT_TYPE_RANGE_OUT = "rangeOut";
export const CONTENT_TYPE_RETRY_OVER = "retryOver";
export const CONTENT_TYPE_CALL_SUCCESS = "call_success";
export const CONTENT_TYPE_CALL_FAIL = "call_fail";
export const CONTENT_TYPE_CALL_REJECT = "call_reject";
export const CONTENT_TYPES = [
  { key: CONTENT_TYPE_SUCCESS, value: "音声認識 - 成功", collectionName: "sttPathFilters", typeString: CONTENT_TYPE_SUCCESS },
  { key: CONTENT_TYPE_SILENT, value: "音声認識 - 無発話", collectionName: "sttPathFilters", typeString: CONTENT_TYPE_SILENT },
  { key: CONTENT_TYPE_REJECT, value: "音声認識 - 不認識", collectionName: "sttPathFilters", typeString: CONTENT_TYPE_REJECT },
  { key: CONTENT_TYPE_RANGE_OUT, value: "退出時", collectionName: "cameraPathFilters", typeString: CONTENT_TYPE_RANGE_OUT },
  { key: CONTENT_TYPE_RETRY_OVER, value: "リトライオーバー時", collectionName: "circuitBreakerPathFilters", typeString: CONTENT_TYPE_RETRY_OVER },
  { key: CONTENT_TYPE_CALL_SUCCESS, value: "呼出成功時", collectionName: "callPathFilters", typeString: "success" },
  { key: CONTENT_TYPE_CALL_FAIL, value: "呼出失敗時", collectionName: "callPathFilters", typeString: "fail" },
  { key: CONTENT_TYPE_CALL_REJECT, value: "呼出拒否時", collectionName: "callPathFilters", typeString: "reject" },
];
const FILTER_COLLECTIONS = ["sttPathFilters", "cameraPathFilters", "callPathFilters", "circuitBreakerPathFilters"];
export const PICTURE_RETAIN_ID = "stay";

// function getStanbyRelation(content, paths, currentContent, depth=0){
//   const currentPath = paths.find(x => x.currentContent.id === currentContent.id);
//   if(!currentPath) return false;
//   const nextContent = currentPath.nextContents.find(x => x.id === content);
//   if(nextContent){
//     return true;
//   }
//   if(depth >= 10) return false;
//   for(let nextContent of currentPath.nextContents){
//     return getStanbyRelation(content, paths, nextContent, ++depth);
//   }
//   return false;
// }

export async function listContents(dispatch, cid, label, character, characterNotFoundCallback, isInit=false) {
  try {
    const companyDoc = db().collection("companies").doc(cid);
    const characterDoc = companyDoc.collection("labels").doc(label).collection("characters").doc(character);
    const contents = await (await getDocsOrderBy(characterDoc.collection("contents"), "content", [{ column: "updatedAt", type: "desc" }]));
    const [models, characterData, keywordGroups, phrases, motions, sttPathFilters, cameraPathFilters, callPathFilters, circuitBreakerPathFilters] = await Promise.all([
        getModelsData(companyDoc),
        getDocData(characterDoc, "character"),
        getKeywordGroupsData(companyDoc.collection("parser").doc(MORPH_PARSER)),
        getPhrasesData(companyDoc),
        getMotionsDataExcludeNod(companyDoc),
        getDocsData(characterDoc.collection("sttPathFilters"), "filter"),
        getDocsData(characterDoc.collection("cameraPathFilters"), "filter"),
        getDocsData(characterDoc.collection("callPathFilters"), "filter"),
        getDocsData(characterDoc.collection("circuitBreakerPathFilters"), "filter"),
    ]);
    if (characterData === null) {
      dispatch(characterNotFoundErrorAction());
      characterNotFoundCallback();
      return;
    }
    sttPathFilters.forEach(filter => {
      if(filter.type === "success"){
        filter.keywordGroups = filter.keywordGroups.map(keywordGroup => {
          return keywordGroups.find(x => x.keywordGroup === keywordGroup.id);
        });
      }
    });
    phrases.forEach(item => { if(item.modelRef) item.modelRef = models.find(x => x.model === item.modelRef.id) });
    motions.forEach(item => { if(item.modelRef) item.modelRef = models.find(x => x.model === item.modelRef.id) });
    let filterCollections = [
      { collection: "sttPathFilters", list: sttPathFilters },
      { collection: "cameraPathFilters", list: cameraPathFilters },
      { collection: "callPathFilters", list: callPathFilters },
      { collection: "circuitBreakerPathFilters", list: circuitBreakerPathFilters },
    ]
    const list = contents.map(item => {
      item.stanbyFlag = characterData.stanbyContentRefs.some(x => x.id === item.content);
      item.phraseRef = item.phraseRef && phrases.find(x => x.phrase === item.phraseRef.id);
      item.motionRef = item.motionRef && motions.find(x => x.motion === item.motionRef.id);
      item.keywordGroups = [];
      item.contentType = null;
      for(let filterCollection of filterCollections){
        const collection = filterCollection.collection;
        for(let filter of filterCollection.list){
          const contentFilter = filter.contents.find(x => x.id === item.content);
          if(contentFilter){
            if(collection === "callPathFilters"){
              item.contentType = "call_" + filter.type;
            } else {
              item.contentType = filter.type;
            }
            break;
          }
        }
        if(item.contentType) break;
      }
      for(let filter of sttPathFilters){
        const contentFilter = filter.contents.find(x => x.id === item.content);
        if(contentFilter){
          item.contentType = filter.type;
          if(filter.type === CONTENT_TYPE_SUCCESS){
            item.keywordGroups.push(filter.keywordGroups);
          }
        }
      }
      return item;
    });
    // モデルと設定されているフレーズやモーションが異なるか確認
    checkModel(list, characterData.modelRef.id);
    dispatch({
      type: "CONTETNT_LIST",
      payload: {
        list: list,
        character: characterData,
        isInit: isInit,
      }
    });
  } catch (e) {
    dispatch(appErrorAction(e));
  }
}

export function searchContent(dispatch, search, searchStanbyFlag, searchContentType){
  dispatch({
    type: "CONTETNT_SEARCH",
    payload: {
      search: search,
      searchStanbyFlag: searchStanbyFlag,
      searchContentType: searchContentType,
    }
  });
}


export async function beginEditContent(dispatch, cid, label, character, content){
  const companyDoc = db().collection("companies").doc(cid);
  const labelDoc = companyDoc.collection("labels").doc(label);
  const characterDoc = labelDoc.collection("characters").doc(character);
  let data = {
    phrases: null,
    motions: null,
    pictures: null,
    keywrodGroups: null,
    character: null,
    paths: null,
    sttPathFilters: null,
    cameraPathFilters: null,
    callPathFilters: null,
    circuitBreakerPathFilters: null,
  }
  let par = [
    getPhrasesData(companyDoc),
    getMotionsDataExcludeNod(companyDoc),
    getPicturesData(labelDoc),
    getKeywordGroupsData(companyDoc.collection("parser").doc(MORPH_PARSER)),
    getDocData(characterDoc, "character"),
  ];
  if(content){
    const contentDoc = characterDoc.collection("contents").doc(content);
    par = [
      ...par,
      getDocsData(characterDoc.collection("paths").where("currentContent", "==", contentDoc), "path"),
      characterDoc.collection("sttPathFilters").where("contents", "array-contains", contentDoc).get(),
      characterDoc.collection("cameraPathFilters").where("contents", "array-contains", contentDoc).get(),
      characterDoc.collection("callPathFilters").where("contents", "array-contains", contentDoc).get(),
      characterDoc.collection("circuitBreakerPathFilters").where("contents", "array-contains", contentDoc).get(),
    ];
  }
  const items = await Promise.all(par);
  Object.keys(data).forEach((key, i) => data[key] = items[i]);
  // console.log(data);
  let contentType = CONTENT_TYPE_SUCCESS
  if(content){
    let filter, collection;
    for(let collectionName of FILTER_COLLECTIONS){
      if(data[collectionName].docs.length){
        filter = data[collectionName].docs[0];
        collection = collectionName;
        break;
      }
    }
    if(filter){
      const contentTypeData = CONTENT_TYPES.find(x => x.collectionName === collection && x.typeString === filter.data().type);
      if(contentTypeData){
        contentType = contentTypeData.key;
      }
    }
  }
  dispatch({
    type: "CONTENT_BEGIN_EDIT",
    payload: {
      content: content,
      contentType: contentType,
      phrases: data.phrases,
      motions: data.motions,
      pictures: data.pictures,
      keywrodGroups: data.keywrodGroups,
      character: data.character,
      paths: data.paths,
    }
  });
}

function createNetwork(array, added, contents, paths, targetContentId, depth){
  const path = paths.find(x => x.currentContent.id === targetContentId);
  const content = contents.find(x => x.content === targetContentId);
  if(!path) return;
  if(added.indexOf(targetContentId) >= 0){
    return;
  }
  depth = ++depth;
  path.nextContents.forEach(nextContent => {
    const target = contents.find(x => x.content === nextContent.id);
    added.push(targetContentId);
    array.push({ source: content.title, target: target.title, sourceData: content, targetData: target, depth: depth, stanbyFlag: content.stanbyFlag });
    createNetwork(array, added, contents, paths, nextContent.id, depth);
  });
}

export function showPathNetworkAll(dispatch, contents, paths){
  const array = [];
  contents.forEach(content => {
      const added = [];
      createNetwork(array, added, contents, paths, content.content, 0);
  });
  // console.log(array);
  const pathNetwork = d3.nest()
    .key(d => { return d.source + d.target })
    .entries(array)
    .map(d => {
      const s = d.values[0];
      return {
        ...s,
        depth: d3.max(d.values, (value) => value.depth),
      }
    });
  // console.log(pathNetwork);
  dispatch({
    type: "CONTENT_PATH_NETWORK",
    pathNetwork: pathNetwork
  });
}

export function showPathNetwork(dispatch, content, contents, paths){
  const array = [];
  createNetwork(array, [], contents, paths, content, 0);
  paths.filter(x => x.nextContents.find(d => d.id === content)).forEach(path => {
    createNetwork(array, [], contents, paths, path.currentContent.id, 0);
  });
  const pathNetwork = d3.nest()
    .key(d => { return d.source + d.target })
    .entries(array)
    .map(d => {
      const s = d.values[0];
      return {
        ...s,
        depth: d3.max(d.values, (value) => value.depth),
      }
    });
  // console.log(pathNetwork);
  dispatch({
    type: "CONTENT_PATH_NETWORK",
    pathNetwork: pathNetwork
  });
}

export async function changeEditingContent(dispatch, editingContent){
  dispatch({
    type: "CONTENT_EDITING_CHANGE",
    payload: {
      editingContent: editingContent,
    }
  });
}

export async function beginSelectKeywordGroup(dispatch, cid, keywordGroups,){
  const companyDoc = db().collection("companies").doc(cid);
  const keywordGroupList = await getKeywordGroupsData(companyDoc.collection("parser").doc(MORPH_PARSER));
  dispatch({
      type: "CONTENT_BEGIN_SELECT_KEYWORD_GROUP",
      payload: {
        keywordGroups: keywordGroups,
        keywordGroupList: keywordGroupList
      }
    }
  );
}

export function changeSelectKeywordGroup(dispatch, selectKeywordGroup, editingContent){
  dispatch({
    type: "CONTENT_CHANGE_SELECT_KEYWORD_GROUP",
    payload: {
      selectKeywordGroup: selectKeywordGroup,
      editingContent: editingContent,
    }
  });
}

export function selectContent(dispatch, contents, content){
  dispatch({
    type: "CONTENT_SELECT_CONTENT",
    payload: {
      content: content,
    }
  })
}

export async function closeContent(dispatch){
  dispatch({ type: "CONTENT_CLOSE" });
}

export function cancelEditContent(dispatch, contentType){
  dispatch({
    type: "CONTENT_EDIT_CANCEL",
    contentType: contentType,
  })
}

export async function updateContentExec(dispatch, cid, label, character, editingContent, characterNotFoundCallback){
  // console.log(cid, label, character, editingContent);
  const companyDoc = db().collection("companies").doc(cid)
  const labelDoc = companyDoc.collection("labels").doc(label);
  const characterDoc = labelDoc.collection("characters").doc(character);
  let contentDoc;
  try{
    // console.log(editingContent);
    const content = {
      title: editingContent.title,
      tag: formatTag(editingContent.tag),
      phraseRef: editingContent.phraseRef ? companyDoc.collection("phrases").doc(editingContent.phraseRef) : null,
      motionRef: editingContent.motionRef ? companyDoc.collection("motions").doc(editingContent.motionRef) : null,
      pictureRef: editingContent.pictureRef && editingContent.pictureRef !== PICTURE_RETAIN_ID ? labelDoc.collection("guide-pictures").doc(editingContent.pictureRef) : null,
      pictureRetainFlag: editingContent.pictureRef === PICTURE_RETAIN_ID,
      childTallMotionRef: editingContent.childTallMotionRef ? companyDoc.collection("motions").doc(editingContent.childTallMotionRef) : null,
      playType: editingContent.playType,
      callFlag: editingContent.callFlag,
      emergencyFlag: editingContent.callFlag ? editingContent.emergencyFlag : false,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };
    // のちの処理用にコンテンツ更新前にキャラクターデータを取得
    const chara = await getDocData(characterDoc, "character");
    if (chara === null) {
      dispatch(characterNotFoundErrorAction());
      characterNotFoundCallback();
      return false;
    }
    if(!editingContent.content){
      // 新規
      contentDoc = characterDoc.collection("contents").doc();
      content.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      await contentDoc.set(content);
    } else {
      // 更新
      contentDoc = characterDoc.collection("contents").doc(editingContent.content);
      const exists = await contentExists(dispatch, contentDoc);
      if (!exists) return false;
      await contentDoc.update(content);
    }

    const batch = db().batch();
    // 待受状態の更新
    if(editingContent.type !== CONTENT_TYPE_SUCCESS){
      editingContent.stanbyFlag = false;
    }
    if(editingContent.stanbyFlag){
      // 待受け状態ONで追加されていない場合は追加
      if(!chara.stanbyContentRefs.find(x => x.id === contentDoc.id)){
        batch.update(characterDoc, {
          stanbyContentRefs: [...chara.stanbyContentRefs, contentDoc]
        });
      }
    } else {
      // 待受け状態OFFで追加されている場合は削除
      if(chara.stanbyContentRefs.find(x => x.id === contentDoc.id)){
        // 追加されていない場合は追加
        batch.update(characterDoc, {
          stanbyContentRefs: chara.stanbyContentRefs.filter(x => x.id !== contentDoc.id)
        });
      }
    }

    const paths = await characterDoc.collection("paths").where("currentContent", "==", contentDoc).get();
    const path = {
      currentContent: contentDoc,
      nextContents: editingContent.nextContentRefs.filter(x => x.length > 0).map(nextContentId => {
        return characterDoc.collection("contents").doc(nextContentId);
      }),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    }
    if(editingContent.type === CONTENT_TYPE_RANGE_OUT){
      // 退出時の次のシナリオはないので空にする
      path.nextContents = [];
    }
    let pathDoc;
    if(!paths.empty){
      // 経路がすでにある場合
      pathDoc = paths.docs[0].ref;
      batch.update(pathDoc, path);
    } else {
      // 経路がない場合
      pathDoc = characterDoc.collection("paths").doc();
      path.createdAt =  firebase.firestore.FieldValue.serverTimestamp();
      batch.set(pathDoc, path);
    }

    // 選択されたtypeにより更新するコレクションが違う
    if(editingContent.type === CONTENT_TYPE_SUCCESS){
      // 設定されたキーワードグループ群の行数分
      const filters = await characterDoc.collection("sttPathFilters").where("type", "==", CONTENT_TYPE_SUCCESS).get();
      for(let keywordGroup of editingContent.keywordGroups){
        // console.log(keywordGroup)
        // 既存の組み合わせか確認
        let filterSnap;
        for(let filter of filters.docs){
          // console.log(filter.data().keywordGroups);
          if(filter.data().keywordGroups.length !== keywordGroup.length){
            continue;
          }
          let containFlg = true;
          for(let item of keywordGroup){
            containFlg = filter.data().keywordGroups.some(x => x.id === item.keywordGroup);
            if(!containFlg) break;
            // keywordGroup.isContain = filterKeywordGroup.some(x => x.id === )
          }
          if(containFlg) filterSnap = filter;
        }
        // console.log(keywordGroup, filterDoc);
        if(filterSnap){
          // 同じkeywordGroupsのデータがあるのでコンテンツに含まれているか確認
          if(!filterSnap.data().contents.find(x => x.id === contentDoc.id)){
            const filterData = {
              contents: [...filterSnap.data().contents, contentDoc],
              updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            };
            batch.update(filterSnap.ref, filterData);
          }
        } else {
          // sttPathFiltersを追加
          const filterDoc = characterDoc.collection("sttPathFilters").doc();
          const parserDoc = companyDoc.collection("parser").doc(MORPH_PARSER);
          const filterData = {
            type: CONTENT_TYPE_SUCCESS,
            keywordGroups: keywordGroup.map(k => {
                return parserDoc.collection("keywordGroups").doc(k.keywordGroup)
            }),
            contents: [contentDoc],
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          };
          batch.set(filterDoc, filterData);
        }
      }
      // 変更前のcontents.keywordGroupsから削除があった場合はsttPathFilters.contentsからも削除しないといけない
      // 削除用にデータを取得しておく
      const contentFilters = await characterDoc.collection("sttPathFilters").where("type", "==", CONTENT_TYPE_SUCCESS).where("contents", "array-contains", contentDoc).get();
      // console.log(editingContent.keywordGroups);
      // console.log(contentFilters.docs.map(x => x.data().keywordGroups));
      for(let filter of contentFilters.docs){
        let isDelete = true;
        for(let keywordGroup of editingContent.keywordGroups){
          if(filter.data().keywordGroups.length !== keywordGroup.length){
            continue;
          }
          let containFlg = true;
          for(let item of keywordGroup){
            containFlg = filter.data().keywordGroups.some(x => x.id === item.keywordGroup);
            if(!containFlg) break;
          }
          if(containFlg){
            isDelete = false;
          }
        }
        if(isDelete){
          if(filter.data().contents.length === 1){
            // contentsが1つの場合はcollectionごと削除
            batch.delete(filter.ref);
          } else {
            // contentsから更新対象のcontentを削除
            batch.update(filter.ref, {
              contents: filter.data().contents.filter(x => x.id !== contentDoc.id),
              updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            });
          }
        }
      }
    }

    // コンテンツタイプの変更はないので、新規かつ音声認識成功でない場合のみデータ更新を行う
    if(!editingContent.content && editingContent.type !== CONTENT_TYPE_SUCCESS){
      const contentType = CONTENT_TYPES.find(x => x.key === editingContent.type);
      const filters = await characterDoc.collection(contentType.collectionName).where("type", "==", contentType.typeString).get();
      if(filters.docs.length){
        // すでにある場合
        const filterSnap = filters.docs[0];
        batch.update(filterSnap.ref, {
          contents: [...filterSnap.data().contents, contentDoc],
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
      } else {
        const filterDoc = characterDoc.collection(contentType.collectionName).doc();
        const data = {
          type: contentType.typeString,
          contents: [contentDoc],
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        }
        if(editingContent.type === CONTENT_TYPE_SILENT || editingContent.type === CONTENT_TYPE_REJECT){
          data.keywordGroups = [];
        }
        batch.set(filterDoc, data);
      }
    }
    await batch.commit();
    const updated = await contentDoc.get();
    if (!updated.exists) {
      dispatch(contentNotFoundErrorAction());
      dispatch(refreshContentAction());
      return false;
    }
    editingContent.tag = updated.data().tag;
    editingContent.createdAt = updated.data().createdAt;
    editingContent.updatedAt = updated.data().updatedAt;
    editingContent.content = contentDoc.id;
    dispatch({
      type: "CONTENT_UPDATED",
      editing: editingContent,
      paths: await getDocsData(characterDoc.collection("paths").where("currentContent", "==", contentDoc), "path"),
    });
    return true;
  } catch(e) {
    if(contentDoc && contentDoc.id && !editingContent.content){
      // 新規登録でコンテンツを作成済みの場合は削除
      await contentDoc.delete();
    }
    // セキュリティールールありだとpermission-denied、なしだとnot-foundエラーが返る
    if (e && e.name === 'FirebaseError' && (e.code === 'permission-denied' || e.code === 'not-found')) {
      const exists = await characterExists(dispatch, cid, label, character);
      if (!exists) {
        characterNotFoundCallback();
        return false;
      }
      if (editingContent.content && contentDoc) {
        // 更新の場合
        const editingContentExists = await contentExists(dispatch, contentDoc);
        if (!editingContentExists) return false;
      }
      dispatch(dbSetDataFailedErrorAction());
      return false;
    }
    dispatch(appErrorAction(e));
  }
  return false;
}

export async function deleteContentExec(dispatch, cid, label, character, content){
  try{
    const companyDoc = db().collection("companies").doc(cid);
    const characterDoc = companyDoc.collection("labels").doc(label).collection("characters").doc(character);
    const contentDoc = characterDoc.collection("contents").doc(content);
    const exists = await contentExists(dispatch, contentDoc);
    if (!exists) return;
    let data = {
      character: null,
      currentPaths: null,
      nextPaths: null,
      sttPathFilters: null,
      cameraPathFilters: null,
      callPathFilters: null,
      circuitBreakerPathFilters: null,
    }
    await Promise.all([
      characterDoc.get(),
      characterDoc.collection("paths").where("currentContent", "==", contentDoc).get(),
      characterDoc.collection("paths").where("nextContents", "array-contains", contentDoc).get(),
      characterDoc.collection("sttPathFilters").where("contents", "array-contains", contentDoc).get(),
      characterDoc.collection("cameraPathFilters").where("contents", "array-contains", contentDoc).get(),
      characterDoc.collection("callPathFilters").where("contents", "array-contains", contentDoc).get(),
      characterDoc.collection("circuitBreakerPathFilters").where("contents", "array-contains", contentDoc).get(),
    ]).then(items => {
      const keys = Object.keys(data);
      for(let i = 0; i < keys.length; i++){
        data[keys[i]] = items[i];
      }
    });
    if(!data.nextPaths.empty){
      // nextContentsに指定がある場合は削除不可
      dispatch(appErrorAction("次の遷移先に指定されているため削除できません。"));
      return;
    }

    const batch = db().batch();
    if(data.character.exists && data.character.data().stanbyContentRefs.find(x => x.id === contentDoc.id)){
      // キャラクタの待受けから削除
      // console.log("キャラクタの待受けから削除")
      batch.update(characterDoc, {
        stanbyContentRefs: data.character.data().stanbyContentRefs.filter(x => x.id !== contentDoc.id)
      });
    }
    if(data.currentPaths.docs.length){
      // 自身がcurrentのpath削除
      // console.log("自身がcurrentのpath削除")
      batch.delete(data.currentPaths.docs[0].ref);
    }
    // nextContentsに入っているpathのnextContentsを更新(仕様変更でここを削除することはなくなったが念のため残しておく)
    data.nextPaths.docs.forEach(nextPathSnap => {
      // console.log("nextContentsに入っているpathのnextContentsを更新")
      batch.update(nextPathSnap.ref, {
        nextContents: nextPathSnap.data().nextContents.filter(x => x.id !== contentDoc.id),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    });
    // 各filtersのcontentsに入っている値の更新
    FILTER_COLLECTIONS.forEach(collectionName => {
      data[collectionName].docs.forEach(filterSnap => {
        // console.log("各filtersのcontentsに入っている値の更新", filterSnap.data());
        if(filterSnap.data().contents.length === 1){
          // contentsが1つの場合は削除
          // console.log("contentsが1つ");
          batch.delete(filterSnap.ref);
        } else {
          // contentsが複数の場合はcontentsから該当のcontentを削除して更新
          // console.log("contentsが複数");
          batch.update(filterSnap.ref, {
            contents:filterSnap.data().contents.filter(x => x.id !== contentDoc.id),
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          });
        }
      });
    });
    // コンテンツの削除
    batch.delete(contentDoc);
    await batch.commit();

    dispatch({
      type: "CONTENT_DELETED",
    });
  } catch(e){
    dispatch(appErrorAction(e));
  }
}

export async function debugContent(dispatch, cid, label, character){
  const companyDoc = db().collection("companies").doc(cid);
  const labelDoc = companyDoc.collection("labels").doc(label);
  const characterDoc = labelDoc.collection("characters").doc(character);
  let data = {
    paths: null,
    sttPathFilters: null,
    cameraPathFilters: null,
    callPathFilters: null,
    circuitBreakerPathFilters: null,
  }
  let par = [
    getDocsData(characterDoc.collection("paths"), "path"),
    getDocsData(characterDoc.collection("sttPathFilters"), "filter"),
    getDocsData(characterDoc.collection("cameraPathFilters"), "filter"),
    getDocsData(characterDoc.collection("callPathFilters"), "filter"),
    getDocsData(characterDoc.collection("circuitBreakerPathFilters"), "filter"),
  ];
  await Promise.all(par).then(items => {
    const keys = Object.keys(data);
    for(let i = 0; i < keys.length; i++){
      data[keys[i]] = items[i];
    }
  });
  console.log(data);
}

async function contentExists(dispatch, contentDoc) {
  const content = await contentDoc.get();
  if (!content.exists) {
    dispatch(contentNotFoundErrorAction());
    dispatch(refreshContentAction());
    return false;
  }
  return true;
}

function refreshContentAction() {
  return { type: "REFRESH_CONTENT" };
}

function contentNotFoundErrorAction() {
  return appErrorAction(["指定された音声対話シナリオは存在しません。","他のアカウントにより削除された可能性があります。"]);
}
