const defaultState = {
  listeners: {},
  company: null,
  labels: [],
  selectedLabel: {label: ""},
  vguards: [],
  afterVguardsListenerInitialized: false,
  eventLogsInitialized: false,
  vguardHeartbeats: {},
  characterManualCommands: [],
  eventLogs: [],
  searchedEventLogs: [],
  eventLogsSearch: "",
  eventLogsCategory: "all",
  vguardStatus: {},
  vguardCommand: {},
  getVguardStatus: (vguardStatus, vguard, key) => {
    if(!vguardStatus[vguard]) vguardStatus[vguard] = {};
    return vguardStatus[vguard][key] === undefined ? null : vguardStatus[vguard][key];
  },
  selectedVguard: null,
  selectedVguardIndex: null,
  selectedManualCommandGroup: null,
  selectedManualCommand: null,
  logSearchMode: "select",
  callingControl:  {
    callingType: null,
    callingIndex: null,
    selectedVguardDisabled: false,
    manualCommandDisabled: false,
  },
  scrollDown: null,
  scroll: {
    clientHeight: 0,
    scrollHeight: 0,
    scrollTop: 0,
    isBottom: true,
    isTop: false,
    topLogLoaded: false,
  },
  error: null,
};

export const statusDefault = () => {
  return {
    current: {
      primaryType: null,
      secondaryType: null,
    },
    statusText: "警戒監視中",
    // statusText: "端末起動中",
    colorStatus: "midZone",
    callStatus: null,
    callType: null,
    motion: null,
    zoneType: "midZone",
    color: getColorByColorStatus("midZone")
  };
}

export const getColorByColorStatus = (colorStatus) => {
  let vguardStatusColor = "#859CCD";
  switch(colorStatus){
    case "nearZone":
      vguardStatusColor = "#336EE1";
      break;
    case "call":
      vguardStatusColor = "#F8A01C";
      break;
    case "emergency":
      vguardStatusColor = "#F8431C";
      break;
    case "midZone":
    default:
      break;
  }
  return vguardStatusColor;
}

// 対話コンテンツ (primaryType == content)
// 音声認識 (primaryType == content && title に 「音声認識 -」が含まれている)
// 音声通話 (title に 「通話」が含まれている)
// 手動操作 (title に 「手動操作」が含まれている)
// 近距離検知 (primaryType == nearZoneDetection || primaryType == nearZoneGreeting)
// 中距離検知 (primaryType == midZoneDetection || primaryType == midZoneGreeting)
// all
const getSearchedEventLogs = (eventLogs, selectedVguard, eventLogsSearch, eventLogsCategory, eventLogSearchMode) =>{
  let result = eventLogs;
  if(selectedVguard){
    result = result.filter(x => {
      if(x.executorUserRef && x.executorUserRef.id === selectedVguard.vguard) return true;
      if(x.relatedVguardRef && x.relatedVguardRef.id === selectedVguard.vguard) return true;
      return false;
    });
  }
  if(eventLogsSearch && eventLogSearchMode === "text"){
    result = result.filter(x => x.title.indexOf(eventLogsSearch) >= 0);
  }
  if(eventLogSearchMode === "select"){
    switch(eventLogsCategory){
      case "contents":
        result = result.filter(x => x.primaryType === "content");
        break;
      case "speech":
        result = result.filter(x => x.primaryType === "content" && x.title.indexOf("音声認識") >= 0);
        break;
      case "call":
        result = result.filter(x => x.title.indexOf("通話") >= 0);
        break;
      case "manualCommand":
        result = result.filter(x => x.title.indexOf("手動操作") >= 0);
        break;
      case "nearZone":
        result = result.filter(x => x.primaryType === "nearZoneDetection" || x.primaryType === "nearZoneGreeting");
        break;
      case "midZone":
        result = result.filter(x => x.primaryType === "midZoneDetection" || x.primaryType === "midZoneGreeting");
        break;
      case "faceDetection":
        result = result.filter(x => x.primaryType === "vguardReceivedExternalCommand" && (x.secondaryType === "faceDetectionExecute" || x.secondaryType === "faceDetectionExecuted"));
        break;
      case "digitalIn":
        result = result.filter(x => x.primaryType === "vguardReceivedExternalCommand" && (x.secondaryType === "digitalInputExecute" || x.secondaryType === "digitalInputExecuted"));
        break;
      default:
        break;
    }
  }
  return result;
}


export const getColorStatusByColorType = (colorType) => {
  let result = "midZone";
  switch(colorType){
    case "deepBlue":
      result = "nearZone";
      break;
    case "orange":
      result = "call";
      break;
    case "red":
      result = "emergency";
      break;
    default:
      break;
  }
  return result;
}

const attachStatusLogColor = (eventLog) => {
  const primaryType = eventLog.primaryType;
  const secondaryType = eventLog.secondaryType;
  if (primaryType === "statusLog") {
    eventLog["color"] = getColorByColorStatus(getColorStatusByColorType(secondaryType));
  }
}

const updateVguardStatus = (vguardStatus, vguard, eventLog) => {
  const primaryType = eventLog.primaryType;
  const secondaryType = eventLog.secondaryType;
  const title = eventLog.title;
  let beforeStatus = vguardStatus[vguard];
  if(!beforeStatus){
    beforeStatus = statusDefault();
  }
  if (beforeStatus.time && (beforeStatus.time.toMillis() > eventLog.createdAt.toMillis())) {
    // 新しいもの以外は信用しない
    return {
      vguardStatus: vguardStatus,
    }
  }
  let status = { ...beforeStatus };
  switch(primaryType){
    case "statusLog":
      status["statusText"] = title;
      status["colorStatus"] = getColorStatusByColorType(secondaryType);
      status["color"] = getColorByColorStatus(status["colorStatus"]);
      status["time"] = eventLog.createdAt;

      // 通話制御系
      status["callStatus"] = (title === "通話中" || title === '一斉通話中') ? "calling" : null;
      status["callType"] = (title === "通話中" || title === '一斉通話中') ? null : status["callType"];
      break;
    case "callFunction":
      if (secondaryType === "failStartCall" || secondaryType === "successStopCall") {
        // 通話拒否・終了はcallTypeを消す
        status["callType"] = null;
      }
      status["current"] = eventLog;
      break;
    case "monitoringToolSendCallCommand":
      if (secondaryType === "sendRejectedCall") {
        // 通話拒否はcallTypeを消す
        status["callType"] = null;
      }
      status["current"] = eventLog;
      break;
    default:
      status["current"] = eventLog;
      break;
  }
  if(eventLog.motionMap && eventLog.motionMap.motion){
    // motionがある場合はmotionを保持
    status["motion"] = eventLog.motionMap.motion;
  }
  vguardStatus[vguard] = status;
  return {
    vguardStatus: vguardStatus,
  }
}

// 再読込などで後からイベントログの状態をシミュレートするときの処理
const simulateEventLogs = (eventLogs, vguards) => {
  if(!eventLogs || !eventLogs.length ) return null;
  if(!vguards || !vguards.length ) return null;
  let checkResult;

  for(let eventLog of eventLogs){
    const vguard = eventLog.relatedVguardRef ? eventLog.relatedVguardRef.id : eventLog.executorUserRef.id
    attachStatusLogColor(eventLog);
    checkResult = updateVguardStatus(checkResult ? checkResult.vguardStatus : {}, vguard, eventLog);
  }
  return { eventLogs: eventLogs, vguardStatus: checkResult.vguardStatus };
}

// イベントログの表示件数を超えたら古いものから削除する
function purgeEventLogs(eventLogs) {
  const EVENT_LOGS_MAXIMUM_NUM = 360; // 保持する最大件数
  if (eventLogs.length > EVENT_LOGS_MAXIMUM_NUM) {
    const deleteNum = eventLogs.length - EVENT_LOGS_MAXIMUM_NUM;
    eventLogs.splice(0, deleteNum);
  }
}

function removeAllListener(listeners) {
  if(listeners){
    for (let [key, value] of Object.entries(listeners)) {
      // console.log("listener stop", key);
      if(key === "heartbeats" || key === "manualCommands"){
        // for (let [key2, value2] of Object.entries(value)) {
        for(let key2 in value){
          value[key2]();
        }
      } else {
        value();
      }
    }
  }
}

const main = (state = defaultState, action) => {
  let listeners, eventLogs, eventLog, vguardStatus, vguard, vguards, vguardCommand, commandType, callType, characterManualCommands, beforeStatus, scroll, selectedManualCommandGroup;
  switch (action.type) {
    case "MAIN_COMPANY":
      return {
        ...state,
        company: action.payload.company,
        error: null
      };
    case "MAIN_LISTENER_ADDED":
      listeners = {...state.listeners};
      if(action.payload.nestId){
        if(!listeners[action.payload.key]) listeners[action.payload.key] = {};
        listeners[action.payload.key][action.payload.nestId] = action.payload.unsub;
      } else {
        listeners[action.payload.key] = action.payload.unsub;
      }
      return {
        ...state,
        listeners: listeners,
      }
    case "UNSUB_MAIN_ALL_LISTENER":
      // ステート更新はREMOVE_MAIN_ALL_LISTENERで行う
      removeAllListener(state.listeners);
      return state;
    case "REMOVE_MAIN_ALL_LISTENER":
      return {
        ...state,
        listeners: {},
      };
    case "MAIN_LABELS_LISTEN":
      return {
        ...state,
        labels: action.payload.labels,
        selectedLabel: action.payload.selectedLabel,
      };
    case "MAIN_VGUARDS_LISTEN":
      let selectedVguard = state.selectedVguard;
      let selectedVguardIndex = state.selectedVguardIndex;
      let searchedEventLogs = state.searchedEventLogs;
      let scrollDown = state.scrollDown;
      if(selectedVguard && selectedVguard.vguard){
        selectedVguard = action.payload.vguards.find(x => x.vguard === selectedVguard.vguard);
        if(selectedVguard === undefined) {
          selectedVguard = null;
          selectedVguardIndex = null;
          searchedEventLogs = getSearchedEventLogs(state.eventLogs, null, state.eventLogsSearch, state.eventLogsCategory, state.logSearchMode);
          scrollDown = "forceBottom";
        } else {
          selectedVguardIndex = action.payload.vguards.findIndex(x => x.vguard === selectedVguard.vguard);
        }
      }
      // console.log(selectedVguard, selectedVguardIndex);
      const vguardNumbers = {};
      action.payload.vguards.forEach((x, i) => {
        if (!x) return;
        vguardNumbers[x.vguard] = i + 1;
        x.no = i + 1;
      });
      // キャラクターが変更された場合はselectedManualCommandGroupをnullにする
      selectedManualCommandGroup = state.selectedManualCommandGroup;
      if (state.selectedVguard && selectedVguard) {
        if (state.selectedVguard.character.character !== selectedVguard.character.character) {
          selectedManualCommandGroup = null;
        }
      }
      return {
        ...state,
        vguards: action.payload.vguards,
        vguardNumbers: vguardNumbers,
        searchedEventLogs: searchedEventLogs,
        selectedVguard: selectedVguard,
        selectedVguardIndex: selectedVguardIndex,
        selectedManualCommandGroup: selectedManualCommandGroup,
        afterVguardsListenerInitialized: true,
        scrollDown: scrollDown,
      };
    case "MAIN_UPDATE_VGUARD_CHARACTER":
      vguards = [...state.vguards];
      const updateCharacter = action.character;
      // console.log(updateCharacter);
      for(let [i, x] of vguards.entries()){
        if(x.character && x.character.character === updateCharacter.character){
          vguards[i].character = {
            ...updateCharacter
          }
        }
      }
      // console.log(vguards);
      return {
        ...state,
        vguards: vguards,
      }
    case "AFTER_VGUARDS_LISTENER":
      return {
        ...state,
        afterVguardsListenerInitialized: false,
      };
    case "MAIN_HEARTBEATS_LISTEN":
      const vguardHeartbeats = {...state.vguardHeartbeats};
      vguardHeartbeats[action.payload.vguard] = action.payload.heartbeatCount;
      return {
        ...state,
        vguardHeartbeats: vguardHeartbeats,
      }
    case "MAIN_MANUAL_COMMANDS_LISTEN":
      characterManualCommands = [...state.characterManualCommands];
      const characterManualCommand = characterManualCommands.find(x => x.character === action.payload.character);
      if(characterManualCommand){
        characterManualCommand.manualCommands = action.payload.manualCommands;
      } else {
        characterManualCommands.push({ character: action.payload.character, manualCommands: action.payload.manualCommands });
      }
      // 手動操作コマンドデータが削除された場合
      let selectedManualCommand = state.selectedManualCommand;
      selectedManualCommandGroup = state.selectedManualCommandGroup;
      if (characterManualCommand) {
        if (state.selectedManualCommand && !characterManualCommand.manualCommands.some(manualCommand => manualCommand.manualCommands === state.selectedManualCommand.manualCommand)) {
          // 選択中手動操作コマンドデータが削除された場合
          selectedManualCommand = null;
        }
        if (!characterManualCommand.manualCommands.some(manualCommand => manualCommand.manualCommandGroupRef.id === state.selectedManualCommandGroup)) {
          // 手動操作コマンドグループデータが削除された場合
          selectedManualCommandGroup = null;
        }
      }
      return {
        ...state,
        characterManualCommands: characterManualCommands,
        selectedManualCommand: selectedManualCommand,
        selectedManualCommandGroup: selectedManualCommandGroup,
      }
    case "MAIN_MANUAL_COMMAND_GROUP_CHANGED":
      characterManualCommands = [...state.characterManualCommands];
      characterManualCommands.forEach(c => {
        c.manualCommands.forEach(m => {
          if(m.manualCommandGroupRef.manualCommandGroup === action.payload.editingCommand.manualCommandsGroup){
            m.manualCommandGroupRef.title = action.payload.editingCommand.title;
          }
        });
      })
      return {
        ...state,
        characterManualCommands: characterManualCommands
      }
    case "MAIN_LIST_EVENT_LOGS":
      // ログ取得済みでラベルの変更ではない場合はそのままを返す
      if(state.eventLogsInitialized) return { ...state };
      let createEventLogsResult = simulateEventLogs(action.payload.eventLogs, state.vguards);
      if(!createEventLogsResult){
        createEventLogsResult = { eventLogs: [], vguardStatus: {} };
      }
      // console.log(createEventLogsResult)
      return {
        ...state,
        eventLogs: createEventLogsResult.eventLogs,
        searchedEventLogs: getSearchedEventLogs(createEventLogsResult.eventLogs, state.selectedVguard, state.eventLogsSearch, state.eventLogsCategory, state.logSearchMode),
        eventLogsInitialized: true,
        vguardStatus: createEventLogsResult.vguardStatus,
        scrollDown: "forceBottom",
      };
    case "MAIN_EVENT_LOGS_LISTEN":
      eventLogs = [...state.eventLogs];
      eventLog = action.payload.eventLog;
      vguard = eventLog.relatedVguardRef ? eventLog.relatedVguardRef.id : eventLog.executorUserRef.id
      attachStatusLogColor(eventLog);
      eventLogs.push(eventLog);
      const checkResult = updateVguardStatus({...state.vguardStatus}, vguard, eventLog, state.vguardCommand);
      vguardStatus = checkResult.vguardStatus;
      purgeEventLogs(eventLogs);
      // console.log("MAIN_EVENT_LOGS_LISTEN", checkResult.vguardStatus)
      return {
        ...state,
        eventLogs: eventLogs,
        searchedEventLogs: getSearchedEventLogs(eventLogs, state.selectedVguard, state.eventLogsSearch, state.eventLogsCategory, state.logSearchMode),
        vguardStatus: vguardStatus,
        scrollDown: "swing",
      };
    case "MAIN_SCROLL_DOWN_CHANGE":
      return {
        ...state,
        scrollDown: action.scrollDown,
      }
    case "MAIN_COMMAND_LISTEN":
      const command = action.payload.command;
      vguard = command.reporterRef.id;
      vguardCommand = {...state.vguardCommand};
      if(!vguardCommand[vguard]) vguardCommand[vguard] = {};
      vguardCommand[vguard][command.commandType] = command;
      return {
        ...state,
        vguardCommand: vguardCommand,
      }
    case "MAIN_COMMAND_DELETE":
      vguard = action.payload.vguard;
      commandType = action.payload.commandType;
      vguardCommand = {...state.vguardCommand};
      // if(!vguardCommand[vguard]) vguardCommand[vguard] = {};
      // vguardCommand[vguard][commandType] = null;
      vguardCommand[vguard] = {};
      return {
        ...state,
        vguardCommand: vguardCommand,
      }
    case "SET_VGUARD_CALL_TYPE":
      vguard = action.payload.vguard;
      callType = action.payload.callType;
      vguardStatus = { ...state.vguardStatus };
      beforeStatus = vguardStatus[vguard];
      if(!beforeStatus) beforeStatus = {};
      vguardStatus[vguard] = { ...beforeStatus };
      vguardStatus[vguard].callType = callType;
      return {
        ...state,
        vguardStatus: vguardStatus,
      }
    case "SELECTED_LABEL_CHANGE":
      const selectedLabel = state.labels.find(x => x.label === action.label);
      // console.log(selectedLabel);
      return {
        ...state,
        selectedLabel: {...selectedLabel},
        eventLogsSearch: "",
        eventLogsCategory: "all",
        selectedVguard: null,
        selectedVguardIndex: null,
        scrollDown: "moment",
        scroll: {
          clientHeight: 0,
          scrollHeight: 0,
          scrollTop: 0,
          isBottom: true,
          isTop: false,
          topLogLoaded: false,
        },
        eventLogsInitialized: false,
        error: null
      };
    case "MAIN_SEARCH_LOG":
      eventLogs = [...state.eventLogs];
      state[action.payload.name] = action.payload.value;
      return{
        ...state,
        searchedEventLogs: getSearchedEventLogs(eventLogs, state.selectedVguard, state.eventLogsSearch, state.eventLogsCategory, state.logSearchMode),
        scrollDown: "moment",
      }
    case "MAIN_CHANGE_SEARCH_MODE":
      return {
        ...state,
        searchedEventLogs: getSearchedEventLogs(state.eventLogs, state.selectedVguard, state.eventLogsSearch, state.eventLogsCategory, action.payload.logSearchMode),
        logSearchMode: action.payload.logSearchMode,
      }
    case "MAIN_UPDATE_SELECTED_VGAURD":
      vguard = state.vguards.find((x, i) => i === action.payload.selectedVguardIndex);
      scroll = { ...state.scroll };
      scroll.isBottom = false;
      return {
        ...state,
        selectedVguard: vguard,
        selectedVguardIndex: action.payload.selectedVguardIndex,
        selectedManualCommandGroup: action.payload.selectedManualCommandGroup || null,
        selectedManualCommand: null,
        searchedEventLogs: getSearchedEventLogs(state.eventLogs, vguard, state.eventLogsSearch, state.eventLogsCategory, state.logSearchMode),
        scrollDown: "forceBottom",
        scroll: scroll,
      }
    case "MAIN_UPDATE_SELECTED_MANUAL_COMMAND_GROUP":
      return {
        ...state,
        selectedManualCommandGroup: action.payload.selectedManualCommandGroup,
        selectedManualCommand: null,
        scrollDown: "moment",
      }
    case "MAIN_UPDATE_SELECTED_MANUAL_COMMAND":
      return {
        ...state,
        selectedManualCommand: action.payload.selectedManualCommand,
      }
    case "MAIN_UPDATE_CALLING_CONTROL":
      return {
        ...state,
        callingControl: {...action.payload.callingControl},
      }
    case "MAIN_EVENT_LOGS_SCROLL_CHANGED":
      return {
        ...state,
        scroll: action.scroll,
      }
    default:
      return state;
  }
};

export default main;
