import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles, Button } from "@material-ui/core";
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import { settingMenu } from "./actions/appAction";

const drawerWidth = 300;
const drawerHeight = 676;
const drawerHeightUser = drawerHeight - 120;

const buttonWidth = 280;

const styles = theme => ({
  container: {
    display: "flex",
  },
  containerMain: {
    display: "flex",
    position: "absolute",
  },
  drawer: {
    width: drawerWidth,
    height: drawerHeight,
    flexShrink: 0,
    backgroundColor: "white",
    borderTopRightRadius: "16px",
    borderBottomRightRadius: "16px",
    boxShadow: "4px 0px 4px rgba(0, 0, 0, 0.1)",
  },
  drawerMain: {
    width: drawerWidth,
    height: drawerHeight,
    flexShrink: 0,
    backgroundColor: "white",
    borderTopRightRadius: "16px",
    borderBottomRightRadius: "16px",
    border: "1px solid #eee",
    boxShadow: "0 4px 4px rgba(0, 0, 0, 0.1)",
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: 10
  },
  white: {
    width: buttonWidth,
    height: 56,
    color: "#444",
    backgroundColor: theme.palette.white.main,
    '&:hover': {
      color: "#444",
      backgroundColor: theme.palette.white.dark,
    },
  },
  selected: {
    width: buttonWidth,
    height: 56,
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      color: "#fff",
      backgroundColor: theme.palette.primary.dark,
    },
  },
  menuList: {
    textAlign: "center"
  },
  menuIconContainer: {
    width: 42,
    height: 42,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }
});

const StyledButton = withStyles({
  label: {
    justifyContent: "left"
  }
})(Button);

const ACCOUNT_MENUS = [
  { key: "accounts", name: "アカウント管理" },
  { key: "approvalRequests", name: "アカウント承認リクエスト" },
];

const VGUARD_MENUS = [
  { key: "vguards", name: "バーチャル警備員端末設定" },
]

const OTHER_MENUS = [
  { key: "keywordGroups", name: "音声認識キーワードグループ管理" },
  { key: "phrases", name: "音声フレーズ管理" },
  { key: "faceDetectionPhrases", name: "顔認証音声フレーズ管理" },
  { key: "motions", name: "モーション管理" },
  { key: "models", name: "モデル管理" },
  { key: "manualCommandsGroups", name: "手動操作コマンドグループ管理" },
  { key: "guild-pictures", name: "案内画像管理" },
  { key: "characters", name: "キャラクター管理" },
]

class SettingMenu extends Component {

  constructor(props){
    super(props);
    this.state = {
      isMain: props.isMain === undefined ? false : true,
    }
  }

  componentDidMount(){
    // console.log("componentDidMount", this.state.isMain);
    if(this.state.isMain){
      settingMenu(this.props.dispatch, false);
    }
  }

  onMenuClick = (url) => {
    this.props.history.push("/" + this.props.user.TenantId + '/' + url);
  }

  getButton = (url, d) => {
    let buttonStyle = "white";
    if(url === d.key){
      buttonStyle = "selected";
    }
    return (
      <StyledButton key={d.key} onClick={() => this.onMenuClick(d.key)}
        startIcon={this.getImage(d, buttonStyle)} className={this.props.classes[buttonStyle]}>
          <span>{ d.name }</span>
      </StyledButton>
    );
  }

  getImage = (d, buttonStyle) => {
    return (
      <div className={this.props.classes.menuIconContainer}>
        <img src={`/images/${d.key}_${buttonStyle}.png`} alt={d.key} />
      </div>
    )
  }

  render() {
    const { classes } = this.props;
    let style = {  };
    // if(!this.props.isSettingMenuOpen){
    //   style["display"] = "none";
    // }
    if(this.props.user && !this.props.user.isManager()){
      style["height"] = drawerHeightUser;
    }
    const p = this.props.location.pathname.split("/");
    let url = p.length >= 3 ? p[2] : "";
    return (
      <div className={this.state.isMain ? classes.containerMain : classes.container} style={{ zIndex: 10 }}>
        <div className={this.state.isMain ? classes.drawerMain : classes.drawer} style={style}>
          { this.props.user && this.props.user.isManager() &&
          <React.Fragment>
            <List className={classes.menuList}>
              { ACCOUNT_MENUS.map((d) => {
                return this.getButton(url, d);
              }) }
            </List>
            <Divider />
          </React.Fragment>
          }
          <List className={classes.menuList}>
            { VGUARD_MENUS.map((d) => {
              return this.getButton(url, d);
            }) }
          </List>
          <Divider />
          <List className={classes.menuList}>
            { OTHER_MENUS.map((d) => {
              return this.getButton(url, d);
            }) }
          </List>
          {/* <div className={classes.drawerHeader}>
            <IconButton onClick={() => { settingMenu(this.props.dispatch, false); }}>
              <ChevronLeftIcon color="primary" /><span className="primaryText">Close</span>
            </IconButton>
          </div> */}
        </div>
        <div style={{ width: "15px" }}>&nbsp;</div>
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(
  connect(state => ({
    user: state.user && state.user.user,
    loading: state.loading && state.loading.loading,
    isSettingMenuOpen: state.appState && state.appState.isSettingMenuOpen,
  }))(SettingMenu)
));