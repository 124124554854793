import React, { Component } from 'react';
import { connect } from 'react-redux';
import Auth from "./auth";
import { withRouter } from 'react-router-dom';
import TopBar from "./TopBar";
import { formatDate } from './utilities/utils';
import { accounts, deleteAccount } from './actions/accounts';
import { withStyles, DialogContent } from "@material-ui/core";
import { onLoading, offLoading } from './actions/loading';
import ErrorMessage from './errorMessage';
import {  appRemoveError } from './actions/appAction';
import muiStyles from "./MuiStyles";
import SettingMenu from './SettingMenu';
import { EnhancedTable } from './AppParts';
import { PrimaryButton, GrayButton, CloseButton, StyledDialog } from './AppParts';

const headCells = [
  { id: 'uid', label: 'UID' },
  { id: 'displayName', label: '担当者名' },
  { id: 'createdAt', label: '作成日時', modFunc: formatDate },
]

export class CompanyAccounts extends Component {
  state = {
    isDeleteConfirmDialog: false,
    deleteAccountUid: null,
    deleteAccountName: null,
    deleteAccountRole: null,
  }

  async componentDidMount() {
    if (this.props.user) {
      onLoading(this.props.dispatch);
      await accounts(this.props.dispatch, this.props.user.TenantId);
      offLoading(this.props.dispatch);
    }
  }

  componentDidUpdate() {
    // console.log(this.props);
  }

  onDelete = (e, item, role) => {
    // console.log(item);
    e.preventDefault();
    this.setState({
      isDeleteConfirmDialog: true,
      deleteAccountUid: item.uid,
      deleteAccountName: item.displayName,
      deleteAccountRole: role,
    });
  }

  onDeleteExecute = async (e) => {
    // console.log(this.state.deleteAccountUid, this.state.deleteAccountName, this.state.deleteAccountRole);
    e.preventDefault();
    if (!this.state.deleteAccountName) return;
    appRemoveError(this.props.dispatch);
    deleteAccount(this.props.dispatch, this.props.user.TenantId, this.state.deleteAccountUid, this.state.deleteAccountRole);
    this.setState({
      isDeleteConfirmDialog: false,
      deleteAccountUid: null,
      deleteAccountName: null,
      deleteAccountRole: null,
    });
  }

  render() {
    const { classes } = this.props;
    // console.log(this.props);
    return (
      <React.Fragment>
        <Auth />
        <React.Fragment>
          <TopBar />
          <div className={classes.content}>
            <SettingMenu />
            <div className={classes.main}>
              <div className="titleText">アカウント管理</div>
              <ErrorMessage />
              <div className={classes.listAccountContainer}>
                <div className="accountTitleText">監視ツール管理者アカウント</div>
                <EnhancedTable list={this.props.toolManagers} loginUid={this.props.user && this.props.user.uid} classes={classes} headCells={headCells} deleteCallback={(this.props.user && this.props.user.isManager()) ? this.onDelete : null} role="tool-manager" />
                <div className="accountTitleText" style={{ marginTop: 40 }}>監視ツール利用者アカウント</div>
                <EnhancedTable list={this.props.toolUsers} classes={classes} headCells={headCells} deleteCallback={(this.props.user && this.props.user.isManager()) ? this.onDelete : null} role="tool-user" />
              </div>
            </div>
          </div>
        </React.Fragment>
        <StyledDialog open={this.state.isDeleteConfirmDialog} onClose={() => this.setState({ isDeleteConfirmDialog: false, deleteAccountUid: null, deleteAccountRole: null })}>
          <DialogContent className={classes.dialogContent}>
            <div className={classes.closeButton}>
              <CloseButton onClick={() => this.setState({ isDeleteConfirmDialog: false, deleteAccountUid: null, deleteAccountRole: null })}/>
            </div>
            <div className="deletePopupContentText">
              <div>
                <div className="wordKeepAll">「{this.state.deleteAccountName}</div>
                <div className="wordKeepAllClose">」</div>
                を削除しますか？
              </div>
              <div style={{ marginTop: 36 }}>
                <PrimaryButton width={160} height={48} onClick={(e) => this.onDeleteExecute(e)} disabled={this.props.loading}>削除する</PrimaryButton>
                <GrayButton width={160} height={48} onClick={() => this.setState({ isDeleteConfirmDialog: false, deleteAccountUid: null, deleteAccountRole: null })} style={{ marginLeft: 20 }}>キャンセル</GrayButton>
              </div>
            </div>
          </DialogContent>
        </StyledDialog>
      </React.Fragment>
    )
  }
}
//Main.contextTypes = {
//  router: PropTypes.object
//};

export default withRouter(withStyles(muiStyles)(
  connect(state => ({
    user: state.user && state.user.user,
    toolManagers: state.accounts && state.accounts.toolManagers,
    toolUsers: state.accounts && state.accounts.toolUsers,
    loading: state.loading && state.loading.loading,
  }))(CompanyAccounts)
));
