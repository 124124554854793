import firebase from 'firebase/app';
import 'firebase/firestore';
import functions, { isFunctionsSdkInternalError, isTokenRevokedError } from '../utilities/functions';
import db, { getDocsOrderBy } from '../utilities/db';
import { appErrorAction, libraryErrorAction, offLineErrorAction } from './appAction';
import { redirectUnauthorizeUser } from './login';

export async function approvalRequests(dispatch, cid) {
  try {
    const requests = await getDocsOrderBy(db().collection("companies").doc(cid).collection("approval-requests"), "uid", [{ column: "createdAt", type: "desc" }]);
    dispatch(approvalRequestsAction(requests));
  } catch (e) {
    dispatch(appErrorAction(e));
  }
}

export async function approve(dispatch, cid, addAccount) {
  try{
    const uid = addAccount.uid;
    const collectionName = addAccount.role + "s";
    const user = await db().collection("companies").doc(cid).collection(collectionName).doc(uid).get();
    if(user.exists){
      dispatch(appErrorAction(["このアカウントはすでに承認されています。"]));
      dispatch(approveAction(cid, uid));
      return;
    }
    const approvalRequest = await db().collection("companies").doc(cid).collection("approval-requests").doc(uid).get();
    if(!user.exists && !approvalRequest.exists){
      // ユーザが存在しないかつリクエストもない場合は拒否されている
      dispatch(appErrorAction(["このアカウントはすでに拒否されているため承認できませんでした。"]));
      dispatch(approveAction(cid, uid));
      return;
    }
    const managersDoc = db().collection("companies").doc(cid).collection(collectionName).doc(uid);
    await managersDoc.set({
      displayName: addAccount.displayName,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    })
    dispatch(approveAction(cid, uid));
  } catch(e){
    dispatch(appErrorAction(["アカウントの承認ができませでした。", "他のアカウントが既に承認をしたか、拒否をした可能性があります。"], e));
    approvalRequests(dispatch, cid);
    return;
  }
}

export async function reject(dispatch, cid, addAccount) {
  try{
    const uid = addAccount.uid;
    const collectionName = addAccount.role + "s";
    const user = await db().collection("companies").doc(cid).collection(collectionName).doc(uid).get();
    if(user.exists){
      dispatch(appErrorAction(["このアカウントはすでに承認されているため拒否できませんでした。"]));
      dispatch(rejectAction(cid, uid));
      return;
    }
    const approvalRequest = await db().collection("companies").doc(cid).collection("approval-requests").doc(uid).get();
    if(!user.exists && !approvalRequest.exists){
      // ユーザが存在しないかつリクエストもない場合は拒否されている
      dispatch(appErrorAction(["このアカウントはすでに拒否されています。"]));
      dispatch(approveAction(cid, uid));
      return;
    }
    try {
      await functions().httpsCallable("deleteMonitoringUser")({cid: cid, uid: uid});
    } catch(e) {
      if (e && e.details && e.details.code === "auth/user-not-found") {
        // 既に削除されているので無視
      } else {
        throw e;
      }
    }
    const approvalRequestsDoc = db().collection("companies").doc(cid).collection("approval-requests").doc(uid);
    await approvalRequestsDoc.delete();
    dispatch(rejectAction(cid, uid));
  } catch(e){
    if (isTokenRevokedError(e)) {
      redirectUnauthorizeUser(dispatch, cid);
      return;
    }
    if (!navigator.onLine) {
      dispatch(offLineErrorAction("アカウントの拒否"));
      return;
    }
    if (isFunctionsSdkInternalError(e)) {
      dispatch(libraryErrorAction(e.code));
      return;
    }
    dispatch(appErrorAction(["アカウントの拒否ができませでした。", "他のアカウントが既に承認をしたか、拒否をした可能性があります。"], e));
    approvalRequests(dispatch, cid);
    return;
  }
}

function approvalRequestsAction(requests) {
  return {
    type: 'REQUESTS',
    payload: {
      requests: requests,
    }
  }
}

function approveAction(cid, uid) {
  return {
    type: 'APPROVE_REQUEST',
    payload: {
      cid: cid,
      uid: uid,
    }
  }
}

function rejectAction(cid, uid) {
  return {
    type: 'REJECT_REQUEST',
    payload: {
      cid: cid,
      uid: uid,
    }
  }
}
