const defaultState = {
  loading: false,
};

const loading = (state = defaultState, action) => {
  switch (action.type) {
    case "ON_LOADING":
      if(state.loding === true) return state;
      return {
        ...state,
        loading: true
      }
    case "OFF_LOADING":
      if(state.loding === false) return state;
      return {
        ...state,
        loading: false
      }
    default:
      return state;
  }
};

export default loading;