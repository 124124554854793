import React, { Component } from 'react';
import { connect } from 'react-redux';
import Auth from "./auth";
import TopBar from './TopBar';
import { formatDate } from './utilities/utils';
import { withRouter } from 'react-router-dom';
import { guidePictures, editGuidePicture, beginEditGuidePicture, updateGuidePicture, deleteGuidePicture, closeEditGuide, getPictureUrl, searchGuidePicture } from './actions/guides';
import { withStyles, Grid, DialogContent } from "@material-ui/core";
import { Field, reduxForm, getFormValues } from 'redux-form';
import { renderSimpleTextField } from "./formRenderer";
import { required } from "./validator";
import ErrorMessage from './errorMessage';
import UpdateMessage from './updateMessage';
import { appError, appRemoveError, updateMessage } from './actions/appAction';
import { onLoading, offLoading } from './actions/loading';
import muiStyles from "./MuiStyles";
import SettingMenu from './SettingMenu';
import { EnhancedTable, SearchTextField } from './AppParts';
import { SecondaryButton, StyledDialog, CloseButton, PrimaryButton, GrayButton, ReferenceButton } from './AppParts';
// import { login } from './actions/login';
// import { mainCompany, mainLablesListener } from './actions/main';

export class GuidePicture extends Component {
  state = {
    editing: false,
    isDeleteConfirmDialog: false,
    deleteId: null,
    deleteTitle: null,
    deleteFileName: null,
    message: null,
    add: false,
  }

  async componentDidMount() {
    // TODO: デバッグ用
    // console.log("componentDidMount", this.props);
    // if(!this.props.user){
    //   const cid = this.props.match.params && this.props.match.params.cid;
    //   login(this.props.dispatch, "test@test.com", "sskkotdotd", cid);
    // }
    // デバッグ用

    if (this.props.user) {
      onLoading(this.props.dispatch);
      await guidePictures(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label);
      offLoading(this.props.dispatch);
    }
  }

  async componentDidUpdate() {
    // TODO: デバッグ用
    // console.log("componentDidMount", this.props);
    // if(!this.props.company && this.props.user){
    //   await mainCompany(this.props.dispatch, this.props.user.TenantId);
    // }
    // if(this.props.company && !this.props.selectedLabel.label){
    //   await mainLablesListener(this.props.dispatch, this.props.user.TenantId, this.props.listeners);
    // }
    // if (this.props.user && this.props.selectedLabel.label && this.props.guidePictures.length === 0) {
    //   await guidePictures(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label);
    // }
    // デバッグ用

    if(this.props.isRefresh && !this.props.loading){
      onLoading(this.props.dispatch);
      await guidePictures(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label);
      offLoading(this.props.dispatch);
      if (this.state.message) {
        updateMessage(this.props.dispatch, true, this.state.message);
        this.setState({ message: null });
      }
    }
    if(this.props.showPictureUrl){
      window.open(this.props.showPictureUrl, "vguard 監視ツール");
      await getPictureUrl(this.props.dispatch, null);
    }
  }

  onSearch = (e) => {
    e.preventDefault();
    searchGuidePicture(this.props.dispatch, e.target.value);
  }

  onChange = (e) => {
    const editingGuidePicture = { ...this.props.formValues };
    if(e.target.name === "pictureFile"){
      if(!e.target.files || e.target.files.length === 0) return;
      const file = e.target.files[0];
      editingGuidePicture[e.target.name] = file;
    } else {
      editingGuidePicture[e.target.name] = e.target.value;
    }
    editGuidePicture(this.props.dispatch, editingGuidePicture);
  }

  onBeginEdit = (e, item) => {
    e.preventDefault();
    appRemoveError(this.props.dispatch);
    const newItem = Object.keys(item).length !== 0 ? false : true;
    beginEditGuidePicture(this.props.dispatch, newItem ? null : item.picture);
    this.setState({
      editing: true,
      deleteId: null,
      deleteTitle: null,
      deleteFileName: null,
      add: newItem ? true : false,
    })
  }

  onCreateExec = async (values) => {
    const editingGuidePicture = { ...values };
    if (this.props.guidePictures.find(x => x.picture !== editingGuidePicture.picture && x.title === editingGuidePicture.title)) {
      appError(this.props.dispatch, "同じ案内画像名がすでにあります。");
      return;
    }
    if (!editingGuidePicture.picture && !editingGuidePicture.pictureFile) {
      appError(this.props.dispatch, "ファイルが選択されていません。");
      return;
    }
    if (editingGuidePicture.pictureFile && editingGuidePicture.pictureFile.size === 0) {
      appError(this.props.dispatch, ["選択したファイルが見つかりません。","ファイルが削除もしくは移動されていないか確認してください。"]);
      return;
    }
    appRemoveError(this.props.dispatch);
    onLoading(this.props.dispatch);
    const success = await updateGuidePicture(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, editingGuidePicture);
    offLoading(this.props.dispatch);
    if (!success) return;
    this.setState({
      editing: false,
      message: this.state.add ? "register" : "update",
    });
  }

  onCloseEdit = (e) => {
    e.preventDefault();
    closeEditGuide(this.props.dispatch);
    this.setState({
      editing: false ,
    });
  };

  onDeleteConfirm = (e, item) => {
    e.preventDefault();
    beginEditGuidePicture(this.props.dispatch, item.picture);
    this.setState({
      isDeleteConfirmDialog: true,
      deleteId: item.picture,
      deleteTitle: item.title,
      deleteFileName: item.picturePath.split('/').pop(),
    });
  }

  onDeleteExecute = async (e) => {
    e.preventDefault();
    if (!this.state.deleteId || !this.state.deleteFileName) return;
    appRemoveError(this.props.dispatch);
    onLoading(this.props.dispatch);
    await deleteGuidePicture(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, this.state.deleteId, this.state.deleteFileName);
    offLoading(this.props.dispatch);
    this.setState({
      isDeleteConfirmDialog: false,
      deleteId: null,
      deleteTitle: null,
      deleteFileName: null,
    });
  }

  onPicturePathClick = async (e, item) => {
    await getPictureUrl(this.props.dispatch, item);
  }

  onTagClick = (e, tag) => {
    e.preventDefault();
    const searchInput = document.querySelector('#searchGuide');
    if (searchInput) {
      searchInput.value = tag;
    }
    searchGuidePicture(this.props.dispatch, tag);
  }

  render() {
    const { handleSubmit, submitting, classes } = this.props;
    const headCells = [
      { id: 'title', label: '案内画像名', minWidth: 120 },
      { id: 'picturePath', label: 'ファイルパス', linkFunc: this.onPicturePathClick },
      { id: 'createdAt', label: '作成日時', modFunc: formatDate },
      { id: 'updatedAt', label: '更新日時', modFunc: formatDate },
    ];
    return (
      <React.Fragment>
        <Auth />
        <React.Fragment>
          <TopBar />
          <div className={classes.content}>
            <SettingMenu />
            <div className={classes.main}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div className="titleText">案内画像管理</div>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  <SearchTextField id="searchGuide" name="search" style={{ width: "280px" }} defaultValue={this.props.search} onChange={this.onSearch} />
                  {(this.props.user && this.props.user.isManager()) &&
                    <SecondaryButton onClick={(e) => this.onBeginEdit(e, {})} style={{ marginLeft: 5 }}>新規登録</SecondaryButton>
                  }
                </Grid>
              </Grid>
              <ErrorMessage />
              <UpdateMessage />
              <div className={classes.listContainer}>
                <EnhancedTable list={this.props.searchedGuidePictures} classes={classes} operationMinWidth={80} headCells={headCells}
                  editCallback={(this.props.user && this.props.user.isManager()) ? this.onBeginEdit : null} deleteCallback={(this.props.user && this.props.user.isManager()) ? this.onDeleteConfirm : null}
                  displayTag tagClickCallback={this.onTagClick} />
              </div>
            </div>
          </div>
          <ErrorMessage />
          { this.props.editingGuidePicture &&
            <StyledDialog open={this.state.editing} onClose={this.onCloseEdit}>
            <DialogContent className={classes.dialogContent}>
              <form onSubmit={handleSubmit(this.onCreateExec)}>
                <div className={classes.closeButton}>
                  <CloseButton onClick={this.onCloseEdit} />
                </div>
                <div style={{ width: "100%" }}>
                  <div>
                    <p className="popupTitleText">案内画像管理 {this.state.add ? "新規登録" : "編集"}</p>
                  </div>
                  <span className="grayText f16L19" style={{ marginTop: 24, marginLeft: 30 }}>案内画像名</span>
                  <div className="alignCenter verticalCenter" style={{ marginBottom: 17 }}>
                    <Field name="title" className="fieldText" style={{ width: 480, height: 40, marginTop: 8, marginLeft: 10 }} component={renderSimpleTextField} validate={[required]} size={"md"} />
                  </div>
                  <span className="grayText f16L19" style={{ marginTop: 24, marginLeft: 30 }}>タグ(任意)</span>
                  <div className="alignCenter verticalCenter" style={{ marginBottom: 17 }}>
                    <Field name="tag" className="fieldText" style={{ width: 480, height: 40, marginTop: 8, marginLeft: 10 }} component={renderSimpleTextField} placeholder="#タグ1 #タグ2" size={"md"} />
                  </div>
                  <span className="grayText f16L19" style={{ marginLeft: 30 }}>ファイル名</span>
                  <div className="alignCenter verticalCenter">
                    <Field name="name" className="fieldText" InputProps={{ readOnly: true }} onChange={this.onChange}
                       style={{ width: 480, height: 40, marginTop: 8, textDecorationLine: "underline" }} component={renderSimpleTextField} size={"md"} />
                    <ReferenceButton style={{ marginTop: 8, marginLeft: -56 }}>
                      <label>
                        参照 <input name="pictureFile" type="file" accept="image/png, image/jpeg" style={{ lineHeight: 24, display: "none" }} onChange={this.onChange} />
                      </label>
                    </ReferenceButton>
                  </div>
                  <p style={{ margin: "40px auto" }}>
                    <PrimaryButton width={160} height={48} type="submit" disabled={submitting}>{this.state.add ? "登録する" : "更新する"}</PrimaryButton>
                  </p>
                </div>
              </form>
            </DialogContent>
          </StyledDialog>
          }
          <StyledDialog open={this.state.isDeleteConfirmDialog} onClose={() => this.setState({ isDeleteConfirmDialog: false, deleteId: null, deleteFileName: null })}>
            <DialogContent className={classes.dialogContent}>
              <div className={classes.closeButton}>
                <CloseButton onClick={() => this.setState({ isDeleteConfirmDialog: false, deleteId: null, deleteFileName: null })}/>
              </div>
              <div className="deletePopupContentText">
                <div>
                  <div className="wordKeepAll">「{this.state.deleteTitle}</div>
                  <div className="wordKeepAllClose">」</div>
                  を削除しますか？
                </div>
                <div style={{ marginTop: 36 }}>
                  <PrimaryButton width={160} height={48} onClick={(e) => this.onDeleteExecute(e)} disabled={this.props.loading}>削除する</PrimaryButton>
                  <GrayButton width={160} height={48} onClick={() => this.setState({ isDeleteConfirmDialog: false, deleteId: null, deleteFileName: null })} style={{ marginLeft: 20 }}>キャンセル</GrayButton>
                </div>
              </div>
            </DialogContent>
          </StyledDialog>
        </React.Fragment>
      </React.Fragment>
    );
  }
}
//Main.contextTypes = {
//  router: PropTypes.object
//};

export default withRouter(withStyles(muiStyles)(
  connect(state => ({
    listeners: state.main && state.main.listeners,
    user: state.user && state.user.user,
    company: state.main && state.main.company,
    selectedLabel: state.main && state.main.selectedLabel,
    guidePictures: (state.guides && state.guides.guidePictures) || [],
    searchedGuidePictures: state.guides && state.guides.searchedGuidePictures,
    search: state.guides && state.guides.search,
    editingGuidePicture: state.guides && state.guides.editingGuidePicture,
    isRefresh: state.guides && state.guides.isRefresh,
    showPictureUrl: state.guides && state.guides.showPictureUrl,
    loading: state.loading && state.loading.loading,
    initialValues: state.guides && state.guides.editingGuidePicture,
    formValues: getFormValues("guideForm")(state),
  }))(reduxForm({ form: 'guideForm', enableReinitialize: true })(GuidePicture))
));
