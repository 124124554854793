const defaultState = {
  user: null,
  logoutBy: "none", // 何によってログアウトされたか（"user"(ログアウトボタンを押した) > "account_delete"(アカウントが削除された) < "permission"(リスナー、ファンクションで落ちた) > "none"（ログインしていない）)の順で優先される
  error: null,
};

const calcLogoutBy = (current, update) => {
  const accept = ["user", "account_delete", "permission", "none"];
  // console.log(current, update)

  // current, updateがacceptにあることを保証
  if (!accept.some(x => x === current) && !accept.some(x => x === update)) return "none";
  if (!accept.some(x => x === current)) return update;
  if (!accept.some(x => x === update)) return current;

  // acceptでのindexが小さいほうを返す
  const currentIndex = accept.indexOf(current);
  const updateIndex = accept.indexOf(update);
  return accept[Math.min(currentIndex, updateIndex)];
}

const user = (state = defaultState, action) => {
  switch (action.type) {
    case 'AUTHORIZED' :
      return {
        ...state,
        user: action.payload.user,
        error: null,
      };
    case 'UNAUTHORIZED':
      return {
        ...defaultState,
        logoutBy: calcLogoutBy(state.logoutBy, "user"),
      };
    case 'ACCOUNT_DELETED':
      return {
        ...defaultState,
        logoutBy: calcLogoutBy(state.logoutBy, "account_delete"),
      };
    case 'UNAUTHORIZED_USER':
      return {
        ...defaultState,
        logoutBy: calcLogoutBy(state.logoutBy, "permission"),
      };
    default:
      return state;
  }
};

export default user;
