import firebase from 'firebase/app';
import 'firebase/firestore';
import db, { getDocsOrderBy } from '../utilities/db';
import { appErrorAction, dbSetDataFailedErrorAction } from './appAction';

export async function commandsGroups(dispatch, cid) {
  try {
    const companyDoc = db().collection("companies").doc(cid);
    const manualCommandsGroups = await getDocsOrderBy(companyDoc.collection("manualCommandsGroups"), "manualCommandsGroup", [{ column: "title", type: "asc" }]);
    dispatch(commandsAction(manualCommandsGroups));
  } catch (e) {
    dispatch(appErrorAction(e));
  }
}

export async function beginEditCommand(dispatch, manualCommandsGroup) {
  dispatch(beginEditCommandAction(manualCommandsGroup));
}

export async function editCommand(dispatch, editingCommand) {
  dispatch(editCommandAction(editingCommand));
}

export async function updateCommand(dispatch, cid, editingCommand) {
  let commandsGroupDoc;
  try{
    if(!editingCommand.manualCommandsGroup){
      // 新規
      const batch = db().batch();
      commandsGroupDoc = db().collection("companies").doc(cid).collection("manualCommandsGroups").doc();
      batch.set(commandsGroupDoc, {
        title: editingCommand.title,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
      // 手動操作コマンドグループ並び替えのための処理
      const labelCollection = await db().collection("companies").doc(cid).collection("labels").get();
      await Promise.all(labelCollection.docs.map(async doc => {
        const characterCollection = await doc.ref.collection("characters").get();
        characterCollection.docs.map(characterDoc => {
          const characterData = characterDoc.data();
          if(characterData.manualCommandGroupsOrder && characterData.manualCommandGroupsOrder.length){
            characterData.manualCommandGroupsOrder.push(commandsGroupDoc);
            batch.update(characterDoc.ref, {
              manualCommandGroupsOrder: characterData.manualCommandGroupsOrder
            })
          }
        })
      }));
      await batch.commit();

      editingCommand.manualCommandsGroup = commandsGroupDoc.id;
      dispatch(updateCommandAction(cid, editingCommand));
    } else {
      // 更新
      commandsGroupDoc = db().collection("companies").doc(cid).collection("manualCommandsGroups").doc(editingCommand.manualCommandsGroup);
      const exists = await commandGroupExists(dispatch, cid, commandsGroupDoc);
      if (!exists) return false;
      await commandsGroupDoc.update({
        title: editingCommand.title,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
      dispatch(updateCommandAction(cid, editingCommand));
      // メインで使用されている手動操作コマンドグループ名を変更
      dispatch({
        type: "MAIN_MANUAL_COMMAND_GROUP_CHANGED",
        payload: {
          editingCommand: editingCommand,
        }
      })
    }
    return true;
  } catch(e){
    console.log(e)
    // セキュリティールールありだとpermission-denied、なしだとnot-foundエラーが返る
    if (e && e.name === 'FirebaseError' && (e.code === 'permission-denied' || e.code === 'not-found')) {
      if (commandsGroupDoc && editingCommand.manualCommandsGroup) {
        // 更新時
        const exists = await commandGroupExists(dispatch, cid, commandsGroupDoc);
        if (!exists) return false;
      }
      dispatch(dbSetDataFailedErrorAction());
      return false;
    }
    dispatch(appErrorAction(e));
  }
  return false;
}

export async function deleteCommand(dispatch, cid, manualCommandsGroup) {
  try{
    const commandsGroupDoc = db().collection("companies").doc(cid).collection("manualCommandsGroups").doc(manualCommandsGroup);
    const exists = await commandGroupExists(dispatch, cid, commandsGroupDoc);
    if (!exists) return;
    const labels = await db().collection("companies").doc(cid).collection("labels").get();
    let isInclude = false;
    const batch = db().batch();
    await Promise.all(labels.docs.map(async labelDoc => {
      const characters = await labelDoc.ref.collection("characters").get();
      await Promise.all(characters.docs.map(async characterDoc => {
        // 手動操作コマンドグループ並び替えのための処理(配列の中の手動操作コマンドグループの削除)
        const characterData = characterDoc.data();
        if(characterData.manualCommandGroupsOrder && characterData.manualCommandGroupsOrder.length){
          // console.log(characterDoc.id, characterData.manualCommandGroupsOrder.filter(x => x.id !== commandsGroupDoc.id))
          batch.update(characterDoc.ref, {
            manualCommandGroupsOrder: characterData.manualCommandGroupsOrder.filter(x => x.id !== commandsGroupDoc.id)
          })
        }
        const manualCommands = await characterDoc.ref.collection("manualCommands").get();
        manualCommands.forEach(manualCommandDoc => {
          if(manualCommandDoc.data().manualCommandGroupRef.id === manualCommandsGroup){
            isInclude = true;
          }
        });
      }));
    }));
    if(!isInclude){
      // await commandsGroupDoc.delete();
      batch.delete(commandsGroupDoc);
      await batch.commit();
      dispatch(deleteCommandAction(manualCommandsGroup));
    } else {
      dispatch(appErrorAction(["この手動操作コマンドは", "他から参照されているので削除できません。"]))
    }
  } catch(e){
    dispatch(appErrorAction(e));
  }
}

async function commandGroupExists(dispatch, cid, commandsGroupDoc) {
  const commandGroup = await commandsGroupDoc.get();
  if (!commandGroup.exists) {
    dispatch(commandNotFoundErrorAction());
    commandsGroups(dispatch, cid);
    return false;
  }
  return true;
}

export async function closeEditCommandGroup(dispatch){
  dispatch({ type: "COMMAND_GROUP_CLOSE_EDIT" });
}

function commandsAction(manualCommandsGroups) {
  return {
    type: 'COMMANDS',
    payload: {
      manualCommandsGroups: manualCommandsGroups
    }
  }
}

function beginEditCommandAction(manualCommandsGroup) {
  return {
    type: 'BEGIN_EDIT_COMMAND',
    payload: {
      manualCommandsGroup: manualCommandsGroup,
    }
  }
}

function editCommandAction(editingCommand) {
  return {
    type: 'EDIT_COMMAND',
    payload: {
      editingCommand: editingCommand,
    }
  }
}

function updateCommandAction(cid, editingCommand) {
  return {
    type: 'UPDATE_COMMAND',
    payload: {
      editingCommand: editingCommand,
    }
  }
}

function deleteCommandAction(manualCommandsGroup) {
  return {
    type: 'DELETE_COMMAND',
    payload: {
      manualCommandsGroup: manualCommandsGroup,
    }
  }
}

function commandNotFoundErrorAction() {
  return appErrorAction(["指定された手動操作コマンドグループは存在しません。","他のアカウントにより削除された可能性があります。"]);
}
