import React, { Component } from 'react';
import { connect } from 'react-redux';
import Auth from "./auth";
import { withRouter } from 'react-router-dom';
import TopBar from "./TopBar";
import { formatDate } from './utilities/utils';
import { approvalRequests, approve, reject } from './actions/approvalRequests';
import ErrorMessage from './errorMessage';
import { appRemoveError } from './actions/appAction';
import { withStyles, DialogContent } from '@material-ui/core';
import { onLoading, offLoading } from './actions/loading';
import muiStyles from "./MuiStyles";
import SettingMenu from './SettingMenu';
import { EnhancedTable } from './AppParts';
import { PrimaryButton, GrayButton, CloseButton, StyledDialog } from './AppParts';

const headCells = [
  { id: 'uid', label: 'UID' },
  { id: 'displayName', label: '担当者名' },
  { id: 'email', label: 'メールアドレス'},
  { id: 'createdAt', label: '作成日時', modFunc: formatDate },
]

export class ApprovalRequests extends Component {
  state = {
    isConfirmDialog: false,
    request: null,
    type: null,
  }

  async componentDidMount() {
    if (this.props.user) {
      onLoading(this.props.dispatch);
      await approvalRequests(this.props.dispatch, this.props.user.TenantId);
      offLoading(this.props.dispatch);
    }
  }

  componentDidUpdate() {
    // console.log(this.props);
  }

  onApprove(e, item) {
    e.preventDefault();
    appRemoveError(this.props.dispatch);
    approve(this.props.dispatch, this.props.user.TenantId, item);
  }

  onReject(e, item) {
    e.preventDefault();
    appRemoveError(this.props.dispatch);
    reject(this.props.dispatch, this.props.user.TenantId, item);
  }

  onConfirm = (e, request, type) => {
    e.preventDefault();
    // console.log(request);
    this.setState({
      isConfirmDialog: true,
      request: request,
      type: type,
    });
  }

  onExecute = async (e) => {
    e.preventDefault();
    onLoading(this.props.dispatch);
    if (this.state.type === "approve") {
      // console.log("approve");
      this.onApprove(e, this.state.request);
    } else {
      // console.log("reject");
      this.onReject(e, this.state.request);
    }
    offLoading(this.props.dispatch);
    this.setState({
      isConfirmDialog: false,
      request: null,
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Auth />
        <React.Fragment>
          <TopBar />
          <div className={classes.content}>
            <SettingMenu />
            <div className={classes.main}>
              <div className="titleText">アカウント承認リクエスト</div>
              <ErrorMessage />
              <div className={classes.listAccountContainer}>
                <div className="accountTitleText">監視ツール管理者アカウント</div>
                <EnhancedTable list={this.props.requests.filter(x => x.role === "tool-manager")} classes={classes} operationMinWidth={135}
                  headCells={headCells} rejectCallback={(this.props.user && this.props.user.isManager()) ? this.onConfirm : null} approveCallback={(this.props.user && this.props.user.isManager()) ? this.onConfirm : null} />
                <div className="accountTitleText" style={{ marginTop: 40 }}>監視ツール利用者アカウント</div>
                <EnhancedTable list={this.props.requests.filter(x => x.role === "tool-user")} classes={classes} operationMinWidth={135}
                  headCells={headCells} rejectCallback={(this.props.user && this.props.user.isManager()) ? this.onConfirm : null} approveCallback={(this.props.user && this.props.user.isManager()) ? this.onConfirm : null} />
              </div>
            </div>
          </div>
        </React.Fragment>
        <StyledDialog open={this.state.isConfirmDialog} onClose={() => this.setState({ isConfirmDialog: false })}>
          <DialogContent className={classes.dialogContent}>
            <div className={classes.closeButton}>
              <CloseButton onClick={() => this.setState({ isConfirmDialog: false })}/>
            </div>
            <div className="popupContentText">
              <p>{this.state.type === "reject" ? "拒否しますか？" : this.state.type === "approve" ? "承認しますか？" : null}</p>
              <p style={{ marginTop: 36 }}>
                <PrimaryButton width={160} height={48} onClick={(e) => this.onExecute(e)} disabled={this.props.loading}>{this.state.type === "reject" ? "拒否する" : "承認する"}</PrimaryButton>
                <GrayButton width={160} height={48} onClick={() => this.setState({ isConfirmDialog: false })} style={{ marginLeft: 20 }}>キャンセル</GrayButton>
              </p>
            </div>
          </DialogContent>
        </StyledDialog>
      </React.Fragment>
    )
  }
}

export default withRouter(withStyles(muiStyles)(
  connect(state => ({
    user: state.user && state.user.user,
    requests: state.approvalRequests && state.approvalRequests.requests,
  }))(ApprovalRequests)
));
