import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Dialog, ButtonBase, Switch, TextField, Select, FormControl, TableContainer, Tooltip, InputAdornment } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { TIME_ZONES } from './greetings';

export const BorderPrimaryButton = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.white.main,
    borderRadius: 16,
    height: 32,
    width: 160,
    border: "2px solid",
    padding: "4px 0",
    '&:hover': {
      backgroundColor: theme.palette.gray.light,
    },
    minWidth: "initial",
  },
  label: {
    // fontFamily: "iragino Kaku Gothic Pro",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "21px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  }
}))(Button);

export const KeywordDeleteButton = withStyles((theme) => ({
  root: {
    borderRadius: 10,
    height: 20,
    // width: props.width ? props.width : 120,
    '&:hover': {
    },
    minWidth: "initial",
    justifyContent: "start",
  },
  text: {
    padding: "0 8px"
  },
  label: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  }
}))(Button);

export const StyledTextFieldSm = withStyles((theme) => ({
    root: {
      "& .MuiInputBase-root": {
        backgroundColor: theme.palette.gray.light,
        height: 24,
        "& .MuiOutlinedInput-notchedOutline":{
          border: "none"
        },
        "& input:-webkit-autofill": {
          "-webkit-box-shadow": "0 0 0px 1000px " + theme.palette.gray.light + " inset !important",
        }
      },
      "& .MuiOutlinedInput-input": {
        paddingTop: "calc((24px - 1.1875em) / 2)",
        paddingBottom: "calc((24px - 1.1875em) / 2)",
      }
    },
}))(TextField);

export const StyledTextFieldSmRadius = withStyles((theme) => ({
  root: {
    "& .MuiInputBase-root": {
      backgroundColor: theme.palette.gray.light,
      height: 24,
      "& .MuiOutlinedInput-notchedOutline":{
        border: "none"
      },
      "& input:-webkit-autofill": {
        "-webkit-box-shadow": "0 0 0px 1000px " + theme.palette.gray.light + " inset !important",
      }
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 12
    },
    "& .MuiOutlinedInput-input": {
      paddingTop: "calc((24px - 1.1875em) / 2)",
      paddingBottom: "calc((24px - 1.1875em) / 2)",
    }
  },
}))(TextField);

export const StyledTextField = withStyles((theme) => ({
  root: {
    "& .MuiInputBase-root": {
      backgroundColor: theme.palette.gray.light,
      height: 32,
      "& .MuiOutlinedInput-notchedOutline":{
        border: "none"
      },
      "& input:-webkit-autofill": {
        "-webkit-box-shadow": "0 0 0px 1000px " + theme.palette.gray.light + " inset !important",
      }
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 16
    },
    "& .MuiOutlinedInput-input": {
      paddingTop: "calc((32px - 1.1875em) / 2)",
      paddingBottom: "calc((32px - 1.1875em) / 2)",
    }
  },
}))(TextField);

export const StyledTextFieldMd = withStyles((theme) => ({
  root: {
    "& .MuiInputBase-root": {
      backgroundColor: theme.palette.gray.light,
      height: 40,
      "& .MuiOutlinedInput-notchedOutline":{
        border: "none"
      },
      "& input:-webkit-autofill": {
        "-webkit-box-shadow": "0 0 0px 1000px " + theme.palette.gray.light + " inset !important",
      }
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 20
    },
  },
}))(TextField)


export const StyledTextFieldBg = withStyles((theme) => ({
  root: {
    "& .MuiInputBase-root": {
      backgroundColor: theme.palette.gray.light,
      height: 48,
      fontSize: 18,
      lineHeight: "21px",
      "& .MuiOutlinedInput-notchedOutline":{
        border: "none"
      },
      "& input:-webkit-autofill": {
        "-webkit-box-shadow": "0 0 0px 1000px " + theme.palette.gray.light + " inset !important",
      }
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 24
    },
  },
}))(TextField)

export const StyledTextFieldCameraQuery = withStyles((theme) => ({
  root: {
    "& .MuiInputBase-root": {
      backgroundColor: theme.palette.gray.light,
      height: 59,
      "& .MuiOutlinedInput-notchedOutline":{
        border: "none"
      },
      "& input:-webkit-autofill": {
        "-webkit-box-shadow": "0 0 0px 1000px " + theme.palette.gray.light + " inset !important",
      }
    },
  },
}))(TextField)

export const StyledTextFieldPhrase = withStyles((theme) => ({
  root: {
    "& .MuiInputBase-root": {
      backgroundColor: theme.palette.gray.light,
      height: 44,
      width: 269,
      borderRadius: 22,
      paddingRight: 30,
      lineHeight: "18px",
      "& .MuiOutlinedInput-notchedOutline":{
        border: "none"
      },
    },
    "& ::-webkit-scrollbar": {
      display: "none",
    },
    "& .MuiInputBase-root:after": {
      content: "...",
      position: "absolute",
      top: 73,
      left: 80,
      backgroundColor: "#C0C0C0",
    },
  },
}))(TextField)

export const StyledFormControlSm = withStyles((theme) => ({
  root: {
    "& .MuiInputBase-root": {
      backgroundColor: theme.palette.gray.light,
      height: 24,
      "& .MuiSelect-outlined": {
        height: 24,
        borderRadius: 12,
      },
      "& .MuiOutlinedInput-notchedOutline":{
        border: "none"
      },
      "& .MuiOutlinedInput-inputMarginDense": {
        paddingTop: 0,
        paddingBottom: 0,
      },
      "& .MuiOutlinedInput-input": {
        paddingLeft: "15px",
      }
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 12,
      fontSize: 12,
      lineHeight: "24px"
    }
  },
}))(FormControl)

export const StyledFormControl = withStyles((theme) => ({
  root: {
    "& .MuiInputBase-root": {
      backgroundColor: theme.palette.gray.light,
      height: 32,
      "& .MuiSelect-outlined": {
        height: 32,
        borderRadius: 16,
      },
      "& .MuiOutlinedInput-notchedOutline":{
        border: "none"
      },
      "& .MuiOutlinedInput-inputMarginDense": {
        paddingTop: 0,
        paddingBottom: 0,
      },
      "& .MuiOutlinedInput-input": {
        paddingLeft: "15px",
      }
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 16,
      lineHeight: "32px"
    }
  },
}))(FormControl)

export const StyledFormControlMd = withStyles((theme) => ({
  root: {
    "& .MuiInputBase-root": {
      backgroundColor: theme.palette.gray.light,
      height: 40,
      "& .MuiOutlinedInput-notchedOutline":{
        border: "none"
      },
      "& .MuiSelect-outlined": {
        height: 40,
        borderRadius: 20,
      },
      "& .MuiOutlinedInput-inputMarginDense": {
        paddingTop: 0,
        paddingBottom: 0,
      },
      "& .MuiOutlinedInput-input": {
        paddingLeft: "15px",
      }
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 20,
      lineHeight: "40px"
    }
  },
}))(FormControl);

export const StyledFormControlBg = withStyles((theme) => ({
  root: {
    "& .MuiInputBase-root": {
      backgroundColor: theme.palette.gray.light,
      height: 48,
      "& .MuiOutlinedInput-notchedOutline":{
        border: "none"
      },
      "& .MuiSelect-outlined": {
        height: 48,
        borderRadius: 24,
      },
      "& .MuiOutlinedInput-inputMarginDense": {
        paddingTop: 0,
        paddingBottom: 0,
      },
      "& .MuiOutlinedInput-input": {
        paddingLeft: "15px",
      }
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 24,
      lineHeight: "48px"
    }
  },
}))(FormControl)

export const StyledFormControlPhrase = withStyles((theme) => ({
  root: {
    "& .MuiInputBase-root": {
      backgroundColor: "transparent",
      height: 44,
      width: 269,
      "& .MuiOutlinedInput-notchedOutline":{
        border: "none"
      },
      "& .MuiSelect-outlined": {
        height: 44,
        borderRadius: 22,
        color: "transparent",
      },
      "& .MuiOutlinedInput-inputMarginDense": {
        paddingTop: 0,
        paddingBottom: 0,
      },
      "& .MuiOutlinedInput-input": {
        paddingLeft: "15px",
      }
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 22,
      lineHeight: "48px",
      marginTop: -44,
    }
  },
}))(FormControl)

export const StyledSelect = withStyles((theme) => ({
  root: {
    // display: "flex",
    // alignItems: "center",
  },
}))(Select)

export const StyledDialog = withStyles((theme) => ({
  paper: {
    width: 664,
    height: 504,
    boxShadow: "none",
    backgroundColor: "inherit",
    overflow: "hidden",
  },
  paperWidthSm: {
    maxWidth: "none",
  }
}))(Dialog);

export const StyledCameraDialog = withStyles((theme) => ({
  paper: {
    width: 1280,
    height: 800,
    top: 30,
    boxShadow: "none",
    backgroundColor: "inherit",
    overflow: "hidden",
  },
  paperWidthSm: {
    maxWidth: "none",
  }
}))(Dialog);

export const CustomSwitch= (props) => {
  const baseWidth = 64;
  const height = 24;
  const customTheme = theme => ({
    root: {
      width: baseWidth,
      height: height,
      padding: 0,
      margin: 0,
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(39px)',
        color: theme.palette.white.main,
        '& + $track': {
          backgroundColor: theme.palette.primary.main,
          opacity: 1,
          border: 'none',
        },
      },
      '&.Mui-disabled': {
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.disabled.light,
          opacity: 1,
        },
      },
    },
    thumb: {
      color: theme.palette.white.main,
      transform: 'translate(1px, 1px)',
      width: 20,
      height: 20,
    },
    track: {
      borderRadius: height / 2,
      backgroundColor: theme.palette.gray.main,
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
  })
  const ComponentName = withStyles(customTheme)(Switch)
  let labelText = "ON";
  const labelStyle = {
    color: "#fff",
    fontSize: 12,
    fontWeight: "bold",
    position: "absolute",
    top: height / 8,
    left: baseWidth - 52,
    pointerEvents: "none",
    lineHeight: "18px",
  }
  if(!props.checked){
    labelStyle["left"] = baseWidth - 36;
    labelText = "OFF";
  }
  return (
    <div style={{ position: "relative" }}>
      <ComponentName checked={props.checked} name={props.name} onChange={props.onChange} disabled={props.disabled} />
      <span style={labelStyle}>{ labelText }</span>
    </div>
  );
}

export const CustomButton = (props) => {
  const height = props.height ? props.height : 32;
  let fontWeight = props.fontWeight || "bold";
  let fontSize = props.fontSize;
  if(!fontSize){
    switch(height){
      case 48:
        fontSize = 18;
        break;
      case 32:
        fontSize = 16;
        break;
      case 24:
        fontSize = 12;
        break;
      default:
        break;
    }
  }
  let lineHeight = props.lineheight ? props.lineheight + "px" : (fontSize * 1.5) + "px";
  const useStyles = makeStyles((theme) => ({
    root: {
      color: props.themecolor === "white" ? theme.palette.primary.main : "#fff",
      backgroundColor: props.themecolor ? theme.palette[props.themecolor].main : theme.palette.white.main,
      borderRadius: props.borderradius || height / 2,
      border: props.border || null,
      height: props.height ? props.height : 32,
      width: props.width ? props.width : null,
      '&:hover': {
        backgroundColor: props.themecolor ? theme.palette[props.themecolor].dark : theme.palette.white.dark,
      },
      '&:disabled': {
        color: props.disablecolor ? props.disablecolor : theme.palette.white.main,
        backgroundColor: props.disablebackgroundcolor ? props.disablebackgroundcolor : theme.palette.disabled.main,
      },
      minWidth: "initial",
      '&:hover.Mui-disabled': {
        backgroundColor: props.disablebackgroundcolor ? props.disablebackgroundcolor : theme.palette.disabled.main,
      },
    },
    label: {
      fontFamily: props.fontFamily ? props.fontFamily : "inherit",
      fontStyle: "normal",
      fontWeight: fontWeight,
      fontSize: fontSize,
      lineHeight: lineHeight,
      display: "flex",
      alignItems: "center",
      textAlign: "center",
    },
  }));
  const classes = useStyles(props);
  return <Button {...props} classes={classes} />
}

export const NoSelectedButton = withStyles((theme) => ({
  root: {
    color: "#fff",
    backgroundColor: theme.palette.gray.main,
    borderRadius: 12,
    height: "auto",
    '&:hover': {
      backgroundColor: theme.palette.gray.dark,
    },
    minWidth: "initial",
  },
  text: {
    padding: "5px 8px"
  },
  label: {
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "14px",
    display: "flex",
    alignItems: "center",
    textAlign: "left",
  }
}))(Button);

export const SelectedButton = withStyles((theme) => ({
  root: {
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
    borderRadius: 12,
    height: "auto",
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    minWidth: "initial",
  },
  text: {
    padding: "5px 8px"
  },
  label: {
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "14px",
    display: "flex",
    alignItems: "center",
    textAlign: "left",
  }
}))(Button);

export const PrimaryButton = (props) => {
  return <CustomButton themecolor="primary" width={120} {...props} />
}

export const SecondaryButton = (props) => {
  return <CustomButton themecolor="secondary" width={120} {...props} />
}

export const GrayButton = (props) => {
  return <CustomButton themecolor="gray" disablebackgroundcolor="#E0E0E0" width={120} {...props} />
}

export const EditButton = (props) => {
  return (
    <CustomButton themecolor="primary" {...props} width={32}>
      <img src="/images/edit.png" alt="edit" />
    </CustomButton>
  );
}

export const EditButtonSm = (props) => {
  return (
    <CustomButton themecolor="primary" {...props} width={24} height={24}>
      <img src="/images/edit_sm.png" alt="edit" />
    </CustomButton>
  );
}

export const DeleteButton = (props) => {
  return (
    <CustomButton themecolor="primary" {...props} width={32}>
      <img src="/images/delete.png" alt="delete" />
    </CustomButton>
  );
}
export const Delete24Button = (props) => {
  return (
    <CustomButton themecolor="primary" {...props} width={24} height={24}>
      <img src="/images/delete24.png" alt="delete" />
    </CustomButton>
  );
}


export const PlayButton = (props) => {
  return (
    <CustomButton themecolor="primary" {...props} width={32}>
      <img src="/images/play.png" alt="play" />
    </CustomButton>
  );
}

export const PlayArrowButton = (props) => {
  return (
    <CustomButton themecolor="primary" {...props} width={32}>
      <img src="/images/play_arrow.png" alt="play" />
    </CustomButton>
  );
}

export const StopButton = (props) => {
  return (
    <CustomButton themecolor="primary" {...props} width={32}>
      <img src="/images/stop.png" alt="stop" />
    </CustomButton>
  );
}

export const SearchButton = (props) => {
  return (
    <CustomButton themecolor="primary" {...props} width={32}>
      <img src="/images/search.png" alt="play" />
    </CustomButton>
  );
}

export const PlusButton = (props) => {
  return (
    <CustomButton themecolor="primary" {...props} width={24} height={24}>
      <img src="/images/plus.png" alt="play" />
    </CustomButton>
  );
}

export const CloseButton = (props) => {
  return (
    <ButtonBase {...props}>
      <img src="/images/close.png" alt="close" />
    </ButtonBase>
  );
}

export const RejectButton = (props) => {
  return (
    <CustomButton themecolor="sub" {...props} width={56} height={24}>拒否</CustomButton>
  );
}

export const ApproveButton = (props) => {
  return (
    <CustomButton themecolor="primary" {...props} width={56} height={24}>承認</CustomButton>
  );
}

export const CallStartButton = (props) => {
  let size = 14;
  if(props.height >= 32){
    size = 20;
  }
  return (
    <CustomButton themecolor="green" {...props} width={props.width || 64} height={props.height || 24} border="2px solid #58D16D" disablebackgroundcolor="white">
      <svg width={size} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill={ props.disabled ? "#58D16D" : "white" } d="M20.0018 13.4759C20.0018 12.5508 19.2517 11.6841 18.335 11.5591L15.6598 10.8674C14.8098 10.5007 13.7264 10.8674 13.268 11.6674C12.8013 12.4675 11.8263 12.6591 11.0929 12.1008L9.50111 10.509L7.90934 8.91723C7.34264 8.18385 7.53432 7.20046 8.3427 6.74209C9.14275 6.2754 9.50111 5.20033 9.14275 4.35028L8.44271 1.66677C8.3177 0.750048 7.45098 0 6.52592 0H1.46726C0.542204 0 -0.116171 0.750048 0.0171706 1.66677C0.267187 4.71697 1.19225 7.57548 2.65067 10.0923C3.53406 11.6257 4.55079 12.9592 5.79254 14.2092C7.03429 15.451 8.37604 16.4677 9.90947 17.3511C12.4263 18.8095 15.2848 19.7346 18.335 19.9846C19.2517 20.1096 20.0018 19.4596 20.0018 18.5345V13.4759ZM18.3183 18.3012C15.6598 18.0595 13.0763 17.2344 10.7595 15.8927C9.3511 15.076 8.13435 14.1592 6.99261 13.0175C5.85087 11.8758 4.92582 10.659 4.11743 9.2506C2.77568 6.93378 1.95896 4.35028 1.70894 1.69177H6.53425C6.61759 1.69177 6.77593 1.82511 6.78427 1.90845C6.7926 1.97512 6.80927 2.03347 6.8176 2.10014L7.50931 4.7753C7.53432 4.85864 7.55932 4.93364 7.59265 5.00865C7.62599 5.07532 7.56765 5.242 7.50098 5.28367C6.70093 5.75036 6.13423 6.51709 5.95922 7.40048C5.7842 8.28387 6.00922 9.20892 6.57592 9.95064C6.61759 10.009 6.66759 10.059 6.7176 10.1173L8.30937 11.7091L9.90113 13.3009C9.95114 13.3509 10.0095 13.4009 10.0678 13.4425C10.8012 14.0092 11.7346 14.2342 12.6096 14.0592C13.493 13.8842 14.2597 13.3259 14.7264 12.5175C14.7681 12.4508 14.9348 12.3925 15.0015 12.4258C15.0765 12.4591 15.1515 12.4841 15.2348 12.5091L17.91 13.2092C17.9767 13.2258 18.035 13.2342 18.1017 13.2508C18.185 13.2592 18.3183 13.4175 18.3183 13.5009V18.3012Z" />
      </svg>
    </CustomButton>
  );
}

export const ArrowUpButton = (props) => {
  return (
    <CustomButton themecolor="primary" {...props} width={props.width || 24} height={props.height || 24}>
      <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 6L6 1L1 6" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </CustomButton>
  );
}

export const ArrowDownButton = (props) => {
  return (
    <CustomButton themecolor="primary" {...props} width={props.width || 24} height={props.height || 24}>
      <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L6 6L11 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </CustomButton>
  );
}

export const CallStopButton = (props) => {
  let size = 19.74;
  if(props.height >= 32){
    size = 28.2;
  }
  return (
    <CustomButton themecolor="red" {...props} width={props.width || 64} height={props.height || 24}>
      <svg width={size} viewBox="0 0 28 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill="white" d="M4.61289 10.7571C5.26701 11.4112 6.41024 11.4937 7.14686 10.9339L9.5276 9.53137C10.388 9.18958 10.8948 8.16421 10.6531 7.27438C10.4174 6.37865 10.9714 5.55364 11.8848 5.42989L14.1359 5.42989L16.387 5.42989C17.3063 5.54775 17.8661 6.37865 17.6186 7.27438C17.3829 8.1701 17.8897 9.18368 18.7441 9.53137L21.1367 10.9339C21.8733 11.4937 23.0165 11.4112 23.6706 10.7571L27.2476 7.18009C27.9018 6.52597 27.8369 5.53007 27.0944 4.97613C24.7608 2.99611 22.0854 1.62895 19.2745 0.880544C17.5656 0.420895 15.9037 0.196963 14.1418 0.19107C12.3857 0.19107 10.718 0.420891 9.00901 0.880539C6.19809 1.62894 3.5227 2.99611 1.1891 4.97613C0.452484 5.53596 0.381764 6.52597 1.03588 7.18009L4.61289 10.7571ZM2.39125 6.15471C4.44199 4.44576 6.8522 3.20235 9.4392 2.51288C11.0126 2.09448 12.5212 1.88235 14.1359 1.88235C15.7505 1.88235 17.265 2.08859 18.8325 2.51288C21.4195 3.20236 23.8239 4.45166 25.8805 6.15472L22.4685 9.56673C22.4095 9.62566 22.2033 9.64334 22.1385 9.5903C22.0854 9.54905 22.0324 9.51958 21.9794 9.47833L19.5986 8.07582C19.522 8.03457 19.4513 7.99921 19.3747 7.96975C19.304 7.94617 19.2274 7.78706 19.245 7.71046C19.4808 6.81473 19.3393 5.87186 18.8384 5.12345C18.3375 4.37505 17.5243 3.88005 16.5991 3.75629C16.5284 3.74451 16.4577 3.74451 16.3811 3.73862L14.13 3.73861L11.8789 3.73861C11.8082 3.73861 11.7316 3.74451 11.6608 3.75629C10.7415 3.87415 9.92243 4.37506 9.42742 5.11756C8.92652 5.86597 8.77919 6.80294 9.0208 7.70456C9.03848 7.78117 8.96187 7.94027 8.89116 7.96385C8.81455 7.99331 8.74384 8.02867 8.66723 8.06993L6.28059 9.46654C6.22166 9.5019 6.17452 9.53727 6.11559 9.57262C6.05077 9.62566 5.84452 9.60798 5.78559 9.54905L2.39125 6.15471Z"/>
      </svg>
    </CustomButton>
  );
}

export const ExpansionButton = (props) => {
  return (
    <CustomButton themecolor="primary" {...props} width={props.width || 24} height={props.height || 24}>
      <img src="/images/expansion.png" alt="call" />
    </CustomButton>
  );
}

export const ReloadButton = (props) => {
  return (
    <CustomButton themecolor="primary" {...props} width={props.width || 24} height={props.height || 24}>
      <img src="/images/reload.png" alt="call" />
    </CustomButton>
  );
}

export const BackButton = (props) => {
  return (
    <CustomButton themecolor="primary" width={32} height={32} {...props}>
      <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 1L0.999997 6L6 11" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </CustomButton>
  )
}

export const BorderBackButton = (props) => {
  return(
    <BorderPrimaryButton style={{ width: 24, height: 24 }} {...props}>
      <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 1L3 3L0.999998 5L5 9" stroke="#38496D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </BorderPrimaryButton>
  )
}

export const ReferenceButton = (props) => {
  return <CustomButton themecolor="primary" width={48} height={24} {...props} />
}

export const EditDialogButton = (props) => {
  const styles = {
    position: "absolute",
    right: 0,
    top: "50%",
    transform: "translateY(-50%)",
    backgroundColor: "transparent",
    color: "#38496D",
  };
  return <CustomButton fontSize="12px" fontWeight="600" style={styles} {...props} />
}

export const SearchTextField = (props) => {
  return (
    <StyledTextField variant="outlined" {...props} InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.705 14.29L10.875 9.46C12.545 7.12 12.335 3.85 10.235 1.75C9.065 0.59 7.535 0 5.995 0C4.455 0 2.925 0.59 1.755 1.76C-0.585 4.1 -0.585 7.9 1.755 10.25C2.925 11.41 4.455 12 5.995 12C7.215 12 8.425 11.62 9.455 10.88L14.285 15.71C14.675 16.1 15.315 16.1 15.695 15.71C16.095 15.32 16.095 14.68 15.705 14.29ZM3.165 8.83C1.605 7.27 1.605 4.73 3.165 3.17C3.925 2.42 4.925 2 5.995 2C7.065 2 8.065 2.42 8.825 3.17C9.575 3.93 9.995 4.93 9.995 6C9.995 7.07 9.575 8.07 8.825 8.83C8.065 9.58 7.065 10 5.995 10C4.925 10 3.925 9.58 3.165 8.83Z" fill="#AAAAAA"/>
          </svg>
        </InputAdornment>
      )
    }}/>
  )
}


export function EnhancedTable({
  list,
  classes,
  headCells,
  editCallback,
  playCallback,
  deleteCallback,
  rejectCallback,
  approveCallback,
  selectCallback,
  selectItem,
  selectItems,
  selectKey,
  focusItem,
  focusKey,
  isCheckBoxSelect,
  editedId,
  role,
  operationMinWidth,
  loginUid,
  isHeaderHidden,
  isOperationHidden,
  displayTag,
  tagPosition, // 0からの整数
  tagClickCallback,
}) {
  const [order, setOrder] = React.useState(null);
  const [orderBy, setOrdereBy] = React.useState(null);
  const [isFocused, setIsFocused] = React.useState(false);
  const focusRef = React.useRef(null);
  React.useEffect(() => {
    if (!isFocused && focusRef && focusRef.current) {
      focusRef.current.focus();
      setIsFocused(true);
    }
  });
  const onRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrdereBy(property);
  };
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  function descendingComparator(a, b, orderBy) {
    let aItem, bItem;
    if(orderBy === "modelRef.title") {
      aItem = a["modelRef"] ? a["modelRef"]["title"] : "";
      bItem = b["modelRef"] ? b["modelRef"]["title"] : "";
    } else if(orderBy === "phraseRef.phraseTitle"){
      aItem = a["phraseRef"] ? a["phraseRef"]["phraseTitle"] : "";
      bItem = b["phraseRef"] ? b["phraseRef"]["phraseTitle"] : "";
    } else if(orderBy === "motionRef.title"){
      aItem = a["motionRef"] ? a["motionRef"]["title"] : "";
      bItem = b["motionRef"] ? b["motionRef"]["title"] : "";
    } else if(orderBy === "manualCommandGroupRef.title"){
      aItem = a["manualCommandGroupRef"] ? a["manualCommandGroupRef"]["title"] : "";
      bItem = b["manualCommandGroupRef"] ? b["manualCommandGroupRef"]["title"] : "";
    } else {
      aItem = a[orderBy];
      bItem = b[orderBy];
    }
    if (bItem < aItem) {
      return -1;
    }
    if (bItem > aItem) {
      return 1;
    }
    return 0;
  }
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function posArray(item) {
    if(!item) return [];
    return [item.pos, item.pos1, item.pos2, item.pos3];
  }

  return (
    <TableContainer style={{ height: "inherit" }}>
      <Table stickyHeader aria-label="simple table">
        <TableHead className={classes.listHeader} style={{ display: isHeaderHidden ? "none" : null }}>
          <TableRow>
            {headCells.map((headCell) => {
              return(
                <TableCell
                  key={headCell.id}
                  sortDirection={orderBy === headCell.id ? order : false}
                  style={{ width: headCell.width ? headCell.width : "none", textAlign: headCell.isTextCenter ? "center" : "left" }}
                >
                  {headCell.sortDisabled ?
                    <React.Fragment>
                      {Array.isArray(headCell.label) ?
                        <span className="headCellText newLineHeadCell">{headCell.label[0]}<br></br>{headCell.label[1]}</span>
                      :
                        <span className="headCellText">{headCell.label}</span>
                      }
                    </React.Fragment>
                  :
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                      className="headCellText"
                      IconComponent={(props) => (
                        <KeyboardArrowDownIcon className={props.className} color="primary" style={{ margin: 0 }} />
                      )}
                    >
                      {Array.isArray(headCell.label) ?
                        <React.Fragment>
                        <span className="headCellText newLineHeadCell">{headCell.label[0]}<br></br>{headCell.label[1]}</span>
                      </React.Fragment>
                      :
                        <span className="headCellText">{headCell.label}</span>
                      }
                    </TableSortLabel>
                  }
                </TableCell>
              )
            })}
            { isOperationHidden !== true &&
              <TableCell></TableCell>
            }
          </TableRow>
        </TableHead>
        <TableBody className={displayTag ? classes.tableBodyWithTag : classes.tableBody}>
        { stableSort(list, getComparator(order, orderBy)).map((row, index) => {
            let timezoneFlag = [ false, false, false, false ];
            let colorStyle = {};
            let loginUser = false;
            let keywordStyle = classes.keyword;
            let faceDetectionStyle = classes.faceDetectionWord;
            let faceDetectionPhraseStyle = classes.keyword;
            let tagStyle = classes.tagText;
            let isSelected = false;
            let isFocus = false;
            if (focusItem && focusKey && row[focusKey] === focusItem[focusKey]) {
              isFocus = true;
            }
            if(selectItem && selectKey && row[selectKey] === selectItem[selectKey]){
              colorStyle = {
                color: "white",
                backgroundColor: "#6781B8",
              }
              keywordStyle = classes.selectedKeyword;
              faceDetectionStyle = classes.selectedFaceDetectionWord;
              tagStyle = classes.tagTextSelected;
              isSelected = true;
            }
            if(selectItems){
              for(let item of selectItems){
                if(item === row[selectKey]){
                  colorStyle = {
                    color: "white",
                    backgroundColor: "#6781B8",
                  }
                  keywordStyle = classes.selectedKeyword;
                  tagStyle = classes.tagTextSelected;
                  isSelected = true;
                  break;
                }
              }
            }
            if(editedId && selectKey && row[selectKey] === editedId){
              colorStyle = {
                backgroundColor: "#EEF2F9",
              }
            }
            if(selectCallback && !isCheckBoxSelect){
              colorStyle = {
                ...colorStyle,
                cursor: "pointer"
              }
            }
            const operationCellStyles = {
              width: ".1%",
              paddingTop: (displayTag && !row.tag) && (16 + 10), // 元のpaddingTop + タグ部分のpaddingBottom
            };
            return (
              <React.Fragment key={index} >
                <TableRow
                  tabIndex={-1}
                  style={colorStyle}
                  onClick={(selectCallback && !isCheckBoxSelect) ? (e) => selectCallback(e, row) : null}
                  ref={isFocus ? focusRef : null}
                >
                  { headCells.map((cell, j) => {
                    const cellStyles = {};
                    if (cell.isTextCenter) {
                      cellStyles.textAlign = "center";
                    }
                    if (displayTag && !row.tag) {
                      cellStyles.paddingTop = 16 + 10; // 元のpaddingTop + タグ部分のpaddingBottom
                    }
                    let cellValue = "";
                    if(cell.isNest){
                      cell.id.split(".").forEach((d, i) => {
                        if(i === 0){
                          cellValue = row[d];
                        } else if(cellValue){
                          cellValue = cellValue[d];
                        }
                        if(!cellValue){
                          if(cell.id === "motionRef.title"){
                            cellValue = "[状態維持]";
                          } else if(cell.id === "phraseRef.phraseTitle"){
                            cellValue = "[再生しない]";
                          }
                        }
                      })
                    } else if(cell.isTimezone) {
                      return(
                        <TableCell key={j} style={cellStyles}>
                        { row[cell.id] && row[cell.id].map((timezone, timezoneIndex) => {
                          return(
                            <React.Fragment key={timezoneIndex}>
                              { TIME_ZONES.forEach((item, i) => {
                                if(item.key === timezone) {
                                  timezoneFlag[i] = true;
                                }
                              })}
                            </React.Fragment>
                          )
                        })}
                        { timezoneFlag.map((item, i) => {
                          let timezoneClass = item ? "timezoneBox" : "timezoneBoxDisabled";
                          if(cell.isMultiline && i === 1){
                            return(
                              <React.Fragment key={i}>
                                <span className={timezoneClass}>{TIME_ZONES[i]['name']}</span><br />
                              </React.Fragment>
                            )
                          } else {
                            return(
                              <span key={i} className={timezoneClass}>{TIME_ZONES[i]['name']}</span>
                            )
                          }
                        })}
                        </TableCell>
                      )
                    } else if(cell.isKeywords || cell.isKeywordGroups){
                      cellStyles.padding = "8px 0 0 0";
                      cellStyles.paddingTop = 8;
                      return(
                        <TableCell key={j} style={cellStyles}>
                        { row[cell.id] && row[cell.id].map((keyword, groupIndex) => {
                          return(
                            <React.Fragment key={groupIndex}>
                              <div className={classes.keywordListContainer} style={{ marginBottom: "4px" }}>
                                <div style={{ margin: "2px 0" }}>
                                  <span className="keywordNumber">{ groupIndex + 1 }.</span>
                                </div>
                                <div className={classes.flexKeywordList}>
                                  <div>
                                    { keyword.map((word, wordIndex) => {
                                      if(cell.isKeywords){
                                        return(
                                          <Tooltip key={wordIndex} placement="bottom" title={<span style={{ fontSize: "14px" }}>{posArray(word).join(", ")}</span>}>
                                            <span className={keywordStyle}>{ word && word.word }</span>
                                          </Tooltip>
                                        );
                                      } else {
                                        return(
                                          <span key={wordIndex} className={keywordStyle}>{ word && word.title }</span>
                                        )
                                      }
                                    }) }
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        }) }
                        </TableCell>
                      )
                    } else if(cell.isPhraseMaps){
                      cellStyles.padding = "8px 0 0 0";
                      cellStyles.paddingTop = 8;
                      return(
                        <TableCell key={j} style={cellStyles}>
                        { row[cell.id] && row[cell.id].map((p, groupIndex) => {
                          if(p.phraseType === "phrase" && p.isPhraseModelDiff){
                            faceDetectionPhraseStyle = classes.modelDiffWord;
                          }
                          return(
                            <React.Fragment key={groupIndex}>
                              <div style={{ display: "flex", alignItems: "center", marginBottom: "4px", justifyContent: "space-between" }}>
                                <div style={{ display: "inline-block" }}>
                                  <span className="keywordNumber">{ groupIndex + 1 }.</span>
                                  { p.phraseType === "organization" && <span className={faceDetectionStyle}>組織名</span> }
                                  { p.phraseType === "name" && <span className={faceDetectionStyle}>氏名</span> }
                                  { p.phraseType === "title" && <span className={faceDetectionStyle}>敬称</span> }
                                  { p.phraseType === "phrase" && <span className={faceDetectionPhraseStyle}>{ p.phraseRef && p.phraseRef.phraseTitle }</span> }
                                </div>
                              </div>
                            </React.Fragment>
                          )
                        }) }
                        </TableCell>
                      );
                    } else if(cell.isFlag) {
                      cellValue = row[cell.id];
                      if(cellValue){
                        return(
                          <TableCell key={j} style={cellStyles}><img src="/images/flag_true.png" alt="t" /></TableCell>
                        )
                      } else {
                        return(
                          <TableCell key={j} style={cellStyles}><img src="/images/flag_false.png" alt="f" /></TableCell>
                        )
                      }
                    } else if(cell.isSelectFlag){
                      let image = "/images/flag_false.png";
                      let alt = "f";
                      if(isSelected) {
                        image = "/images/flag_true.png";
                        alt = "t";
                      }
                      return (
                        <TableCell key={j} style={cellStyles}><img src={image} alt={alt} onClick={selectCallback ? (e) => selectCallback(e, row) : null} style={{ cursor: "pointer" }} /></TableCell>
                      );
                    } else if(cell.isKV) {
                      cellValue = cell.kv[row[cell.id]];
                    } else if(cell.isVirturalwall){
                      cellValue = row["virtualwallNo"] + " / " + row["virtualwallCourse"];
                    } else {
                      cellValue = row[cell.id];
                      if(cellValue === loginUid) loginUser = true;
                    }
                    if(cell.modFunc){
                      cellValue = cell.modFunc(cellValue);
                    }
                    if(cell.linkFunc){
                      return (
                        <TableCell key={j}>
                          <div onClick={(e) => cell.linkFunc(e, row)} style={{ cursor: "pointer", color: "#38496D", textDecoration: "underline" }}>
                            {cellValue}
                          </div>
                        </TableCell>
                      )
                    } else {
                      if((cell.id === "phraseRef.phraseTitle" && row.isPhraseModelDiff === true) || (cell.id === "motionRef.title" && row.isMotionModelDiff === true)){
                        cellStyles.color = "#F8431C";
                      }
                      return (
                        <TableCell key={j} style={cellStyles}>{cellValue}</TableCell>
                      )
                    }
                  }) }
                  { isOperationHidden !== true &&
                  <TableCell style={operationCellStyles}>
                    <div style={{ textAlign: "right", minWidth: operationMinWidth || "none" }}>
                      { editCallback && <EditButton onClick={(e) => editCallback(e, row)}/> }
                      { playCallback && <PlayButton onClick={(e) => playCallback(e, row)} style={{ marginLeft: "5px" }} /> }
                      { deleteCallback && <DeleteButton　onClick={(e) => deleteCallback(e, row, role)} style={{ marginLeft: "5px" }} disabled={loginUser} /> }
                      { rejectCallback && <RejectButton　onClick={(e) => rejectCallback(e, row, "reject")} style={{ marginLeft: "5px" }} /> }
                      { approveCallback && <ApproveButton　onClick={(e) => approveCallback(e, row, "approve")} style={{ marginLeft: "5px" }} /> }
                    </div>
                  </TableCell>
                  }
                </TableRow>
                { displayTag &&
                  <TableRow tabIndex={-1}
                    style={colorStyle}
                    onClick={(selectCallback && !isCheckBoxSelect) ? (e) => selectCallback(e, row) : null}>
                      { (typeof tagPosition === "number" && tagPosition >= 1) ?
                        <React.Fragment>
                          <TableCell colSpan={tagPosition - 1}></TableCell>
                          <TableCell colSpan={(isOperationHidden !== true ? headCells.length + 1 : headCells.length) - (tagPosition - 1)} >
                            <div className={classes.flexTagList}>
                              { row.tag && row.tag.split(" ").map((tag, index) => (
                                <span key={index} className={tagStyle} style={{ cursor: tagClickCallback ? "pointer" : "inherit" }} onClick={tagClickCallback ? ((e) => {e.stopPropagation(); tagClickCallback(e, tag)}) : null} >{tag}</span>
                              ))}
                            </div>
                          </TableCell>
                        </React.Fragment>
                      :
                        <TableCell colSpan={isOperationHidden !== true ? headCells.length + 1 : headCells.length} >
                          <div className={classes.flexTagList}>
                            { row.tag && row.tag.split(" ").map((tag, index) => (
                              <span key={index} className={tagStyle} style={{ cursor: tagClickCallback ? "pointer" : "inherit" }} onClick={tagClickCallback ? ((e) => {e.stopPropagation(); tagClickCallback(e, tag)}) : null} >{tag}</span>
                            ))}
                          </div>
                        </TableCell>
                      }
                  </TableRow>
                }
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
