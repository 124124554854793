//import firebase from 'firebase/app';
import 'firebase/firestore';
import db, {getDocsData, getDocsOrderBy} from '../utilities/db';
import { appErrorAction } from './appAction';

export async function motionManagement(dispatch, cid, begin, count) {
  try {
    const companyDoc = db().collection("companies").doc(cid);
    const [items, models] = await Promise.all([
      getDocsOrderBy(companyDoc.collection("motions"), "motion", [{ column: "modelRef", type: "asc" }, { column: "title", type: "asc" }]),
      getDocsData(companyDoc.collection("models"), "model"),
    ]);
    items.forEach(item => {
      if(item.modelRef){
        item.modelRef = models.find(x => x.model === item.modelRef.id);
      }
    });
    dispatch(motionsAction(items, models));
  } catch (e) {
    dispatch(appErrorAction(e));
  }
}

export function searchMotions(dispatch, searchModel) {
  dispatch(searchMotionsAction(searchModel));
}

function motionsAction(motions, models) {
  return {
    type: 'MOTIONS',
    payload: {
      motions: motions,
      models: models,
    }
  }
}

function searchMotionsAction(searchModel) {
  return {
    type: 'SEARCH_MOTIONS',
    payload: {
      searchModel: searchModel,
    }
  };
}
