import db, { getDocData, getDocsOrderBy, getModelsData, getPhrasesData, getPicturesData, getMotionsDataExcludeNod } from "../utilities/db";
import firebase from 'firebase/app';
import { appErrorAction, dbSetDataFailedErrorAction } from './appAction';
import { callItems } from "../externalCommands";
import { characterExists, characterNotFoundErrorAction, DIGITAL_IN_NUM } from "./characters";
import { zeroPadding, formatTag } from "../utilities/utils";

export async function listExternalCommands(dispatch, cid, label, character, characterNotFoundCallback, isInit=false){
  try{
    // console.log(cid, label, character);
    const companyDoc = db().collection("companies").doc(cid);
    const labelDoc = companyDoc.collection("labels").doc(label);
    const characterDoc = companyDoc.collection("labels").doc(label).collection("characters").doc(character);
    let par = [
      getModelsData(companyDoc),
      getDocData(characterDoc, "character"),
      getPhrasesData(companyDoc),
      getMotionsDataExcludeNod(companyDoc),
      getPicturesData(labelDoc),
    ];
    const [models, characterData, phrases, motions, pictures] = await Promise.all(par);
    if (characterData === null) {
      dispatch(characterNotFoundErrorAction());
      characterNotFoundCallback();
      return;
    }
    characterData.modelRef = characterData.modelRef && models.find(x => x.model === characterData.modelRef.id);
    const items = await getDocsOrderBy(characterDoc.collection("externalCommands"), "externalCommand", [{ column: "updatedAt", type: "desc" }]);
    items.forEach(item => {
      // フレーズなどはDocumentReferenceが入ってくるので、それぞれの一覧用配列から値を参照できるようにデータを持ってくる
      item.isPhraseModelDiff = false;
      if(item.phraseRef) {
        item.phraseRef = phrases.find(x => x.phrase === item.phraseRef.id);
        item.isPhraseModelDiff = item.phraseRef.modelRef.id !== characterData.modelRef.model;
      }
      item.isMotionModelDiff = false;
      if(item.motionRef) {
        item.motionRef = motions.find(x => x.motion === item.motionRef.id);
        item.isMotionModelDiff = item.motionRef.modelRef.id !== characterData.modelRef.model;
      }
      if(item.pictureRef) item.pictureRef = pictures.find(x => x.picture === item.pictureRef.id);
      if(item.faceDetectionPhraseMaps && item.faceDetectionPhraseMaps.length){
        item.faceDetectionPhraseMaps.forEach((p) => {
          if(p.phraseType === "phrase" && p.phraseRef){
            p.phraseRef = phrases.find(x => x.phrase === p.phraseRef.id);
            p.isPhraseModelDiff = p.phraseRef.modelRef.id !== characterData.modelRef.model;
          }
        });
      }
    });
    dispatch({
      type: "EXTERNAL_COMMAND_LIST",
      list: items,
      models: models,
      phrases: phrases,
      motions: motions,
      characterData: characterData,
      isInit: isInit,
    });
  } catch(e){
    dispatch(appErrorAction(e));
  }
}

export function searchExternalCommand(dispatch, search, searchMode) {
  dispatch({
    type: "SEARCH_EXTERNAL_COMMAND",
    payload: {
      search: search,
      searchMode: searchMode,
    },
  });
}

export async function beginEditExternalCommand(dispatch, cid, label, character, externalCommand, mode){
  const companyDoc = db().collection("companies").doc(cid);
  const labelDoc = companyDoc.collection("labels").doc(label);
  const characterDoc = labelDoc.collection("characters").doc(character);
  let par = [
    getModelsData(companyDoc),
    getDocData(characterDoc, "character"),
    getPhrasesData(companyDoc),
    getMotionsDataExcludeNod(companyDoc),
    getPicturesData(labelDoc),
  ];
  const [models, characterData, phrases, motions, pictures] = await Promise.all(par);
  dispatch({
    type: "EXTERNAL_COMMAND_BEGIN_EDIT",
    externalCommand: externalCommand,
    mode: mode,
    models: models,
    characterData: characterData,
    phrases: phrases,
    motions: motions,
    pictures: pictures,
  });
}

export async function changeExternalCommand(dispatch, editing){
  dispatch({
    type: "EXTERNAL_COMMAND_EDITING_CHANGE",
    editing: editing,
  });
}

export async function closeExternalCommand(dispatch){
  dispatch({ type: "EXTERNAL_COMMAND_CLOSE" });
}

export async function cancelExternalCommand(dispatch){
  dispatch({ type: "EXTERNAL_COMMAND_CANCEL" });
}

export async function deleteExternalCommandExec(dispatch, cid, label, character, externalCommand){
  try{
    const companyDoc = db().collection("companies").doc(cid);
    const characterDoc = companyDoc.collection("labels").doc(label).collection("characters").doc(character);
    const externalCommandDoc = characterDoc.collection("externalCommands").doc(externalCommand);
    const exists = await externalCommandExists(dispatch, externalCommandDoc);
    if (!exists) return;
    let canDelete = true;
    let characterData = await characterDoc.get();
    if(characterData.exists && characterData.data().digitalInSettings){
      for(let i = 0; i < DIGITAL_IN_NUM; i++){
        const columnName = "digitalIn" + zeroPadding(i, 2);
        if(characterData.data().digitalInSettings[columnName]){
          if(characterData.data().digitalInSettings[columnName].externalCommandRef && characterData.data().digitalInSettings[columnName].externalCommandRef.id === externalCommandDoc.id){
            canDelete = false;
            break;
          }
        }
      }
    }
    if(!canDelete){
      dispatch(appErrorAction(["この外部コマンドは", "他から参照されているので削除できません。"]));
    } else {
      await externalCommandDoc.delete();
    }
    dispatch({
      type: "EXTERNAL_COMMAND_DELETED",
    });
  } catch(e){
    dispatch(appErrorAction(["データの削除ができませんでした。", "時間を置いて再度作業を行ってください。"]));
  }
}

export async function updateExternalCommand(dispatch, cid, label, character, editing, characterNotFoundCallback){
  const companyDoc = db().collection("companies").doc(cid)
  const labelDoc = companyDoc.collection("labels").doc(label);
  const characterDoc = labelDoc.collection("characters").doc(character);
  let doc;
  try{
    // console.log(editing);
    const data = {};
    data.title = editing.title;
    data.tag = formatTag(editing.tag);
    data.mode = editing.mode;
    data.faceDetectionType = editing.faceDetectionType;
    data.faceDetectionPhraseMaps = editing.faceDetectionPhraseMaps.filter(x => x && x.phraseType).map(x => {
      // console.log(x);
      return {
        phraseType: x.phraseType,
        phraseRef: x.phraseRef ? companyDoc.collection("phrases").doc(x.phraseRef) : null,
      }
    });
    data.motionRef = editing.motionRef ? companyDoc.collection("motions").doc(editing.motionRef) : null;
    data.phraseRef = editing.phraseRef ? companyDoc.collection("phrases").doc(editing.phraseRef) : null;
    data.pictureRef = editing.pictureRef ? labelDoc.collection("guide-pictures").doc(editing.pictureRef) : null;
    data.digitalOut = editing.digitalOut !== "" ? editing.digitalOut: null;
    data.digitalOutOneShotTime = Number(editing.digitalOutOneShotTime.toString().replace(/,/g, ""));
    data.timeoutType = editing.timeoutType;
    data.timeoutSec = Number(editing.timeoutSec.toString().replace(/,/g, ""));
    if(data.timeoutType === "Instantly") data.timeoutSec = 0;
    data.playType = editing.playType;
    data.callFlag = editing.callFlag;
    data.emergencyFlag = editing.emergencyFlag;
    if(data.callFlag){
      callItems.forEach(c => {
        const callData = {};
        callData.motionRef = editing[c.id].motionRef ? companyDoc.collection("motions").doc(editing[c.id].motionRef) : null;
        callData.phraseRef = editing[c.id].phraseRef ? companyDoc.collection("phrases").doc(editing[c.id].phraseRef) : null;
        callData.pictureRef = editing[c.id].pictureRef ? labelDoc.collection("guide-pictures").doc(editing[c.id].pictureRef) : null;
        callData.digitalOut = editing[c.id].digitalOut !== "" ? editing[c.id].digitalOut : null;
        callData.digitalOutOneShotTime = Number(editing[c.id].digitalOutOneShotTime.toString().replace(/,/g, ""));
        callData.timeoutType = editing[c.id].timeoutType;
        callData.timeoutSec = Number(editing[c.id].timeoutSec.toString().replace(/,/g, ""));
        if(callData.timeoutType === "Instantly") callData.timeoutSec = 0;
        callData.playType = editing[c.id].playType;
        data[c.id] = callData;
      });
    } else {
      data.callSuccess = null;
      data.callFail = null;
      data.callReject = null;
    }
    data.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
    // console.log(data);
    if (!editing.externalCommand) {
      // 新規
      doc = characterDoc.collection("externalCommands").doc();
      data.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      await doc.set(data);
      // console.log(doc);
    } else {
      // 更新
      doc = characterDoc.collection("externalCommands").doc(editing.externalCommand);
      const exists = await externalCommandExists(dispatch, doc);
      if (!exists) return false;
      await doc.update(data);
    }
    const updated = await doc.get();
    if (!updated.exists) {
      dispatch(externalCommandNotFoundErrorAction());
      dispatch(refreshExternalCommandAction());
      return false;
    }
    editing.tag = updated.data().tag;
    editing.createdAt = updated.data().createdAt;
    editing.updatedAt = updated.data().updatedAt;
    editing.externalCommand = doc.id;
    dispatch({
      type: "EXTERNAL_COMMAND_UPDATED",
      editing: editing,
    });
    return true;
  } catch(e) {
    // セキュリティールールありだとpermission-denied、なしだとnot-foundエラーが返る
    if (e && e.name === 'FirebaseError' && (e.code === 'permission-denied' || e.code === 'not-found')) {
      const exists = await characterExists(dispatch, cid, label, character);
      if (!exists) {
        characterNotFoundCallback();
        return false;
      }
      if (editing.externalCommand && doc) {
        // 更新の場合
        const editingCommandExists = await externalCommandExists(dispatch, doc);
        if (!editingCommandExists) return false;
      }
      dispatch(dbSetDataFailedErrorAction());
      return false;
    }
    dispatch(appErrorAction(e));
  }
  return false;
}

async function externalCommandExists(dispatch, doc) {
  const data = await doc.get();
  if (!data.exists) {
    dispatch(externalCommandNotFoundErrorAction());
    dispatch(refreshExternalCommandAction());
    return false;
  }
  return true;
}

function refreshExternalCommandAction() {
  return { type: "EXTERNAL_COMMAND_REFRESH" };
}

function externalCommandNotFoundErrorAction() {
  return appErrorAction(["指定された外部コマンドは存在しません。","他のアカウントにより削除された可能性があります。"]);
}
