import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { appRemoveError } from './actions/appAction';
import { withStyles, DialogContent } from '@material-ui/core';
import muiStyles from "./MuiStyles";
import { StyledDialog, CloseButton } from './AppParts';

export class ErrorMessage extends Component {

  componentDidMount(){
    // appRemoveError(this.props.dispatch);
  }

  componentDidUpdate(){
    // console.log(this.props);
  }

  onClose = (e) => {
    e.preventDefault();
    appRemoveError(this.props.dispatch);
  }

  render(){
    const { classes } = this.props;
    return(
      <StyledDialog open={this.props.error ? true : false} onClose={this.onClose}>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.closeButton}>
            <CloseButton onClick={this.onClose}/>
          </div>
          <div className="popupContentText">
            { typeof(this.props.error) === "string" &&
              <p>{ this.props.error }</p>
            }
            { Array.isArray(this.props.error) &&
              <React.Fragment>
              { this.props.error.map((str, i) => {
                return(
                  <p key={i}>{ str }</p>
                )
              }) }
              </React.Fragment>
            }
          </div>
        </DialogContent>
      </StyledDialog>
    )
  }

}

export default withRouter(withStyles(muiStyles)(
  connect(state => ({
    error: state.appState && state.appState.error,
  }))
(ErrorMessage)));
