import { PICTURE_RETAIN_ID } from "../actions/contents";
import { isEqualExceptFalsy, searchByTag } from "../utilities/utils";

const defaultState = {
  contents: [],
  searchedContents: [],
  search: "",
  searchStanbyFlag: false,
  searchContentType: null,
  paths: [],
  originalContent: null,
  editingContent: null,
  isRefresh: false,
  selectKeywordGroup: defaultSelectKeywordGroup(),
  pathNetwork: [],
  nextContentRefs: [],
};

function defaultSelectKeywordGroup(){
  return {
    originalList: [],
    searchList: [],
    selectedList: [],
    keywordGroups: [],
  }
}

function defaultContent() {
  return {
    content: null,
    title: "",
    stanbyFlag: true,
    type: "success",
    keywordGroups: [],
    phraseRef: null,
    motionref: null,
    playType: "SyncSoundAndMotion",
    pictureRef: null,
    pictureRetainFlag: false,
    childTallMotionRef: null,
    callFlag: false,
    emergencyFlag: false,
    nextContentRefs: [],
  };
}

function setBeginEditingData(editingContent, phraseMenuItems, motionMenuItems, character, paths, contentType){
  // modelが異なる場合の処理
  if(editingContent.isPhraseModelDiff) {
    const existPhraseData = phraseMenuItems.find(x => x.phrase === editingContent.phraseRef.phrase);
    if (!existPhraseData) phraseMenuItems.push({ ...editingContent.phraseRef, disabled: true });
  }
  if(editingContent.isMotionModelDiff) {
    const existMotionData = motionMenuItems.find(x => x.motion === editingContent.motionRef.motion);
    if (!existMotionData) motionMenuItems.push({ ...editingContent.motionRef, disabled: true });
  }
  const standbyContent = character.stanbyContentRefs.find(x => x.id === editingContent.content);
  editingContent.stanbyFlag = standbyContent ? true : false;
  if(editingContent.phraseRef) editingContent.phraseRef = editingContent.phraseRef.phrase;
  if(editingContent.motionRef) editingContent.motionRef = editingContent.motionRef.motion;
  if(editingContent.pictureRef) editingContent.pictureRef = editingContent.pictureRef.id;
  if(editingContent.childTallMotionRef) editingContent.childTallMotionRef = editingContent.childTallMotionRef.id;
  editingContent.nextContentRefs = [];
  if(paths && paths.length){
    const nextContentRefs = [];
    paths[0].nextContents.forEach((item, i) => {
      nextContentRefs.push(item.id);
      editingContent["nextContentRefs" + i] = item.id;
    });
    editingContent.nextContentRefs = nextContentRefs;
  }
  if(editingContent.pictureRetainFlag){
    editingContent.pictureRef = PICTURE_RETAIN_ID;
  }
  editingContent.type = contentType;
}

function searchList(list, search, searchContentType, searchStanbyFlag) {
  let searchedList = [ ...list ];
  if(search){
    searchedList = searchByTag(searchedList, "title", search);
  }
  if(searchContentType){
    searchedList = searchedList.filter(x => x.contentType === searchContentType);
  } else if(searchStanbyFlag){
    searchedList = searchedList.filter(x => x.stanbyFlag);
  }
  return searchedList;
}

const contents = (state = defaultState, action) => {
  let editingContent, selectKeywordGroup;
  switch(action.type) {
    case 'CONTETNT_LIST':
      let obj = {
        ...state,
        contents: action.payload.list,
        searchedContents: searchList(action.payload.list, state.search, state.searchContentType, state.searchStanbyFlag),
        characterData: action.payload.character,
        isRefresh: false,
      }
      if(action.payload.isInit){
        obj.editingContent = null;
      }
      return obj;
    case "CONTETNT_SEARCH":
      return {
        ...state,
        search: action.payload.search,
        searchStanbyFlag: action.payload.searchStanbyFlag,
        searchContentType: action.payload.searchContentType,
        searchedContents: searchList(state.contents, action.payload.search, action.payload.searchContentType, action.payload.searchStanbyFlag),
      }
    case 'CONTENT_BEGIN_EDIT':
      editingContent = { ...state.contents.find(item => {
        return item.content === action.payload.content;
      })};

      const phraseMenuItems = (state.characterData.modelRef && action.payload.phrases.filter(x => x.modelRef.id === state.characterData.modelRef.id)) || [];
      const motionMenuItems = (state.characterData.modelRef && action.payload.motions.filter(x => x.modelRef.id === state.characterData.modelRef.id).filter(x => x.title.indexOf("緊急地震速報") === -1)) || [];
      if(!editingContent.content) {
        editingContent = defaultContent();
      } else {
        setBeginEditingData(editingContent, phraseMenuItems, motionMenuItems, action.payload.character, action.payload.paths, action.payload.contentType);
      }
      const contentMenuItems = state.contents.filter(x => x.content !== editingContent.content).sort((a, b) => {
        if(a.title < b.title) return -1;
        if(a.title > b.title) return 1;
        return 0;
      });
      editingContent.isEditingUpdate = false;
      return {
        ...state,
        originalContent: editingContent,
        editingContent: editingContent,
        phrases: action.payload.phrases,
        motions: action.payload.motions,
        pictures: action.payload.pictures,
        phraseMenuItems: phraseMenuItems,
        motionMenuItems: motionMenuItems,
        contentMenuItems: contentMenuItems,
        keywrodGroups: action.payload.keywrodGroups,
        paths: action.payload.paths,
        character: action.payload.character,
      }
    case "CONTENT_EDITING_CHANGE":
      editingContent = { ...action.payload.editingContent };
      // originalContentと比較するためいったんfalseにする
      editingContent.isEditingUpdate = false;
      editingContent.isEditingUpdate = isEqualExceptFalsy(state.originalContent, editingContent) ? false : true;
      return {
        ...state,
        editingContent: editingContent
      }
    case "CONTENT_BEGIN_SELECT_KEYWORD_GROUP":
      selectKeywordGroup = defaultSelectKeywordGroup();
      selectKeywordGroup.keywordGroups = action.payload.keywordGroups;
      selectKeywordGroup.originalList = [...action.payload.keywordGroupList];
      selectKeywordGroup.searchList = action.payload.keywordGroupList;
      return {
        ...state,
        selectKeywordGroup: selectKeywordGroup
      }
    case "CONTENT_CHANGE_SELECT_KEYWORD_GROUP":
      editingContent = {...action.payload.editingContent};
      // originalContentと比較するためいったんfalseにする
      editingContent.isEditingUpdate = false;
      editingContent.isEditingUpdate = isEqualExceptFalsy(state.originalContent, editingContent) ? false : true;
      return {
        ...state,
        selectKeywordGroup: action.payload.selectKeywordGroup,
        editingContent: editingContent,
      }
    case "CONTENT_ADD_KEYWORD_GROUPS":
      selectKeywordGroup = state.selectKeywordGroup;
      selectKeywordGroup.selectedList = [];
      return{
        ...state,
        selectKeywordGroup: selectKeywordGroup
      }
    case "CONTENT_SELECT_CONTENT":
      editingContent = state.contents.find(x => x.content === action.payload.content);
      return {
        ...state,
        editingContent: editingContent
      }
    case "CONTENT_CLOSE":
      return {
        ...state,
        editingContent: null,
      }
    case "CONTENT_EDIT_CANCEL":
      let beforeEditing = state.contents.find(item => {
        return item.content === state.editingContent.content;
      });
      beforeEditing = {...beforeEditing};
      if(!beforeEditing.content) {
        beforeEditing = defaultContent();
      } else {
        setBeginEditingData(beforeEditing, state.phraseMenuItems, state.motionMenuItems, state.character, state.paths, action.contentType);
      }
      beforeEditing.test = new Date().getTime();
      beforeEditing.isEditingUpdate = false;
      return {
        ...state,
        originalContent: beforeEditing,
        editingContent: beforeEditing,
      }
    case "CONTENT_UPDATED":
      editingContent = { ...action.editing };
      editingContent.isEditingUpdate = false;
      return{
        ...state,
        originalContent: editingContent,
        editingContent: editingContent,
        paths: action.paths,
        isRefresh: true
      }
    case "CONTENT_DELETED":
      return{
        ...state,
        editingContent: null,
        isRefresh: true
      }
    case "REFRESH_CONTENT":
      return{
        ...state,
        editingContent: null,
        isRefresh: true
      }
    case "CONTENT_PATH_NETWORK":
      return {
        ...state,
        pathNetwork: action.pathNetwork
      }
    default :
      return state;
    }
}

export default contents;
