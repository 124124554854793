const defaultState = {
  vguards: [],
  editingVguard: null,
  characters: [],
  isInit: false,
  showUpdateToast: false,
};

const vguardDefault = {
  using: false,
  settings: {
    scheduleEnabled: false,
    callTimeoutSec: 20,
    faceDetection: {
      faceDetectionEnabled: true,
      faceDetectionServerIP: null,
      faceDetectionServerIP1: "",
      faceDetectionServerIP2: "",
      faceDetectionServerIP3: "",
      faceDetectionServerIP4: "",
    },
    speechDetection: {
      noddingMotionEnabled: true,
      textDisplayEnabled: false,
      srsResultDisplayEnabled: false,
    }
  }
}

const vguards = (state = defaultState, action) => {
  let editData;
  switch (action.type) {
    case "LIST_VGUARDS":
      return {
        ...state,
        vguards: action.payload.vguards,
        isInit: action.payload.isInit,
        characters: action.payload.characters,
      }
    case "BEGIN_EDIT_VGUARD":
      if(!action.payload.vguard){
        return {
          ...state,
          isInit: false,
          editingVguard: null,
        }
      }
      const d = state.vguards.find(x => x.vguard === action.payload.vguard);
      editData = {...d};
      if(editData.settings.characterRef){
        editData.settings.characterRef = editData.settings.characterRef.character;
      }
      // もとのデータがないときでも更新できるように値をセットする
      if(!editData.settings.faceDetection) editData.settings.faceDetection = vguardDefault.settings.faceDetection;
      if(!editData.settings.speechDetection) editData.settings.speechDetection = vguardDefault.settings.speechDetection;
      if(!editData.settings.scheduleEnabled) editData.settings.scheduleEnabled = vguardDefault.settings.scheduleEnabled;
      if(!editData.settings.callTimeoutSec && editData.settings.callTimeoutSec !== 0) editData.settings.callTimeoutSec = vguardDefault.settings.callTimeoutSec;
      return {
        ...state,
        isInit: false,
        editingVguard: editData,
      }
    case "CHANGE_VGUARD":
      return {
        ...state,
        editingVguard: action.payload.editingVguard,
      }
    case "UPDATED_VGUARD":
      return {
        ...state,
        changed: action.payload.changed,
        showUpdateToast: action.payload.showUpdateToast,
      }
    case "VGAURD_HIDE_TOAST":
      return {
        ...state,
        showUpdateToast: false,
      }
    default:
      return state;
  }
}

export default vguards;
