import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import { beginEdit, changeEditing, approvalAccountExec, autoLoginExecute } from './actions/addAccount';
import { withStyles, MenuItem, DialogContent } from '@material-ui/core';
import { onLoading, offLoading } from './actions/loading';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { renderSimpleTextField, renderSimpleSelectField } from './formRenderer';
import { required, minLength6, emailRegexp } from './validator';
import ErrorMessage from './errorMessage';
import { appError, appRemoveError } from './actions/appAction';
import muiStyles from "./MuiStyles";
import TopBar from "./TopBar";
import { PrimaryButton, StyledDialog, CloseButton } from './AppParts';

export class AddAccount extends Component {

  state = {
    error: null
  }

  componentDidMount() {
    this.onBeginEdit();
  }

  async componentDidUpdate() {
    if(!this.props.user && this.props.approvalWaiting && this.props.autoLoginUser && this.props.autoLoginUser.email){
      const cid = this.props.match.params && this.props.match.params.cid;
      await autoLoginExecute(this.props.dispatch, cid, this.props.autoLoginUser);
    }
    if (this.props.user) {
      // ログイン状態になったらメインに遷移
      const cid = this.props.match.params && this.props.match.params.cid;
      this.props.history.push("/" + cid);
      return;
    }
  }

  onBeginEdit = () => {
    appRemoveError(this.props.dispatch);
    beginEdit(this.props.dispatch);
  }

  onCloseEdit = (e) => {
    e.preventDefault();
    appRemoveError(this.props.dispatch);
    this.setState({
      error: null,
    });
  };

  onEditingChange = (e) => {
    e.preventDefault();
    const editingAccount = { ...this.props.editingAccount };
    editingAccount[e.target.name] = e.target.value;
    changeEditing(this.props.dispatch, editingAccount);
  }

  onApproval = async (values) => {
    const editingAccount = { ...values };
    if (editingAccount.password !== editingAccount.passwordConfirm) {
      appError(this.props.dispatch, "パスワードが一致しません");
      return;
    }
    appRemoveError(this.props.dispatch);
    const cid = this.props.match.params && this.props.match.params.cid;
    onLoading(this.props.dispatch);
    await approvalAccountExec(this.props.dispatch, cid, editingAccount);
    offLoading(this.props.dispatch);
    this.setState({
      error: null,
      showExecError: true
    });
  }

  onBack = (e) => {
    e.preventDefault();
    const cid = this.props.match.params && this.props.match.params.cid;
    this.props.history.push("/" + cid);
  }

  render() {
    const { handleSubmit, submitting, classes } = this.props
    return (
      <React.Fragment>
        <TopBar />
        <ErrorMessage />
        <div className={classes.content}>
          <div>
            {(this.props.editingAccount && !this.props.authorized && !this.props.approvalWaiting) &&
              <React.Fragment>
                <form className={classes.accountForm} onSubmit={handleSubmit(this.onApproval)} style={{ width: 1280, height: "calc(100vh - 20px)", minHeight: 550 }}>
                  <div style={{ width: "100%" }}>
                    <div className="alignCenter verticalCenter" style={{ marginTop: 46 }}>
                      <p className="popupTitleText">監視ツール</p>
                    </div>
                    <div className="alignCenter verticalCenter" style={{ marginTop: -22 }}>
                      <p className="popupTitleText">アカウント新規登録画面</p>
                    </div>
                    <div className="alignCenter verticalCenter" style={{ marginTop: 40 }}>
                      <span className="grayText" style={{ marginLeft: -42, textAlign: "right" }}>表示名</span>
                      <Field name="displayName" type="text" className="fieldText" style={{ width: 380, height: 40, marginLeft: 16 }} component={renderSimpleTextField} validate={[required]} size={"md"} />
                    </div>
                    <div className="alignCenter verticalCenter" style={{ marginTop: 40 }}>
                      <span className="grayText" style={{ marginLeft: -98, textAlign: "right" }}>メールアドレス</span>
                      <Field name="email" type="text" className="fieldText" style={{ width: 380, height: 40, marginLeft: 16 }} component={renderSimpleTextField} validate={[required, emailRegexp]} size={"md"} />
                    </div>
                    <div className="alignCenter verticalCenter" style={{ marginTop: 40 }}>
                      <span className="grayText" style={{ marginLeft: -70, textAlign: "right" }}>パスワード</span>
                      <Field name="password" type="password" className="fieldText" style={{ width: 380, height: 40, marginLeft: 16 }} component={renderSimpleTextField} validate={[required, minLength6]} size={"md"} />
                    </div>
                    <div className="alignCenter verticalCenter" style={{ marginTop: 40 }}>
                      <span className="grayText" style={{ marginLeft: -98, textAlign: "right" }}>パスワード確認</span>
                      <Field name="passwordConfirm" type="password" className="fieldText" style={{ width: 380, height: 40, marginLeft: 16 }} component={renderSimpleTextField} validate={[required, minLength6]} size={"md"} />
                    </div>
                    <div className="alignCenter verticalCenter" style={{ marginTop: 40 }}>
                      <span className="grayText" style={{ marginLeft: 414, minInlineSize: "fit-content" }}>権限</span>
                      <Field name="role" className="fieldText" component={renderSimpleSelectField} size="bg" style={{ width: 380, marginLeft: 16, textAlignLast: "center" }}>
                        <MenuItem value={"tool-manager"} className="approvalRequestText">管理者</MenuItem>
                        <MenuItem value={"tool-user"} className="approvalRequestText">利用者</MenuItem>
                      </Field>
                    </div>
                    <div className="alignCenter verticalCenter requestGrayText" style={{ marginTop: 24 }}>
                      <p>※ 監視ツールアカウントは管理者に承認されるまで有効になりません。</p>
                    </div>
                    <p className="alignCenter verticalCenter" style={{ margin: "40px auto" }}>
                      <PrimaryButton width={240} height={48} type="submit" disabled={submitting}>承認リクエスト発行</PrimaryButton>
                    </p>
                  </div>
                </form>
              </React.Fragment>
            }
            {this.props.approvalWaiting &&
              <React.Fragment>
                <div className={classes.accountForm} style={{ width: 1280, height: "calc(100vh - 20px)", minHeight: 550 }}>
                  <div style={{ width: "100%" }}>
                    <div className="alignCenter verticalCenter" style={{ marginTop: 46 }}>
                      <p className="popupTitleText">承認リクエスト発行</p>
                    </div>
                    <div className="alignCenter verticalCenter" style={{ marginTop: -5 }}>
                      <p className="approvalRequestWaitText">リクエストが承認されるまでしばらくお待ち下さい。</p>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            }
            <StyledDialog open={this.props.isApprovalReject ? true : false} onClose={this.onBack}>
              <DialogContent className={classes.dialogContent}>
                <div className={classes.closeButton}>
                  <CloseButton onClick={this.onBack}/>
                </div>
                <div className="popupContentText">
                  <p>承認リクエストが拒否されました</p>
                </div>
              </DialogContent>
            </StyledDialog>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(muiStyles)(
  connect(state => ({
    user: state.user && state.user.user,
    editingAccount: state.addAccount && state.addAccount.editingAccount,
    authorized: state.addAccount && state.addAccount.authorized,
    approvalWaiting: state.addAccount && state.addAccount.approvalWaiting,
    autoLoginUser: state.addAccount && state.addAccount.autoLoginUser,
    isApprovalReject: state.addAccount && state.addAccount.isApprovalReject,
    loading: state.loading && state.loading.loading,
    initialValues: state.addAccount && state.addAccount.editingAccount,
    formValues: getFormValues("addAccountForm")(state),
  }))(reduxForm({ form: 'addAccountForm', enableReinitialize: true })(AddAccount))
));