import { DIGITAL_IN_NUM } from "../actions/characters";
import { zeroPadding } from "../utilities/utils";

const defaultState = {
  characters: [],
  editingCharacter: null,
  models: [],
  motions: [],
  motionMenuItems: [],
  isCreated: false,
  isUpdated: false,
};

function defaultCharacter(){
  return{
    character: null,
    copyCharacter: "",
    name: "",
    modelRef: null,
    idlingMotionRef: null,
    stanbyContentRefs: [],
    digitalInSettings: {},
    manualCommandGroupsOrder: [],
  }
}

const characters = (state = defaultState, action) => {
  // console.log(action.type);
  let editingCharacter, motionMenuItems, orderManualCommandGroups;
  switch (action.type) {
    case "LIST_CHARACTERS":
      return {
        ...state,
        characters: action.payload.characters,
        models: action.payload.models,
        motions: action.payload.motions,
        charactersExternalCommands: action.payload.charactersExternalCommands,
        manualCommandGroups: action.payload.manualCommandGroups,
        isCreated: false,
        isUpdated: false,
      }
    case "BEGIN_EDIT_CHARACTERS":
      const d = state.characters.find(c => {
        return c.character === action.payload.character;
      });
      // console.log(state.characters, d);
      if(d){
        editingCharacter = {...d};
      } else {
        editingCharacter = defaultCharacter();
        new Array(DIGITAL_IN_NUM).fill(null).forEach((_, i) => {
          const columnName = "digitalIn" + zeroPadding(i, 2);
          editingCharacter.digitalInSettings[columnName] = { title: null, condition: null, externalCommandRef: null };
        });
      }
      // モデルの初期値
      if(editingCharacter.modelRef){
        editingCharacter.modelRef = editingCharacter.modelRef.model;
      }
      // idlingMotionの初期値
      if(editingCharacter.idlingMotionRef){
        editingCharacter.idlingMotionRef = editingCharacter.idlingMotionRef.motion;
      }
      // digitalInの初期値
      if(editingCharacter.digitalInSettings){
        new Array(DIGITAL_IN_NUM).fill(null).forEach((_, i) => {
          const columnName = "digitalIn" + zeroPadding(i, 2);
          // console.log(columnName, editingCharacter.digitalInSettings[columnName]);
          if(editingCharacter.digitalInSettings[columnName] && editingCharacter.digitalInSettings[columnName].externalCommandRef && typeof(editingCharacter.digitalInSettings[columnName].externalCommandRef) !== "string"){
            editingCharacter.digitalInSettings[columnName].externalCommandRef = editingCharacter.digitalInSettings[columnName].externalCommandRef.id;
          }
          // console.log(editingCharacter.digitalInSettings[columnName].externalCommandRef);
        });
      }

      // Selectのメニュー用のmotions配列を作成
      motionMenuItems = state.motions.filter(x => x.modelRef.model === editingCharacter.modelRef);
      // console.log(editingCharacter);

      // 手動操作コマンドの並び順(設定がない場合は一覧の並び順)
      orderManualCommandGroups = [...state.manualCommandGroups];
      if(editingCharacter.manualCommandGroupsOrder && editingCharacter.manualCommandGroupsOrder.length){
        orderManualCommandGroups = [];
        for(let manualCommandGroup of editingCharacter.manualCommandGroupsOrder){
          const m = state.manualCommandGroups.find(x => x.id === manualCommandGroup.id);
          if(m) orderManualCommandGroups.push(m);
        }
      }

      return {
        ...state,
        editingCharacter: editingCharacter,
        motionMenuItems: [...motionMenuItems],
        orderManualCommandGroups: orderManualCommandGroups,
        isCreated: false,
        isUpdated: false,
      }
    case "CHANGE_EDITING_CHARACTER":
      editingCharacter = action.payload.editingCharacter;
      motionMenuItems = state.motions;
      if(editingCharacter.modelRef){
        motionMenuItems = state.motions.filter(x => x.modelRef.model === editingCharacter.modelRef);
        if(action.payload.target === "modelRef"){
          editingCharacter.idlingMotionRef = motionMenuItems.length ? motionMenuItems[0].motion : null;
        }
      }
      return {
        ...state,
        editingCharacter: editingCharacter,
        motionMenuItems: [...motionMenuItems],
      }
    case "CHANGE_MANUAL_COMMAND_GROUP_ORDER_CHARACTER":
      return {
        ...state,
        orderManualCommandGroups: action.orderManualCommandGroups,
      }
    case "CREATED_CHARACER":
      return {
        ...state,
        editingCharacter: action.payload.editingCharacter,
        isCreated: true,
      }
    case "UPDATED_CHARACTER":
      return {
        ...state,
        editingCharacter: action.payload.editingCharacter,
        charactersExternalCommands: action.payload.charactersExternalCommands,
        isUpdated: true,
      }
    case "DELETED_CHARACTER":
      const characters = [...state.characters];
      return {
        ...state,
        characters: characters.filter(x => x.character !== action.payload.character),
      }
    case "CHARACTER_CLOSE_EDIT":
      return {
        ...state,
        editingCharacter: null,
      }
    case "UPDATE_CHARACTERS_EXTERNAL_COMMANDS":
      const charactersExternalCommands = { ...state.charactersExternalCommands };
      charactersExternalCommands[action.payload.character] = action.payload.externalCommands;
      return {
        ...state,
        charactersExternalCommands: charactersExternalCommands,
      }
    default:
      return state;
  }
};

export default characters;
