import db, { getDocsOrderBy } from "../utilities/db";
import firebase from 'firebase/app';
import functions, { isFunctionsSdkInternalError, isTokenRevokedError } from '../utilities/functions';
import { appErrorAction, dbSetDataFailedErrorAction, libraryErrorAction, offLineErrorAction } from './appAction';
import { formatTag } from "../utilities/utils";
import { redirectUnauthorizeUser } from "./login";

export const MORPH_PARSER = "mecab@0.996";

export async function listKeywordGroups(dispatch, cid, isInit=false) {
  try {
    const companyDoc = db().collection("companies").doc(cid);
    const [keywordGroups, keywordCollection] = await Promise.all([
      getDocsOrderBy(companyDoc.collection("parser").doc(MORPH_PARSER).collection("keywordGroups"), "keywordGroup", [{ column: "updatedAt", type: "desc" }]),
      companyDoc.collection("parser").doc(MORPH_PARSER).collection("keywords").get()
    ]);
    keywordGroups.forEach(keywordGroup => {
      const keywords = [];
      const keywordRefs = [];
      if(keywordGroup.keywords){
        for(let keywordRef of keywordGroup.keywords){
          const keyword = keywordCollection.docs.find(x => x.id === keywordRef.id);
          if(keyword) {
            keywords.push(keyword.data().tokens);
            keywordRefs.push(keyword.ref);
          }
        }
      }
      keywordGroup.keywords = keywords;
      keywordGroup.keywordRefs = keywordRefs;
    });
    dispatch(listKeywordGroupsAction(keywordGroups, isInit));
  } catch (e) {
    dispatch(appErrorAction(e));
  }
}

export function searchKeywordGroup(dispatch, search) {
  dispatch({
    type: "SEARCH_KEYWORD_GROUP",
    payload: {
      search: search,
    }
  });
}

export function beginEditKeywordGroup(dispatch, keywordGroup) {
  dispatch(beginEditKeywordGroupAction(keywordGroup));
}

export function closeEditKeywordGroup(dispatch) {
  dispatch({ type: "CLOSE_EDIT_KEYWORD_GROUP" });
}

export function cancelEditKeywordGroup(dispatch) {
  dispatch({ type: "CANCEL_EDIT_KEYWORD_GROUP" });
}

export async function changeEditingKeywordGroup(dispatch, editingKeywordGroup, isEditingUpdate=true){
  dispatch(changeEditingKeywordGroupAction(editingKeywordGroup, isEditingUpdate));
}

export async function changeSearchSynonym(dispatch, cid, editingKeywordGroup){
  try{
    changeEditingKeywordGroup(dispatch, editingKeywordGroup, true);
    const result = await (functions().httpsCallable('getSynonyms'))({ cid: cid, targetWord: editingKeywordGroup.searchSynonym, time: (new Date()).getTime() });
    // console.log(result.data);
    if(result.data.synonyms.length === 0){
      result.data.synonyms.push(editingKeywordGroup.searchSynonym);
    }
    dispatch(changeSearchSynonymAction(result.data.synonyms, editingKeywordGroup, result.data.time));
  } catch(e){
    if (isTokenRevokedError(e)) {
      redirectUnauthorizeUser(dispatch, cid);
      return;
    }
    if (!navigator.onLine) {
      dispatch(offLineErrorAction("類義語検索"));
      return;
    }
    if (isFunctionsSdkInternalError(e)) {
      dispatch(libraryErrorAction(e.code));
      return;
    }
    dispatch(appErrorAction(e));
  }
}

export async function changeSearchMorpheme(dispatch, cid, editingKeywordGroup){
  try{
    const result = await (functions().httpsCallable("getMorpheme"))({ cid: cid, targetWord: editingKeywordGroup.searchMorpheme, time: (new Date()).getTime() });
    // console.log(result.data);
    const morph = result.data.morpheme.filter(d => {
      if(d.word === "*"){
        d.word = d.posAll[0];
      }
      // console.log(d);
      if(d.pos === "助詞" || d.pos === "記号") return false;
      if (d.pos === "助動詞") {
        const SPECIAL_ACCEPT_WORDS = [
          ["XX","助動詞","*","*","*","不変化型","XX","ん","XX","XX"],
          ["XX","助動詞","*","*","*","特殊・ヌ","XX","ぬ","XX","XX"],
          ["XX","助動詞","*","*","*","特殊・ナイ","XX","ない","XX","XX"],
          ["XX","助動詞","*","*","*","不変化型","XX","へん","XX","XX"],
          ["XX","助動詞","*","*","*","不変化型","XX","ひん","XX","XX"],
        ];
        const isInclude = SPECIAL_ACCEPT_WORDS.some(accept_word => {
          const match = accept_word.every((accept_pos, index) => {
            // "XX"の部分は無視
            if (accept_pos === "XX") return true;
            if (d.posAll[index] === accept_pos) return true;
            return false;
          });
          return match;
        });
        if (isInclude) {
          // XXの部分以外がSPECIAL_ACCEPT_WORDSと完全一致した場合
          d.word = "否定助動詞";
          d.pos = "否定助動詞";
          d.pos1 = "*";
          d.pos2 = "*";
          d.pos3 = "*";
          return true;
        }
        return false;
      }
      return true;
    });
    dispatch(changeSearchMorphemeAction(morph, editingKeywordGroup, result.data.time))
  } catch(e){
    if (isTokenRevokedError(e)) {
      redirectUnauthorizeUser(dispatch, cid);
      return;
    }
    if (!navigator.onLine) {
      dispatch(offLineErrorAction("形態素解析実行"));
      return;
    }
    if (isFunctionsSdkInternalError(e)) {
      dispatch(libraryErrorAction(e.code));
      return;
    }
    dispatch(appErrorAction(e));
  }
}

export function changeMorphemeSeleted(dispatch, morphemeList){
  dispatch(changeMorphemeSeletedAction(morphemeList));
}

export async function deleteKeywordGroup(dispatch, cid, editingKeywordGroup){
  try{
    // console.log(editingKeywordGroup);
    const companyDoc = db().collection("companies").doc(cid);
    const parserDoc = companyDoc.collection("parser").doc(MORPH_PARSER);
    const exists = await keywordGroupExists(dispatch, parserDoc, editingKeywordGroup.keywordGroup);
    if (!exists) return;
    const keywordGroupDoc = parserDoc.collection("keywordGroups").doc(editingKeywordGroup.keywordGroup);
    let canDelete = true;
    for(let labelDoc of (await db().collection("companies").doc(cid).collection("labels").get()).docs){
      for(let characterDoc of (await labelDoc.ref.collection("characters").get()).docs){
        const d = await characterDoc.ref.collection("sttPathFilters").where("keywordGroups", "array-contains", keywordGroupDoc).get();
        if(!d.empty){
          canDelete = false;
          break;
        }
      }
    }
    // console.log(canDelete);
    if(!canDelete){
      dispatch(appErrorAction(["この音声認識キーワードグループは", "他から参照されているので削除できません。"]));
      return;
    }
    await keywordGroupDoc.delete();
    dispatch({ type: "DELETE_KEYWORD_GROUP" });
  } catch(e){
    dispatch(appErrorAction(e));
  }
}

export async function addKeywordAndUpdate(dispatch, cid, editingKeywordGroup){
  try{
    const companyDoc = db().collection("companies").doc(cid);
    const parserDoc = companyDoc.collection("parser").doc(MORPH_PARSER);
    if (editingKeywordGroup.keywordGroup) {
      const exists = await keywordGroupExists(dispatch, parserDoc, editingKeywordGroup.keywordGroup);
      if (!exists) return false;
    }
    // console.log(editingKeywordGroup.keywords);
    if (!editingKeywordGroup.keywords || !editingKeywordGroup.keywords.length) {
      dispatch(appErrorAction("キーワードが入力されていません。"));
      return false;
    }
    const newKeywordGroups = [];
    let isAddKeyword = false;
    const keywordCollection = await parserDoc.collection("keywords").get();
    for(let editKeywords of editingKeywordGroup.keywords){
      let keywordDoc = null;
      for(let keywordsSnap of keywordCollection.docs){
        const tokens = keywordsSnap.data().tokens;
        if(editKeywords.length !== tokens.length){
          continue;
        }
        let containFlg = true;
        for(let [i, token] of tokens.entries()){
          let editKeyword = editKeywords[i];
          if(token.word !== editKeyword.word ||
             token.pos  !== editKeyword.pos  ||
             token.pos1 !== editKeyword.pos1 ||
             token.pos2 !== editKeyword.pos2 ||
             token.pos3 !== editKeyword.pos3){
              containFlg = false;
            break;
          }
        }
        if(containFlg){
          keywordDoc =  keywordsSnap.ref;
          break;
        }
      }
      if(!keywordDoc){
        // ない場合は追加
        // console.log("ない", editKeywords);
        isAddKeyword = true;
        keywordDoc = parserDoc.collection("keywords").doc();
        await keywordDoc.set({
          tokens: editKeywords.map(x => {
            return {
              word: x.word,
              pos: x.pos,
              pos1: x.pos1,
              pos2: x.pos2,
              pos3: x.pos3,
            }
          }),
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
      }
      newKeywordGroups.push(keywordDoc);
    }
    // console.log(newKeywordGroups);
    if(!isAddKeyword && editingKeywordGroup.keywordGroup){
      // 新規追加がない、編集の場合同じものがないか確認
      for(let k of newKeywordGroups){
        const list = newKeywordGroups.filter(x => x.id === k.id);
        // console.log(list);
        if(list.length > 1){
          dispatch(appErrorAction("同じキーワードが既に追加されています"));
          return false;
        }
      }
    }

    let keywordGroupDoc = null;
    const data = {
      title: editingKeywordGroup.title,
      tag: formatTag(editingKeywordGroup.tag),
      keywords: newKeywordGroups,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    }
    if (!editingKeywordGroup.keywordGroup) {
      keywordGroupDoc = parserDoc.collection("keywordGroups").doc();
      data.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      await keywordGroupDoc.set(data);
    } else {
      keywordGroupDoc = parserDoc.collection("keywordGroups").doc(editingKeywordGroup.keywordGroup);
      await keywordGroupDoc.update(data);
    }
    const updated = await keywordGroupDoc.get();
    if (!updated.exists) {
      dispatch(keywordGroupNotFoundErrorAction());
      dispatch(refreshKeywordGroupAction());
      return false;
    }
    editingKeywordGroup.keywordGroup = keywordGroupDoc.id;
    editingKeywordGroup.createdAt = updated.data().createdAt;
    editingKeywordGroup.updatedAt = updated.data().updatedAt;
    editingKeywordGroup.tag = data.tag;
    dispatch({
      type: "ADD_KEYWORD_UPDATE_KEYWORD_GROUP",
      payload: {
        editingKeywordGroup: editingKeywordGroup
      }
    });
    return true;
  } catch(e){
    // セキュリティールールありだとpermission-denied、なしだとnot-foundエラーが返る
    if (e && e.name === 'FirebaseError' && (e.code === 'permission-denied' || e.code === 'not-found')) {
      if (editingKeywordGroup.keywordGroup) {
        // 更新時
        const exists = await keywordGroupExists(dispatch, db().collection("companies").doc(cid).collection("parser").doc(MORPH_PARSER), editingKeywordGroup.keywordGroup);
        if (!exists) return false;
      }
      dispatch(dbSetDataFailedErrorAction());
      return false;
    }
    dispatch(appErrorAction(e));
  }
  return false;
}

export async function updateKeywordGroup(dispatch, cid, editingKeywordGroup) {
  try{
    const companyDoc = db().collection("companies").doc(cid);
    const parserDoc = companyDoc.collection("parser").doc(MORPH_PARSER);
    const exists = await keywordGroupExists(dispatch, parserDoc, editingKeywordGroup.keywordGroup);
    if (!exists) return;
    // console.log(editingKeywordGroup.keywords);
    const newKeywordGroups = [];
    const keywordCollection = await parserDoc.collection("keywords").get();
    for(let editKeywords of editingKeywordGroup.keywords){
      let keywordDoc = null;
      for(let keywordsSnap of keywordCollection.docs){
        const tokens = keywordsSnap.data().tokens;
        if(editKeywords.length !== tokens.length){
          continue;
        }
        let containFlg = true;
        for(let editKeyword of editKeywords){
          containFlg = tokens.some(x => x.word === editKeyword.word);
          if(!containFlg) break;
        }
        if(containFlg){
          // ある場合
          // console.log("ある", editKeywords);
          keywordDoc = keywordsSnap.ref;
          break;
        }
      }
      if(!keywordDoc){
        // ない場合は追加
        // console.log("ない", editKeywords);
        keywordDoc = parserDoc.collection("keywords").doc();
        await keywordDoc.set({
          tokens: editKeywords.map(x => {
            return {
              word: x.word,
              pos: x.pos,
              pos1: x.pos1,
              pos2: x.pos2,
              pox3: x.pos3,
            }
          }),
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
      }
      newKeywordGroups.push(keywordDoc);
    }
    // console.log(newKeywordGroups);
    let keywordGroupDoc = null;
    const data = {
      title: editingKeywordGroup.title,
      keywords: newKeywordGroups,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    }
    if (!editingKeywordGroup.keywordGroup) {
      keywordGroupDoc = parserDoc.collection("keywordGroups").doc();
      data.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      await keywordGroupDoc.set(data);
    } else {
      keywordGroupDoc = parserDoc.collection("keywordGroups").doc(editingKeywordGroup.keywordGroup);
      await keywordGroupDoc.update(data);
    }
    editingKeywordGroup.keywordGroup = keywordGroupDoc.id;
    dispatch(updateKeywordGroupAction(editingKeywordGroup));
  } catch(e){
    dispatch(appErrorAction(e));
  }
}

export async function beginEditKeywords(dispatch, editingKeywordGroup) {
  dispatch(beginEditKeywordsAction(editingKeywordGroup));
}

async function keywordGroupExists(dispatch, parserDoc, keywordGroupId) {
  const keywordGroup = await parserDoc.collection("keywordGroups").doc(keywordGroupId).get();
  if (!keywordGroup.exists) {
    dispatch(keywordGroupNotFoundErrorAction());
    dispatch(refreshKeywordGroupAction());
    return false;
  }
  return true;
}

function listKeywordGroupsAction(keywordGroups, isInit) {
  return {
    type: 'KEYWORD_GROUPS',
    payload: {
      keywordGroups: keywordGroups,
      isInit: isInit,
    }
  }
}

function beginEditKeywordGroupAction(keywordGroup) {
  return {
    type: 'BEGIN_EDIT_KEYWORD_GROUP',
    payload: {
      keywordGroup: keywordGroup,
    }
  }
}

function changeEditingKeywordGroupAction(editingKeywordGroup, isEditingUpdate){
  return {
    type: 'CHANGE_EDITING_KEYWORD_GROUP',
    payload: {
      editingKeywordGroup: editingKeywordGroup,
      isEditingUpdate: isEditingUpdate,
    }
  }
}

function changeSearchSynonymAction(synonymList, editingKeywordGroup, time){
  return {
    type: 'CHANGE_SEARCH_SYNONYM',
    payload: {
      synonymList: synonymList,
      editingKeywordGroup: editingKeywordGroup,
      time: time,
    }
  }
}

function changeSearchMorphemeAction(morphemeList, editingKeywordGroup, time){
  return {
    type: 'CHANGE_SEARCH_MORPHEME',
    payload: {
      morphemeList: morphemeList,
      editingKeywordGroup: editingKeywordGroup,
      time: time,
    }
  }
}

function changeMorphemeSeletedAction(morphemeList){
  return {
    type: "CHANGE_MORPHEME_SELECTED",
    payload: {
      morphemeList: morphemeList,
    }
  }
}

function updateKeywordGroupAction(editingKeywordGroup) {
  return {
    type: 'UPDATE_KEYWORD_GROUP',
    payload: {
      editingKeywordGroup: editingKeywordGroup,
    }
  }
}

function refreshKeywordGroupAction() {
  return { type: 'REFRESH_KEYWORD_GROUP' };
}

function keywordGroupNotFoundErrorAction() {
  return appErrorAction(["指定された音声認識キーワードグループは存在しません。","他のアカウントにより削除された可能性があります。"]);
}

function beginEditKeywordsAction(editingKeywordGroup) {
  return {
    type: 'BEGIN_EDIT_KEYWORDS',
    payload: {
      editingKeywordGroup,
    },
   };
}
