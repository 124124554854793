import React, { Component } from 'react';
import { connect } from 'react-redux';
import Auth from "./auth";
import TopBar from './TopBar';
import { withRouter } from 'react-router-dom';
import { listPhrases, editPhrase, beginEditPhrase, getMorphRequest, synthesizeRequest, deletePhrase, getPhraseUrl, phraseExists, searchPhrase, setSynthesizedPhrase } from './actions/phrases';
import { formatDate } from './utilities/utils';
import { withStyles, Grid, DialogContent, MenuItem } from "@material-ui/core";
import { Field, reduxForm, getFormValues } from 'redux-form';
import { renderSimpleSelectField, renderSimpleTextField } from "./formRenderer";
import { required } from "./validator";
import { onLoading, offLoading } from "./actions/loading";
import ErrorMessage from './errorMessage';
import UpdateMessage from './updateMessage';
import { appError, appRemoveError, updateMessage } from './actions/appAction';
import muiStyles from "./MuiStyles";
import { Editor } from "felix-ui";
import SettingMenu from './SettingMenu';
import { SecondaryButton, PrimaryButton, GrayButton, CloseButton, StyledDialog, PlayArrowButton, StopButton, EnhancedTable, StyledFormControl, StyledSelect, SearchTextField } from './AppParts';
import * as d3 from 'd3';
// import { login } from "./actions/login";
// import { mainCompany } from "./actions/main";

export class Phrases extends Component {
  state = {
    editing: false,
    testPlay: false,
    requestingSynthesize: false,
    isDeleteConfirmDialog: false,
    deleteId: null,
    deleteTitle: null,
    order: null,
    orderBy: null,
    message: null,
    add: false,
  }

  constructor(props) {
    super(props);
    this.isTestPlayMode = false; // 再生のみか再生と保存かの判定用
    this.synthesizerRef = React.createRef();
  }

  async componentDidMount() {
    // TODO: デバッグ用
    // console.log("componentDidMount", this.props);
    // if(!this.props.user){
    //   const cid = this.props.match.params && this.props.match.params.cid;
    //   await login(this.props.dispatch, "test@test.com", "sskkotdotd", cid);
    // }
    // デバッグ用
    if (this.props.user) {
      getPhraseUrl(this.props.dispatch, null);
      onLoading(this.props.dispatch);
      await listPhrases(this.props.dispatch, this.props.user.TenantId);
      offLoading(this.props.dispatch);
    }
  }

  async componentDidUpdate() {
    // TODO: デバッグ用
    // console.log("componentDidUpdate", this.props);
    // if(!this.props.company && this.props.user){
    //   await mainCompany(this.props.dispatch, this.props.user.TenantId);
    // }
    // if(this.props.company && this.props.phrases.length === 0){
    //   await listPhrases(this.props.dispatch, this.props.user.TenantId);
    // }
    // デバッグ用

    if(this.props.isRefresh && !this.props.loading){
      onLoading(this.props.dispatch);
      await listPhrases(this.props.dispatch, this.props.user.TenantId);
      offLoading(this.props.dispatch);
      if (this.state.message) {
        updateMessage(this.props.dispatch, true, this.state.message);
        this.setState({ message: null });
      }
    }

    // felixコンポーネントの見た目を強引に変更
    // 最初に選択されているタブのcolorを白にする
    d3.selectAll("li.react-tabs__tab--selected")
      .attr("style", "color: white !important");
    // 選択されたタブのcolorを白に、他のを通常に戻す
    const tabs = d3.selectAll("li.react-tabs__tab");
    tabs.on("click", (data, idx, elem) => {
      elem.forEach((e, i) => {
        if(idx === i){
          d3.select(e).attr("style", "color: white !important");
        } else {
          d3.select(e).attr("style", "");
        }
      })
    });
    // 読みのボタンの中身を変更
    d3.selectAll("button.mode-btn")
      .text("")
      .append("img")
      .attr("src", "/images/right_arrow_sm.png")
      .attr("alt", ">");
  }

  onSearch = (e) => {
    e.preventDefault();
    searchPhrase(this.props.dispatch, e.target.value, this.props.searchModel);
  }

  onChange = (e) => {
    const editingPhrase = { ...this.props.editingPhrase, ...this.props.formValues };
    editingPhrase[e.target.name] = e.target.value;
    editPhrase(this.props.dispatch, editingPhrase, e.target.name === "phraseTitle");
  }

  onBeginEdit = (e, phraseItem) => {
    e.preventDefault();
    appRemoveError(this.props.dispatch);
    beginEditPhrase(this.props.dispatch, phraseItem ? phraseItem.phrase: null, this.props.searchModel);
    this.setState({
      editing: true,
      deleteId: null,
      deleteTitle: null,
      add: phraseItem ? false : true,
    })
  }

  onEndEdit = async (e) => {
    // console.log(e.target);
    if(!e.target) return;
    appRemoveError(this.props.dispatch);
    setSynthesizedPhrase(this.props.dispatch, null);
    this.setState({
      editing: false,
      testPlay: false,
    });
  }

  onPlayPhrase = async (e, phraseItem) => {
    e.preventDefault();
    console.log(phraseItem);
    await getPhraseUrl(this.props.dispatch, null);
    await getPhraseUrl(this.props.dispatch, phraseItem);
  }

  getMorphRequestHook = async (path, method, responseParser, options) => {
    // console.log("getMorphRequestHook");
    // console.log(options);
    // console.log(this.props.editingPhrase);
    return await getMorphRequest(this.props.dispatch, this.props.user.TenantId, path, method, responseParser, options, this.props.editingPhrase.interlanguage, this.props.isFirstMorphRequest);
  };

  onUpdatePhrase = async () => {
    if (!this.props.formValues.modelRef) {
      appError(this.props.dispatch, "モデルを選択してください");
      return;
    }
    if (!this.props.formValues.phraseTitle) {
      appError(this.props.dispatch, "フレーズ名を入力してください");
      return;
    }
    if (this.props.editingPhrase.phrase) {
      const exists = await phraseExists(this.props.dispatch, this.props.user.TenantId, this.props.editingPhrase.phrase);
      if (!exists) return;
    }
    appRemoveError(this.props.dispatch);
    this.isTestPlayMode = false;
    this.setState({
      requestingSynthesize: true, // ボタン無効化用
    });
    if (this.synthesizerRef.current) {
      this.synthesizerRef.current.synthesize();
    }
  }

  onPlaySynthesizedPhrase = () => {
    setSynthesizedPhrase(this.props.dispatch, null);
    this.isTestPlayMode = true;
    this.setState({
      testPlay: true, // テストプレイの有効化
      requestingSynthesize: true, // ボタン無効化用
    });
    if (this.synthesizerRef.current) {
      this.synthesizerRef.current.synthesize(); // 音声合成
    }
  }

  onStopSynthesizedPhrase = () => {
    this.setState({
      testPlay: false, // テストプレイの中断
    });
    this.synthesizerRef.current.cancel();
  }

  onDeleteConfirm = (e, phraseItem) => {
    e.preventDefault();
    this.setState({
      isDeleteConfirmDialog: true,
      deleteId: phraseItem.phrase,
      deleteTitle: phraseItem.phraseTitle,
    });
  }

  onDeleteExecute = async (e) => {
    e.preventDefault();
    if (!this.state.deleteTitle) return;
    deletePhrase(this.props.dispatch, this.props.user.TenantId, this.state.deleteId);
    this.setState({
      isDeleteConfirmDialog: false,
      deleteId: null,
      deleteTitle: null,
    });
  }

  synthesizeRequestHook = async (path, method, responseParser, payload) => {
    // console.log("synthesizeRequestHook");
    const editingPhrase = { ...this.props.editingPhrase, ...this.props.formValues };
    console.log(payload.morph)
    let phrase_title = payload.morph.map(x => x[0]).join("");
    if(payload.morph.length <= 1 && phrase_title === "。"){
      appError(this.props.dispatch, "フレーズを入力してください");
      this.setState({ requestingSynthesize: false });
      return;
    }
    let phrase_yomi = payload.morph.filter(x => x[1] !== "記号").map(x => x[9]).join("");
    if(phrase_yomi === ""){
      appError(this.props.dispatch, "読みを入力してください");
      this.setState({ requestingSynthesize: false });
      return;
    }
    console.log(phrase_yomi);
    const data = await synthesizeRequest(this.props.dispatch, this.props.user.TenantId, path, method, responseParser, payload, this.isTestPlayMode, editingPhrase);
    if (!data) return;
    this.setState({ message: this.state.add ? "register" : "update" });
    return data;
  };

  setOrder(order, orderBy) {
    this.setState({
      order: order,
      orderBy: orderBy,
    });
  }

  onModelChange = (e) => {
    const searchModel = e.target.value;
    searchPhrase(this.props.dispatch, this.props.search, searchModel);
    this.setState({
      searchModel: searchModel,
    });
  }

  onTagClick = (e, tag) => {
    e.preventDefault();
    const searchInput = document.querySelector('#searchPhrase');
    if (searchInput) {
      searchInput.value = tag;
    }
    searchPhrase(this.props.dispatch, tag, this.props.searchModel);
  }

  render() {
    const { handleSubmit, classes } = this.props;
    const headCells = [
      { id: 'phraseTitle', label: 'フレーズ名' },
      { id: 'createdAt', label: '作成日時', modFunc: formatDate },
      { id: 'updatedAt', label: '更新日時', modFunc: formatDate },
    ];
    return (
      <React.Fragment>
        <Auth />
        {this.props.user &&
        <React.Fragment>
          <TopBar />
          <div className={classes.content}>
            <SettingMenu />
            <div className={classes.main}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div className="titleText">音声フレーズ管理</div>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  <SearchTextField id="searchPhrase" name="search" style={{ width: "280px" }} defaultValue={this.props.search} onChange={this.onSearch} />
                  {(this.props.user && this.props.user.isManager()) &&
                    <SecondaryButton onClick={(e) => this.onBeginEdit(e)} style={{ marginLeft: 5 }}>新規登録</SecondaryButton>
                  }
                </Grid>
              </Grid>
              <ErrorMessage />
              <UpdateMessage />
              <Grid container spacing={2} style={{ marginTop: 15 }}>
                <Grid item xs={6} className="verticalCenter">
                  <span>モデル</span>
                  <StyledFormControl style={{ width: 200, marginLeft: 10, textAlign: "center" }}>
                    <StyledSelect name="searchModel" value={this.props.searchModel} onChange={this.onModelChange}>
                      { this.props.models.map(item => {
                        return(
                          <MenuItem key={item.model} value={item.model}>{ item.title }</MenuItem>
                        )
                      }) }
                    </StyledSelect>
                  </StyledFormControl>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  <audio src={this.props.playPhraseUrl} autoPlay />
                </Grid>
              </Grid>
              <div className={classes.listContainer} style={{ height: "calc(100vh - 56px - 48px - 90px - 55px)" }}>
              <EnhancedTable list={this.props.searchedPhrases} classes={classes} operationMinWidth={120}
                  headCells={headCells} editCallback={(this.props.user && this.props.user.isManager()) ? this.onBeginEdit : null} playCallback={this.onPlayPhrase}
                  deleteCallback={(this.props.user && this.props.user.isManager()) ? this.onDeleteConfirm : null} displayTag tagClickCallback={this.onTagClick} />
              </div>
            </div>
          </div>
          { this.props.editingPhrase &&
          <StyledDialog open={this.state.editing} onClose={this.onEndEdit} PaperProps={{ style: { width: 1152, minHeight: 776 } }}>
            <DialogContent className={classes.dialogContent} style={{ alignItems: "baseline", padding: "8px 0" }}>
              <form onSubmit={handleSubmit((values) => this.onEndEdit)} style={{ width: "100%" }}>
                <div className={classes.closeButton}>
                  <CloseButton onClick={this.onEndEdit}/>
                </div>
                <div className="alignCenter mb15" style={{ marginTop: 20 }}>
                  <span className="f28">音声フレーズ管理 {this.state.add ? "新規登録" : "編集"}</span>
                </div>
                <div className="alignCenter verticalCenter mb15">
                  <span className="f16" style={{ marginRight: 10, width: 100, textAlign: "right" }}>モデル:</span>
                  <Field name="modelRef" component={renderSimpleSelectField} validate={[required]} onChange={this.onChange} width={348} disabled={this.props.editingPhrase.phrase ? true : false}>
                    {this.props.models.map(model => (
                      <MenuItem key={model.title} value={model.model}>{model.title}</MenuItem>
                    ))}
                  </Field>
                </div>
                <div className="alignCenter verticalCenter mb15">
                  <span className="f16" style={{ marginRight: 10, width: 100, textAlign: "right" }}>フレーズ名:</span>
                  <Field name="phraseTitle" uncontrolled component={renderSimpleTextField} onChange={this.onChange} style={{ width: 348 }} />
                </div>
                <div className="alignCenter verticalCenter mb15">
                  <span className="f16" style={{ marginRight: 10, width: 100, textAlign: "right" }}>タグ(任意):</span>
                  <Field name="tag" uncontrolled component={renderSimpleTextField} placeholder="#タグ1 #タグ2" onChange={this.onChange} style={{ width: 348 }} />
                </div>
                <Editor
                  text={this.props.interlanguageTitle}
                  ref={this.synthesizerRef}
                  modelID={this.props.editingPhrase.modelID}
                  speakerID={this.props.editingPhrase.speakerID}
                  onSynthesizeRequestResolved={() => {
                    // 合成処理終了トリガー
                    this.setState({ requestingSynthesize: false }); // ボタン無効化用
                  }}
                  onSynthesized={(base64EncodedWav) => {
                    if (this.isTestPlayMode) {
                      setSynthesizedPhrase(this.props.dispatch, "data:audio/wav;base64," + base64EncodedWav);
                    }
                  }}
                  onSynthesizeError={(e) => {
                    console.log(e)
                    this.setState({
                      requestingSynthesize: false, // ボタン無効化用
                      message: null,
                     });
                  }}
                  // APIリクエストのフック
                  getMorphRequestHook={this.getMorphRequestHook}
                  synthesizeRequestHook={this.synthesizeRequestHook}
                />
                { this.state.testPlay && <audio src={this.props.synthesizedPhrase} autoPlay onEnded={() => {
                  this.setState({
                    testPlay: false,
                    requestingSynthesize: false, // ボタン無効化用
                  });
                }} />}
                <div className="alignCenter" style={{ marginBottom: 20, marginRight: 50, justifyContent: "flex-end" }}>
                  <div style={{ marginRight: 50 }}>
                    <PlayArrowButton style={{ marginRight: 10 }}
                      disabled={this.state.requestingSynthesize || this.state.testPlay}
                      onClick={this.onPlaySynthesizedPhrase}
                    />
                    <StopButton variant="contained" color="default" onClick={this.onStopSynthesizedPhrase}>停止</StopButton>
                  </div>
                  <div>
                    <GrayButton width={120} height={32} onClick={this.onEndEdit} style={{ marginRight: 15 }}>キャンセル</GrayButton>
                    <PrimaryButton width={120} height={32} disabled={this.state.requestingSynthesize || this.state.testPlay} onClick={this.onUpdatePhrase}>{this.state.add ? "登録する" : "更新する"}</PrimaryButton>
                  </div>
                </div>
              </form>
            </DialogContent>
          </StyledDialog>
          }
          <StyledDialog open={this.state.isDeleteConfirmDialog} onClose={() => this.setState({ isDeleteConfirmDialog: false, deleteId: null })}>
            <DialogContent className={classes.dialogContent}>
              <div className={classes.closeButton}>
                <CloseButton onClick={() => this.setState({ isDeleteConfirmDialog: false, deleteId: null })}/>
              </div>
              <div className="deletePopupContentText">
                <div>
                  <div className="wordKeepAll">「{this.state.deleteTitle}</div>
                  <div className="wordKeepAllClose">」</div>
                  を削除しますか？
                </div>
                <div style={{ marginTop: 36 }}>
                  <PrimaryButton width={160} height={48} onClick={(e) => this.onDeleteExecute(e)} disabled={this.props.loading}>削除する</PrimaryButton>
                  <GrayButton width={160} height={48} onClick={() => this.setState({ isDeleteConfirmDialog: false, deleteId: null })} style={{ marginLeft: 20 }}>キャンセル</GrayButton>
                </div>
              </div>
            </DialogContent>
          </StyledDialog>
        </React.Fragment>
        }
      </React.Fragment >
    );
  }
}

export default withRouter(withStyles(muiStyles)(
  connect(state => ({
    user: state.user && state.user.user,
    company: state.main && state.main.company,
    phrases: state.phrases && state.phrases.phrases,
    searchedPhrases: state.phrases && state.phrases.searchedPhrases,
    search: state.phrases && state.phrases.search,
    searchModel: state.phrases && state.phrases.searchModel,
    editingPhrase: state.phrases && state.phrases.editingPhrase,
    models: (state.phrases && state.phrases.models) || [],
    isFirstMorphRequest: state.phrases && state.phrases.isFirstMorphRequest,
    audio: state.phrases && state.phrases.audio,
    playPhraseUrl: state.phrases && state.phrases.playPhraseUrl,
    synthesizedPhrase: state.phrases && state.phrases.synthesizedPhrase,
    isRefresh: state.phrases && state.phrases.isRefresh,
    interlanguageTitle: state.phrases && state.phrases.interlanguageTitle,
    loading: state.loading && state.loading.loading,
    initialValues: state.phrases && state.phrases.editingPhrase,
    formValues: getFormValues("phraseForm")(state),
  }))(reduxForm({ form: 'phraseForm', enableReinitialize: true })(Phrases))
));
