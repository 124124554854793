import { searchByTag } from "../utilities/utils";

const defaultState = {
  guidePictures: [],
  searchedGuidePictures: [],
  search: '',
  editingGuidePicture: {},
  isRefresh: false,
};

function defaultGuidePictures() {
  return {
    picture: null,
    title: '',
    pictureMd5: '',
    picturePath: '',
    pictureFile: null,
    createdAt: '',
    updatedAt: '',
  }
}

function searchList(list, search) {
  let searchedList = [ ...list ];
  if (search) {
    searchedList = searchByTag(searchedList, 'title', search);
  }
  return searchedList;
}

const guides = (state = defaultState, action) => {
  let editingGuidePicture;
  switch (action.type) {
    case 'GUIDES' :
      return {
        ...state,
        guidePictures: action.payload.guidePictures || defaultGuidePictures(),
        searchedGuidePictures: searchList(action.payload.guidePictures, state.search),
        editingGuidePicture: null,
        isRefresh: false,
      };
    case 'SEARCH_GUIDE_PICTURE':
      return {
        ...state,
        search: action.payload.search,
        searchedGuidePictures: searchList(state.guidePictures, action.payload.search),
      };
    case 'BEGIN_EDIT_GUIDE_PICTURE':
      editingGuidePicture = state.guidePictures.find((guide) => (
        guide.picture === action.payload.picture
      ));
      return {
        ...state,
        editingGuidePicture: editingGuidePicture || defaultGuidePictures(),
        isRefresh: false,
      };
    case 'EDIT_GUIDE_PICTURE':
      return {
        ...state,
        editingGuidePicture: action.payload.guidePictures,
      };
    case 'UPDATE_GUIDE_PICTURE' :
      return{
        ...state,
        editingGuidePicture: null,
        isRefresh: true,
      };
    case 'DELETE_GUIDE_PICTURE' :
      return {
        ...state,
        editingGuidePicture: null,
        isRefresh: true,
      };
    case 'REFRESH_GUIDE_PICTURE':
      return {
        ...state,
        editingGuidePicture: null,
        isRefresh: true,
      };
    case "GUIDE_CLOSE_EDIT":
      return {
        ...state,
        editingGuidePicture: null,
      }
    case "PICTURE_SHOW_URL":
      return {
        ...state,
        showPictureUrl: action.showPictureUrl,
      }
    default:
      return state;
  }
};

export default guides;
