//import firebase from 'firebase/app';
import 'firebase/firestore';
import db, { getDocsData } from '../utilities/db';
import { appErrorAction } from './appAction';

export async function modelsList(dispatch, cid, begin, count) {
  try {
    const companyDoc = db().collection("companies").doc(cid);
    const models = await getDocsData(companyDoc.collection("models"), "model");
    dispatch({
      type: "MODELS_LIST",
      models: models,
    });
  } catch (e) {
    dispatch(appErrorAction(e));
  }
}

