import { insertThousandSeparator, isEqualExceptFalsy } from "../utilities/utils";

const defaultState = {
  characterData: null,
  manualCommands: [],
  searchedmanualCommands: [],
  search: "",
  originalManualCommand: null,
  editingManualCommand: null,
  manualCommandsGroups: [],
  phrases: [],
  motions: [],
  pictures: [],
  manualCommandGroupMenuItem: [],
  phraseMenuItems: [],
  motionMenuItems: [],
  digitalOutItems: [0,1,2,3,4,5,6,7,8,9],
  timeoutTypeItems: ["Instantly", "EnableInterrupt", "DisableInterrupt"],
  isRefresh: false,
  isEditingUpdate: false,
};

function defaultManualCommand(){
  return{
    manualCommand: null,
    manualCommandGroupRef: null,
    title: "",
    motionRef: null,
    phraseRef: null,
    playType: "SyncSoundAndMotion",
    pictureRef: null,
    digitalOut: null,
    timeoutType: "Instantly",
    digitalOutOneShotTime: "0",
    timeoutSec: "0",
    createdAt: null,
    updatedAt: null
  }
}

function setBeginEditingData(editData, phraseMenuItems, motionMenuItems){
  // modelが異なる場合の処理
  if(editData.isPhraseModelDiff) {
    const existPhraseData = phraseMenuItems.find(x => x.phrase === editData.phraseRef.phrase);
    if (!existPhraseData) phraseMenuItems.push({ ...editData.phraseRef, disabled: true });
  }
  if(editData.isMotionModelDiff) {
    const existMotionData = motionMenuItems.find(x => x.motion === editData.motionRef.motion);
    if (!existMotionData) motionMenuItems.push({ ...editData.motionRef, disabled: true });
  }
  // フレーズなどは編集時はidの部分があれば良いのでデータを変える
  editData.manualCommandGroupRef = editData.manualCommandGroupRef && editData.manualCommandGroupRef.manualCommandsGroup;
  editData.phraseRef = editData.phraseRef && editData.phraseRef.phrase;
  editData.motionRef = editData.motionRef && editData.motionRef.motion;
  editData.pictureRef = editData.pictureRef && editData.pictureRef.picture;

  if (typeof editData.digitalOutOneShotTime === 'number') {
    editData.digitalOutOneShotTime = insertThousandSeparator(editData.digitalOutOneShotTime);
  }
  if (typeof editData.timeoutSec === 'number') {
    editData.timeoutSec = insertThousandSeparator(editData.timeoutSec);
  }
}

function searchList(list, search) {
  let searchedList = [ ...list ];
  if (search) {
    searchedList = searchedList.filter(x => x.title.indexOf(search) >= 0);
  }
  return searchedList;
}

const manualCommands = (state = defaultState, action) => {
  let editData;
  switch (action.type) {
    case "LIST_MANUAL_COMMANDS":
      let obj = {
        ...state,
        characterData: action.payload.characterData,
        manualCommands: action.payload.manualCommands,
        searchedmanualCommands: searchList(action.payload.manualCommands, state.search),
        manualCommandsGroups: action.payload.manualCommandsGroups,
        phrases: action.payload.phrases,
        motions: action.payload.motions,
        pictures: action.payload.pictures,
        isRefresh: false,
      }
      if(action.payload.isInit){
        obj.editingManualCommand = null;
      }
      return obj;
    case "MANUAL_COMMAND_SEARCH":
      return {
        ...state,
        search: action.payload.search,
        searchedmanualCommands: searchList(state.manualCommands, action.payload.search),
      }
    case "BEGIN_EDIT_MANUAL_COMMAND":
      const d = state.manualCommands.find(c => {
        return c.manualCommand === action.payload.manualCommand;
      });
      editData = {...d};
      const manualCommandGroupMenuItem = state.manualCommandsGroups || [];
      const phraseMenuItems = (state.characterData.modelRef && state.phrases.filter(x => x.modelRef.model === state.characterData.modelRef.model)) || [];
      const motionMenuItems = (state.characterData.modelRef && state.motions.filter(x => x.modelRef.model === state.characterData.modelRef.model)) || [];
      if(!editData.manualCommand){
        editData = defaultManualCommand();
        if(state.manualCommandsGroups.length > 0){
          editData.manualCommandGroupRef = manualCommandGroupMenuItem[0].manualCommandsGroup;
        }
      } else {
        setBeginEditingData(editData, phraseMenuItems, motionMenuItems);
      }
      editData.isEditingUpdate = false;
      return {
        ...state,
        originalManualCommand: editData,
        editingManualCommand: editData,
        manualCommandGroupMenuItem: manualCommandGroupMenuItem,
        phraseMenuItems: phraseMenuItems,
        motionMenuItems: motionMenuItems,
        digitalOutItems: state.digitalOutItems,
        timeoutTypeItems: state.timeoutTypeItems,
        isRefresh: false,
      }
    case "CHANGE_EDITING_MANUAL_COMMAND":
      editData = { ...action.payload.editingManualCommand };
      // originalManualCommandと比較するためいったんfalseにする
      editData.isEditingUpdate = false;
      editData.isEditingUpdate = isEqualExceptFalsy(state.originalManualCommand, editData) ? false : true;
      return {
        ...state,
        editingManualCommand: editData,
        isRefresh: false,
      }
    case "MANUAL_COMMAND_CLOSE":
      return {
        ...state,
        editingManualCommand: null,
      }
    case "MANUAL_COMMAND_EDIT_CANCEL":
      let beforeEditing = state.manualCommands.find(item => {
        return item.manualCommand === state.editingManualCommand.manualCommand;
      });
      beforeEditing = {...beforeEditing};
      if(!beforeEditing.manualCommand) {
        beforeEditing = defaultManualCommand();
        if(state.manualCommandsGroups.length > 0){
          beforeEditing.manualCommandGroupRef = state.manualCommandsGroups[0].manualCommandsGroup;
        }
      } else {
        setBeginEditingData(beforeEditing, state.phraseMenuItems, state.motionMenuItems);
      }
      beforeEditing.test = new Date().getTime();
      beforeEditing.isEditingUpdate = false;
      return {
        ...state,
        originalManualCommand: beforeEditing,
        editingManualCommand: beforeEditing,
      }
    case "UPDATED_MANUAL_COMMAND":
      editData = { ...action.editing };
      editData.isEditingUpdate = false;
      // console.log(editData);
      return {
        ...state,
        originalManualCommand: editData,
        editingManualCommand: editData,
        isRefresh: true,
      }
    case "DELETED_MANUAL_COMMAND":
      return {
        ...state,
        editingManualCommand: null,
        isRefresh: true,
      }
    case "REFRESH_MANUAL_COMMAND":
      return {
        ...state,
        editingManualCommand: null,
        isRefresh: true,
      }
    case "MANUAL_COMMANDS_ERROR":
      return {
        ...state,
        error: action.error,
        isRefresh: false,
      }
    default:
      return state;
  }
}

export default manualCommands;
