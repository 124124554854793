import React, { Component } from 'react';
import { connect } from 'react-redux';
import Auth from './auth';
import TopBar from './TopBar';
import { withRouter } from 'react-router-dom';
import { commandsGroups, beginEditCommand, updateCommand, deleteCommand, closeEditCommandGroup } from './actions/commandsGroups';
import { withStyles, Grid, DialogContent } from "@material-ui/core";
import { Field, reduxForm, getFormValues } from 'redux-form';
import { renderSimpleTextField } from "./formRenderer";
import { required } from "./validator";
import { onLoading, offLoading } from "./actions/loading";
import ErrorMessage from './errorMessage';
import UpdateMessage from './updateMessage';
import { appError, appRemoveError, updateMessage } from './actions/appAction';
import muiStyles from "./MuiStyles";
import SettingMenu from './SettingMenu';
import { EnhancedTable } from './AppParts';
// import { login } from "./actions/login";
// import { mainCompany } from "./actions/main";
import { SecondaryButton, StyledDialog, CloseButton, PrimaryButton, GrayButton } from './AppParts';

const headCells = [
  { id: 'title', label: 'グループ名' },
]

export class CommandGroups extends Component {
  state = {
    editing: false,
    isDeleteConfirmDialog: false,
    deleteId: null,
    deleteTitle: null,
    message: null,
    add: false,
  }

  async componentDidMount() {
    // TODO: デバッグ用
    // console.log("componentDidMount", this.props);
    // if(!this.props.user){
    //   const cid = this.props.match.params && this.props.match.params.cid;
    //   await login(this.props.dispatch, "test@test.com", "sskkotdotd", cid);
    // }
    // デバッグ用

    if (this.props.user) {
      onLoading(this.props.dispatch);
      await commandsGroups(this.props.dispatch, this.props.user.TenantId);
      offLoading(this.props.dispatch);
    }
  }

  async componentDidUpdate() {
    // TODO: デバッグ用
    // console.log("componentDidUpdate", this.props);
    // if(!this.props.company && this.props.user){
    //   await mainCompany(this.props.dispatch, this.props.user.TenantId);
    // }
    // if(this.props.company && this.props.manualCommandsGroups.length === 0){
    //   await commandsGroups(this.props.dispatch, this.props.user.TenantId);
    // }
    // デバッグ用

    if (this.props.isRefresh && !this.props.loading) {
      onLoading(this.props.dispatch);
      await commandsGroups(this.props.dispatch, this.props.user.TenantId);
      offLoading(this.props.dispatch);
      if (this.state.message) {
        updateMessage(this.props.dispatch, true, this.state.message);
        this.setState({ message: null });
      }
    }
  }

  onBeginEdit = (e, item) => {
    e.preventDefault();
    const newItem = Object.keys(item).length !== 0 ? false : true;
    beginEditCommand(this.props.dispatch, newItem ? null : item.manualCommandsGroup);
    this.setState({
      editing: true,
      deleteId: null,
      deleteTitle: null,
      add: newItem ? true : false,
    });
  }

  onCreateExec = async (values) => {
    const editingCommand = { ...values };
    // if ((!editingCommand.manualCommandsGroup && this.props.manualCommandsGroups.find(x => x.title === editingCommand.title)) ||
    //     (editingCommand.manualCommandsGroup && this.props.manualCommandsGroups.find(x => x.manualCommandsGroups !== editingCommand.manualCommandsGroups && x.title === editingCommand.title))
    // ) {
    console.log(editingCommand, this.props.manualCommandsGroups);
    if(this.props.manualCommandsGroups.find(x => x.manualCommandsGroup !== editingCommand.manualCommandsGroup && x.title === editingCommand.title)){
      appError(this.props.dispatch, "同じグループ名があります");
      return;
    }
    appRemoveError(this.props.dispatch);
    onLoading(this.props.dispatch);
    const success = await updateCommand(this.props.dispatch, this.props.user.TenantId, editingCommand);
    offLoading(this.props.dispatch);
    if (!success) return;
    this.setState({
      editing: false,
      message: this.state.add ? "register" : "update",
    });
  }

  onCloseEdit= (e) => {
    e.preventDefault();
    closeEditCommandGroup(this.props.dispatch);
    this.setState({
      editing: false ,
    });
  }

  onDeleteConfirm = (e, item) => {
    e.preventDefault();
    beginEditCommand(this.props.dispatch, item.manualCommandsGroup);
    this.setState({
      isDeleteConfirmDialog: true,
      deleteId: item.manualCommandsGroup,
      deleteTitle: item.title,
    });
  }

  onDeleteExecute = async (e) => {
    e.preventDefault();
    if (!this.state.deleteId) return;
    appRemoveError(this.props.dispatch);
    onLoading(this.props.dispatch);
    await deleteCommand(this.props.dispatch, this.props.user.TenantId, this.state.deleteId);
    offLoading(this.props.dispatch);
    this.setState({
      isDeleteConfirmDialog: false,
      deleteId: null,
      deleteTitle: null,
    });
  }

  render() {
    const { handleSubmit, submitting, classes } = this.props
    return (
      <React.Fragment>
        <Auth />
        <React.Fragment>
          <TopBar />
          <div className={classes.content}>
            <SettingMenu />
            <div className={classes.main}>
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  <div className="titleText">手動操作コマンドグループ管理</div>
                </Grid>
                <Grid item xs={2} style={{ textAlign: "right" }}>
                  {(this.props.user && this.props.user.isManager()) &&
                    <SecondaryButton onClick={(e) => this.onBeginEdit(e, {})}>新規登録</SecondaryButton>
                  }
                </Grid>
              </Grid>
              <ErrorMessage />
              <UpdateMessage />
              <div className={classes.listContainer}>
                <EnhancedTable list={this.props.manualCommandsGroups} classes={classes} operationMinWidth={80}
                  headCells={headCells} editCallback={(this.props.user && this.props.user.isManager()) ? this.onBeginEdit : null} deleteCallback={(this.props.user && this.props.user.isManager()) ? this.onDeleteConfirm : null} />
              </div>
            </div>
          </div>
          {this.props.editingCommand &&
            <StyledDialog open={this.state.editing} onClose={this.onCloseEdit}>
              <DialogContent className={classes.dialogContent}>
                <form onSubmit={handleSubmit(this.onCreateExec)}>
                  <div className={classes.closeButton}>
                    <CloseButton onClick={this.onCloseEdit} />
                  </div>
                  <div style={{ width: "100%" }}>
                    <div>
                      <p className="popupTitleText">手動操作コマンドグループ</p>
                      <p className="popupTitleText">{this.state.add ? "新規登録" : "編集"}</p>
                    </div>
                    <div className="alignCenter verticalCenter" style={{ marginTop: "40px" }}>
                      <span className="grayText f16L19" style={{ textAlign: "center" }}>グループ名</span>
                      <Field name="title" className="fieldText" inputProps={{ style: { textAlign: "center" }}} style={{ width: "300px", marginLeft: 10 }}
                        component={renderSimpleTextField} validate={[required]} size={"md"} />
                    </div>
                    <p style={{ margin: "40px auto" }}>
                      <PrimaryButton width={160} height={48} type="submit" disabled={submitting}>{this.state.add ? "登録する" : "更新する"}</PrimaryButton>
                      <GrayButton width={160} height={48} onClick={this.onCloseEdit} style={{ marginLeft: 20 }}>キャンセル</GrayButton>
                    </p>
                  </div>
                </form>
              </DialogContent>
            </StyledDialog>
          }
          <StyledDialog open={this.state.isDeleteConfirmDialog} onClose={() => this.setState({ isDeleteConfirmDialog: false, deleteId: null })}>
            <DialogContent className={classes.dialogContent}>
              <div className={classes.closeButton}>
                <CloseButton onClick={() => this.setState({ isDeleteConfirmDialog: false, deleteId: null })} />
              </div>
              <div className="deletePopupContentText">
                <div>
                  <div className="wordKeepAll">「{this.state.deleteTitle}</div>
                  <div className="wordKeepAllClose">」</div>
                  を削除しますか？
                </div>
                <div style={{ margin: "40px auto" }}>
                  <PrimaryButton width={160} height={48} type="submit" onClick={(e) => this.onDeleteExecute(e)} disabled={this.props.loading}>削除する</PrimaryButton>
                  <GrayButton width={160} height={48} onClick={() => this.setState({ isDeleteConfirmDialog: false, deleteId: null })} style={{ marginLeft: 20 }}>キャンセル</GrayButton>
                </div>
              </div>
            </DialogContent>
          </StyledDialog>
        </React.Fragment>
      </React.Fragment>
    );
  }
}
//Main.contextTypes = {
//  router: PropTypes.object
//};

export default withRouter(withStyles(muiStyles)(
  connect(state => ({
    user: state.user && state.user.user,
    company: state.main && state.main.company,
    manualCommandsGroups: state.commandsGroups && state.commandsGroups.manualCommandsGroups,
    editingCommand: state.commandsGroups && state.commandsGroups.editingCommand,
    initialValues: state.commandsGroups && state.commandsGroups.editingCommand,
    formValues: getFormValues("commandGroupForm")(state),
    isRefresh: state.commandsGroups && state.commandsGroups.isRefresh,
    loading: state.loading && state.loading.loading,
  }))(reduxForm({ form: 'commandGroupForm', enableReinitialize: true })(CommandGroups))
));
