import React, { Component } from "react";
import { connect } from 'react-redux';
import Auth from "./auth";
import { withRouter } from "react-router-dom";
import TopBar from "./TopBar";
import { listGreetings, searchGreeting, beginEditGreeting, updateGreetingExec, deleteGreetingExec, GREETING_TYPE_NEAR, GREETING_TYPE_MID, changeEditingGreeting, closeGreeting, cancelEditGreeting } from "./actions/greetings";
import { characterExists } from "./actions/characters";
import { withStyles, DialogContent, MenuItem } from '@material-ui/core';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { onLoading, offLoading } from "./actions/loading";
import { formatDate } from "./utilities/utils";
import { renderSimpleTextField, renderSimpleSelectField, renderSimpleSwitch } from "./formRenderer";
import { required, toNumber, isNumber } from "./validator";
import ErrorMessage from './errorMessage';
import UpdateMessage from './updateMessage';
import { appRemoveError, updateMessage, appError } from './actions/appAction';
import muiStyles from "./MuiStyles";
import CharacterTopBar from "./CharacterTopBar";
import { EnhancedTable, GrayButton, PrimaryButton, BorderPrimaryButton, SecondaryButton, CloseButton, StyledDialog, SearchTextField, BorderBackButton, Delete24Button, EditDialogButton } from "./AppParts";
import SelectDialog from './selectDialog';

// import { login } from "./actions/login";
// import { mainCompany, mainLablesListener } from "./actions/main";
// import { listCharacters } from "./actions/characters";

const nearZoneHeadCells = [
  { id: 'title', label: '挨拶名' },
  { id: 'timeZones', label: '時間帯', isTimezone: true, isMultiline: true },
  { id: 'motionRef.title', label: 'モーション', isNest: true },
  { id: 'phraseRef.phraseTitle', label: 'フレーズ', isNest: true, },
  { id: 'updatedAt', label: '更新日時', modFunc: formatDate },
]

const editNearZoneHeadCells = [
  { id: 'title', label: '挨拶名' },
  { id: 'timeZones', label: '時間帯', isTimezone: true, isMultiline: true, isTextCenter: true },
  { id: 'motionRef.title', label: 'モーション', isNest: true },
  { id: 'phraseRef.phraseTitle', label: 'フレーズ', isNest: true, },
]

const midZoneHeadCells = [
  { id: 'title', label: '挨拶名' },
  { id: 'virtualwallNo', label: ['バーチャル','センサー番号'], isTextCenter: true },
  { id: 'virtualwallCourse', label: ['バーチャルセンサー','進入方向'], isTextCenter: true },
  { id: 'timeZones', label: '時間帯', isTimezone: true, isMultiline: true },
  { id: 'motionRef.title', label: 'モーション', isNest: true },
  { id: 'phraseRef.phraseTitle', label: 'フレーズ', isNest: true, },
  { id: 'updatedAt', label: '更新日時', modFunc: formatDate },
]

const editMidZoneHeadCells = [
  { id: 'title', label: '挨拶名' },
  { id: 'virtualwallNo', label: ['バーチャル', 'センサー'], isVirturalwall: true, isTextCenter: true },
  { id: 'timeZones', label: '時間帯', isTimezone: true, isMultiline: true, isTextCenter: true },
  { id: 'motionRef.title', label: 'モーション', isNest: true },
  { id: 'phraseRef.phraseTitle', label: 'フレーズ', isNest: true, },
]


export const TIME_ZONES = [
  { key: "morning", name: "朝"},
  { key: "daytime", name: "昼"},
  { key: "evening", name: "夕"},
  { key: "night", name: "夜"},
]

const validate = values => {
  const errors = {};
  if(!Object.keys(values).length) return errors;
  let timeZoneChecked = false;
  TIME_ZONES.forEach(timeZone => {
    if(values["timeZone_" + timeZone.key]){
      timeZoneChecked = true;
    }
  });
  if(!timeZoneChecked){
    let errorString = "どれか一つは選択";
    errors.timeZoneError = errorString;
  }
  return errors;
}

export class Greetings extends Component {
  state ={
    editingOriginalTitle: null,
    isDeleteConfirmDialog: false,
    deleteGreetingType: null,
    deleteGreeting: null,
    deleteGreetingTitle: null,
    message: null,
    add: false,
    editingPhrase: false,
  }

  async componentDidMount() {
    // TODO: デバッグ用
    // console.log("componentDidMount", this.props);
    // if(!this.props.user){
    //   const cid = this.props.match.params && this.props.match.params.cid;
    //   login(this.props.dispatch, "test@test.com", "sskkotdotd", cid);
    // }
    // デバッグ用

    if(this.props.company && this.props.selectedLabel){
      const character = this.props.match.params && this.props.match.params.character;
      onLoading(this.props.dispatch);
      const exists = await characterExists(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, character);
      if (!exists) {
        this.backToCharacterPage();
        offLoading(this.props.dispatch);
        return;
      }
      await listGreetings(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, character, this.backToCharacterPage, true);
      offLoading(this.props.dispatch);
    }
  }

  async componentDidUpdate() {
    // TODO: デバッグ用
    // console.log("componentDidMount", this.props);
    // if(!this.props.company && this.props.user){
    //   await mainCompany(this.props.dispatch, this.props.user.TenantId);
    // }
    // if(this.props.company && !this.props.selectedLabel.label){
    //   await mainLablesListener(this.props.dispatch, this.props.user.TenantId, this.props.listeners);
    // }
    // if(this.props.company && this.props.selectedLabel.label && this.props.characters.length === 0){
    //   await listCharacters(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label);
    // }
    // if(this.props.company && this.props.selectedLabel && this.props.characters.length > 0 && !this.props.greetings[GREETING_TYPE_NEAR].length){
    //   const character = this.props.match.params && this.props.match.params.character;
    //   onLoading(this.props.dispatch);
    //   await listGreetings(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, character, this.backToCharacterPage);
    //   offLoading(this.props.dispatch);
    // }
    // デバッグ用

    if(this.props.isRefresh && !this.props.loading){
      const character = this.props.match.params && this.props.match.params.character;
      onLoading(this.props.dispatch);
      await listGreetings(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, character, this.backToCharacterPage, false);
      offLoading(this.props.dispatch);
    }
    if (this.state.message) {
      updateMessage(this.props.dispatch, true, this.state.message);
      this.setState({ message: null });
    }
  }

  onSearch = (e) => {
    e.preventDefault();
    searchGreeting(this.props.dispatch, e.target.value, this.props.greetingType);
   }

  onGreetingTypeFilter = (greetingType) => {
    if (this.props.editingGreeting && this.props.editingGreeting.greetingType !== greetingType) {
      if (this.isFormChanged()) return;
      closeGreeting(this.props.dispatch);
    }
    searchGreeting(this.props.dispatch, this.props.search, greetingType);
  }

  onBeginEdit = (e, item) => {
    e.preventDefault();
    if(this.isFormChanged()) return;
    appRemoveError(this.props.dispatch);
    const newItem = Object.keys(item).length !== 0 ? false : true;
    beginEditGreeting(this.props.dispatch, this.props.greetingType, newItem ? null : item.greeting);
    this.setState({
      editingOriginalTitle: newItem ? null : item.title,
      add: newItem ? true : false,
    });
  }

  onBeginDialogEdit = (e, name) =>{
    e.preventDefault();
    this.setState({
      editingPhrase: name === "phrase",
    });
  }

  onCloseDialogEdit = (e) =>{
    e.preventDefault();
    this.setState({
      editingPhrase: false,
    });
  }

  onRegisterDialog = (name, value, change) => {
    const editingGreeting = { ...this.props.formValues };
    if (name === "phrase") {
      editingGreeting["phraseRef"] = value;
      if (change) {
        editingGreeting.isPhraseModelDiff = false;
      }
    }
    changeEditingGreeting(this.props.dispatch, editingGreeting);
  }

  onEditingChange = (e) =>{
    e.preventDefault();
    const editingGreeting = { ...this.props.formValues };
    editingGreeting[e.target.name] = e.target.value;
    if(e.target.name === "motionRef"){
      editingGreeting.isMotionModelDiff = false;
    } else if(e.target.name === "phraseRef"){
      editingGreeting.isPhraseModelDiff = false;
    }
    changeEditingGreeting(this.props.dispatch, editingGreeting);
  }

  onClose = () => {
    if(this.isFormChanged()) return;
    closeGreeting(this.props.dispatch);
    this.setState({
      editingOriginalTitle: null,
    });
  }

  onCancel = () => {
    cancelEditGreeting(this.props.dispatch);
  }

  onUpdateExec = async (values) => {
    const editingGreeting = {...values };
    const exists = await characterExists(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, this.props.characterData.character);
    if (!exists) {
      this.backToCharacterPage();
      return;
    }
    const timeZones = [];
    TIME_ZONES.forEach(timeZone => {
      if(editingGreeting["timeZone_"+timeZone.key]) timeZones.push(timeZone.key);
    });
    editingGreeting.timeZones = timeZones;
    if(editingGreeting.isMotionModelDiff){
      appError(this.props.dispatch, "モーションに異なるモデルのデータが指定されています。");
      return;
    }
    if(editingGreeting.isPhraseModelDiff){
      appError(this.props.dispatch, "フレーズに異なるモデルのデータが指定されています。");
      return;
    }
    appRemoveError(this.props.dispatch);
    onLoading(this.props.dispatch);
    const success = await updateGreetingExec(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, this.props.characterData, editingGreeting, this.backToCharacterPage);
    offLoading(this.props.dispatch);
    if (!success) return;
    this.setState({
      editingOriginalTitle: editingGreeting.title,
      message: this.state.add ? "register" : "update",
      add: false,
    });
  }

  onDeleteConfirm = (e, item) => {
    e.preventDefault();
    this.setState({
      isDeleteConfirmDialog: true,
      deleteGreetingType: item.greetingType,
      deleteGreeting: item.greeting,
      deleteGreetingTitle: this.state.editingOriginalTitle || item.title,
    });
  }

  onDeleteExecute = async (e) =>{
    e.preventDefault();
    const exists = await characterExists(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, this.props.characterData.character);
    if (!exists) {
      this.backToCharacterPage();
      return;
    }
    if(!this.state.deleteGreeting) return;
    appRemoveError(this.props.dispatch);
    onLoading(this.props.dispatch);
    await deleteGreetingExec(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, this.props.characterData,
      this.state.deleteGreetingType, this.state.deleteGreeting);
    offLoading(this.props.dispatch);
    this.setState({
      isDeleteConfirmDialog: false,
      deleteGreetingType: null,
      deleteGreeting: null,
      deleteGreetingTitle: null,
    });
  }

  isFormChanged = () => {
    if(this.props.editingGreeting && (this.props.pristine === false || this.props.editingGreeting.isEditingUpdate === true)){
      this.setState({ message: this.props.editingGreeting.greeting ? "changing_edit" : "changing_new" });
      return true;
    }
    return false;
  }

  backToCharacterPage = () => {
    this.props.history.push("/" + this.props.user.TenantId + "/characters");
  }

  render(){
    const { handleSubmit, submitting, classes } = this.props
    let NearZoneButton, MidZoneButton, headCells, editHeadCells;
    if(this.props.greetingType === GREETING_TYPE_NEAR) {
      NearZoneButton = PrimaryButton;
      MidZoneButton = BorderPrimaryButton;
      headCells = nearZoneHeadCells;
    } else {
      NearZoneButton = BorderPrimaryButton;
      MidZoneButton = PrimaryButton;
      headCells = midZoneHeadCells;
    }
    if(this.props.editingGreeting){
      if(this.props.greetingType === GREETING_TYPE_NEAR){
        editHeadCells = editNearZoneHeadCells;
      } else {
        editHeadCells = editMidZoneHeadCells;
      }
      // console.log(editHeadCells);
    }
    return(
      <React.Fragment>
        <Auth />
        <React.Fragment>
          <TopBar isCharacterEdit={true} />
          <div className={classes.content}>
            <div className={classes.characterMain}>
              <CharacterTopBar />
              <ErrorMessage />
              <UpdateMessage />
              <div className={classes.characerForm}>
                <div className="verticalCenter" style={{ justifyContent: "space-between", marginBottom: 15 }}>
                  <div>
                    <NearZoneButton width={160} onClick={() => this.onGreetingTypeFilter(GREETING_TYPE_NEAR)}><span className="f14">近距離挨拶 {this.props.greetings.nearZone.length}件</span></NearZoneButton>
                    <MidZoneButton width={160} style={{ marginLeft: 5 }} onClick={() => this.onGreetingTypeFilter(GREETING_TYPE_MID)}><span className="f14">中距離挨拶 {this.props.greetings.midZone.length}件</span></MidZoneButton>
                  </div>
                  <div>
                    <SearchTextField name="search" style={{ width: "280px" }} defaultValue={this.props.search} onChange={this.onSearch} />
                    {/* <SearchButton onClick={this.onSearch} style={{ marginLeft: 5 }} /> */}
                    {(this.props.user && this.props.user.isManager()) &&
                      <SecondaryButton onClick={(e) => this.onBeginEdit(e, {})} style={{ marginLeft: 5 }} disabled={this.props.loading}>新規登録</SecondaryButton>
                    }
                  </div>
                </div>
                { !this.props.editingGreeting &&
                  <div className={classes.characterListContainer}>
                    <EnhancedTable list={this.props.searchedGreetings} classes={classes} operationMinWidth={80}
                      headCells={headCells} editCallback={(this.props.user && this.props.user.isManager()) ? this.onBeginEdit : null} deleteCallback={(this.props.user && this.props.user.isManager()) ? this.onDeleteConfirm : null} />
                  </div>
                }
                <form onSubmit={handleSubmit(this.onUpdateExec)}>
                  { this.props.editingGreeting &&
                  <div className={classes.listEditContainer}>
                    <div className={classes.listEditList}>
                      <EnhancedTable list={this.props.searchedGreetings} classes={classes} selectCallback={(this.props.user && this.props.user.isManager()) ? this.onBeginEdit : null}
                        headCells={editHeadCells} selectItem={this.props.editingGreeting} selectKey="greeting" focusItem={this.props.editingGreeting} focusKey="greeting" isOperationHidden={true} />
                    </div>
                    <div className={classes.listEditEdit}>
                      <div className={classes.arrowAndSideMenuContainer}>
                        <div className={classes.listEditSpace}>
                          <img src="/images/left_arrow.png" alt="<" />
                        </div>
                        <div className={classes.insideEditContainer} style={{ width: 431 }}>
                          <div className="verticalCenter" style={{ justifyContent: "space-between" }}>
                            <div className="verticalCenter">
                              <BorderBackButton onClick={this.onClose}/>
                              <span className="f16 subText ml8">{ this.props.editingGreeting.greetingType === GREETING_TYPE_NEAR ? "近距離挨拶" : "中距離挨拶" }{this.state.add ? "新規登録" : "編集"}</span>
                            </div>
                            { (this.props.editingGreeting.greeting && this.props.user && this.props.user.isManager()) &&
                              <Delete24Button onClick={(e) => this.onDeleteConfirm(e, this.props.editingGreeting)} />
                            }
                          </div>
                          <div className="verticalCenter mb15" style={{ marginTop: 15 }}>
                            <span className="contentEditLabel">挨拶名</span>
                            <Field name="title" style={{ width: "100%" }} component={renderSimpleTextField} validate={[required]} />
                          </div>
                          <div className={classes.characterInsideEditScroll}>
                            { this.props.editingGreeting.greetingType === GREETING_TYPE_MID ?
                            <React.Fragment>
                              <div className="verticalCenter mb15">
                                <span className="fitText f12" style={{ minWidth: 200 }}>バーチャルセンサー番号</span>
                                <Field name="virtualwallNo" type="number" component={renderSimpleSelectField} size={"sm"} validate={[required, isNumber]} normalize={toNumber} >
                                    <MenuItem value="1">1</MenuItem>
                                    <MenuItem value="2">2</MenuItem>
                                    <MenuItem value="3">3</MenuItem>
                                </Field>
                              </div>
                              <div className="verticalCenter mb15">
                                <span className="fitText f12" style={{ minWidth: 200 }}>バーチャルセンサー進入方向</span>
                                <Field name="virtualwallCourse" component={renderSimpleSelectField} size={"sm"} validate={[required]} >
                                  <MenuItem value="IN">IN</MenuItem>
                                  <MenuItem value="OUT">OUT</MenuItem>
                                </Field>
                              </div>
                            </React.Fragment>
                            : null}
                            <div className="verticalCenter mb15">
                              <span className="contentEditLabel f12">モーション</span>
                              <Field name="motionRef" component={renderSimpleSelectField} size={"sm"} displayEmpty textcolor={this.props.editingGreeting.isMotionModelDiff && "red"} onChange={this.onEditingChange}>
                                <MenuItem value="">[状態維持]</MenuItem>
                                { this.props.motionMenuItems.map((item, i) => (
                                  <MenuItem key={i} value={item.motion} style={{display: item.disabled && "none"}}>{ item.title }</MenuItem>
                                )) }
                              </Field>
                            </div>
                            <div className="verticalCenter mb15">
                              <span className="contentEditLabel f12">音声フレーズ</span>
                              <Field name="phraseRef" component={renderSimpleSelectField} size={"sm"} displayEmpty SelectDisplayProps={{ style: {paddingRight: 45} }}
                                textcolor={this.props.editingGreeting.isPhraseModelDiff ? "#F8431C" : "#444444"} disabled IconComponent={() => (
                                  <EditDialogButton onClick={(e) => this.onBeginDialogEdit(e, "phrase")}>[編集]</EditDialogButton>
                                  )}
                                >
                                <MenuItem value="">[再生しない]</MenuItem>
                                { this.props.phraseMenuItems.map((item, i) => (
                                  <MenuItem key={i} value={item.phrase}>{ item.phraseTitle }</MenuItem>
                                )) }
                              </Field>
                              <SelectDialog
                                open={this.state.editingPhrase}
                                name="phrase"
                                type="table"
                                list={[{phrase: '', phraseTitle: '[再生しない]'}].concat(this.props.phraseMenuItems)}
                                initialSelected={this.props.editingGreeting.phraseRef}
                                isDiff={this.props.editingGreeting.isPhraseModelDiff}
                                onRegister={this.onRegisterDialog}
                                isCreate={!this.props.editingGreeting.createdAt}
                                onClose={this.onCloseDialogEdit} />
                            </div>
                            <div className="verticalCenter mb15">
                              <span className="contentEditLabel f12">再生タイプ</span>
                              <Field name="playType" component={renderSimpleSelectField} size={"sm"} >
                                <MenuItem value="SyncSoundAndMotion">音声・モーション同時再生</MenuItem>
                                <MenuItem value="SoundAfterMotion">音声再生のあとにモーション再生</MenuItem>
                                <MenuItem value="MotionAfterSound">モーション再生のあとに音声再生</MenuItem>
                              </Field>
                            </div>
                            { TIME_ZONES.map(timeZone => (
                              <div key={timeZone.key} className="verticalCenter mb15">
                                <span className="contentEditLabel f12">{timeZone.name}</span>
                                <div>
                                  <Field keys={timeZone.key} name={"timeZone_"+timeZone.key} component={renderSimpleSwitch} />
                                </div>
                              </div>
                            ))}
                            <div className="verticalCenter mb15">
                              <span className="contentEditLabel f12">作成日時</span>
                              <span className="f12" >{ formatDate(this.props.editingGreeting.createdAt) }</span>
                            </div>
                            <div className="verticalCenter mb15">
                              <span className="contentEditLabel f12">更新日時</span>
                              <span className="f12">{ formatDate(this.props.editingGreeting.updatedAt) }</span>
                            </div>
                          </div>
                          <div className="verticalCenter alignCenter" style={{ marginTop: 25 }}>
                            <GrayButton width={120} height={32} onClick={this.onCancel} style={{ marginRight: 20 }}>リセット</GrayButton>
                            <PrimaryButton type="submit" width={120} height={32} disabled={submitting}>{this.state.add ? "登録する" : "更新する"}</PrimaryButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  }
                </form>
              </div>
            </div>
          </div>
          <StyledDialog open={this.state.isDeleteConfirmDialog} onClose={() => this.setState({ isDeleteConfirmDialog: false, deleteGreetingType: null, deleteGreeting: null })}>
            <DialogContent className={classes.dialogContent}>
              <div className={classes.closeButton}>
                <CloseButton onClick={() => this.setState({ isDeleteConfirmDialog: false, deleteGreetingType: null, deleteGreeting: null })}/>
              </div>
              <div className="deletePopupContentText">
                <div>
                  <div className="wordKeepAll">「{this.state.deleteGreetingTitle}</div>
                  <div className="wordKeepAllClose">」</div>
                  を削除しますか？
                </div>
                <div style={{ marginTop: 36 }}>
                  <PrimaryButton width={160} height={48} onClick={(e) => this.onDeleteExecute(e)} disabled={this.props.loading}>削除する</PrimaryButton>
                  <GrayButton width={160} height={48} onClick={() => this.setState({ isDeleteConfirmDialog: false, deleteGreetingType: null, deleteGreeting: null })} style={{ marginLeft: 20 }}>キャンセル</GrayButton>
                </div>
              </div>
            </DialogContent>
          </StyledDialog>
        </React.Fragment>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(muiStyles)(
  connect(state => ({
    user: state.user && state.user.user,
    listeners: state.main && state.main.listeners,
    company: state.main && state.main.company,
    selectedLabel: state.main && state.main.selectedLabel,
    characters: (state.characters && state.characters.characters) || [],
    characterData: state.greetings && state.greetings.characterData,
    greetings: (state.greetings && state.greetings.greetings) || [],
    searchedGreetings: (state.greetings && state.greetings.searchedGreetings) || [],
    search: state.greetings && state.greetings.search,
    editingGreeting: state.greetings && state.greetings.editingGreeting,
    greetingType: state.greetings && state.greetings.greetingType,
    phrases: (state.greetings && state.greetings.phrases) || [],
    phraseMenuItems: (state.greetings && state.greetings.phraseMenuItems) || [],
    motions: (state.greetings && state.greetings.motions) || [],
    motionMenuItems: (state.greetings && state.greetings.motionMenuItems) || [],
    isRefresh: state.greetings && state.greetings.isRefresh,
    loading: state.loading && state.loading.loading,
    initialValues: (state.greetings && state.greetings.editingGreeting) || {},
    formValues: getFormValues('greetingForm')(state),
  }))(reduxForm({ form: 'greetingForm', validate: validate, enableReinitialize: true })(Greetings))
));
