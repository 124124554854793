import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { withStyles, Button } from "@material-ui/core";
import { settingMenu } from "./actions/appAction";
import { BackButton } from "./AppParts";
import { listCharacters } from "./actions/characters";
import { onLoading, offLoading } from "./actions/loading";

const styles = theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between ",
  },
  titleContainer: {
    
  },
  title: {
    fontSize: 28,
    lineHeight: "42px",
  },
  buttonContainer: {
    minWidth: 760,
    height: "32px",
  },
  white: {
    height: 32,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.white.main,
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.white.dark,
    },
  },
  selected: {
    height: 32,
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      color: "#fff",
      backgroundColor: theme.palette.primary.dark,
    },
  },
});

const StyledButton = withStyles({
  label: {
    fontSize: 16,
    lineHeight: "24px"
  }
})(Button);

const MENUS = [
  { key: "characterForm", name: "基本設定", width: 100 },
  { key: "greetings", name: "挨拶設定", width: 100 },
  { key: "manualCommands", name: "手動操作コマンド設定", width: 180 },
  { key: "externalCommands", name: "外部コマンド設定", width: 160 },
  { key: "contents", name: "音声対話シナリオ設定", width: 180 },
];

class CharacterTopBar extends Component {

  state = {
    characterName: "",
  }

  componentDidMount(){
    if(this.props.editingCharacter){
      // console.log("componentDidMount", this.props.editingCharacter)
      this.setState({
        characterName: this.props.editingCharacter.name
      })
    }
  }

  async componentDidUpdate(){
    if(this.props.editingCharacter && this.props.isUpdated){
      // console.log("componentDidUpdate", this.props.editingCharacter)
      // characterIsUpdateChange(this.props.dispatch, false);
      onLoading(this.props.dispatch);
      await listCharacters(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label);
      offLoading(this.props.dispatch);
      this.setState({
        characterName: this.props.editingCharacter.name
      })
    }
  }

  onMove = (url) => {
    this.props.history.push("/" + this.props.user.TenantId + "/" + url + "/" + (this.props.editingCharacter.character));
  }

  onBack = (e) => {
    e.preventDefault();
    settingMenu(this.props.dispatch, true);
    this.props.history.push("/" + this.props.user.TenantId + "/characters");
  }

  render() {
    const { classes } = this.props;
    const p = this.props.location.pathname.split("/");
    let url = p.length >= 3 ? p[2] : "";
    return (
      <div className={classes.container}>
        <div className={classes.titleContainer} style={{ display: "flex", alignItems: "center" }}>
          <BackButton onClick={this.onBack} style={{ minWidth: 32 }} />
          { this.props.editingCharacter &&
            <span className={classes.title}>「{this.state.characterName}」編集</span>
          }
        </div>
        <div className={classes.buttonContainer}>
          { MENUS.map((menu, i) => {
            let buttonClass = "white";
            if(url === menu.key){
              buttonClass = "selected";
            }
            return(
              <StyledButton key={i} className={classes[buttonClass]} style={{ width: menu.width }} onClick={() => this.onMove(menu.key)}>{ menu.name }</StyledButton>
            );
          }) }
        </div>
      </div>
    )
  }

}

export default withRouter(withStyles(styles)(
  connect(state => ({
    user: state.user && state.user.user,
    selectedLabel: state.main && state.main.selectedLabel,
    editingCharacter: state.characters && state.characters.editingCharacter,
    isUpdated: state.characters && state.characters.isUpdated,
    loading: state.loading && state.loading.loading,
  }))(CharacterTopBar)
));
