import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import appReducer from './appReducer';
import user from './user';
import main from './main';
import loading from './loading';
import characters from './characters';
import phrases from './phrases';
import motions from './motions';
import guides from './guides';
import commandsGroups from './commandsGroups';
import accounts from './accounts'
import manualCommands from './manualCommands'
import greetings from './greetings';
import approvalRequests from './approvalRequests';
import contents from './contents';
import vguards from './vguards';
import addAccount from './addAccount';
import keywordGroups from './keywordGroups';
import voiceCalling from './voiceCalling';
import faceDetectionPhrases from './faceDetectionPhrases';
import externalCommands from './externalCommands';
import models from './models';

const resetStateWithLogout = (state, action) => ({
  appState: { ...state.appState }, // エラーは使うので残す
  user: user(undefined, action),
  form: { ...state.form }, // フォームも使うので残す
  main: main(undefined, action),
  loading: loading(undefined, action),
  characters: characters(undefined, action),
  phrases: phrases(undefined, action),
  faceDetectionPhrases: faceDetectionPhrases(undefined, action),
  motions: motions(undefined, action),
  guides: guides(undefined, action),
  commandsGroups: commandsGroups(undefined, action),
  accounts: accounts(undefined, action),
  manualCommands: manualCommands(undefined, action),
  greetings: greetings(undefined, action),
  approvalRequests: approvalRequests(undefined, action),
  contents: contents(undefined, action),
  vguards: vguards(undefined, action),
  addAccount: addAccount(undefined, action),
  keywordGroups: keywordGroups(undefined, action),
  voiceCalling: voiceCalling(undefined, action),
  externalCommands: externalCommands(undefined, action),
  models: models(undefined, action),
})

const combinedReducers = combineReducers({
  appState: appReducer,
  user: user,
  form: formReducer,
  main: main,
  loading: loading,
  characters: characters,
  phrases: phrases,
  faceDetectionPhrases: faceDetectionPhrases,
  motions: motions,
  guides: guides,
  commandsGroups: commandsGroups,
  accounts: accounts,
  manualCommands: manualCommands,
  greetings: greetings,
  approvalRequests: approvalRequests,
  contents: contents,
  vguards: vguards,
  addAccount: addAccount,
  keywordGroups: keywordGroups,
  voiceCalling: voiceCalling,
  externalCommands: externalCommands,
  models: models,
});

const rootReducer = (state, action) => {
  if (action.type === "CLEAR_STATE_WITH_LOGOUT") {
    state = resetStateWithLogout(state, action);
  }
  return combinedReducers(state, action);
}

export default rootReducer;
