import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from "@material-ui/core";
import Auth from './auth';
import TopBar from './TopBar';
import { withRouter } from 'react-router-dom';
import { modelsList } from './actions/models';
import { formatDate } from './utilities/utils';
import ErrorMessage from './errorMessage';
import muiStyles from "./MuiStyles";
import SettingMenu from './SettingMenu';
import { EnhancedTable } from './AppParts';
// import { login } from "./actions/login";
// import { mainCompany } from "./actions/main";

const headCells = [
  { id: 'title', label: 'モデル名' },
  { id: 'ttsModelId', label: ['音声合成用', 'Model ID'] },
  { id: 'ttsSpeakerId', label: ['音声合成用', 'Speaker ID'] },
  { id: 'vcModelId', label: ['音声変換用', 'Model ID'] },
  { id: 'createdAt', label: '作成日時', modFunc: formatDate },
  { id: 'updatedAt', label: '更新日時', modFunc: formatDate },
]

export class Model extends Component {
  state = {
    order: null,
    orderBy: null,
  }

  async componentDidMount() {
    // TODO: デバッグ用
    // console.log("componentDidMount", this.props);
    // if(!this.props.user){
    //   const cid = this.props.match.params && this.props.match.params.cid;
    //   await login(this.props.dispatch, "test@test.com", "sskkotdotd", cid);
    // }
    // デバッグ用

    if (this.props.user) {
      modelsList(this.props.dispatch, this.props.user.TenantId);
    }
  }

  async componentDidUpdate() {
    // TODO: デバッグ用
    // console.log("componentDidUpdate", this.props);
    // if(!this.props.company && this.props.user){
    //   await mainCompany(this.props.dispatch, this.props.user.TenantId);
    // }
    // if(this.props.company && this.props.motions.length === 0){
    //   await modelList(this.props.dispatch, this.props.user.TenantId);
    // }
    // デバッグ用
  }

  setOrder(order, orderBy) {
    this.setState({
      order: order,
      orderBy: orderBy,
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Auth />
        <React.Fragment>
          <TopBar />
          <div className={classes.content}>
            <SettingMenu />
            <div className={classes.main}>
              <div className="titleText">モデル管理</div>
              <ErrorMessage />
              <div className={classes.listContainer}>
                <EnhancedTable list={this.props.models} classes={classes}
                  headCells={headCells} />
              </div>
            </div>
          </div>
        </React.Fragment>
      </React.Fragment>
    );
  }
}

//Main.contextTypes = {
//  router: PropTypes.object
//};

export default withRouter(withStyles(muiStyles)(
  connect(state => ({
    user: state.user && state.user.user,
    company: state.main && state.main.company,
    models: (state.models && state.models.models) || [],
  }))(Model)
));