import React, { Component } from "react";
import { connect } from 'react-redux';
import Auth from "./auth";
import { withRouter } from "react-router-dom";
import TopBar from "./TopBar";
import { withStyles, DialogContent, MenuItem } from '@material-ui/core';
import { listManualCommands, beginEditManualCommand, changeEditingManualCommand, updateManualCommandExec, deleteManualCommandExec, cancelEditManualCommand, searchManualCommand, closeManualCommand } from "./actions/manualCommands";
import { characterExists } from "./actions/characters";
import { Field, reduxForm, getFormValues, change } from 'redux-form';
import { formatDate } from "./utilities/utils";
import { onLoading, offLoading } from "./actions/loading";
import { renderSimpleTextField, renderSimpleSelectField } from "./formRenderer";
import { required } from "./validator";
import ErrorMessage from './errorMessage';
import UpdateMessage from './updateMessage';
import { appError, appRemoveError, updateMessage } from './actions/appAction';
import muiStyles from "./MuiStyles";
import CharacterTopBar from "./CharacterTopBar";
import { EnhancedTable, GrayButton, PrimaryButton, SecondaryButton, CloseButton, StyledDialog, SearchTextField, BorderBackButton, Delete24Button, EditDialogButton} from "./AppParts";
import SelectDialog from './selectDialog';

// import { login } from "./actions/login";
// import { mainCompany, mainLablesListener } from "./actions/main";
// import { listCharacters } from "./actions/characters";

const headCells = [
  { id: 'manualCommandGroupRef.title', label: 'グループ', isNest: true },
  { id: 'title', label: 'コマンド名' },
  { id: 'motionRef.title', label: 'モーション', isNest: true },
  { id: 'phraseRef.phraseTitle', label: 'フレーズ', isNest: true, },
  { id: 'updatedAt', label: '更新日時', modFunc: formatDate },
]

const editHeadCells = [
  { id: 'manualCommandGroupRef.title', label: 'グループ', isNest: true },
  { id: 'title', label: 'コマンド名' },
  { id: 'motionRef.title', label: 'モーション', isNest: true },
  { id: 'phraseRef.phraseTitle', label: 'フレーズ', isNest: true, },
]

const validate = values => {
  const errors = {};
  if (values.timeoutSec && Number(values.timeoutSec.toString().replace(/,/g, "")) > 40000000) {
    errors.timeoutSec = "40,000,000以下";
  }
  if (values.digitalOutOneShotTime && Number(values.digitalOutOneShotTime.toString().replace(/,/g, "")) > 3600) {
    errors.digitalOutOneShotTime = "3,600以下";
  }
  return errors;
}

export class ManualCommands extends Component {
  state ={
    editingOriginalTitle: null,
    isDeleteConfirmDialog: false,
    deleteManualCommand: null,
    deleteManualCommandTitle: null,
    message: null,
    add: false,
    editingPhrase: false,
    editingPicture: false,
  }

  async componentDidMount() {
    // TODO: デバッグ用
    // console.log("componentDidMount", this.props);
    // if(!this.props.user){
    //   const cid = this.props.match.params && this.props.match.params.cid;
    //   login(this.props.dispatch, "test@test.com", "sskkotdotd", cid);
    // }
    // デバッグ用

    if(this.props.company && this.props.selectedLabel){
      const character = this.props.match.params && this.props.match.params.character;
      onLoading(this.props.dispatch);
      const exists = await characterExists(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, character);
      if (!exists) {
        this.backToCharacterPage();
        offLoading(this.props.dispatch);
        return;
      }
      await listManualCommands(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, character, this.backToCharacterPage, true);
      offLoading(this.props.dispatch);
    }
  }

  async componentDidUpdate() {
    // TODO: デバッグ用
    // console.log("componentDidUpdate", this.props);
    // if(!this.props.company && this.props.user){
    //   await mainCompany(this.props.dispatch, this.props.user.TenantId);
    // }
    // if(this.props.company && !this.props.selectedLabel.label){
    //   await mainLablesListener(this.props.dispatch, this.props.user.TenantId, this.props.listeners);
    // }
    // if(this.props.company && this.props.selectedLabel.label && this.props.characters.length === 0){
    //   await listCharacters(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label);
    // }
    // if(this.props.company && this.props.selectedLabel.label && this.props.characters.length > 0 && !this.props.manualCommands.length){
    //   const character = this.props.match.params && this.props.match.params.character;
    //   await listManualCommands(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, character, this.backToCharacterPage);
    // }
    // デバッグ用

    if(this.props.isRefresh && !this.props.loading){
      const character = this.props.match.params && this.props.match.params.character;
      onLoading(this.props.dispatch);
      await listManualCommands(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, character, this.backToCharacterPage, false);
      offLoading(this.props.dispatch);
    }
    if (this.state.message) {
      updateMessage(this.props.dispatch, true, this.state.message);
      this.setState({ message: null });
    }
  }

  onSearch = (e) => {
    e.preventDefault();
    searchManualCommand(this.props.dispatch, e.target.value);
  }

  onBeginEdit = async (e, item) => {
    e.preventDefault();
    if(this.isFormChanged()) return;
    appRemoveError(this.props.dispatch);
    const newItem = Object.keys(item).length !== 0 ? false : true;
    const character = this.props.match.params && this.props.match.params.character;
    await beginEditManualCommand(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, character, newItem ? null : item.manualCommand);
    this.setState({
      editingOriginalTitle: newItem ? null : item.title,
      add: newItem ? true : false,
    });
  }

  onClose = () => {
    if(this.isFormChanged()) return;
    closeManualCommand(this.props.dispatch);
    this.setState({
      editingOriginalTitle: null,
    });
  }

  onCancel = () => {
    cancelEditManualCommand(this.props.dispatch);
  }

  onBeginDialogEdit = (e, name) =>{
    e.preventDefault();
    this.setState({
      editingPhrase: name === "phrase",
      editingPicture: name === "picture",
    });
  }

  onCloseDialogEdit = (e) =>{
    e.preventDefault();
    this.setState({
      editingPhrase: false,
      editingPicture: false,
    });
  }

  onRegisterDialog = (name, value, change) => {
    const editingManualCommand = { ...this.props.formValues };
    if (name === "phrase") {
      editingManualCommand["phraseRef"] = value;
      if (change) {
        editingManualCommand.isPhraseModelDiff = false;
      }
    } else if (name === "picture") {
      editingManualCommand["pictureRef"] = value;
    }
    changeEditingManualCommand(this.props.dispatch, editingManualCommand);
  }

  onEditingChange = (e) =>{
    e.preventDefault();
    const editingManualCommand = { ...this.props.formValues };
    editingManualCommand[e.target.name] = e.target.value;
    if(e.target.name === "motionRef"){
      editingManualCommand.isMotionModelDiff = false;
    } else if(e.target.name === "phraseRef"){
      editingManualCommand.isPhraseModelDiff = false;
    }
    this.setState({
      editingPhrase: false,
      editingPicture: false,
    });

    changeEditingManualCommand(this.props.dispatch, editingManualCommand);
  }

  onUpdateExec = async (values) => {
    const editingManualCommand = {...values };
    const exists = await characterExists(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, this.props.characterData.character);
    if (!exists) {
      this.backToCharacterPage();
      return;
    }
    if(this.props.manualCommands.find(x => x.manualCommand !== editingManualCommand.manualCommand && x.title === editingManualCommand.title)){
      appError(this.props.dispatch, "同じコマンド名がすでにあります。");
      return;
    }
    if(editingManualCommand.isMotionModelDiff){
      appError(this.props.dispatch, "モーションに異なるモデルのデータが指定されています。");
      return;
    }
    if(editingManualCommand.isPhraseModelDiff){
      appError(this.props.dispatch, "フレーズに異なるモデルのデータが指定されています。");
      return;
    }
    const motion = this.props.motionMenuItems.find(x => x.motion === editingManualCommand.motionRef);
    if (motion && motion.title && motion.title.indexOf("緊急地震速報") !== -1) {
      if (editingManualCommand.timeoutType === "Instantly") {
        appError(this.props.dispatch, ["モーションに緊急地震速報が設定されている場合、", "タイムアウトタイプにInstantlyは設定できません。"]);
        return;
      }
    }
    appRemoveError(this.props.dispatch);
    onLoading(this.props.dispatch);
    const success = await updateManualCommandExec(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, this.props.characterData, editingManualCommand, this.backToCharacterPage);
    offLoading(this.props.dispatch);
    if (!success) return;
    this.setState({
      editingOriginalTitle: editingManualCommand.title,
      message: this.state.add ? "register" : "update",
      add: false,
    });
  }

  onDeleteConfirm = (e, item) => {
    e.preventDefault();
    this.setState({
      isDeleteConfirmDialog: true,
      deleteManualCommand: item.manualCommand,
      deleteManualCommandTitle: this.state.editingOriginalTitle || item.title,
    });
  }

  onDeleteExecute = async (e) =>{
    e.preventDefault();
    const exists = await characterExists(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, this.props.characterData.character);
    if (!exists) {
      this.backToCharacterPage();
      return;
    }
    if (!this.state.deleteManualCommand) return;
    appRemoveError(this.props.dispatch);
    onLoading(this.props.dispatch);
    await deleteManualCommandExec(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, this.props.characterData, this.state.deleteManualCommand);
    offLoading(this.props.dispatch);
    this.setState({
      isDeleteConfirmDialog: false,
      deleteManualCommand: null,
      deleteManualCommandTitle: null,
    });
  }

  onChangeTimeoutType = (e) => {
    if (e.target.value === "Instantly") {
      this.props.dispatch(change("manualCommandForm", "timeoutSec", "0"));
    }
  }

  isFormChanged = () => {
    if(this.props.editingManualCommand && (this.props.pristine === false || this.props.editingManualCommand.isEditingUpdate === true)){
      this.setState({ message: this.props.editingManualCommand.manualCommand ? "changing_edit" : "changing_new" });
      return true;
    }
    return false;
  }

  backToCharacterPage = () => {
    this.props.history.push("/" + this.props.user.TenantId + "/characters");
  }

  render(){
    const { handleSubmit, submitting, classes } = this.props
    const textRight = { style: { textAlign: "right" }};
    let timeoutSecDisabled = true;
    if((this.props.formValues && this.props.formValues.timeoutType !== "Instantly")){
      timeoutSecDisabled = false;
    }

    return(
      <React.Fragment>
        <Auth />
        <React.Fragment>
          <TopBar isCharacterEdit={true} />
          <div className={classes.content}>
            <div className={classes.characterMain}>
              <CharacterTopBar />
              <ErrorMessage />
              <UpdateMessage />
              <div className={classes.characerForm}>
                  <div className="verticalCenter" style={{ justifyContent: "flex-end", marginBottom: 15 }}>
                    <div>
                      <SearchTextField name="search" style={{ width: "280px" }} defaultValue={this.props.search} onChange={this.onSearch} />
                      {/* <Field name="search" style={{ width: "200px" }} component={renderSimpleTextField} /> */}
                      {/* <SearchButton onClick={this.onSearch} style={{ marginLeft: 5 }} /> */}
                      {(this.props.user && this.props.user.isManager()) &&
                        <SecondaryButton onClick={(e) => this.onBeginEdit(e, {})} style={{ marginLeft: 5 }} disabled={this.props.loading}>新規登録</SecondaryButton>
                      }
                    </div>
                  </div>
                  { !this.props.editingManualCommand &&
                  <div className={classes.characterListContainer}>
                    <EnhancedTable list={this.props.searchedmanualCommands} classes={classes} operationMinWidth={80}
                      headCells={headCells} editCallback={(this.props.user && this.props.user.isManager()) ? this.onBeginEdit : null} deleteCallback={(this.props.user && this.props.user.isManager()) ? this.onDeleteConfirm : null} />
                  </div>
                  }
                <form onSubmit={handleSubmit(this.onUpdateExec)}>
                  { this.props.editingManualCommand &&
                  <div className={classes.listEditContainer}>
                    <div className={classes.listEditList}>
                      <EnhancedTable list={this.props.searchedmanualCommands} classes={classes} selectCallback={(this.props.user && this.props.user.isManager()) ? this.onBeginEdit : null}
                        headCells={editHeadCells} selectItem={this.props.editingManualCommand} selectKey="manualCommand" focusItem={this.props.editingManualCommand} focusKey="manualCommand" isOperationHidden={true} />
                    </div>
                    <div className={classes.listEditEdit}>
                      <div className={classes.arrowAndSideMenuContainer}>
                        <div className={classes.listEditSpace}>
                          <img src="/images/left_arrow.png" alt="<" />
                        </div>
                        <div className={classes.insideEditContainer} style={{ width: 431 }}>
                          <div className="verticalCenter" style={{ justifyContent: "space-between" }}>
                            <div className="verticalCenter">
                              <BorderBackButton onClick={this.onClose}/>
                              <span className="f16 subText ml8">手動操作コマンド{this.state.add ? "新規登録" : "編集"}</span>
                            </div>
                            { (this.props.editingManualCommand.manualCommand && this.props.user && this.props.user.isManager()) &&
                              <Delete24Button onClick={(e) => this.onDeleteConfirm(e, this.props.editingManualCommand)} />
                            }
                          </div>
                          <div className="verticalCenter mb15" style={{ marginTop: 15 }}>
                            <span className="contentEditLabel">コマンド名</span>
                            <Field name="title" style={{ width: "100%" }} component={renderSimpleTextField} validate={[required]} />
                          </div>
                          <div className={classes.characterInsideEditScroll}>
                            <div className="verticalCenter mb15">
                              <span className="contentEditLabel f12">グループ</span>
                              <Field name="manualCommandGroupRef" component={renderSimpleSelectField} size={"sm"} style={{ marginRight: 10 }} >
                                { this.props.manualCommandGroupMenuItem.map((item, i) => (
                                  <MenuItem key={i} value={item.manualCommandsGroup}>{ item.title }</MenuItem>
                                )) }
                              </Field>
                            </div>
                            <div className="verticalCenter mb15">
                              <span className="contentEditLabel f12">モーション</span>
                              <Field name="motionRef" component={renderSimpleSelectField} size={"sm"} style={{ marginRight: 10 }} displayEmpty textcolor={this.props.editingManualCommand.isMotionModelDiff && "red"} onChange={this.onEditingChange}>
                                <MenuItem value="">[状態維持]</MenuItem>
                                { this.props.motionMenuItems.map((item, i) => (
                                  <MenuItem key={i} value={item.motion} style={{display: item.disabled && "none"}}>{ item.title }</MenuItem>
                                )) }
                              </Field>
                            </div>
                            <div className="verticalCenter mb15">
                              <span className="contentEditLabel f12">フレーズ</span>
                              <Field name="phraseRef" component={renderSimpleSelectField} size={"sm"} style={{ marginRight: 10 }} displayEmpty SelectDisplayProps={{ style: {paddingRight: 45} }}
                                textcolor={this.props.editingManualCommand.isPhraseModelDiff ? "#F8431C" : "#444444"} disabled IconComponent={() => (
                                  <EditDialogButton onClick={(e) => this.onBeginDialogEdit(e, "phrase")}>[編集]</EditDialogButton>
                                  )}
                                >
                                <MenuItem value="">[再生しない]</MenuItem>
                                { this.props.phraseMenuItems.map((item, i) => (
                                  <MenuItem key={i} value={item.phrase}>{ item.phraseTitle }</MenuItem>
                                )) }
                              </Field>
                              <SelectDialog
                                open={this.state.editingPhrase}
                                name="phrase"
                                type="table"
                                list={[{phrase: '', phraseTitle: '[再生しない]'}].concat(this.props.phraseMenuItems)}
                                initialSelected={this.props.editingManualCommand.phraseRef}
                                isDiff={this.props.editingManualCommand.isPhraseModelDiff}
                                onRegister={this.onRegisterDialog}
                                isCreate={!this.props.editingManualCommand.createdAt}
                                onClose={this.onCloseDialogEdit} />
                            </div>
                            <div className="verticalCenter mb15">
                              <span className="contentEditLabel f12">再生タイプ</span>
                              <Field name="playType" component={renderSimpleSelectField} size={"sm"} style={{ marginRight: 10 }} >
                                <MenuItem value="SyncSoundAndMotion">音声・モーション同時再生</MenuItem>
                                <MenuItem value="SoundAfterMotion">音声再生のあとにモーション再生</MenuItem>
                                <MenuItem value="MotionAfterSound">モーション再生のあとに音声再生</MenuItem>
                              </Field>
                            </div>
                            <div className="verticalCenter mb15">
                              <span className="contentEditLabel f12">案内画像</span>
                              <Field name="pictureRef" component={renderSimpleSelectField} size={"sm"}  style={{ marginRight: 10 }} displayEmpty SelectDisplayProps={{ style: {paddingRight: 45} }}
                                textcolor="#444444" disabled IconComponent={() => (
                                  <EditDialogButton onClick={(e) => this.onBeginDialogEdit(e, "picture")}>[編集]</EditDialogButton>)}
                                >
                                <MenuItem value="">[非表示]</MenuItem>
                                { this.props.pictures.map((item, i) => (
                                  <MenuItem key={i} value={item.picture}>{ item.title }</MenuItem>
                                )) }
                              </Field>
                              <SelectDialog
                                open={this.state.editingPicture}
                                name="picture"
                                type="table"
                                list={[{picture: '', title: '[非表示]'}].concat(this.props.pictures)}
                                initialSelected={this.props.editingManualCommand.pictureRef}
                                onRegister={this.onRegisterDialog}
                                isCreate={!this.props.editingManualCommand.createdAt}
                                onClose={this.onCloseDialogEdit} />
                            </div>
                            <div className="mb15" style={{ display: "flex", alignItems: "baseline" }}>
                              <span className="contentEditLabel f12">接点出力</span>
                              <Field name="digitalOut" component={renderSimpleSelectField} size={"sm"} width={90} style={{ marginRight: 10 }} displayEmpty>
                                <MenuItem value="">[なし]</MenuItem>
                                { this.props.digitalOutItems.map(item => (
                                  <MenuItem key={item} value={item}>{item}</MenuItem>
                                )) }
                              </Field>
                              <span className="contentEditLabel f12">ワンショット時間</span>
                              <Field name="digitalOutOneShotTime" type="text" numberFormat={true} inputProps={{ ...textRight }} className="timeoutSecField" style={{ width: 80 }}
                                component={renderSimpleTextField} validate={[required]} size={"sm"} />
                              <span className="f12 ml6">s</span>
                            </div>
                            <div className="verticalCenter mb15">
                              <span className="contentEditLabel fitText f12" >タイムアウトタイプ</span>
                              <Field name="timeoutType" className="timeoutTypeField" component={renderSimpleSelectField} size={"sm"} style={{ marginLeft: 40 }} onChange={this.onChangeTimeoutType} >
                                { this.props.timeoutTypeItems.map(item => (
                                  <MenuItem key={item} value={item}>{item}</MenuItem>
                                )) }
                              </Field>
                            </div>
                            <div className="mb15" style={{ marginTop: 15, display: "flex", alignItems: "baseline" }}>
                              <span className="contentEditLabel fitText f12">タイムアウト秒数</span>
                              <Field name="timeoutSec" type="text" numberFormat={true} inputProps={{ ...textRight }} className="timeoutSecField" style={{ width: 48, marginLeft: 55 }}
                                component={renderSimpleTextField} validate={[required]} size={"sm"} disabled={timeoutSecDisabled} />
                              <span className="contentEditLabel fitText f12" style={{ marginLeft: 4 }}>s</span>
                            </div>
                            <div className="verticalCenter mb15">
                              <span className="contentEditLabel f12">作成日時</span>
                              <span className="f12">{ formatDate(this.props.editingManualCommand.createdAt) }</span>
                            </div>
                            <div className="verticalCenter mb15">
                              <span className="contentEditLabel f12">更新日時</span>
                              <span className="f12">{ formatDate(this.props.editingManualCommand.updatedAt) }</span>
                            </div>
                          </div>
                          <div className="verticalCenter alignCenter" style={{ marginTop: 25 }}>
                            <GrayButton width={120} height={32} onClick={this.onCancel} style={{ marginRight: 20 }}>リセット</GrayButton>
                            <PrimaryButton type="submit" width={120} height={32} disabled={submitting}>{this.state.add ? "登録する" : "更新する"}</PrimaryButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  }
                </form>
              </div>
            </div>
          </div>
          <StyledDialog open={this.state.isDeleteConfirmDialog} onClose={() => this.setState({ isDeleteConfirmDialog: false, deleteManualCommand: null })}>
            <DialogContent className={classes.dialogContent}>
              <div className={classes.closeButton}>
                <CloseButton onClick={() => this.setState({ isDeleteConfirmDialog: false, deleteManualCommand: null })}/>
              </div>
              <div className="deletePopupContentText">
                <div>
                  <div className="wordKeepAll">「{this.state.deleteManualCommandTitle}</div>
                  <div className="wordKeepAllClose">」</div>
                  を削除しますか？
                </div>
                <div style={{ marginTop: 36 }}>
                  <PrimaryButton width={160} height={48} onClick={(e) => this.onDeleteExecute(e)} disabled={this.props.loading}>削除する</PrimaryButton>
                  <GrayButton width={160} height={48} onClick={() => this.setState({ isDeleteConfirmDialog: false, deleteManualCommand: null })} style={{ marginLeft: 20 }}>キャンセル</GrayButton>
                </div>
              </div>
            </DialogContent>
          </StyledDialog>
        </React.Fragment>
      </React.Fragment>
    )
  }

}

export default withRouter(withStyles(muiStyles)(
  connect(state => ({
    user: state.user && state.user.user,
    listeners: state.main && state.main.listeners,
    company: state.main && state.main.company,
    selectedLabel: state.main && state.main.selectedLabel,
    characters: (state.characters && state.characters.characters) || [],
    characterData: state.manualCommands && state.manualCommands.characterData,
    manualCommands: (state.manualCommands && state.manualCommands.manualCommands) || [],
    searchedmanualCommands: (state.manualCommands && state.manualCommands.searchedmanualCommands) || [],
    search: state.manualCommands && state.manualCommands.search,
    editingManualCommand: state.manualCommands && state.manualCommands.editingManualCommand,
    manualCommandsGroups: (state.manualCommands && state.manualCommands.manualCommandsGroups) || [],
    manualCommandGroupMenuItem: (state.manualCommands && state.manualCommands.manualCommandGroupMenuItem) || [],
    phrases: (state.manualCommands && state.manualCommands.phrases) || [],
    phraseMenuItems: (state.manualCommands && state.manualCommands.phraseMenuItems) || [],
    motions: (state.manualCommands && state.manualCommands.motions) || [],
    motionMenuItems: (state.manualCommands && state.manualCommands.motionMenuItems) || [],
    pictures: (state.manualCommands && state.manualCommands.pictures) || [],
    digitalOutItems: (state.manualCommands && state.manualCommands.digitalOutItems) || [],
    timeoutTypeItems: (state.manualCommands && state.manualCommands.timeoutTypeItems) || [],
    appError: state.manualCommands && state.manualCommands.error,
    isRefresh: state.manualCommands && state.manualCommands.isRefresh,
    loading: state.loading && state.loading.loading,
    initialValues: (state.manualCommands && state.manualCommands.editingManualCommand) || {},
    formValues: getFormValues("manualCommandForm")(state),
  }))(reduxForm({ form: 'manualCommandForm', validate: validate, enableReinitialize: true })(ManualCommands))
));
