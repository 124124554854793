import authorizerFB from './authorizerFirebase';
import db from '../utilities/db';
import firebase from 'firebase/app';
import { appErrorAction } from './appAction';
import functions, { isTokenRevokedError } from '../utilities/functions';
import { removeAllMainListeners, unsubAllMainListeners } from './main';
import { removeAccountListener, unsubAccountListener } from './addAccount';
import { clearStateWithLogoutAction } from '.';

export async function login(dispatch, email, password, cid) {
  try {
    const authorizer = new authorizerFB(cid);
    const authorized = await authorizer.authorize(email, password);
    if (!authorized) {
      return dispatch(appErrorAction("メールアドレスまたはパスワードが異なっています"));
    }
    const user = await authorizer.getUser(authorized);
    if(!user){
      return dispatch(appErrorAction("メールアドレスまたはパスワードが異なっています"));
    }
    if(user.role !== "tool-manager" && user.role !== "tool-user"){
      await authorizer.unauthorize(cid, user.uid);
      dispatch(appErrorAction(["権限が不足しています。", "承認リクエストを発行されている場合は、", "監視ツール管理者へご連絡ください。"]));
      if(user.role){
        //権限のないログイン
        addLoginEventLog(dispatch, user, cid, "loginFailed");
      }
      return;
    }
    sessionStorage.setItem("user"+cid, JSON.stringify(user));
    dispatch(updateUser(user));
    // ログイン成功
    addLoginEventLog(dispatch, user, cid, "loginSuccess");
  } catch (e) {
    dispatch(appErrorAction(e));
  }
}

export function updateUser(user) {
  return {
    type: 'AUTHORIZED',
    payload: {
      user: user
    }
  }
}

export async function logout(dispatch, user) {
  const cid = user.TenantId;
  await signOut(dispatch, unauthorize(), cid, user.uid);
}

export async function redirectDeletedUser(dispatch, cid) {
  await signOut(dispatch, accountDeleted(), cid);
}

export async function redirectUnauthorizeUser(dispatch, cid) {
  await signOut(dispatch, unauthorizeUser(), cid);
}

async function signOut(dispatch, action, cid, revokeTokenUid = null) {
  // ログアウトする前にリスナーを停止
  unsubAllMainListeners(dispatch);
  unsubAccountListener(dispatch);

  if (revokeTokenUid) {
    try {
      await (functions().httpsCallable('signOutRevokeRefreshTokenForTenant'))({ cid: cid, uid: revokeTokenUid });
    } catch (e) {
      if (isTokenRevokedError(e)) {
        // 既にトークン無効化済み
      } else {
        console.warn('トークンの無効化に失敗した可能性があります');
      }
    }
  }

  sessionStorage.removeItem("user" + cid);
  const authorizer = new authorizerFB(cid);
  dispatch(action);

  // サインアウト(dispatch(action)のあとでないとメッセージが正しく出ない)
  await authorizer.unauthorize();

  // リスナー削除(dispatch(action)のあとでないとリスナーが再取得されてしまう)
  removeAllMainListeners(dispatch);
  removeAccountListener(dispatch);

  dispatch(clearStateWithLogoutAction());
}

export async function sessionLogin(dispatch, user){
  const cid = user.TenantId;
  const authorizer = new authorizerFB(cid);
  const isAuthorized = await authorizer.isAuthorized();
  if (!isAuthorized) {
    redirectUnauthorizeUser(dispatch, cid);
    console.log("no permission");
    return;
  }
  user.isManager = function(){
    if(user.role === "tool-manager"){
      return true;
    }
    return false;
  }
  dispatch(updateUser(user));
}

function addLoginEventLog(dispatch, user, cid, type) {
  const companyDoc = db().collection("companies").doc(cid);
  const toolRef = user.isManager() ? companyDoc.collection("tool-managers").doc(user.uid) : companyDoc.collection("tool-users").doc(user.uid);
  companyDoc.collection("labels").get().then(labels => {
    labels.docs.forEach(label => {
      label.ref.collection("event-logs").doc().set({
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        executorRole: user.role ? user.role : null,
        executorUserRef: toolRef,
        executorUserDisplayName: user.displayName,
        executorProcess: "monitoringTool",
        level: "DEBUG",
        primaryType: type,
        secondaryType: type === "loginSuccess" ? null : "dontHavePermission",
        title: type === "loginSuccess" ? "ログイン成功" : "ログイン失敗 - 権限がない",
        state: "done",
        motionMap: null,
        phraseMap: null,
        pictureMap: null,
        keywords: null,
        keywordFull: null,
        note: null,
      }).catch(e => {
        if (e && e.name === "FirebaseError" && e.code === "permission-denied") {
          redirectUnauthorizeUser(dispatch, cid);
          return;
        }
        dispatch(appErrorAction(e));
      });
    });
  }).catch(e => {
    if (e && e.name === "FirebaseError" && e.code === "permission-denied") {
      redirectUnauthorizeUser(dispatch, cid);
      return;
    }
    dispatch(appErrorAction(e));
  });
}

function unauthorize() {
  return {
    type: 'UNAUTHORIZED'
  }
}

function accountDeleted() {
  return {
    type: 'ACCOUNT_DELETED',
  }
}

function unauthorizeUser() {
  return {
    type: 'UNAUTHORIZED_USER',
  }
}
