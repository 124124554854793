import crypto from 'crypto';
import _ from 'lodash';

export const formatDate = (timestamp) => {
  try{
    let date;
    try{
      date = timestamp.toDate();
    } catch(e){
      date = timestamp;
    }
    return [date.getFullYear(), zeroPadding(date.getMonth() + 1, 2), zeroPadding(date.getDate(), 2)].join('/')
    + ' ' + [zeroPadding(date.getHours(), 2), zeroPadding(date.getMinutes(), 2), zeroPadding(date.getSeconds(), 2)].join(':');
  } catch(e){
    return "";
  }
}

export const formatDateShort = (timestamp) => {
  try{
    let date;
    try{
      date = timestamp.toDate();
    } catch(e){
      date = timestamp;
    }
    return [zeroPadding(date.getMonth() + 1, 2), zeroPadding(date.getDate(), 2)].join('/')
    + ' ' + [zeroPadding(date.getHours(), 2), zeroPadding(date.getMinutes(), 2), zeroPadding(date.getSeconds(), 2)].join(':');
  } catch(e){
    return "";
  }
}

export function getMD5(file){
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      var buf = new Buffer(reader.result)
      var hash = crypto.createHash("md5")
      hash.update(buf)
      resolve(hash.digest("hex"));
    }
    reader.onerror = (err) => {
      reject(err);
    }
    reader.readAsArrayBuffer(file);
  });
}

export function zeroPadding(num, length){
  return ('0000000000000000' + num).slice(-length);
}

export function checkModel(list, model){
  list.forEach(item => {
    item.isPhraseModelDiff = false;
    if(item.phraseRef && item.phraseRef.modelRef.model !== model){
      item.isPhraseModelDiff = true;
    }
    item.isMotionModelDiff = false;
    if(item.motionRef && item.motionRef.modelRef.model !== model){
      item.isMotionModelDiff = true;
    }
  });
  return list;
}

export function insertThousandSeparator(number) {
  return String(number).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
}

export function isEqualExceptFalsy(a, b) {
  return _.isEqual(_.pickBy(a, _.identity), _.pickBy(b, _.identity));
}

export function formatTag(tagString) {
  if (typeof tagString !== "string") {
    console.warn("Unexpected type: tagString is not string");
    return "";
  }
  if (tagString === "") return "";

  const slicedTagArray = tagString.trim().replace(/　/g, " ").split(/ +/);
  const tagArray = slicedTagArray.filter(x => x !== "#");
  tagArray.forEach((tag, i) => {
    if (tag.indexOf("#") !== 0) tagArray[i] = "#" + tag;
  });
  tagArray.sort();
  return tagArray.join(" ");
}

export function searchByTag(originalList, titleField, searchText) {
  if (!Array.isArray(originalList)) {
    console.warn("Unexpected type: originalList is not Array");
     return originalList;
  }
  if (typeof titleField !== "string") {
    console.warn("Unexpected type: titleField is not string");
    return originalList;
  }
  if (typeof searchText !== "string") {
    console.warn("Unexpected type: searchText is not string");
    return originalList;
  }

  let list = [ ...originalList ];
  const searchArray = searchText.split(/ +/);
  searchArray.forEach((x, i) => {
    searchArray[i] = x.toLowerCase();
  });
  searchArray.forEach(word => {
    if (word.indexOf('#') === 0) {
      // タグのみで検索
      list = list.filter(x => x.tag && x.tag.toLowerCase().indexOf(word) >= 0);
    } else {
      // タグとタイトルで検索
      list = list.filter(x => ((x.tag && x.tag.toLowerCase().indexOf(word) >= 0) || (x[titleField] && x[titleField].toLowerCase().indexOf(word) >= 0)));
    }
  });
  return list
}
