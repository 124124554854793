import db, { getDocsData, getModelsData, getCharactersData, deleteCollection, getMotionsDataExcludeNod, getDigitalInputExternalCommandsData, getManualCommandGroupsData } from "../utilities/db";
import firebase from 'firebase/app';
import { appErrorAction, dbSetDataFailedErrorAction } from './appAction';
import { zeroPadding } from "../utilities/utils";

export const DIGITAL_IN_NUM = 10;
const CHARACTER_CHILD_COLLECTIONS = ["nearZoneGreetings", "midZoneGreetings", "manualCommands", "externalCommands", "contents", "paths", "sttPathFilters", "cameraPathFilters", "callPathFilters", "circuitBreakerPathFilters"];

export async function listCharacters(dispatch, cid, label) {
  try {
    const companyDoc = db().collection("companies").doc(cid);
    let par = [
      getCharactersData(companyDoc.collection("labels").doc(label)),
      getModelsData(companyDoc),
      getMotionsDataExcludeNod(companyDoc),
      getManualCommandGroupsData(companyDoc),
    ];
    const [characters, models, motions, manualCommandGroups] = await Promise.all(par);
    motions.forEach(item => {
      if(item.modelRef) item.modelRef = models.find(x => x.model === item.modelRef.id);
    });
    const charactersExternalCommands = {};
    await Promise.all(characters.map(async item => {
      if(item.modelRef) item.modelRef = models.find(x => x.model === item.modelRef.id);
      if(item.idlingMotionRef) item.idlingMotionRef = motions.find(x => x.motion === item.idlingMotionRef.id);
      // 外部コマンド情報の取得
      const externalCommands = await getDigitalInputExternalCommandsData(companyDoc.collection("labels").doc(label).collection("characters").doc(item.character));
      charactersExternalCommands[item.character] = externalCommands;
      new Array(DIGITAL_IN_NUM).fill(null).forEach((_, i) => {
        const columnName = "digitalIn" + zeroPadding(i, 2);
        if(item.digitalInSettings[columnName] && item.digitalInSettings[columnName].externalCommandRef){
          item.digitalInSettings[columnName].externalCommandRef = externalCommands.find(x => x.externalCommand === item.digitalInSettings[columnName].externalCommandRef.id);
          // console.log(item.digitalInSettings[columnName].externalCommandRef);
        }
      });
    }));
    dispatch(listCharactersAction(characters, models, motions, charactersExternalCommands, manualCommandGroups));
  } catch (e) {
    dispatch(appErrorAction(e));
  }
}

export function beginEditCharacter(dispatch, character){
  dispatch(beginEditCharacterAction(character));
}

export function changeEditingCharacter(dispatch, editingCharacter, target){
  dispatch(changeEditingCharacterAction(editingCharacter, target));
}

export function manualCommandGroupOrderChange(dispatch, orderManualCommandGroups){
  dispatch({
    type: "CHANGE_MANUAL_COMMAND_GROUP_ORDER_CHARACTER",
    orderManualCommandGroups: orderManualCommandGroups,
  });
}

export async function deleteCharacterExec(dispatch, cid, label, character){
  try{
    // vguardに設定されているかチェック
    const vguards = await getDocsData(db().collection("companies").doc(cid).collection("labels").doc(label).collection("vguards"), "vguard");
    for(let vguard of vguards){
      if(vguard.settings.characterRef && vguard.settings.characterRef.id === character){
        dispatch(appErrorAction("バーチャル警備員端末に設定されているため削除できません"));
        return;
      }
    }
    const characterDoc = db().collection("companies").doc(cid).collection("labels").doc(label).collection("characters").doc(character);
    const exists = await characterExists(dispatch, cid, label, character);
    if (!exists) return;
    const characters = await db().collection("companies").doc(cid).collection("labels").doc(label).collection("characters").get();
    if (characters.size <= 1) {
      dispatch(appErrorAction("キャラクターが1つしかないため削除できません"));
      listCharacters(dispatch, cid, label);
      return;
    }
    await deleteCharacter(characterDoc);
    dispatch(deletedCharacterAction(character));
  }catch(e){
    dispatch(appErrorAction(e));
  }
}

export async function createCharacterExec(dispatch, cid, label, editingCharacter, characters, charactersExternalCommands, models, manualCommandGroups){
  try{
    const copyCharacter = characters.find(x => x.character === editingCharacter.copyCharacter);
    const companyDoc = db().collection("companies").doc(cid);
    const labelDoc = companyDoc.collection("labels").doc(label);

    // 新規
    const characterDoc = labelDoc.collection("characters").doc();
    if(copyCharacter){
      // コピー元があった場合
      editingCharacter.modelRef = copyCharacter.modelRef && companyDoc.collection("models").doc(copyCharacter.modelRef.model);
      editingCharacter.idlingMotionRef = copyCharacter.idlingMotionRef && companyDoc.collection("motions").doc(copyCharacter.idlingMotionRef.motion);
      editingCharacter.manualCommandGroupsOrder = copyCharacter.manualCommandGroupsOrder || [];
      editingCharacter.stanbyContentRefs = copyCharacter.stanbyContentRefs.map(x => {
        return characterDoc.collection("contents").doc(x.id);
      });
      if(copyCharacter.digitalInSettings){
        editingCharacter.digitalInSettings = {};
        new Array(DIGITAL_IN_NUM).fill(null).forEach((_, i) => {
          const columnName = "digitalIn" + zeroPadding(i, 2);
          // console.log(copyCharacter.digitalInSettings[columnName].externalCommandRef);
          editingCharacter.digitalInSettings[columnName] = {...copyCharacter.digitalInSettings[columnName]};
          editingCharacter.digitalInSettings[columnName] = {
            title: copyCharacter.digitalInSettings[columnName].title ? copyCharacter.digitalInSettings[columnName].title : null,
            condition: copyCharacter.digitalInSettings[columnName].condition ? copyCharacter.digitalInSettings[columnName].condition : null,
            externalCommandRef: copyCharacter.digitalInSettings[columnName].externalCommandRef ?
                                  characterDoc.collection("externalCommands").doc(copyCharacter.digitalInSettings[columnName].externalCommandRef.id) : null
          }
        });
      } else {
        editingCharacter.digitalInSettings = {};
        new Array(DIGITAL_IN_NUM).fill(null).forEach((_, i) => {
          const columnName = "digitalIn" + zeroPadding(i, 2);
          editingCharacter.digitalInSettings[columnName] = { title: null, condition: null, externalCommandRef: null };
        });
      }
    } else {
      // デフォルトでモデルを入れておく
      editingCharacter.modelRef = models.length && companyDoc.collection("models").doc(models[0].model);
      // TODO:デフォルトで101(立哨)を入れておく
      editingCharacter.idlingMotionRef = companyDoc.collection("motions").doc(editingCharacter.modelRef.id + "_101");
      editingCharacter.manualCommandGroupsOrder = manualCommandGroups.map(x => companyDoc.collection("manualCommandsGroups").doc(x.id));
    }
    await characterDoc.set({
      name: editingCharacter.name,
      modelRef: editingCharacter.modelRef,
      idlingMotionRef: editingCharacter.idlingMotionRef,
      stanbyContentRefs: editingCharacter.stanbyContentRefs,
      manualCommandGroupsOrder: editingCharacter.manualCommandGroupsOrder,
      digitalInSettings: editingCharacter.digitalInSettings,
    });
    if(copyCharacter){
      // modelやidlingMotionの値をrefからdataのIDに変えておく
      editingCharacter.modelRef = copyCharacter.modelRef && copyCharacter.modelRef.model;
      editingCharacter.idlingMotionRef = copyCharacter.idlingMotionRef && copyCharacter.idlingMotionRef.motion;
      new Array(DIGITAL_IN_NUM).fill(null).forEach((_, i) => {
        const columnName = "digitalIn" + zeroPadding(i, 2);
        editingCharacter.digitalInSettings[columnName].externalCommandRef = copyCharacter.digitalInSettings[columnName].externalCommandRef &&
                                                                            copyCharacter.digitalInSettings[columnName].externalCommandRef.id;
      });
    }
    if(copyCharacter){
      try{
        const batch = db().batch();
        const orgCharacterDoc = labelDoc.collection("characters").doc(copyCharacter.character);
        // 対話シナリオ関係のコレクションのコピー
        await Promise.all(["sttPathFilters", "cameraPathFilters", "callPathFilters", "circuitBreakerPathFilters", "paths", "contents"].map(async collectionName => {
          (await orgCharacterDoc.collection(collectionName).get()).docs.forEach(doc => {
            const d = {
              ...doc.data()
            }
            switch(collectionName){
              case "sttPathFilters":
              case "cameraPathFilters":
              case "callPathFilters":
              case "circuitBreakerPathFilters":
                d.contents = doc.data().contents.map(x => {
                  return characterDoc.collection("contents").doc(x.id);
                });
                break;
              case "paths":
                d.currentContent = characterDoc.collection("contents").doc(doc.data().currentContent.id);
                d.nextContents = doc.data().nextContents.map(x => {
                  return characterDoc.collection("contents").doc(x.id);
                });
                break;
              case "contents":
                // タグがなければ空文字で作成
                d.tag = d.tag || "";
                break;
              default:
                break;
            }
            d.createdAt = firebase.firestore.FieldValue.serverTimestamp();
            d.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
            // batch.set(characterDoc.collection(collectionName).doc(doc.id), doc.data());
            characterDoc.collection(collectionName).doc(doc.id).set(d);
          });
        }));
        // 挨拶・手動操作のコピー
        await Promise.all(["nearZoneGreetings", "midZoneGreetings", "manualCommands", "externalCommands"].map(async collectionName => {
          // コピー元からcollectionを持ってきて新しいcharacterの下に保存
          (await orgCharacterDoc.collection(collectionName).get()).docs.forEach(doc => {
            const d = { ...doc.data() };
            if (collectionName === "externalCommands") {
              // タグがなければ空文字で作成
              d.tag = d.tag || "";
            }
            d.createdAt = firebase.firestore.FieldValue.serverTimestamp();
            d.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
            batch.set(characterDoc.collection(collectionName).doc(doc.id), d);
          });
        }));
        await batch.commit();
        // 新しく作られた手動操作コマンドを取得
        const externalCommands = await getDocsData(characterDoc.collection("externalCommands"), "externalCommand");
        charactersExternalCommands[characterDoc.id] = externalCommands;
      } catch(e){
        try{
          await deleteCharacter(characterDoc);
        } catch(e2){
          dispatch(appErrorAction(e));
          return;
        }
        dispatch(appErrorAction(e));
      }
    }
    editingCharacter.character = characterDoc.id;
    dispatch(createdCharacterAction(editingCharacter, charactersExternalCommands));
  } catch(e){
    dispatch(appErrorAction(e));
  }
}

export async function updateCharacterExec(dispatch, cid, label, editingCharacter, characters, charactersExternalCommands, orderManualCommandGroups, notFoundCallback){
  try{
    const companyDoc = db().collection("companies").doc(cid);
    const labelDoc = companyDoc.collection("labels").doc(label);
    // 更新
    const characterDoc = labelDoc.collection("characters").doc(editingCharacter.character);
    const digitalInSettings = {};
    new Array(DIGITAL_IN_NUM).fill(null).forEach((_, i) => {
      const columnName = "digitalIn" + zeroPadding(i, 2);
      digitalInSettings[columnName] = {
        title: editingCharacter.digitalInSettings[columnName].title ? editingCharacter.digitalInSettings[columnName].title : null,
        condition: editingCharacter.digitalInSettings[columnName].condition ? editingCharacter.digitalInSettings[columnName].condition : null,
        externalCommandRef: null,
      }
      // console.log(editingCharacter.digitalInSettings[columnName].externalCommandRef);
      if (editingCharacter.digitalInSettings[columnName].externalCommandRef === "") {
        digitalInSettings[columnName].externalCommandRef = null;
      }
      if(editingCharacter.digitalInSettings[columnName].externalCommandRef){
        digitalInSettings[columnName].externalCommandRef = characterDoc.collection("externalCommands").doc(editingCharacter.digitalInSettings[columnName].externalCommandRef);
      }
    });
    const data = {
      name: editingCharacter.name,
      modelRef: companyDoc.collection("models").doc(editingCharacter.modelRef),
      idlingMotionRef: editingCharacter.idlingMotionRef  ? companyDoc.collection("motions").doc(editingCharacter.idlingMotionRef) : null,
      digitalInSettings: digitalInSettings,
      manualCommandGroupsOrder: orderManualCommandGroups.map(x => companyDoc.collection("manualCommandsGroups").doc(x.id)),
    }
    await characterDoc.update(data);
    // console.log(editingCharacter);
    dispatch(updatedCharacterAction(editingCharacter, charactersExternalCommands));
  } catch(e) {
    // セキュリティールールありだとpermission-denied、なしだとnot-foundエラーが返る
    if (e && e.name === 'FirebaseError' && (e.code === 'permission-denied' || e.code === 'not-found')) {
      const exists = await characterExists(dispatch, cid, label, editingCharacter.character);
      if (!exists) {
        notFoundCallback();
        return;
      }
      dispatch(dbSetDataFailedErrorAction());
      return false;
    }
    dispatch(appErrorAction(e));
  }
}

export async function closeEditCharacter(dispatch){
  dispatch({ type: "CHARACTER_CLOSE_EDIT" });
}

async function deleteCharacter(characterDoc){
  await Promise.all(CHARACTER_CHILD_COLLECTIONS.map(async collection => {
    await deleteCollection(db(), characterDoc.collection(collection));
  }));
  await characterDoc.delete();
}

export async function characterExists(dispatch, cid, label, characterId) {
  const character = await db().collection("companies").doc(cid).collection("labels").doc(label).collection("characters").doc(characterId).get();
  if (!character.exists) {
    dispatch(characterNotFoundErrorAction());
    listCharacters(dispatch, cid, label);
    return false;
  }
  return true;
}

export async function updateCharactersExternalCommands(dispatch, cid, label, character) {
  try {
    const companyDoc = db().collection("companies").doc(cid);
    const externalCommands = await getDigitalInputExternalCommandsData(companyDoc.collection("labels").doc(label).collection("characters").doc(character));
    dispatch({
      type: "UPDATE_CHARACTERS_EXTERNAL_COMMANDS",
      payload: {
        character: character,
        externalCommands: externalCommands,
      }
    });
  } catch (e) {
    dispatch(appErrorAction(e));
  }
}

function listCharactersAction(characters, models, motions, charactersExternalCommands, manualCommandGroups){
  return {
    type: "LIST_CHARACTERS",
    payload: {
      characters: characters,
      models: models,
      motions: motions,
      charactersExternalCommands: charactersExternalCommands,
      manualCommandGroups: manualCommandGroups,
    }
  }
}

function beginEditCharacterAction(character){
  return {
    type: "BEGIN_EDIT_CHARACTERS",
    payload: {
      character: character,
    }
  }
}

function changeEditingCharacterAction(editingCharacter, target){
  return {
    type: "CHANGE_EDITING_CHARACTER",
    payload: {
      editingCharacter: editingCharacter,
      target: target,
    }
  }
}

function createdCharacterAction(editingCharacter, charactersExternalCommands){
  return {
    type: "CREATED_CHARACER",
    payload: {
      editingCharacter: editingCharacter,
      charactersExternalCommands: charactersExternalCommands
    }
  }
}

function updatedCharacterAction(editingCharacter, charactersExternalCommands){
  return {
    type: "UPDATED_CHARACTER",
    payload: {
      editingCharacter: editingCharacter,
      charactersExternalCommands: charactersExternalCommands
    }
  }
}

function deletedCharacterAction(character){
  return {
    type: "DELETED_CHARACTER",
    payload: {
      character: character,
    }
  }
}

export function characterNotFoundErrorAction() {
  return appErrorAction(["指定されたキャラクターは存在しません。","他のアカウントにより削除された可能性があります。"]);
}
