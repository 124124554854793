import firebase from 'firebase/app';
import 'firebase/storage';

let _storage;

export default function storage(){
  if(!_storage){
    _storage = firebase.storage();
  }
  return _storage;
}