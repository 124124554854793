import { callItems, EXTERNAL_COMMAND_MODE_FACE } from "../externalCommands";
import { insertThousandSeparator, isEqualExceptFalsy, searchByTag } from "../utilities/utils";

const defaultState = {
  list: [],
  searchedExternalCommand: [],
  search: "",
  searchMode: EXTERNAL_COMMAND_MODE_FACE.key,
  original: null,
  editing: null,
  isRefresh: false,
  timeoutTypeItems: ["Instantly", "EnableInterrupt", "DisableInterrupt"],
  digitalOutItems: [0,1,2,3,4,5,6,7,8,9],
};

function defaultItem(){
  return {
    externalCommand: null,
    title: "",
    mode: null,
    faceDetectionType: null,
    faceDetectionPhraseMaps: [{ phraseType: '', phraseRef: null }],
    digitalInputInfoMap: null,
    motionRef: null,
    phraseRef: null,
    pictureRef: null,
    digitalOut: null,
    digitalOutOneShotTime: "0",
    timeoutType: "Instantly",
    timeoutSec: "0",
    playType: "SyncSoundAndMotion",
    callFlag: false,
    emergencyFlag: false,
    callSuccess: defaultCallItem(),
    callFail: defaultCallItem(),
    callReject: defaultCallItem(),
    isMotionModelDiff: false,
    isPhraseModelDiff: false,
  }
}

function defaultCallItem(){
  return {
    motionRef: null,
    phraseRef: null,
    pictureRef: null,
    digitalOut: null,
    digitalOutOneShotTime: "0",
    timeoutType: "Instantly",
    timeoutSec: "0",
    playType: "SyncSoundAndMotion",
    isMotionModelDiff: false,
    isPhraseModelDiff: false,
  }
}

function setBeginEditingData(editing, motions, phrases, characterData){
  if(editing.isPhraseModelDiff) {
    // phraseMenuItems.push({ ...editing.phraseRef, disabled: true })
    editing.phraseData = editing.phraseRef;
  }
  if(editing.isMotionModelDiff) {
    // motionMenuItems.push({ ...editing.motionRef, disabled: true })
    editing.motionData = editing.motionRef;
  }
  editing.motionRef = editing.motionRef && editing.motionRef.motion;
  const phraseMaps = [];
  if (editing.faceDetectionPhraseMaps) {
    if (editing.faceDetectionPhraseMaps.length) {
      editing.faceDetectionPhraseMaps.forEach((x, phraseMapIndex) => {
        const d = { phraseType: x.phraseType, phraseRef: null };
        editing["phraseType" + phraseMapIndex] = x.phraseType;
        editing["isPhraseModelDiff" + phraseMapIndex] = x.isPhraseModelDiff;
        if(x.phraseType === "phrase" && x.phraseRef){
          editing["phraseRef" + phraseMapIndex] = x.phraseRef.phrase;
          d.phraseRef = x.phraseRef.phrase;
        }
        if(x.isPhraseModelDiff){
          editing["phraseData" + phraseMapIndex] = x.phraseRef;
        }
        phraseMaps.push(d);
      });
    } else {
      phraseMaps.push({ phraseType: '', phraseRef: null });
    }
  }
  editing.faceDetectionPhraseMaps = phraseMaps;
  editing.phraseRef = editing.phraseRef && editing.phraseRef.phrase;
  editing.pictureRef = editing.pictureRef && editing.pictureRef.picture;

  if (typeof editing.digitalOutOneShotTime === 'number') {
    editing.digitalOutOneShotTime = insertThousandSeparator(editing.digitalOutOneShotTime);
  }
  if (typeof editing.timeoutSec === 'number') {
    editing.timeoutSec = insertThousandSeparator(editing.timeoutSec);
  }

  if(editing.callFlag){
    callItems.forEach(c => {
      const callData = {...editing[c.id]};
      callData.motionRef = editing[c.id].motionRef ? editing[c.id].motionRef.id : null;
      callData.phraseRef = editing[c.id].phraseRef ? editing[c.id].phraseRef.id : null;
      callData.pictureRef = editing[c.id].pictureRef ? editing[c.id].pictureRef.id : null;
      //action.characterData.modelRef
      if(callData.motionRef){
        const motionData = motions.find(x => x.motion === callData.motionRef);
        callData.isMotionModelDiff = motionData.modelRef.id !== characterData.modelRef.id;
        if(callData.isMotionModelDiff) callData.motionData = motionData;
      }
      if(callData.phraseRef){
        const phraseData = phrases.find(x => x.phrase === callData.phraseRef);
        callData.isPhraseModelDiff = phraseData.modelRef.id !== characterData.modelRef.id;
        if(callData.isPhraseModelDiff) callData.phraseData = phraseData;
      }
      if (typeof callData.digitalOutOneShotTime === 'number') {
        callData.digitalOutOneShotTime = insertThousandSeparator(callData.digitalOutOneShotTime);
      }
      if (typeof callData.timeoutSec === 'number') {
        callData.timeoutSec = insertThousandSeparator(callData.timeoutSec);
      }
      editing[c.id] = callData;
    });
  }
  if(!editing.callSuccess) editing.callSuccess = defaultCallItem();
  if(!editing.callFail) editing.callFail = defaultCallItem();
  if(!editing.callReject) editing.callReject = defaultCallItem();
  return editing;
}

function searchList(list, search, searchMode) {
  let searchedList = [ ...list ];
  searchedList = searchedList.filter(x => x.mode === searchMode);
  if (search) {
    if(searchMode === EXTERNAL_COMMAND_MODE_FACE.key){
      searchedList = searchByTag(searchedList, 'faceDetectionType', search);
    } else {
      searchedList = searchByTag(searchedList, 'title', search);
    }
  }
  return searchedList;
}

const externalCommands = (state = defaultState, action) => {
  let editing;
  switch (action.type) {
    case 'EXTERNAL_COMMAND_LIST':
      let obj = {
        ...state,
        list: action.list,
        searchedExternalCommand: searchList(action.list, state.search, state.searchMode),
        models: action.models,
        phrases: action.phrases,
        motions: action.motions,
        isRefresh: false,
      }
      if(action.isInit){
        obj.editing = null;
      }
      return obj;
    case "SEARCH_EXTERNAL_COMMAND":
      return {
        ...state,
        searchedExternalCommand: searchList(state.list, action.payload.search, action.payload.searchMode),
        search: action.payload.search,
        searchMode: action.payload.searchMode,
      }
    case "EXTERNAL_COMMAND_BEGIN_EDIT":
      editing = state.list.find(item => {
        return item.externalCommand === action.externalCommand;
      });
      editing = {...editing};
      const phraseMenuItems = (action.characterData.modelRef && action.phrases.filter(x => x.modelRef.id === action.characterData.modelRef.id)) || [];
      const motionMenuItems = (action.characterData.modelRef && action.motions.filter(x => x.modelRef.id === action.characterData.modelRef.id)) || [];
      if(!editing.externalCommand){
        // 新規
        editing = defaultItem();
        editing.mode = action.mode;
      } else {
        // 更新
        setBeginEditingData(editing, action.motions, action.phrases, action.characterData);
      }
      editing.isEditingUpdate = false;
      return {
        ...state,
        original: editing,
        editing: editing,
        phraseMenuItems: phraseMenuItems,
        motionMenuItems: motionMenuItems,
        pictures: action.pictures,
        characterData: action.characterData,
      }
    case "EXTERNAL_COMMAND_EDITING_CHANGE":
      editing = { ...action.editing };

      let originalForComapare = { ...state.original };
      let editingForCompare = { ...editing };
      editingForCompare.isEditingUpdate = false;

      const callItemsForCompare = { original: {}, edit: {} };
      let isEqual = true;

      // コールアイテムのチェック
      callItems.forEach(c => {
        callItemsForCompare["original"][c.id] = { ...originalForComapare[c.id] };
        callItemsForCompare["edit"][c.id] = { ...editingForCompare[c.id] };
        originalForComapare[c.id] = null;
        editingForCompare[c.id] = null;
      });
      callItems.forEach(c => {
        isEqual = isEqual && isEqualExceptFalsy(callItemsForCompare.original[c.id], callItemsForCompare.edit[c.id]);
      });

      // 顔認証音声フレーズのチェック
      const originalFaceDetectionPhraseMaps = [ ...originalForComapare.faceDetectionPhraseMaps ];
      const editingFaceDetectionPhraseMaps = [ ...editingForCompare.faceDetectionPhraseMaps ];
      originalForComapare.faceDetectionPhraseMaps = null;
      editingForCompare.faceDetectionPhraseMaps = null;
      if (originalFaceDetectionPhraseMaps.length === editingFaceDetectionPhraseMaps.length) {
        originalFaceDetectionPhraseMaps.forEach((originalFaceDetectionPhrase, i) => {
          isEqual = isEqual && isEqualExceptFalsy(originalFaceDetectionPhrase, editingFaceDetectionPhraseMaps[i]);
        });
      } else {
        isEqual = false;
      }

      isEqual = isEqual && isEqualExceptFalsy(originalForComapare, editingForCompare);
      editing.isEditingUpdate = isEqual ? false : true;
      return {
        ...state,
        editing: editing,
      }
    case "EXTERNAL_COMMAND_CLOSE":
      return {
        ...state,
        editing: null,
      }
    case "EXTERNAL_COMMAND_CANCEL":
      let beforeEditing = state.list.find(item => {
        return item.externalCommand === state.editing.externalCommand;
      });
      beforeEditing = {...beforeEditing};
      if(!beforeEditing.externalCommand) {
        beforeEditing = defaultItem();
        beforeEditing.mode = state.editing.mode;
      } else {
        setBeginEditingData(beforeEditing, state.motions, state.phrases, state.characterData);
      }
      // console.log(beforeEditing);
      beforeEditing.test = new Date().getTime();
      beforeEditing.isEditingUpdate = false;
      return {
        ...state,
        original: beforeEditing,
        editing: beforeEditing,
      }
    case "EXTERNAL_COMMAND_UPDATED":
      editing = { ...action.editing };
      editing.isEditingUpdate = false;
      return {
        ...state,
        original: editing,
        editing: editing,
        isRefresh: true
      }
    case "EXTERNAL_COMMAND_DELETED":
      return {
        ...state,
        editing: null,
        isRefresh: true
      }
    case "EXTERNAL_COMMAND_REFRESH":
      return {
        ...state,
        editing: null,
        isRefresh: true
      }
    default:
      return state;
  }
}

export default externalCommands;
