import React, { Component } from "react";
import { connect } from 'react-redux';
import Auth from "./auth";
import { withRouter } from "react-router-dom";
import TopBar from "./TopBar";
import CharacterTopBar from "./CharacterTopBar";
import { beginEditCharacter, changeEditingCharacter, updateCharacterExec, characterExists, DIGITAL_IN_NUM, manualCommandGroupOrderChange } from "./actions/characters";
import { withStyles, TableContainer, MenuItem } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { onLoading, offLoading } from "./actions/loading";
import { renderSimpleTextField, renderSimpleSelectField } from "./formRenderer";
import { CustomButton } from "./AppParts";
import { required } from "./validator";
import ErrorMessage from './errorMessage';
import UpdateMessage from './updateMessage';
import { appError, appRemoveError, updateMessage } from './actions/appAction';
import { zeroPadding } from "./utilities/utils";
import muiStyles from "./MuiStyles";

// import { login } from "./actions/login";
// import { mainCompany, mainLablesListener } from "./actions/main";

export class CharacterForm extends Component {

  async componentDidMount() {
    // TODO: デバッグ用
    // console.log("componentDidMount", this.props);
    // if(!this.props.user){
    //   const cid = this.props.match.params && this.props.match.params.cid;
    //   login(this.props.dispatch, "test@test.com", "sskkotdotd", cid);
    // }
    // デバッグ用

    if(this.props.user && this.props.company){
      const character = this.props.match.params && this.props.match.params.character;
      onLoading(this.props.dispatch);
      const exists = await characterExists(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, character);
      if (!exists) {
        this.backToCharacterPage();
        offLoading(this.props.dispatch);
        return;
      }
      beginEditCharacter(this.props.dispatch, character);
      offLoading(this.props.dispatch);
    }
  }

  async componentDidUpdate() {
    // TODO: デバッグ用
    // console.log("componentDidUpdate", this.props);
    // if(!this.props.company && this.props.user){
    //   await mainCompany(this.props.dispatch, this.props.user.TenantId);
    // }
    // if(this.props.company && !this.props.selectedLabel.label){
    //   await mainLablesListener(this.props.dispatch, this.props.user.TenantId, this.props.listeners);
    // }
    // if(this.props.company && this.props.selectedLabel.label && this.props.characters.length === 0){
    //   await listCharacters(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label);
    // }
    // if(this.props.characters.length > 0 && !this.props.editingCharacter){
    //   beginEditCharacter(this.props.dispatch, this.props.characters[0].character);
    // }

    // デバッグ用
  }

  onEditingChange = (e) =>{
    e.preventDefault();
    appRemoveError(this.props.dispatch);
    const editingCharanter = { ...this.props.formValues };
    editingCharanter[e.target.name] = e.target.value;
    changeEditingCharacter(this.props.dispatch, editingCharanter, e.target.name);
  }

  onUpdateExec = async (values) => {
    const editingCharacter = {...values};
    const exists = await characterExists(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, editingCharacter.character);
    if (!exists) {
      this.backToCharacterPage();
      return;
    }
    // console.log(editingCharacter);
    if(this.props.characters.filter(item => item.character !== editingCharacter.character).find(x => x.name === editingCharacter.name)){
      appError(this.props.dispatch, "同じキャラクター名があります");
      return;
    }
    appRemoveError(this.props.dispatch);
    onLoading(this.props.dispatch);
    await updateCharacterExec(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, editingCharacter, this.props.characters, this.props.charactersExternalCommands, this.props.orderManualCommandGroups, this.backToCharacterPage);
    offLoading(this.props.dispatch);
    updateMessage(this.props.dispatch, true, "update");
  }

  onManualCommandGroupOrderChange = async (type, index) => {
    const a = [...this.props.orderManualCommandGroups]
    if(type === "up"){
      a.splice(index - 1, 2, a[index], a[index - 1]);
    } else {
      a.splice(index, 2, a[index+1], a[index]);
    }
    manualCommandGroupOrderChange(this.props.dispatch, a);
  }

  backToCharacterPage = () => {
    this.props.history.push("/" + this.props.user.TenantId + "/characters");
  }

  render(){
    const { classes, handleSubmit, submitting } = this.props;
    const disabled = this.props.user && !this.props.user.isManager();
    const colorDisabled = { color: disabled && "#444444" };
    return (
      <React.Fragment>
        <Auth />
        <React.Fragment>
          <TopBar isCharacterEdit={true} />
          { this.props.editingCharacter &&
          <div className={classes.content}>
            <div className={classes.characterMain}>
              <CharacterTopBar />
              <ErrorMessage />
              <UpdateMessage />
              <div className={classes.characerForm}>
                <form onSubmit={handleSubmit(this.onUpdateExec)}>
                  <div className="verticalCenter" style={{ justifyContent: "space-between" }}>
                    <div className="verticalCenter" style={{ marginLeft: 178 }}>
                      <span className="f12 mr10">キャラクター名</span>
                      <Field name="name" type="text" component={renderSimpleTextField} width={125} validate={[required]} inputProps={{ style: { textAlign: "center", ...colorDisabled }}} disabled={disabled} />
                    </div>
                    <div className="verticalCenter">
                      <span className="f12 mr10">モデル</span>
                      <Field name="modelRef" component={renderSimpleSelectField} width={174} height={32} onChange={this.onEditingChange} style={{ textAlign: "center", ...colorDisabled }} disabled={disabled} >
                        { this.props.models.map(model => {
                          return(
                            <MenuItem key={model.model} value={model.model}>{model.title}</MenuItem>
                          );
                        }) }
                      </Field>
                    </div>
                    <div className="verticalCenter">
                      <span className="f12 mr10">アイドリング時のモーション設定</span>
                      <Field name="idlingMotionRef" component={renderSimpleSelectField} width={240} height={32} onChange={this.onEditingChange} style={{ textAlign: "center", ...colorDisabled }} displayEmpty disabled={disabled} >
                        { this.props.motionMenuItems.map(motion => (
                          <MenuItem key={motion.motion} value={motion.motion}>{motion.title}</MenuItem>
                        )) }
                      </Field>
                    </div>
                  </div>
                  <hr style={{ margin: "20px 0" }} />
                  <div style={{ display: "flex" }}>
                    <div>
                      <span className="vguardSettingTitle">・Digital Input 設定</span>
                      <TableContainer style={{ height:  "calc(100vh - 56px - 48px - 306px )" }}>
                        <Table stickyHeader aria-label="simple table" style={{ tableLayout: "fixed", paddingRight: 16 }}>
                          <TableHead className={classes.listHeader}>
                            <TableRow>
                              <TableCell style={{textAlign: "center", width: 80 }}>番号</TableCell>
                              <TableCell style={{textAlign: "center", width: 140}}>Digital Input名</TableCell>
                              <TableCell style={{textAlign: "center", width: 120}}>条件</TableCell>
                              <TableCell style={{textAlign: "center"}}>外部コマンド（接点入力）</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody className={classes.tableBody}>
                          { new Array(DIGITAL_IN_NUM).fill(null).map((_, index) => {
                            return(
                              <RenderDigitalInput key={index} parentProps={this.props} index={index} colorDisabled={colorDisabled} disabled={disabled} />
                            )
                          }) }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                    <div style={{ paddingLeft: 40 }}>
                      <span className="vguardSettingTitle">・手動操作コマンドグループ表示順</span>
                      <TableContainer style={{ height:  "calc(100vh - 56px - 48px - 306px )", width: 376 }}>
                        <Table stickyHeader aria-label="simple table" style={{ tableLayout: "fixed", paddingRight: 16 }}>
                          <TableHead className={classes.listHeader}>
                            <TableRow>
                              <TableCell style={{textAlign: "center", width: "50%"}}>グループ名</TableCell>
                              <TableCell style={{textAlign: "center", width: "50%"}}></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody className={classes.tableBody}>
                          { this.props.orderManualCommandGroups.map((item, i) => {
                            let upDisabled = false;
                            let downDisabled = false;
                            if(i === 0) upDisabled = true;
                            if(i === this.props.orderManualCommandGroups.length - 1) downDisabled = true;
                            return(
                              <TableRow key={"manualCommandGroup"+i}>
                                <TableCell className="tableCommandOrder" style={{ textAlign: "center" }}>
                                  { item.title }
                                </TableCell>
                                <TableCell style={{ textAlign: "center" }}>
                                  <CustomButton variant="contained" themecolor="primary" type="button" disabled={upDisabled} width={64} height={24} onClick={() => this.onManualCommandGroupOrderChange("up", i)}>
                                    <img src="/images/vector_up.png" alt="up"/>
                                  </CustomButton>
                                  <CustomButton style={{ marginLeft: 8 }} variant="contained" themecolor="primary" type="button" disabled={downDisabled} width={64} height={24} onClick={() => this.onManualCommandGroupOrderChange("down", i)}>
                                    <img src="/images/vector_down.png" alt="down"/>
                                  </CustomButton>
                                </TableCell>
                              </TableRow>
                            )
                          }) }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                  <div style={{ width: "center", textAlign: "center", marginTop: 30 }}>
                  {(this.props.user && this.props.user.isManager()) &&
                    <CustomButton variant="contained" themecolor="primary" type="submit" disabled={submitting} width={160} height={48}>更新する</CustomButton>
                  }
                  </div>
                </form>
              </div>
            </div>
          </div>
          }
        </React.Fragment>
      </React.Fragment>
    )
  }
}

function RenderDigitalInput(props){
  const parentProps = props.parentProps;
  const index = props.index;
  const indexStr = zeroPadding(index, 2);
  const externalCommands = parentProps.charactersExternalCommands[parentProps.editingCharacter.character];
  const disabled = props.disabled;
  const colorDisabled = props.colorDisabled;
  if(!parentProps.formValues) return null;
  // const digitalIn = parentProps.formValues.digitalInSettings["digitalIn" + indexStr];
  return(
    <React.Fragment>
      <TableRow>
        <TableCell style={{ textAlign: "center", borderRadius: "8px 0 0 8px" }}>
          <span>{indexStr}</span>
        </TableCell>
        <TableCell style={{ textAlign: "center" }}>
          <Field name={"digitalInSettings.digitalIn" + indexStr + ".title"} component={renderSimpleTextField} width={128} size="sm_radius" inputProps={{ style: { textAlign: "center", ...colorDisabled }}} disabled={disabled} />
        </TableCell>
        <TableCell style={{ textAlign: "center" }}>
          <Field name={"digitalInSettings.digitalIn" + indexStr + ".condition"} component={renderSimpleSelectField} width={102} size="sm" style={colorDisabled} disabled={disabled} >
            <MenuItem value="make">メーク</MenuItem>
            <MenuItem value="break">ブレーク</MenuItem>
          </Field>
        </TableCell>
        <TableCell style={{ textAlign: "center" }}>
          <Field name={"digitalInSettings.digitalIn" + indexStr + ".externalCommandRef"} component={renderSimpleSelectField} size="sm" displayEmpty style={colorDisabled} disabled={disabled} >
            <MenuItem value="">[設定なし]</MenuItem>
            { externalCommands && externalCommands.map(item => {
              return(
                <MenuItem key={item.externalCommand} value={item.externalCommand}>{item.title}</MenuItem>
              );
            }) }
          </Field>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default withRouter(withStyles(muiStyles)(
  connect(state => ({
    user: state.user && state.user.user,
    listeners: state.main && state.main.listeners,
    company: state.main && state.main.company,
    selectedLabel: state.main && state.main.selectedLabel,
    characters: (state.characters && state.characters.characters) || [],
    editingCharacter: state.characters && state.characters.editingCharacter,
    addedCharacter: state.characters && state.characters.addedCharacter,
    models: (state.characters && state.characters.models) || [],
    motions: (state.characters && state.characters.motions) || [],
    motionMenuItems: (state.characters && state.characters.motionMenuItems) || [],
    charactersExternalCommands: (state.characters && state.characters.charactersExternalCommands) || [],
    orderManualCommandGroups: (state.characters && state.characters.orderManualCommandGroups) || [],
    loading: state.loading && state.loading.loading,
    initialValues: state.characters && state.characters.editingCharacter,
    formValues: getFormValues("characterForm")(state),
  }))(reduxForm({ form: 'characterForm', enableReinitialize: true })(CharacterForm))
));
