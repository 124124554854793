import db, { getVguardsData, getCharactersData } from "../utilities/db";
import firebase from 'firebase/app';
import { appErrorAction } from './appAction';

const VGUARD_SETTINGS = [
  { key: "idling", isNest: false ,columns: ["idlingEyesEnabled", "idlingEyesSpeed", "idlingFaceEnabled", "idlingFaceSpeed", "idlingFaceRange"] },
  { key: "cameraSettings", isNest: true, columns: ["camera1IP", "camera1ID", "camera1Password", "camera1QueryString", "camera2IP", "camera2ID", "camera2Password", "camera2QueryString",
                                            "camera3IP", "camera3ID", "camera3Password", "camera3QueryString"] },
  { key: "childTall", isNest: false, columns: ["childTallEnabled", "childTallHeight"] },
  { key: "nearZone", isNest: true, columns: ["distance", "waitTime", "captureEnabled", "greetingEnabled"] },
  { key: "midZone", isNest: true, columns: ["virtualwall01X1", "virtualwall01Z1", "virtualwall01X2", "virtualwall01Z2", "virtualwall02X1", "virtualwall02Z1",
                                            "virtualwall02X2", "virtualwall02Z2", "virtualwall03X1", "virtualwall03Z1", "virtualwall03X2", "virtualwall03Z2", "speed", "rerunWaitTime", "captureEnabled", "greetingEnabled"] },
  { key: "srs", isNest: true, columns: ["bargeIn", "silentDuration", "rejectDuration", "retryOverTimes"] },
  { key: "character", isNest: false, columns: ["characterRef"] },
  { key: "faceDetection", isNest: true, columns: ["faceDetectionEnabled", "faceDetectionServerIP"] },
  { key: "callTimeoutSec", isNest: false, columns: ["callTimeoutSec"] },
  { key: "scheduleEnabled", isNest: false, columns: ["scheduleEnabled"] },
  { key: "speechDetection", isNest: true, columns: ["noddingMotionEnabled", "textDisplayEnabled", "srsResultDisplayEnabled"] },
]

// 数値のカンマ区切り判定用
const THOUSAND_SEPARATOR = ["idlingEyesSpeed", "idlingFaceRange", "childTallHeight", "distance", "waitTime", "speed", "rerunWaitTime", "silentDuration", "rejectDuration", "callTimeoutSec", "retryOverTimes",
  "virtualwall01X1", "virtualwall01X2", "virtualwall01Z1", "virtualwall01Z2",
  "virtualwall02X1", "virtualwall02X2", "virtualwall02Z1", "virtualwall02Z2",
  "virtualwall03X1", "virtualwall03X2", "virtualwall03Z1", "virtualwall03Z2",
];

export async function listVguards(dispatch, cid, label, isInit){
  try{
    // console.log(cid, label);
    const compnayDoc = db().collection("companies").doc(cid);
    const labelDoc = compnayDoc.collection("labels").doc(label);
    const [vguards, characters, heartbeatsVguard] = await Promise.all([
      getVguardsData(labelDoc),
      getCharactersData(labelDoc),
      db().collection("heartbeatsVguard").get(),
    ]);
    vguards.forEach((item, index) => {
      if(item.settings.characterRef) item.settings.characterRef = characters.find(x => x.character === item.settings.characterRef.id);
      if(item.settings.cameraSettings){
        new Array(3).fill(null).forEach((_, index) => {
          let ipString = item.settings.cameraSettings["camera" + (index + 1) + "IP"];
          if(!ipString) ipString = '...';
          const ips = ipString.split(".");
          ips.forEach((ip, i) => {
            // console.log(ip);
            item.settings.cameraSettings["camera" + (index + 1) + "IP" + (i + 1)] = (isNaN(Number(ip)) || ip === "") ? null : Number(ip);
          });
        });
      }
      if(item.settings.faceDetection){
        let ipString = item.settings.faceDetection["faceDetectionServerIP"];
        if(!ipString) ipString = '...';
        const ips = ipString.split(".");
        ips.forEach((ip, i) => {
          item.settings.faceDetection["faceDetectionServerIP" + (i + 1)] = (isNaN(Number(ip)) || ip === "") ? null : Number(ip);
        });
      }
      item.index = index;
      // heartbeat設定があるか
      item.heartbeat = false;
      const vguardDoc = labelDoc.collection("vguards").doc(item.vguard);
      for(let doc of heartbeatsVguard.docs){
        // console.log(doc.data().vguardHeartbeatRef.id, vguardDoc.id);
        if(doc.data().vguardHeartbeatRef && doc.data().vguardHeartbeatRef.id === vguardDoc.id){
          item.heartbeat = true;
          break;
        }
      }
    });
    // console.log(vguards);
    dispatch(listVguardsAction(vguards, characters, isInit));
  } catch(e){
    dispatch(appErrorAction(e));
  }
}

export function beginEditVguard(dispatch, vguard){
  dispatch(beginEditVguardAction(vguard));
}

export async function changeEditingVguard(dispatch, editingVguard){
  dispatch(changeEditingVguardAction(editingVguard));
}

export async function updateVguardExec(dispatch, cid, label, editingVguard){
  try{
    // console.log(cid, label, editingVguard);
    // 元のデータを取得してどこに変更があったか確認
    const vguardDoc = db().collection("companies").doc(cid).collection("labels").doc(label).collection("vguards").doc(editingVguard.vguard);
    const org = (await vguardDoc.get()).data();
    let changed = [];
    const batch = db().batch();
    // console.log(org);
    // console.log(editingVguard);
    VGUARD_SETTINGS.forEach(s => {
      for(let i = 0; i < s.columns.length; i++){
        const column = s.columns[i];
        let orgValue = (s.isNest && org.settings[s.key]) ? org.settings[s.key][column] : org.settings[column];
        let editValue = s.isNest ? editingVguard.settings[s.key][column] : editingVguard.settings[column];
        if(column === "characterRef"){
          orgValue = orgValue && orgValue.id;
        } else if(column === "camera1IP" || column === "camera2IP" || column === "camera3IP" || column === "faceDetectionServerIP"){
          editValue = "";
          for(let ipIndex = 1; ipIndex <= 4; ipIndex++){
            if(editingVguard.settings[s.key][column + ipIndex] || editingVguard.settings[s.key][column + ipIndex] === 0){
              // console.log(editingVguard.settings[s.key][column + ipIndex]);
              editValue += parseInt(editingVguard.settings[s.key][column + ipIndex].toString().replace(/,/g, ""));
            } else {
              editValue += "";
            }
            if(ipIndex !== 4) editValue += ".";
          }
          // console.log(editValue);
          if(editValue === "...") editValue = null;
          // editValue = parseInt(editingVguard.settings[s.key][column + "1"].toString().replace(/,/g, "")) + "." + parseInt(editingVguard.settings[s.key][column + "2"].toString().replace(/,/g, "")) + "." + parseInt(editingVguard.settings[s.key][column + "3"].toString().replace(/,/g, "")) + "." + parseInt(editingVguard.settings[s.key][column + "4"].toString().replace(/,/g, ""));
        }
        if(!isNaN(Number(orgValue))) orgValue = Number(orgValue);
        if(!isNaN(Number(editValue))) editValue = Number(editValue);
        if(orgValue !== editValue){
          let d = { key: s.key, map: {} };
          // console.log(s.key, orgValue, editValue);
          s.columns.forEach(changeColumn => {
            const value = s.isNest ? editingVguard.settings[s.key][changeColumn] : editingVguard.settings[changeColumn];
            let offcomma = value;
            if(changeColumn === "characterRef"){
              d.map[changeColumn] = db().collection("companies").doc(cid).collection("labels").doc(label).collection("characters").doc(value);
            } else if(changeColumn === "camera1IP" || changeColumn === "camera2IP" || changeColumn === "camera3IP" || changeColumn === "faceDetectionServerIP"){
              d.map[changeColumn] = "";
              for(let ipIndex = 1; ipIndex <= 4; ipIndex++){
                if(editingVguard.settings[s.key][changeColumn + ipIndex] || editingVguard.settings[s.key][changeColumn + ipIndex]  === 0){
                  // console.log(parseInt(editingVguard.settings[s.key][changeColumn + ipIndex].toString().replace(/,/g, "")));
                  d.map[changeColumn] += parseInt(editingVguard.settings[s.key][changeColumn + ipIndex].toString().replace(/,/g, ""));
                } else {
                  d.map[changeColumn] += "";
                }
                if(ipIndex !== 4) d.map[changeColumn] += ".";
              }
              // console.log(d.map[changeColumn]);
              if(d.map[changeColumn] === "...") d.map[changeColumn] = null;
              // d.map[changeColumn] = parseInt(editingVguard.settings[s.key][changeColumn + "1"].replace(/,/g, "")) + "." + parseInt(editingVguard.settings[s.key][changeColumn + "2"].replace(/,/g, "")) + "." + parseInt(editingVguard.settings[s.key][changeColumn + "3"].replace(/,/g, "")) + "." + parseInt(editingVguard.settings[s.key][changeColumn + "4"].replace(/,/g, ""));
            } else if (THOUSAND_SEPARATOR.includes(changeColumn)) {
              if (typeof(offcomma) === "string") {
                offcomma = offcomma.includes('.') ? parseFloat(offcomma.replace(/,/g, "")) : parseInt(offcomma.replace(/,/g, ""));
              }
              d.map[changeColumn] = offcomma;
            } else {
              d.map[changeColumn] = value;
            }
          });
          // console.log(d, orgValue, editValue)
          changed.push(d);
          // batchに追加
          batch.set(vguardDoc.collection("setting-updates").doc(), {
            settingType: d.key,
            settingMap: d.map,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          });
          // このkeyのグループの処理は終了
          break;
        }
      }
    });

    // heartbeatsVguardの変更
    let isHeartbeatsVguardChange = false;
    const heartbeatsVguard = await db().collection("heartbeatsVguard").get();
    let heartbeatsVguardDoc = null;
    for(let doc of heartbeatsVguard.docs){
      if(doc.data().vguardHeartbeatRef && doc.data().vguardHeartbeatRef.id === vguardDoc.id){
        heartbeatsVguardDoc = doc;
        break;
      }
    }
    if(heartbeatsVguardDoc && !editingVguard.heartbeat){
      // heartbeatsVguardがあって今回削除する
      isHeartbeatsVguardChange = true;
      batch.delete(heartbeatsVguardDoc.ref);
    } else if(!heartbeatsVguardDoc && editingVguard.heartbeat){
      // heartbeatsVguardがなくて今回追加する
      isHeartbeatsVguardChange = true;
      // TODO:collectionだと登録できないのでvguardのrefを登録しておく
      batch.set(db().collection("heartbeatsVguard").doc(), { vguardHeartbeatRef: vguardDoc, });
    }

    if(isHeartbeatsVguardChange || changed.length > 0){
      await batch.commit();
      dispatch(updatedVguardAction(changed, true));
    } else {
      dispatch(updatedVguardAction(changed, false));
    }
  } catch(e){
    dispatch(appErrorAction(e));
  }
}

export function hideUpdateToast(dispatch){
  dispatch({ type: "VGAURD_HIDE_TOAST" });
}

function listVguardsAction(vguards, characters, isInit){
  return {
    type: "LIST_VGUARDS",
    payload: {
      vguards: vguards,
      characters: characters,
      isInit: isInit,
    }
  }
}

function beginEditVguardAction(vguard){
  return {
    type: "BEGIN_EDIT_VGUARD",
    payload: {
      vguard: vguard,
    }
  }
}

function changeEditingVguardAction(editingVguard){
  return {
    type: "CHANGE_VGUARD",
    payload: {
      editingVguard: editingVguard,
    }
  }
}

function updatedVguardAction(changed, showUpdateToast){
  return {
    type: "UPDATED_VGUARD",
    payload: {
      changed: changed,
      showUpdateToast: showUpdateToast,
    }
  }
}
