const defaultState = {
  motions: [],
  searchedMotions: [],
  models: [],
  searchModel: "1",
};

function defaultMotions() {
  return {
    motion: '',
    title: '',
    modelRef: '',
    createdAt: null,
    updatedAt: null,
  }
}

function searchList(list, model) {
  let searchedList = [ ...list ];
  searchedList = searchedList.filter(x => x.modelRef.model === model);
  return searchedList;
}

const motions = (state = defaultState, action) => {
  switch (action.type) {
    case 'MOTIONS':
      return {
        ...state,
        motions: action.payload.motions || defaultMotions(),
        searchedMotions: searchList(action.payload.motions, state.searchModel),
        models: action.payload.models,
      };
    case 'SEARCH_MOTIONS':
      return {
        ...state,
        searchedMotions: searchList(state.motions, action.payload.searchModel),
        searchModel: action.payload.searchModel,
      };
    default:
      return state;
  }
};

export default motions;
