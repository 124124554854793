import { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import { sessionLogin } from './actions/login';
import { appError } from './actions/appAction';

export class Auth extends Component {

  componentDidMount() {
    this.checkAuth();
  }

  componentDidUpdate() {
    this.checkAuth();
  }

  checkAuth() {
    const cid = this.props.match.params && this.props.match.params.cid;
    const loginPath = "/" + cid + '/login';
    const sessionUser = sessionStorage.getItem("user"+cid);
    if (this.props.location.pathname !== loginPath && this.props.user == null && sessionUser == null) {
      this.props.history.replace(loginPath);
      if (this.props.logoutBy === "account_delete") {
        appError(this.props.dispatch, "お使いのユーザは管理者によって削除されました");
      } else if (this.props.logoutBy === "permission") {
        appError(this.props.dispatch, "他の端末でログアウトしたため、もう一度ログインしてください");
      }
    } else if(this.props.user == null && sessionUser){
      // 画面リロードした場合
      sessionLogin(this.props.dispatch, JSON.parse(sessionUser)).then(() => {
        this.props.history.push("/" + cid);
      });
    }
  }

  render() {
    return '';
  }
}

export default withRouter(connect(state => ({
  user: state.user && state.user.user,
  logoutBy: state.user && state.user.logoutBy,
}))(Auth));
