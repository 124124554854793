import { GREETING_TYPE_NEAR } from "../actions/greetings";
import { isEqualExceptFalsy } from "../utilities/utils";

const defaultState = {
  characterData: null,
  greetings: {
    "nearZone": [],
    "midZone": [],
  },
  searchedGreetings: [],
  search: "",
  originalGreeding: null,
  editingGreeting: null,
  greetingType: "nearZone",
  phrases: [],
  motions: [],
  phraseMenuItems: [],
  motionMenuItems: [],
  isRefresh: false,
};

function defaultGreeting() {
  return{
    greeting: null,
    title: "",
    phraseRef: null,
    motionRef: null,
    timeZones: ["morning", "daytime", "evening", "night"],
    timeZone_morning: false,
    timeZone_daytime: false,
    timeZone_evening: false,
    timeZone_night: false,
    virtualwallNo: 1,
    virtualwallCourse: "IN",
    playType: "SyncSoundAndMotion",
    createdAt: null,
    updatedAt: null
  }
}

function defaultTimeZones() {
  return {
    timeZones: ["morning", "daytime", "evening", "night"],
    timeZone_morning: false,
    timeZone_daytime: false,
    timeZone_evening: false,
    timeZone_night: false,
  };
}

function setBeginEditingData(editData, phraseMenuItems, motionMenuItems) {
  // modelが異なる場合の処理
  if (editData.isPhraseModelDiff) {
    const existPhraseData = phraseMenuItems.find(x => x.phrase === editData.phraseRef.phrase);
    if (!existPhraseData) phraseMenuItems.push({ ...editData.phraseRef, disabled: true });
  }
  if (editData.isMotionModelDiff) {
    const existMotionData = motionMenuItems.find(x => x.motion === editData.motionRef.motion);
    if (!existMotionData) motionMenuItems.push({ ...editData.motionRef, disabled: true });
  }
  // フレーズなどは編集時はidの部分があれば良いのでデータを変える
  editData.phraseRef = editData.phraseRef && editData.phraseRef.phrase;
  editData.motionRef = editData.motionRef && editData.motionRef.motion;

  // タイムゾーン関係のデフォルトデータセット
  const defaultTimeZoneItems = defaultTimeZones();
  defaultTimeZoneItems.timeZones.forEach(timeZone => {
    editData["timeZone_" + timeZone] = defaultTimeZoneItems["timeZone_" + timeZone];
  });
  editData.timeZones = [...editData.timeZones];
}

function searchList(list, search) {
  let searchedList = [ ...list ];
  if (search) {
    searchedList = searchedList.filter(x => x.title.indexOf(search) >= 0);
  }
  return searchedList;
}

const greetings = (state = defaultState, action) => {
  let editData;
  switch (action.type) {
    case "LIST_GREETINGS":
      let obj = {
        ...state,
        characterData: action.payload.characterData,
        greetings: action.payload.greetings,
        searchedGreetings: state.greetingType === GREETING_TYPE_NEAR ? searchList(action.payload.greetings.nearZone, state.search) : searchList(action.payload.greetings.midZone, state.search),
        phrases: action.payload.phrases,
        motions: action.payload.motions,
        isRefresh: false,
      }
      if(action.payload.isInit){
        obj.editingGreeting = null;
      }
      return obj;
    case "GREETING_SEARCH":
      return {
        ...state,
        search: action.payload.search,
        greetingType: action.payload.greetingType,
        searchedGreetings: action.payload.greetingType === GREETING_TYPE_NEAR ? searchList(state.greetings.nearZone, action.payload.search) : searchList(state.greetings.midZone, action.payload.search),
      }
    case "BEGIN_EDIT_GREETING":
      // console.log(state.greetings, action.payload.greetingType);
      const d = state.greetings[action.payload.greetingType].find(item => { return item.greeting === action.payload.greeting });
      editData = { ...d };
      // console.log(state.characterData.modelRef);
      const phraseMenuItems = (state.characterData.modelRef && state.phrases.filter(x => x.modelRef.model === state.characterData.modelRef.model)) || [];
      // 挨拶のモーションは緊急地震速報は抜く
      const motionMenuItems = (state.characterData.modelRef && state.motions.filter(x => x.modelRef.model === state.characterData.modelRef.model).filter(x => x.title.indexOf("緊急地震速報") === -1)) || [];
      // console.log(editData);
      if(!editData.greeting){
        // 新規
        editData = defaultGreeting();
      } else {
        setBeginEditingData(editData, phraseMenuItems, motionMenuItems);
      }
      editData.greetingType = action.payload.greetingType;
      editData.timeZones.forEach(timeZone => {
        editData["timeZone_" + timeZone] = true;
      });
      editData.isEditingUpdate = false;
      return {
        ...state,
        originalGreeding: editData,
        editingGreeting: editData,
        phraseMenuItems: phraseMenuItems,
        motionMenuItems: motionMenuItems,
        greetingType: action.payload.greetingType,
        isRefresh: false,
      }
    case "CHANGE_EDITING_GREETING":
      editData = { ...action.payload.editingGreeting };
      // originalGreedingと比較するためいったんfalseにする
      editData.isEditingUpdate = false;
      editData.isEditingUpdate = isEqualExceptFalsy(state.originalGreeding, editData) ? false : true;
      return {
        ...state,
        editingGreeting: editData,
        isRefresh: false,
      }
    case "UPDATED_GREETING":
      editData = { ...action.editing };
      editData.isEditingUpdate = false;
      return {
        ...state,
        originalGreeding: editData,
        editingGreeting: editData,
        isRefresh: true,
      }
    case "DELETED_GREETING":
      return {
        ...state,
        editingGreeting: null,
        isRefresh: true,
      }
    case "REFRESH_GREEDING":
      return {
        ...state,
        editingGreeting: null,
        isRefresh: true,
      }
    case "GREETING_CLOSE":
      return {
        ...state,
        editingGreeting: null,
      }
    case "GREETING_EDIT_CANCEL":
      let beforeEditing = state.greetings[state.editingGreeting.greetingType].find(item => {
        return item.greeting === state.editingGreeting.greeting;
      });
      beforeEditing = {...beforeEditing};
      if(!beforeEditing.greeting) {
        beforeEditing = defaultGreeting();
      } else {
        setBeginEditingData(beforeEditing, state.phraseMenuItems, state.motionMenuItems);
      }
      beforeEditing.greetingType = state.editingGreeting.greetingType;
      beforeEditing.timeZones.forEach(timeZone => {
        beforeEditing["timeZone_"+timeZone] = true;
      });
      beforeEditing.test = new Date().getTime();
      beforeEditing.isEditingUpdate = false;
      return {
        ...state,
        originalGreeding: beforeEditing,
        editingGreeting: beforeEditing,
      }
    default:
      return state;
  }
}

export default greetings;
