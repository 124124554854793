import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import muiStyles from "./MuiStyles";
import { appRemoveError, updateMessage } from './actions/appAction';
import { withStyles, Snackbar, Slide, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

export class UpdateMessage extends Component {
  state = {
    vertical: "top",
    horizontal: "center",
    Transition: Slide,
  }

  componentDidMount(){
    updateMessage(this.props.dispatch, false);
  }

  componentDidUpdate(){
  }

  onClose = (e) => {
    e.preventDefault();
    appRemoveError(this.props.dispatch);
  }

  handleClose = (event, reason) => {
    updateMessage(this.props.dispatch, false, null);
  };

  render(){
    const { classes } = this.props;
    let message = null;
    switch (this.props.message) {
      case "register":
        message = "新規登録が完了しました";
        break;
      case "update":
        message = "更新が完了しました";
        break;
      case "add":
        message = "追加しました";
        break;
      case "copy":
        message = "コピーを実行中です";
        break;
      case "changing_edit":
        message = "すでにサイドメニューの内容が変更されています。「更新する」または「リセット」をクリックしてから操作し直してください。";
        break;
      case "changing_new":
        message = "すでにサイドメニューの内容が入力されています。「登録する」または「リセット」をクリックしてから操作し直してください。";
        break;
      case "changing_edit_add":
        message = "すでにサイドメニューの内容が変更されています。「追加する」または「リセット」をクリックしてから操作し直してください。";
        break;
      case "changing_new_add":
        message = "すでにサイドメニューの内容が入力されています。「追加する」または「リセット」をクリックしてから操作し直してください。";
        break;
      default:
        break;
    }
    return (
      <div>
        <Snackbar
          anchorOrigin={{ vertical: this.state.vertical, horizontal: this.state.horizontal}}
          key={this.state.vertical + this.state.horizontal}
          TransitionComponent={this.state.Transition}
          open={this.props.isUpdateMessageOpen}
          onClose={this.handleClose}
          autoHideDuration={6000}
          message={message}
          action={
            <React.Fragment>
              <IconButton
                aria-label="close"
                color="inherit"
                className={classes.close}
                onClick={this.handleClose}
                onClose={this.handleClose}
              >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
          }
        />
      </div>
    );
  }
}

export default withRouter(withStyles(muiStyles)(
  connect(state => ({
    isUpdateMessageOpen: state.appState && state.appState.isUpdateMessageOpen,
    message: state.appState && state.appState.message,
  }))
(UpdateMessage)));
