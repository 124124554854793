import { searchByTag } from "../utilities/utils";

const defaultState = {
  phrases: [],
  searchedPhrases: [],
  search: "",
  searchModel: "1",
  editingPhrase: {},
  models: [],
  isFirstMorphRequest: true,
  isRefresh: false,
  interlanguageTitle: "",
  audio: null,
  synthesizeAudio: null,
  playPhraseUrl: "",
  synthesizedPhrase: null,
};

function defaultPhrases() {
  return {
    phrase: null,
    phraseTitle: '',
    interlanguage: [],
    phraseLanguage: "ja",
    phraseMd5: null,
    modelRef: null,
    phrasePath: null,
    createdAt: '',
    updatedAt: '',
    modelID: "all_adapt",
    speakerID: null,
  }
}

function searchList(list, search, model) {
  let searchedList = [ ...list ];
  searchedList = searchedList.filter(x => x.modelRef.model === model);
  if (search) {
    searchedList = searchByTag(searchedList, 'phraseTitle', search);
  }
  return searchedList;
}

// 古いデータ補正用
export const MODEL_SPEAKERS = {
  "1": { modelID: "all_adapt", speakerID: "mamoru" },
  "2": { modelID: "all_adapt", speakerID: "ai" },
  "3": { modelID: "all_adapt", speakerID: "mamoru" },
  "4": { modelID: "all_adapt", speakerID: "ai" },
};

const phrases = (state = defaultState, action) => {
  let editingPhrase;
  switch (action.type) {
    case 'PHRASES':
      return {
        ...state,
        phrases: action.payload.phrases || defaultPhrases(),
        searchedPhrases: searchList(action.payload.phrases, state.search, state.searchModel),
        editingPhrase: null,
        models: action.payload.models,
        isRefresh: false,
      };
    case "SEARCH_PHRASE":
      return {
        ...state,
        search: action.payload.search,
        searchModel: action.payload.searchModel,
        searchedPhrases: searchList(state.phrases, action.payload.search, action.payload.searchModel),
      };
    case 'BEGIN_EDIT_PHRASE':
      editingPhrase = state.phrases.find((phrase) => (
        phrase.phrase === action.payload.phrase
      ));
      if(editingPhrase){
        editingPhrase = {...defaultPhrases(), ...editingPhrase};
        // 先にspeakerIDとmodelIDをセットする
        editingPhrase.speakerID = editingPhrase.modelRef && editingPhrase.modelRef.ttsSpeakerId;
        editingPhrase.modelID = editingPhrase.modelRef && editingPhrase.modelRef.ttsModelId;
        editingPhrase.modelRef = editingPhrase.modelRef && editingPhrase.modelRef.model;
        if(!editingPhrase.speakerID){
          // 古いデータ用補正
          editingPhrase.speakerID = MODEL_SPEAKERS[editingPhrase.modelRef].speakerID;
          editingPhrase.modelID = MODEL_SPEAKERS[editingPhrase.modelRef].modelID;
        }
      } else {
        editingPhrase = defaultPhrases();
        editingPhrase.modelRef = action.payload.model;
        const model = state.models.find(m => (
          m.model === editingPhrase.modelRef
        ));
        editingPhrase.speakerID = model.ttsSpeakerId;
        editingPhrase.modelID = model.ttsModelId;
        if(!editingPhrase.speakerID){
          // 古いデータ用補正
          editingPhrase.speakerID = MODEL_SPEAKERS[editingPhrase.modelRef].speakerID;
          editingPhrase.modelID = MODEL_SPEAKERS[editingPhrase.modelRef].modelID;
        }
      }
      // console.log(editingPhrase);
      return {
        ...state,
        editingPhrase: editingPhrase,
        isFirstMorphRequest: true,
        interlanguageTitle: editingPhrase.interlanguage.map(x => x[0]).join("") || editingPhrase.phraseTitle,
      };
    case 'EDIT_PHRASE':
      editingPhrase = {...action.payload.phrases};
      const modelData = state.models.find((m) => (
        m.model === editingPhrase.modelRef
      ));
      editingPhrase.speakerID = modelData && modelData.ttsSpeakerId;
      editingPhrase.modelID = modelData && modelData.ttsModelId;
      if(!editingPhrase.speakerID){
        // 古いデータ用補正
        editingPhrase.speakerID = MODEL_SPEAKERS[editingPhrase.modelRef].speakerID;
        editingPhrase.modelID = MODEL_SPEAKERS[editingPhrase.modelRef].modelID;
      }
      // console.log(editingPhrase);
      return {
        ...state,
        editingPhrase: editingPhrase,
        interlanguageTitle: action.payload.isChangeTitle ? editingPhrase.phraseTitle : state.interlanguageTitle,
        isRefresh: false,
      };
    case "PHRASE_DELETED":
      return {
        ...state,
        isRefresh: true,
      }
    case 'CREATED_PHRASE_TEST':
      return {
        ...state,
        audio: action.payload.audio,
      }
    case "PHRASE_GET_MORPH":
      return {
        ...state,
        isFirstMorphRequest: false,
      }
    case "PHRASE_SYNTHESIZE":
      return {
        ...state,
        // editingPhrase: action.payload.editingPhrase,
        editingPhrase: null,
        isRefresh: true,
      }
    case "PHRASE_PLAY_URL":
      return {
        ...state,
        playPhraseUrl: action.playPhraseUrl,
      }
    case "FACE_SYNTHESIZED_PHRASE":
      return {
        ...state,
        synthesizedPhrase: action.synthesizedPhrase,
      }
    case "PHRASE_REFRESH":
      return {
        ...state,
        editingPhrase: null,
        isRefresh: true,
      }
    default:
      return state;
  }
};

export default phrases;
