
const muiStyles = theme => ({
  content: {
    margin: "20px auto 15px auto",
    display: "flex",
    width: 1280,
  },
  accountForm: {
    marginTop: -76,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    "& p": {
      textAlign: "center",
    }
  },
  main: {
    width: "100%",
    height: "calc(100% - 15px)",
    backgroundColor: theme.palette.white.main,
    borderTopLeftRadius: "16px",
    borderBottomLeftRadius: "16px",
    padding: "32px",
    boxShadow: "0px 4px 4px rgba(0,0,0,0.1)",
  },
  characterMain: {
    width: "100%",
    maxWidth: "calc(100% - (32px * 2))",
    backgroundColor: theme.palette.white.main,
    // margin: "0 16px",
    borderRadius: "16px",
    padding: "32px",
    boxShadow: "0px 4px 4px rgba(0,0,0,0.1)",
  },
  characerForm: {
    marginTop: 20,
  },
  keywordGroupInsideEditScroll: {
    minHeight: 100,
    maxHeight: "calc(100vh - 56px - 48px - 90px - 268px)",
    overflowY: "auto",
    width: "100%",
  },
  characterInsideEditScroll: {
    minHeight: 100,
    maxHeight: "calc(100vh - 56px - 48px - 170px - 195px)",
    overflowY: "auto",
  },
  listKeywordGroupEditContainer: {
    width: "100%",
    height: "max(350px, calc(100vh - 56px - 48px - 90px))",
    display: "flex",
  },
  listEditContainer: {
    width: "100%",
    height: "max(300px, calc(100vh - 56px - 48px - 170px))",
    display: "flex",
  },
  listEditList: {
    width: "60%",
    maxWidth: 754,
    height: "inherit"
  },
  listEditSpace: {
    minWidth: 30,
    maxWidth: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 16,
  },
  listEditEdit: {
    minWidth: 462,
  },
  arrowAndSideMenuContainer: {
    display: 'flex',
  },
  insideEditContainer: {
    width: "100%",
    border: "2px solid #6781B8",
    boxSizing: "border-box",
    borderRadius: 8,
    marginTop: 16,
    padding: 16,
  },
  listContainer: {
    width: "100%",
    maxWidth: 900,
    height: "calc(100vh - 56px - 48px - 90px)",
    // overflowY: "auto"
    // height: "calc(100% - 30px)"
  },
  characterListContainer: {
    width: "100%",
    height: "calc(100vh - 56px - 48px - 170px)",
  },
  selectListContainer: {
    border: "1px solid #eee",
    boxSizing: "border-box",
    borderRadius: 6,
    marginBottom: 15,
  },
  listHeader: {
    backgroundColor: theme.palette.white.main,
    "& th": {
      fontWeight: 600,
      backgroundColor: theme.palette.white.main,
    },
  },
  listAccountContainer: {
    width: "100%",
    maxWidth: 900,
    height: "100%",
    marginTop: 35
    // overflowY: "auto"
    // height: "calc(100% - 30px)"
  },
  tableBody: {
    color: "inherit",
    "& tr:nth-child(odd)": {
      backgroundColor: "#F9F9F9",
    },
    "& tr > td:first-child": {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
    "& tr > td:last-child": {
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
    },
  },
  tableBodyWithTag: {
    color: "inherit",
    "& tr:nth-child(4n+1), tr:nth-child(4n+2)": {
      backgroundColor: "#F9F9F9",
    },
    "& tr:nth-child(odd)": {
      "& > td:first-child": {
        borderTopLeftRadius: 8,
      },
      "& > td:last-child": {
        borderTopRightRadius: 8,
      },
    },
    "& tr:nth-child(even)": {
      "& > td": {
        paddingTop: 0,
        paddingBottom: 10,
      },
      "& > td:first-child": {
        borderBottomLeftRadius: 8,
      },
      "& > td:last-child": {
        borderBottomRightRadius: 8,
      },
    },
  },
  dialogContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    margin: 12,
    borderRadius: 16,
    "& p": {
      textAlign: "center",
    }
  },
  dialogContentFull: {
    backgroundColor: "#fff",
    margin: 12,
    borderRadius: 16,
    "& p": {
      textAlign: "center",
    }
  },
  closeButton: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  cameraTitle: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.gray.main,
    borderRadius: 8,
    width: 200,
    height: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10,
  },
  submitButton: {
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
    borderRadius: 20,
    height: 40,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    },
    minWidth: "initial",
    "& .MuiButton-label": {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "24px",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
    }
  },
  toggleSelected: {
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.5)",
    color: theme.palette.white.main,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    "& .MuiButton-label": {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "24px",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
    }
  },
  toggleNotSelected: {
    color: theme.palette.primary.main,
    // backgroundColor: theme.palette.white.main,
    backgroundColor: "transparent",
    "& .MuiButton-label": {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "24px",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
    }
  },
  textGray: {
    color: theme.palette.gray.main
  },
  grayFieldBack: {
    color: theme.palette.text.main,
    backgroundColor: theme.palette.gray.light,
    fontSize: 12,
    fontWeight: 300,
    lineHeight: "21px",
    height: 24,
    width : 199,
    borderRadius: 12,
    padding: "2px 10px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  keywordListContainer: {
    margin: "5px 0",
    display: "flex",
    alignItems: "start",
    justifyContent: "space-between"
  },
  keyword: {
    color: "white",
    backgroundColor: theme.palette.gray.main,
    fontSize: 10,
    lineHeight: "16px",
    fontWeight: 600,
    letterSpacing: 0,
    height: 16,
    borderRadius: 8,
    margin: "2px 0 2px 3px",
    padding: "1px 10px",
  },
  keywordToken: {
    fontSize: "inherit",
    lineHeight: "inherit",
    marginLeft: 10,
  },
  keywordInsideDialog: {
    fontSize: 12,
    height: 22,
    lineHeight: "22px",
    margin: "2px 0 2px 8px",
    borderRadius: 11,
  },
  flexKeywordList: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    '& > div:first-child': {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
    },
  },
  selectedKeyword: {
    color: theme.palette.gray.main,
    backgroundColor: "white",
    fontSize: 10,
    lineHeight: "16px",
    fontWeight: 600,
    letterSpacing: 0,
    height: 16,
    borderRadius: 8,
    margin: "2px 0 2px 3px",
    padding: "1px 10px",
  },
  faceDetectionWord: {
    color: "white",
    backgroundColor: theme.palette.sub.dark,
    fontSize: 10,
    lineHeight: "20px",
    fontWeight: 600,
    height: 16,
    borderRadius: 8,
    marginLeft: "3px",
    padding: "2px 10px",
  },
  selectedFaceDetectionWord: {
    color: theme.palette.sub.dark,
    backgroundColor: "white",
    fontSize: 10,
    lineHeight: "20px",
    fontWeight: 600,
    height: 16,
    borderRadius: 8,
    marginLeft: "3px",
    padding: "2px 10px",
  },
  modelDiffWord:{
    color: "#F8431C",
    backgroundColor: "#FEE3DD",
    fontSize: 10,
    lineHeight: "20px",
    fontWeight: 600,
    height: 16,
    borderRadius: 8,
    marginLeft: "3px",
    padding: "2px 10px",
  },
  securityText: {
    fontFamily: "Oswald",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 18,
    lineHeight: "22px",
    marginLeft: "9px",
  },
  dialogSecurityText: {
    fontFamily: "Oswald",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 20,
    lineHeight: "24px",
  },
  dialogDisplayNameText: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 18,
    lineHeight: "20px",
    marginLeft: 20,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "inline-block",
  },
  tagText: {
    fontFamily: "Hiragino Kaku Gothic Pro",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    lineHeight: "18px",
    textDecorationLine: "underline",
    color: theme.palette.sub.main,
    marginLeft: 5,
  },
  tagTextSelected: {
    fontFamily: "Hiragino Kaku Gothic Pro",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    lineHeight: "18px",
    textDecorationLine: "underline",
    color: theme.palette.white.main,
    marginLeft: 5,
  },
  flexTagList: {
    display: "flex",
    flexWrap: "wrap",
  },

  registering: {
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '28px',
    color: '#444444',
  },

});

export default muiStyles;
