import React, { Component } from "react";
import { withStyles, DialogContent } from '@material-ui/core';
import muiStyles from "./MuiStyles";
import { GrayButton, PrimaryButton, CloseButton, StyledDialog, SearchTextField, EnhancedTable } from "./AppParts";
import { searchByTag } from "./utilities/utils";

class SelectDialog extends Component {

  constructor(props) {
    super(props);
    if (this.props.name === "phrase") {
      this.dialogTitle = "音声フレーズ"; // タイトル

      // this.props.listの設定
      this.idKey = "phrase";
      this.displayKey = "phraseTitle";
      this.headCells = [
        { id: "phraseTitle", /*label: "フレーズ"*/ },
      ];
    }
    if (this.props.name === "picture") {
      this.dialogTitle = "案内画像";

      this.idKey = "picture";
      this.displayKey = "title";
      this.headCells = [
        { id: "title", /*label: "案内画像"*/ },
      ];
    }
    this.state = {
      selected: undefined,
      change: false,
      isSearchUsed: false,
      searchedList: [],
    };
  }

  componentDidMount() {
    if (this.props.list && this.props.list.length) {
      this.searchList(this.state.search);
    }
  }

  componentDidUpdate() {
    if (this.state.selected !== this.props.initialSelected && !this.state.change) {
      this.setState({
        selected: this.props.initialSelected,
      });
    }
  }

  onSearch = (e) => {
    e.preventDefault();
    const search = e.target.value;
    this.searchList(search);
    this.setState({
      isSearchUsed: true,
    });
  }

  searchList = (search) => {
    const list = this.props.list.filter(x => !x.disabled);
    const searchedList = searchByTag(list, this.displayKey, search);
    this.setState({
      searchedList: searchedList,
    });
  }

  onSelect = (e, item) => {
    e.preventDefault();
    const selected = item[this.idKey];
    this.setState({
      selected: selected,
      change: true,
    });
  }

  onRegister = (e) => {
    e.preventDefault();
    if (this.props.saveOption) {
      const { position } = this.props.saveOption;
      this.props.onRegister(this.props.name, this.state.selected, this.state.change, position);
    } else {
      this.props.onRegister(this.props.name, this.state.selected, this.state.change);
    }
    this.props.onClose(e);
    this.searchList("");
    this.setState({
      selected: undefined,
      search: "",
      change: false,
      isSearchUsed: false,
    });
  }

  onCancel = (e) => {
    e.preventDefault();
    this.props.onClose(e);
    this.searchList("");
    this.setState({
      selected: undefined,
      search: "",
      change: false,
      isSearchUsed: false,
    });
  }

  onTagClick = (e, tag) => {
    e.preventDefault();
    this.searchList(tag);
    const searchInput = document.querySelector('#searchSelectDialog');
    if (searchInput) {
      searchInput.value = tag;
    }
  }

  // タグ対応
  renderTableComponent(classes) {
    const selectItem = {};
    const isSelectedDefaultValue = !this.props.isCreate && this.state.selected === null; // 編集時に [再生しない] [非表示] が選択されているか
    if (isSelectedDefaultValue) {
      selectItem[this.idKey] = "";
    } else {
      selectItem[this.idKey] = this.state.selected;
    }
    return (
      <EnhancedTable list={this.state.searchedList} classes={classes} headCells={this.headCells} isHeaderHidden isOperationHidden
        selectCallback={this.onSelect} selectItem={selectItem} selectKey={this.idKey} focusItem={!this.state.isSearchUsed && selectItem} focusKey={!this.state.isSearchUsed && this.idKey}
        displayTag tagClickCallback={this.onTagClick} />
    );
  }

  render() {
    const { classes } = this.props;

    // 登録ボタンの無効化条件
    const isNotSelectWhenCreate = this.props.isCreate && this.state.selected === null && !this.state.change;
    const isUndefinedSelected = this.state.selected === undefined;
    const isDiffError = !!this.props.isDiff && !this.state.change;
    const cannotRegister = isNotSelectWhenCreate || isUndefinedSelected || isDiffError;

    // 違う時の表示するもの
    const diffTarget = (this.props.list || []).find(x => (x[this.props.name] && x.id === this.state.selected));
    return (
      <StyledDialog open={this.props.open} onClose={this.onCancel} PaperProps={{ style: { width: 1144, height: "auto", minHeight: 776 } }}>
        <DialogContent className={classes.dialogContent} style={{ padding: 32 }}>
          <div className={classes.closeButton}>
            <CloseButton onClick={this.onCancel}/>
          </div>
          <div>
            <div style={{ width: "100%", textAlign: "center" }}>
              <span className="f28">{this.dialogTitle} 選択</span>
            </div>
            <div className={classes.insideEditContainer} style={{ width: 1056, padding: "32px 48px" }}>
              <div className="verticalCenter alignCenter mb30">
                <SearchTextField id="searchSelectDialog" style={{ width: "100%", maxWidth: 496 }} defaultValue="" onChange={this.onSearch} />
              </div>
              <div className={classes.selectListContainer + " mb25"} style={{ padding: 15 }}>
                <div className="mb8" style={{ height: (isDiffError && diffTarget) ? 380 : 430, overflowY: "auto" }}>
                  { this.props.type === "table" && this.renderTableComponent(classes) }
                </div>
                { (isDiffError && diffTarget) &&
                  <div style={{ textAlign: "center", color: "#F8431C" }}>
                    <span>現在の設定と異なったモデルの{this.dialogTitle}「{diffTarget[this.displayKey]}」が設定されています。<br/>一覧より{this.dialogTitle}を選択し直してください。</span>
                  </div>
                }
              </div>
              <div className="alignCenter">
                <GrayButton width={120} height={32} onClick={this.onCancel} style={{ marginRight: 20 }} >キャンセル</GrayButton>
                <PrimaryButton type="submit" width={120} height={32} onClick={this.onRegister} disabled={cannotRegister}>登録する</PrimaryButton>
              </div>
            </div>
          </div>
        </DialogContent>
      </StyledDialog>
    );
  }
}

export default withStyles(muiStyles)(SelectDialog);
