const defaultState = {
  error: null,
  isSettingMenuOpen: true,
  isUpdateMessageOpen: false,
  message: null,
};

const appReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'APP_ERROR':
      console.log(action.error);
      return {
        ...state,
        error: action.error,
      }
    case 'APP_REMOVE_ERROR':
      return {
        ...state,
        error: null,
      }
    case 'APP_SETTING_MENU':
      return {
        ...state,
        isSettingMenuOpen: action.isOpen
      }
    case 'APP_UPDATE':
      return {
        ...state,
        isUpdateMessageOpen: action.isOpen,
        message: action.message,
      }
    default:
      return state;
  }
}

export default appReducer;