import 'firebase/firestore';
import functions, { isFunctionsSdkInternalError, isTokenRevokedError } from '../utilities/functions';
import db, { getDocsOrderBy, getModelsData } from '../utilities/db';
import { appErrorAction, libraryErrorAction, offLineErrorAction } from './appAction';
import storage from '../utilities/storage';
import { formatTag } from '../utilities/utils';
import { redirectUnauthorizeUser } from './login';


export async function listFaceDetectionPhrases(dispatch, cid) {
  try {
    const companyDoc = db().collection("companies").doc(cid);
    const [items, models] = await Promise.all([
      getDocsOrderBy(companyDoc.collection("faceDetectionPhrases"), "faceDetectionPhrase", [{ column: "updatedAt", type: "desc" }]),
      getModelsData(companyDoc)
    ]);
    items.forEach(item => {
      if(item.modelRef) item.modelRef = models.find(x => x.model === item.modelRef.id);
      let interlanguage = [];
      if(item.interlanguage){
        interlanguage = JSON.parse(item.interlanguage);
      }
      item.interlanguage = interlanguage;
    });
    dispatch({ type: "FACE_DETECTION_LIST", list: items, models: models });
  } catch (e) {
    dispatch(appErrorAction(e));
  }
}

export function searchFaceDetectionPhrase(dispatch, search, searchModel, phraseType) {
  dispatch({
    type: "SEARCH_FACE_DETECTION_PHRASE",
    payload: {
      search: search,
      searchModel: searchModel,
      phraseType: phraseType,
    }
  });
}

export function beginEditFaceDetectionPhrase(dispatch, itemId, model, phraseType) {
  dispatch({ type: "FACE_DETECTION_BEGIN_EDIT", itemId: itemId, model: model, phraseType: phraseType });
}

export function editFaceDetectionPhrase(dispatch, editing, isChangeTitle) {
  dispatch({ type: "FACE_DETECTION_EDIT", editing: editing, isChangeTitle: isChangeTitle });
}

export async function getFaceDetectionPhraseMorphRequest(dispatch, cid, path, method, responseParser, options, interlanguage, isFirstMorphRequest){
  let morphData = null;
   try{
    // console.log("getMorphRequest", interlanguage);
    const res = await (functions().httpsCallable('synthesize'))({
      cid: cid,
      method: method,
      path: path,
      payload: options.query,
    });
    const data = typeof res.data === 'string' ? JSON.parse(res.data) : res.data;
    if(isFirstMorphRequest && interlanguage && interlanguage.length){
      morphData = interlanguage;
    } else {
      morphData = responseParser(data);
    }
    dispatch({
      type: "FACE_DETECTION_PHRASE_GET_MORPH",
    });
  } catch(e){
    if (isTokenRevokedError(e)) {
      redirectUnauthorizeUser(dispatch, cid);
      return;
    }
    if (!navigator.onLine) {
      dispatch(offLineErrorAction("音声合成"));
      return;
    }
    if (isFunctionsSdkInternalError(e)) {
      dispatch(libraryErrorAction(e.code));
      return;
    }
    dispatch(appErrorAction(e));
  }
  return {
    response: morphData
  }
}

export async function faceDetectionPhraseSynthesizeRequest(dispatch, cid, path, method, responseParser, payload, isTestPlayMode, editing){
  let audio = null;
  try{
    // console.log("synthesizeRequest");
    const res = await (functions().httpsCallable('synthesize'))({
      cid: cid,
      method: method,
      path: path,
      payload: payload
    });
    audio = responseParser(res.data);
    if (!isTestPlayMode) {
      // テストモードでない場合は登録モード
      if (editing.faceDetectionPhrase) {
        const exists = await faceDetectionPhraseExists(dispatch, cid, editing.faceDetectionPhrase);
        if (!exists) return;
      }
      await (functions().httpsCallable('createAudioFile'))({
        //この行がなければ新規音声、あれば上書き、非実在ID指定時はエラー
        executeType: "faceDetectionPhrases",
        phrase_id: editing.faceDetectionPhrase,
        cid: cid,
        phrase_type: editing.phraseType,
        phrase_title: editing.phraseTitle,
        tag: formatTag(editing.tag),
        interlanguage: payload.morph || [],
        phrase_language: 'ja',
        model_ref: editing.modelRef,
        audio_content: audio
      });
      dispatch({
        type: "FACE_DETECTION_PHRASE_SYNTHESIZE",
        synthesizeAudio: audio,
        editing: editing,
      });
    }
  } catch(e) {
    if (isTokenRevokedError(e)) {
      redirectUnauthorizeUser(dispatch, cid);
      return;
    }
    if (!navigator.onLine) {
      dispatch(offLineErrorAction("音声作成"));
      return;
    }
    if (isFunctionsSdkInternalError(e)) {
      dispatch(libraryErrorAction(e.code));
      return;
    }
    // 削除されている可能性があり
    const exists = await faceDetectionPhraseExists(dispatch, cid, editing.faceDetectionPhrase);
    if (!exists) return;
    dispatch(appErrorAction("音声作成に失敗しました"));
    return;
  }
  return {
    response: audio
  };
}

export async function deleteFaceDetectionPhrase(dispatch, cid, phrase){
  try{
    // console.log(phrase);
    const phraseDoc = db().collection("companies").doc(cid).collection("faceDetectionPhrases").doc(phrase);
    const exists = await faceDetectionPhraseExists(dispatch, cid, phrase);
    if (!exists) return;
    await phraseDoc.delete();
    const fileName = cid + "/faceDetectionPhrases/" + phraseDoc.id + ".wav";
    const phraseStorage = storage().ref();
    await phraseStorage.child(fileName).delete();
    dispatch({
      type: "FACE_DETECTION_PHRASE_DELETED",
    })
  } catch(e){
    if (e && e.code === 'storage/object-not-found') {
      dispatch(faceDetectionPhraseNotFoundErrorAction());
      dispatch(faceDetectionPhraseRefreshAction());
      return;
    }
    dispatch(appErrorAction(e));
  }
}

export async function getFaceDetectionPhraseUrl(dispatch, item){
  const phraseStorage = storage().ref();
  if(item){
    const playPhraseUrl = await phraseStorage.child(item.phrasePath).getDownloadURL();
    dispatch({
      type: "FACE_DETECTION_PHRASE_PLAY_URL",
      playPhraseUrl: playPhraseUrl,
    });
  } else {
    dispatch({
      type: "FACE_DETECTION_PHRASE_PLAY_URL",
      playPhraseUrl: null,
    });
  }
}

export async function setSynthesizedFaceDetectionPhrase(dispatch, item) {
  dispatch({
    type: "FACE_SYNTHESIZED_FACE_DETECTION_PHRASE",
    synthesizedPhrase: item,
  });
}

export async function allCopyExecute(dispatch, cid, originalModel, targetModel){
  try{
    await (functions().httpsCallable('phraseAllCopyExecute'))({
      cid: cid,
      originalModel: originalModel,
      targetModel: targetModel,
      collectionName: "faceDetectionPhrases",
    });
    dispatch({
      type: "FACE_DETECTION_PHRASE_ALL_COPY"
    })
  } catch(e){
    if (isTokenRevokedError(e)) {
      redirectUnauthorizeUser(dispatch, cid);
      return;
    }
    if (!navigator.onLine) {
      dispatch(offLineErrorAction("コピー実行"));
      return;
    }
    if (isFunctionsSdkInternalError(e)) {
      dispatch(libraryErrorAction(e.code));
      return;
    }
    dispatch(appErrorAction(e));
  }
}

export async function faceDetectionPhraseExists(dispatch, cid, faceDetectionPhraseId) {
  const data = await db().collection("companies").doc(cid).collection("faceDetectionPhrases").doc(faceDetectionPhraseId).get();
  if (!data.exists) {
    dispatch(faceDetectionPhraseNotFoundErrorAction());
    dispatch(faceDetectionPhraseRefreshAction());
    return false;
  }
  return true;
}

function faceDetectionPhraseRefreshAction() {
  return { type: "FACE_DETECTION_PHRASE_REFRESH" };
}

function faceDetectionPhraseNotFoundErrorAction() {
  return appErrorAction(["指定された顔認証音声フレーズは存在しません。","他のアカウントにより削除された可能性があります。"]);
}
