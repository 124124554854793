import firebase from 'firebase/app';
import 'firebase/firestore';
import db, { getDocsOrderBy } from '../utilities/db';
import storage from '../utilities/storage';
import { getMD5, formatTag } from '../utilities/utils';
import { appErrorAction, dbSetDataFailedErrorAction } from './appAction';

export async function guidePictures(dispatch, cid, label) {
  try {
    const guidePictures = await getDocsOrderBy(db().collection("companies").doc(cid).collection("labels").doc(label).collection("guide-pictures"), "picture", [{ column: "updatedAt", type: "desc" }]);
    dispatch(guidesAction(guidePictures.filter(x => x.picture !== "stay")));
  } catch (e) {
    dispatch(appErrorAction(e));
  }
}

export function searchGuidePicture(dispatch, search) {
  dispatch({
    type: "SEARCH_GUIDE_PICTURE",
    payload: {
      search: search,
    }
  });
}

export async function getPictureUrl(dispatch, pictureItem){
  const pictureStorage = storage().ref();
  if(pictureItem){
    const showPictureUrl = await pictureStorage.child(pictureItem.picturePath).getDownloadURL();
    dispatch({
      type: "PICTURE_SHOW_URL",
      showPictureUrl: showPictureUrl,
    });
  } else {
    dispatch({
      type: "PICTURE_SHOW_URL",
      showPictureUrl: null,
    });
  }
}

export async function editGuidePicture(dispatch, editingGuidePicture) {
  // console.log(editingGuidePicture);
  const pictureFile = editingGuidePicture.pictureFile;
  editingGuidePicture.name = pictureFile.name;
  dispatch(editGuidePictureAction(editingGuidePicture));
}

export async function beginEditGuidePicture(dispatch, picture) {
  dispatch(beginEditGuidePictureAction(picture));
}

function loadImage(file) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    const reader = new FileReader();
    reader.onload = () => {
      image.onload = () => {
        resolve(image);
      };
      image.onerror = () => {
        resolve(undefined);
      };
      image.src = reader.result;
    };
    reader.onerror = () => {
      reject(reader.error);
    };
    reader.readAsDataURL(file);
  });
}

export async function updateGuidePicture(dispatch, cid, label, editingGuidePicture) {
  // console.log(editingGuidePicture);
  let guidePicturesDoc;
  try {
    const file = editingGuidePicture.pictureFile;
    if (file) {
      const image = await loadImage(file);
      if (!image || !image.naturalHeight || !image.naturalWidth) {
        dispatch(appErrorAction("選択されているファイルは画像ではない可能性があります。"));
        return false;
      }
      // typeは拡張子から計算されており、これは単純な拡張子チェックで、厳密なMIMEチェックではない
      if (file.type !== "image/png" && file.type !== "image/jpeg") {
        dispatch(appErrorAction("選択されているファイルの拡張子が不正です。"));
        return false;
      }
      if (image.naturalHeight > 4000 || image.naturalWidth > 4000) {
        dispatch(appErrorAction(["選択されている画像が大きすぎます。", "大きさは4000x4000以下である必要があります。"]));
        return false;
      }
    }

    const data = {
      title: editingGuidePicture.title,
      tag: formatTag(editingGuidePicture.tag),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };
    if (!editingGuidePicture.picture) {
      // 新規
      guidePicturesDoc = db().collection("companies").doc(cid).collection("labels").doc(label).collection("guide-pictures").doc();
      data.createdAt = firebase.firestore.FieldValue.serverTimestamp();
    } else {
      // 更新
      guidePicturesDoc = db().collection("companies").doc(cid).collection("labels").doc(label).collection("guide-pictures").doc(editingGuidePicture.picture);
      const exists = await guideExists(dispatch, cid, label, guidePicturesDoc);
      if (!exists) return false;
    }
    // 画像アップロードとデータの更新
    if(file){
      const ext = file.name.split('.').pop();
      const fileName = cid + "/pictures/" + guidePicturesDoc.id + "." + ext;
      const md5 = await getMD5(editingGuidePicture.pictureFile);
      const pictureRef = storage().ref();
      // console.log(pictureRef);
      await pictureRef.child(fileName).put(editingGuidePicture.pictureFile);
      data.pictureMd5 = md5;
      data.picturePath = fileName;
      // data.pictureUrl = await pictureRef.child(fileName).getDownloadURL();
    }
    // console.log(data);
    if (!editingGuidePicture.picture) {
      // 新規作成
      await guidePicturesDoc.set(data);
    } else {
      // 更新
      await guidePicturesDoc.update(data);
    }
    dispatch(updateGuidePictureAction());
    return true;
  } catch(e) {
    // セキュリティールールありだとpermission-denied、なしだとnot-foundエラーが返る
    if (e && e.name === 'FirebaseError' && (e.code === 'permission-denied' || e.code === 'not-found')) {
      if (guidePicturesDoc && editingGuidePicture.picture) {
        // 更新時
        const exists = await guideExists(dispatch, cid, label, guidePicturesDoc);
        if (!exists) return false;
      }
      dispatch(dbSetDataFailedErrorAction());
      return false;
    }
    dispatch(appErrorAction(e));
  }
  return false;
}

export async function deleteGuidePicture(dispatch, cid, label, picture, deleteFileName) {
  try{
    const guidePictureDoc = db().collection("companies").doc(cid).collection("labels").doc(label).collection("guide-pictures").doc(picture);
    const exists = await guideExists(dispatch, cid, label, guidePictureDoc);
    if (!exists) return;
    let canDelete = true;
    for(let labelDoc of (await db().collection("companies").doc(cid).collection("labels").get()).docs){
      for(let characterDoc of (await labelDoc.ref.collection("characters").get()).docs){
        const [manualCommands, contents, externalCommands] = await Promise.all([
          characterDoc.ref.collection("manualCommands").where("pictureRef", "==", guidePictureDoc).get(),
          characterDoc.ref.collection("contents").where("pictureRef", "==", guidePictureDoc).get(),
          characterDoc.ref.collection("externalCommands").get(),
        ]);
        let containExternalCommand = false;
        for(let ec of externalCommands.docs){
          if(ec.data().pictureRef && ec.data().pictureRef.id === guidePictureDoc.id){
            containExternalCommand = true;
          } else if(ec.data().callSuccess && ec.data().callSuccess.pictureRef && ec.data().callSuccess.pictureRef.id === guidePictureDoc.id){
            containExternalCommand = true;
          } else if(ec.data().callFail && ec.data().callFail.pictureRef && ec.data().callFail.pictureRef.id === guidePictureDoc.id){
            containExternalCommand = true;
          } else if(ec.data().callReject && ec.data().callReject.pictureRef && ec.data().callReject.pictureRef.id === guidePictureDoc.id){
            containExternalCommand = true;
          }
          if(containExternalCommand) break;
        }
        if(!manualCommands.empty || !contents.empty || containExternalCommand){
          canDelete = false;
          break;
        }
      }
      if(!canDelete) break;
    }
    // console.log(canDelete);
    if(canDelete){
      const ext = deleteFileName.split('.').pop();
      await guidePictureDoc.delete();
      const fileName = cid + "/pictures/" + guidePictureDoc.id + "." + ext;
      const pictureRef = storage().ref();
      await pictureRef.child(fileName).delete();
      dispatch(deleteGuidePictureAction(picture));
    } else {
      dispatch(appErrorAction(["この案内画像は", "他から参照されているので削除できません。"]));
    }
  } catch(e) {
    if (e && e.code === 'storage/object-not-found') {
      dispatch(guidePuctureNotFoundErrorAction());
      dispatch(refreshGuidePictureAction());
      return;
    }
    dispatch(appErrorAction(e));
  }
}

async function guideExists(dispatch, cid, label, guidePictureDoc) {
  const guidePicture = await guidePictureDoc.get();
  if (!guidePicture.exists) {
    dispatch(guidePuctureNotFoundErrorAction());
    dispatch(refreshGuidePictureAction());
    return false;
  }
  return true;
}

export async function closeEditGuide(dispatch){
  dispatch({ type: "GUIDE_CLOSE_EDIT" });
}

function guidesAction(guidePictures) {
  return {
    type: 'GUIDES',
    payload: {
      guidePictures: guidePictures
    }
  }
}

function beginEditGuidePictureAction(picture) {
  return {
    type: 'BEGIN_EDIT_GUIDE_PICTURE',
    payload: {
      picture: picture,
    }
  }
}

function editGuidePictureAction(editingGuidePicture) {
  return {
    type: 'EDIT_GUIDE_PICTURE',
    payload: {
      guidePictures: editingGuidePicture,
    }
  }
}

function updateGuidePictureAction() {
  return {
    type: 'UPDATE_GUIDE_PICTURE',
  }
}

function deleteGuidePictureAction(picture) {
  return {
    type: 'DELETE_GUIDE_PICTURE',
    payload: {
      picture: picture,
    }
  }
}

function refreshGuidePictureAction() {
  return {
    type: 'REFRESH_GUIDE_PICTURE',
  };
}

function guidePuctureNotFoundErrorAction() {
  return appErrorAction(["指定された案内画像は存在しません。","他のアカウントにより削除された可能性があります。"]);
}
