import db, { getDocData, getDocsOrderBy, getModelsData, getMotionsData, getPhrasesData } from "../utilities/db";
import firebase from 'firebase/app';
import { appErrorAction, dbSetDataFailedErrorAction } from './appAction';
import { checkModel } from "../utilities/utils";
import { characterExists, characterNotFoundErrorAction } from "./characters";

export const GREETING_TYPE_NEAR = "nearZone";
export const GREETING_TYPE_MID = "midZone";
const GREETING_TYPES = [
  GREETING_TYPE_NEAR,
  GREETING_TYPE_MID
];

export async function listGreetings(dispatch, cid, label, character, characterNotFoundCallback, isInit=false){
  try{
    // console.log(cid, label, character);
    const companyDoc = db().collection("companies").doc(cid);
    const characterDoc = companyDoc.collection("labels").doc(label).collection("characters").doc(character);
    let par = [
      getModelsData(companyDoc),
      getDocData(characterDoc, "character"),
      getPhrasesData(companyDoc),
      getMotionsData(companyDoc),
    ];
    const [models, characterData, phrases, motions] = await Promise.all(par);
    if (characterData === null) {
      dispatch(characterNotFoundErrorAction());
      characterNotFoundCallback();
      return;
    }
    characterData.modelRef = characterData.modelRef && models.find(x => x.model === characterData.modelRef.id);
    phrases.forEach(item => { if(item.modelRef) item.modelRef = models.find(x => x.model === item.modelRef.id) });
    motions.forEach(item => { if(item.modelRef) item.modelRef = models.find(x => x.model === item.modelRef.id) });
    let greetings = {};
    await Promise.all(GREETING_TYPES.map( async t => {
      const items = await getDocsOrderBy(characterDoc.collection(t + "Greetings"), "greeting", [{ column: "updatedAt", type: "desc" }])
      items.forEach(item => {
        item.greetingType = t;
        // フレーズなどはDocumentReferenceが入ってくるので、それぞれの一覧用配列から値を参照できるようにデータを持ってくる
        if(item.phraseRef) item.phraseRef = phrases.find(x => x.phrase === item.phraseRef.id);
        if(item.motionRef) item.motionRef = motions.find(x => x.motion === item.motionRef.id);
      });
      greetings[t] = items;
    }));
    // モデルと設定されているフレーズやモーションが異なるか確認
    GREETING_TYPES.forEach(t => {
      checkModel(greetings[t], characterData.modelRef.model);
    });
    dispatch(listGreetingsAction(characterData, greetings, phrases, motions, isInit));
  } catch(e){
    dispatch(appErrorAction(e));
  }
}

export function searchGreeting(dispatch, search, greetingType){
  dispatch({
    type: "GREETING_SEARCH",
    payload: {
      search: search,
      greetingType: greetingType
    }
  });
}

export function beginEditGreeting(dispatch, type, greeting){
  dispatch(beginEditGreetingAction(type, greeting));
}

export async function changeEditingGreeting(dispatch, editingGreeting){
  dispatch({
    type: "CHANGE_EDITING_GREETING",
    payload: {
      editingGreeting: editingGreeting,
    }
  });
}

export async function updateGreetingExec(dispatch, cid, label, characterData, editingGreeting, characterNotFoundCallback){
  // console.log(cid, label, characterData, editingGreeting, greetingType);
  let greetingDoc;
  try{
    const companyDoc = db().collection("companies").doc(cid)
    const characerDoc = companyDoc.collection("labels").doc(label).collection("characters").doc(characterData.character);
    const greetingType = editingGreeting.greetingType;
    const data = {};
    data.title = editingGreeting.title;
    data.timeZones = editingGreeting.timeZones;
    if(greetingType === GREETING_TYPE_MID){
      data.virtualwallNo = editingGreeting.virtualwallNo;
      data.virtualwallCourse = editingGreeting.virtualwallCourse;
    }
    data.motionRef = editingGreeting.motionRef ? companyDoc.collection("motions").doc(editingGreeting.motionRef) : null;
    data.phraseRef = editingGreeting.phraseRef ? companyDoc.collection("phrases").doc(editingGreeting.phraseRef): null;
    data.playType = editingGreeting.playType;
    data.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
    if(!editingGreeting.greeting){
      // 新規作成
      data.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      greetingDoc = characerDoc.collection(greetingType + "Greetings").doc();
      await greetingDoc.set(data);
    } else {
      // 更新
      greetingDoc = characerDoc.collection(greetingType + "Greetings").doc(editingGreeting.greeting);
      const exists = await greetingExists(dispatch, greetingDoc);
      if (!exists) return false;
      await greetingDoc.update(data);
    }
    const updated = await greetingDoc.get();
    if (!updated.exists) {
      dispatch(greetingNotFoundErrorAction());
      dispatch(refreshGreetingAction());
      return false;
    }
    editingGreeting.createdAt = updated.data().createdAt;
    editingGreeting.updatedAt = updated.data().updatedAt;
    editingGreeting.greeting = greetingDoc.id;
    dispatch(updatedGreetingAction(editingGreeting));
    return true;
  } catch(e) {
    // セキュリティールールありだとpermission-denied、なしだとnot-foundエラーが返る
    if (e && e.name === 'FirebaseError' && (e.code === 'permission-denied' || e.code === 'not-found')) {
      const exists = await characterExists(dispatch, cid, label, characterData.character);
      if (!exists) {
        characterNotFoundCallback();
        return false;
      }
      if (editingGreeting.greeting && greetingDoc) {
        // 更新の場合
        const editingGreetingExists = await greetingExists(dispatch, greetingDoc);
        if (!editingGreetingExists) return false;
      }
      dispatch(dbSetDataFailedErrorAction());
      return false;
    }
    dispatch(appErrorAction(e));
  }
  return false;
}

export async function deleteGreetingExec(dispatch, cid, label, characterData, greetingType, greeting){
  // console.log(cid, label, characterData, greetingType, greeting);
  try{
    const greetingDoc = db().collection("companies").doc(cid).collection("labels").doc(label).collection("characters").doc(characterData.character).collection(greetingType + "Greetings").doc(greeting);
    const exists = await greetingExists(dispatch, greetingDoc);
    if (!exists) return;
    await greetingDoc.delete();
    dispatch(deleteGreetingAction(greetingType, greeting));
  } catch(e){
    dispatch(appErrorAction(e));
  }
}

async function greetingExists(dispatch, greetingDoc) {
  const greeting = await greetingDoc.get();
  if (!greeting.exists) {
    dispatch(greetingNotFoundErrorAction());
    dispatch(refreshGreetingAction());
    return false;
  }
  return true;
}

export async function closeGreeting(dispatch){
  dispatch({ type: "GREETING_CLOSE" });
}

export function cancelEditGreeting(dispatch){
  dispatch({ type: "GREETING_EDIT_CANCEL" });
}

function listGreetingsAction(characterData, greetings, phrases, motions, isInit){
  return {
    type: "LIST_GREETINGS",
    payload: {
      characterData: characterData,
      greetings: greetings,
      phrases: phrases,
      motions: motions,
      isInit: isInit,
    }
  }
}

function beginEditGreetingAction(type, greeting){
  return {
    type: "BEGIN_EDIT_GREETING",
    payload: {
      greetingType: type,
      greeting: greeting,
    }
  }
}

function updatedGreetingAction(editingGreeting){
  return {
    type: "UPDATED_GREETING",
    editing: editingGreeting,
  }
}

function deleteGreetingAction(greetingType, greeting){
  return {
    type: "DELETED_GREETING",
    payload: {
      greetingType: greetingType,
      greeting: greeting,
    }
  }
}

function refreshGreetingAction() {
  return { type: "REFRESH_GREEDING" };
}

function greetingNotFoundErrorAction() {
  return appErrorAction(["指定された挨拶は存在しません。","他のアカウントにより削除された可能性があります。"]);
}
