const defaultState = {
  callMode: {},
  callingData: {
    mode: null,
    client: null,
    stream: null,
    remoteStreams: [],
  },
  volume: 100,
  isMute: false,
};

const calling = (state = defaultState, action) => {
  let callMode, callingData;
  switch (action.type) {
    case "VOICE_CALLING_START_CALL":
      callMode = {...state.callMode};
      callMode[action.vguard] = { status: "start", mode: "call" };
      return {
        ...state,
        callMode: callMode,
      }
    case "VOICE_CALLING_STOP_CALL":
      callMode = {...state.callMode};
      callMode[action.vguard] = { status: "stop", mode: null };
      return {
        ...state,
        callMode: callMode,
      }
    case "VOICE_CALLING_START_BROADCAST_CALL":
      callMode = {...state.callMode};
      action.vguards.forEach(x => {
        callMode[x.vguard] = { status: "start", mode: "broadcast" };
      });
      return {
        ...state,
        callMode: callMode,
      }
    case "VOICE_CALLING_STOP_BROADCAST_CALL":
      return {
        ...state,
        callMode: {},
      }
    case "VOICE_CALLING_START_SUCCESS":
      return {
        ...state,
        callingData: {
          mode: action.payload.mode,
          client: action.payload.client,
          stream: action.payload.stream,
          remoteStreams: [],
        }
      }
    case "VOICE_CALLING_RECEIVE_SUCCESS":
      callMode = {...state.callMode};
      callMode[action.vguard] = { status: "start", mode: "call", isReceive: true, };
      return {
        ...state,
        callMode: callMode,
        callingData: {
          mode: action.payload.mode,
          client: action.payload.client,
          stream: action.payload.stream,
          remoteStreams: [],
        }
      }
    case "VOICE_CALLING_STOP_SUCCESS":
      return {
        ...state,
        callingData: {
          mode: null,
          client: null,
          stream: null,
          remoteStreams: [],
        }
      }
    case "VOICE_CALLING_ADD_REMOTE_STREAM":
      callingData = {...state.callingData};
      callingData.remoteStreams = [...callingData.remoteStreams, action.payload.remoteStream];
      return {
        ...state,
        callingData: callingData
      }
    case "VOICE_CALLILNG_REMOVE_REMOTE_STREAM":
      callingData = {...state.callingData};
      callingData.remoteStreams = callingData.remoteStreams.filter(x => x.getId() !== action.payload.remoteStream.getId());
      return {
        ...state,
        callingData: callingData
      }
    case "VOICE_CALLING_VOLUME_CHANGE":
      return {
        ...state,
        volume: action.payload.volume,
      }
    case "VOICE_CALLING__MUTE_CHANGE":
      return {
        ...state,
        isMute: action.payload.isMute,
      }
    default:
      return state;
  }
};

export default calling;
