import React from "react";
import { FormControl, FormHelperText, FormControlLabel, Checkbox, Grid, Tooltip } from "@material-ui/core";
import { CustomSwitch, StyledTextField, StyledTextFieldMd, StyledTextFieldSm, StyledTextFieldBg, StyledTextFieldCameraQuery, StyledSelect, StyledFormControl, StyledFormControlMd, StyledFormControlSm, StyledFormControlBg, StyledFormControlPhrase, StyledTextFieldPhrase, StyledTextFieldSmRadius } from "./AppParts";
import NumberFormat from 'react-number-format';

const DECIMAL_DATA=["settings.idlingEyesSpeed", "settings.nearZone.distance", "settings.midZone.speed", "settings.childTallHeight",
  "settings.midZone.virtualwall01X1", "settings.midZone.virtualwall01X2", "settings.midZone.virtualwall01Z1", "settings.midZone.virtualwall01Z2",
  "settings.midZone.virtualwall02X1", "settings.midZone.virtualwall02X2", "settings.midZone.virtualwall02Z1", "settings.midZone.virtualwall02Z2",
  "settings.midZone.virtualwall03X1", "settings.midZone.virtualwall03X2", "settings.midZone.virtualwall03Z1", "settings.midZone.virtualwall03Z2",
];

const renderFromHelper = ({ touched, error }) => {
  if (!(touched && error)) {
    return
  } else {
    return <FormHelperText>{touched && error}</FormHelperText>
  }
}

export const renderTextField = ({
  label,
  input,
  placeholder,
  tooltip,
  labelGirdSize,
  suffixText,
  size,
  uncontrolled, // uncontrolled指定の場合はonChangeで変更をhandlingする必要あり(NumberFormatはUncontrollにならないが数字しか入れないので問題ない)
  numberFormat,
  disabled,
  meta: { touched, invalid, error },
  ...custom
}) => {
  if(!labelGirdSize) labelGirdSize = 2;
  const formGridSize = 12 - labelGirdSize;
  let ComponentName = StyledTextField;
  switch(size){
    case "md":
      ComponentName = StyledTextFieldMd;
      break;
    case "sm":
      ComponentName = StyledTextFieldSm;
      break;
    case "bg":
      ComponentName = StyledTextFieldBg;
      break;
    default:
      ComponentName = StyledTextField;
      break;
  }
  let customInput = input;
  if (uncontrolled) {
    customInput.defaultValue = input.value;
    delete customInput.value;
  }
  return(
    <Grid container spacing={2}>
      <Grid item xs={labelGirdSize} className="formLabel">
        <span className="labelText">{label}</span>
        {tooltip &&
        <Tooltip placement="top" title="tooltip" classes={{ tooltip: "tooltip" }}>
          <span className="primaryText">[ ? ]</span>
        </Tooltip>
        }
      </Grid>
      <Grid item xs={formGridSize}>
        <div>
          { numberFormat ?
            <div className="thousandSeparator" style={{ display: 'inline-block', ...custom.style }}>
              <NumberFormat
                thousandSeparator={true}
                className="thousandSeparatorInput"
                allowNegative={false}
                decimalScale={DECIMAL_DATA.includes(input.name) ? 2 : 0}
                style={custom.inputProps ? custom.inputProps.style : null}
                disabled={disabled}
                {...customInput}
              />
            </div>
          :
            <ComponentName variant="outlined"
              placeholder={placeholder}
              error={touched && invalid}
              helperText={touched && error}
              inputProps={{ autoComplete: "new-password", form: { autoComplete: "off" } }}
              disabled={disabled}
              {...input}
              {...customInput}
            />
          }
          { suffixText &&
            <span className="vguardSuffixText" style={{ lineHeight: "2" }}>{suffixText}</span>
          }
          { (numberFormat && touched && error) &&
            <span style={{ color: "#e53935", marginLeft: 5, fontSize: "0.64em", fontWeight: 700 }}>{ error }</span>
          }
        </div>
      </Grid>
    </Grid>
  );
}
export const renderSimpleTextField = ({
  label,
  input,
  placeholder,
  size,
  lineHeight,
  newLine,
  meta: { touched, invalid, error },
  uncontrolled, // uncontrolled指定の場合はonChangeで変更をhandlingする必要あり(NumberFormatはUncontrollにならないが数字しか入れないので問題ない)
  numberFormat,
  disabled,
  style,
  ...custom
}) => {
  let ComponentName = StyledTextField;
  switch(size){
    case "md":
      ComponentName = StyledTextFieldMd;
      break;
    case "sm":
      ComponentName = StyledTextFieldSm;
      break;
    case "sm_radius":
      ComponentName = StyledTextFieldSmRadius;
      break;
    case "bg":
      ComponentName = StyledTextFieldBg;
      break;
    case "cameraQuery":
      ComponentName = StyledTextFieldCameraQuery;
      break;
    default:
      ComponentName = StyledTextField;
      break;
  }
  let customInput = input;
  if (uncontrolled) {
    customInput.defaultValue = input.value;
    delete customInput.value;
  }
  return(
    <React.Fragment>
      { numberFormat ?
        <div>
          <div className="thousandSeparator" style={style}>
            <NumberFormat
              thousandSeparator={true}
              className="thousandSeparatorInput"
              allowNegative={custom.allowNegative || false}
              decimalScale={DECIMAL_DATA.includes(input.name) ? 2 : 0}
              style={custom.inputProps ? custom.inputProps.style : null}
              disabled={disabled}
              {...customInput}
              // {...custom}
            />
          </div>
          { (touched && error) &&
            <p style={{ color: "#e53935", margin: "0 0 0 5px", fontSize: "0.64em", fontWeight: 700, marginLeft: style.marginLeft ? style.marginLeft : "none" }}>{ error }</p>
          }
        </div>
      :
        <ComponentName variant="outlined"
          placeholder={placeholder}
          error={touched && invalid}
          helperText={touched && error}
          autoComplete='off'
          inputProps={{ autoComplete: "new-password", form: { autoComplete: "off" }, style: { lineHeight: lineHeight ? lineHeight : null } }}
          multiline={newLine ? true : false}
          rows={newLine}
          disabled={disabled}
          style={style}
          {...customInput}
          {...custom}
        />
      }
    </React.Fragment>
  );
}


export const renderSelectField = ({
  input,
  label,
  tooltip,
  labelGirdSize,
  meta: { touched, error },
  children,
  size,
  ...custom
}) => {
  if(!labelGirdSize) labelGirdSize = 2;
  const formGridSize = 12 - labelGirdSize;
  let ComponentName = StyledFormControl;
  switch(size){
    case "bg":
      ComponentName = StyledFormControlBg;
      break;
    case "md":
      ComponentName = StyledFormControlMd;
      break;
    case "sm":
      ComponentName = StyledFormControlSm;
      break;
    default:
      ComponentName = StyledFormControl;
      break;
  }
  return(
      <Grid container spacing={2}>
        <Grid item xs={labelGirdSize} className="formLabel">
        <span className="labelText">{label}</span>
        {tooltip &&
        <Tooltip placement="top" title={tooltip} classes={{ tooltip: "tooltip" }}>
          <span className="primaryText">[ ? ]</span>
        </Tooltip>
        }
      </Grid>
      <Grid item xs={formGridSize}>
        <ComponentName error={(touched && error)? true : false} variant="outlined" style={{ width: custom.width ? custom.width : "100%" }}>
          <StyledSelect
            {...input}
            {...custom}
          >
            {children}
          </StyledSelect>
          {renderFromHelper({ touched, error })}
        </ComponentName>
      </Grid>
    </Grid>
  )
}
export const renderSimpleSelectField = ({
  input,
  label,
  meta: { touched, error },
  children,
  size,
  textcolor,
  ...custom
}) => {
  let ComponentName = StyledFormControl;
  switch(size){
    case "bg":
      ComponentName = StyledFormControlBg;
      break;
    case "md":
      ComponentName = StyledFormControlMd;
      break;
    case "sm":
      ComponentName = StyledFormControlSm;
      break;
    case "phrase":
      ComponentName = StyledFormControlPhrase;
      break;
    default:
      ComponentName = StyledFormControl;
      break;
  }
  let SelectComponentName = StyledTextFieldPhrase;
  let value = "[再生しない]";
  if (size === "phrase") {
    children[1].forEach((item) => {
      if (input.value === item.props.value) {
        value = item.props.children;
      }
    });
  }
  const selectStyles = {};
  if(textcolor){
    selectStyles.color = textcolor;
    if(textcolor === "red" || textcolor === "#F8431C") selectStyles.fontWeight = "bold";
  }
  return(
    <React.Fragment>
      { size === "phrase" ?
      <div style={{ height: 44 }}>
        <SelectComponentName
          variant="outlined"
          autoComplete='off'
          className={(value.length > 34 ? "multilineMask " : "") + ((textcolor === "red" || textcolor === "#F8431C") ? "quoteRed" : "")}
          style={{...custom.style}}
          inputProps={{ autoComplete: "new-password", form: { autoComplete: "off" }, tabIndex: "-1", style: { fontSize: 12, ...selectStyles} }}
          InputProps={{ style: { ...custom.SelectDisplayProps.style } }}
          multiline={true}
          rowsMax={2}
          value={value}
          onChange={input.onChange}
        />
        <ComponentName error={(touched && error)? true : false} variant="outlined">
          <StyledSelect
            {...input}
            {...custom}
            style={{...custom.style, ...selectStyles}}
          >
            {children}
          </StyledSelect>
          {renderFromHelper({ touched, error })}
        </ComponentName>
      </div>
      :
        <ComponentName error={(touched && error)? true : false} variant="outlined" style={{ width: custom.width ? custom.width : "100%" }}>
          <StyledSelect
            {...input}
            {...custom}
            style={{...custom.style, ...selectStyles}}
          >
            {children}
          </StyledSelect>
          {renderFromHelper({ touched, error })}
        </ComponentName>
      }
    </React.Fragment>
  )
}

export const SelectArrow = (props) =>{
  const baseWidth = 14, baseHeight = 8;
  let width = 14, height = 8;
  switch(props.size){
    case "sm":
      width = 8;
      height = 4;
      break;
    case "md":
      width = 12;
      height = 6;
      break;
    case "bg":
      width = 14;
      height = 8;
      break;
    default:
      break;
  }
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: 10 }}>
      <path transform={`scale(${width/baseWidth}, ${height/baseHeight})`} d="M1 1L7 7L13 1" stroke="#38496D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export const renderCheckbox = ({
  input,
  disabled,
  label,
  groupTitle,
  tooltip,
  labelGirdSize
}) => {
  if(!labelGirdSize) labelGirdSize = 2;
  const formGridSize = 12 - labelGirdSize;
  return(
    <Grid container spacing={2}>
      <Grid item xs={labelGirdSize} className="formLabel">
        {groupTitle}
        {tooltip &&
        <Tooltip placement="top" title={tooltip} classes={{ tooltip: "tooltip" }}>
          <span className="primaryText">[ ? ]</span>
        </Tooltip>
        }
      </Grid>
      <Grid item xs={formGridSize}>
        <FormControlLabel
          control={
              <Checkbox
                checked={input.value ? true : false}
                onChange={input.onChange}
                disabled={disabled}
                {...input}
              />
          }
          label={label}
        />
      </Grid>
    </Grid>
  );
}

export const renderSimpleCheckbox = ({
  input,
  disabled,
  label,
  meta: { touched, error },
}) => {
  return(
    <React.Fragment>
      <FormControlLabel
        error={(touched && error)? true : false}
        control={
          <Checkbox
            checked={input.value ? true : false}
            onChange={input.onChange}
            disabled={disabled}
            {...input}
          />
        }
        label={label}
      />
      {renderFromHelper({ touched, error })}
    </React.Fragment>
  );
}

export const renderSimpleError = ({
  labelGirdSize,
  meta: { touched, error },
}) => {
  if(!touched || !error) return null;
  if(!labelGirdSize) labelGirdSize = 2;
  const formGridSize = 12 - labelGirdSize;
  return(
    <Grid container spacing={2}>
      <Grid item xs={labelGirdSize} className="formLabel">
      </Grid>
      <Grid item xs={formGridSize}>
        <FormControl error={(touched && error)? true : false} variant="outlined" style={{ width: "100%" }}>
          {renderFromHelper({ touched, error })}
        </FormControl>
      </Grid>
    </Grid>
  );
}

export const renderSwitch = ({
  input,
  disabled,
  label,
  tooltip,
  labelGirdSize
}) => {
  if(!labelGirdSize) labelGirdSize = 2;
  const formGridSize = 12 - labelGirdSize;
  return(
    <Grid container spacing={2}>
      <Grid item xs={labelGirdSize} className="formLabel">
        <span className="labelText">{label}</span>
        {tooltip &&
        <Tooltip placement="top" title={tooltip} classes={{ tooltip: "tooltip" }}>
          <span className="primaryText">[ ? ]</span>
        </Tooltip>
        }
      </Grid>
      <Grid item xs={formGridSize}>
        <CustomSwitch
          checked={input.value ? true : false}
          disabled={disabled}
          {...input}
        />
      </Grid>
    </Grid>
  );
}

export const renderSimpleSwitch = ({
  input,
  disabled,
}) => {
  return(
    <React.Fragment>
      <CustomSwitch
        checked={input.value ? true : false}
        disabled={disabled}
        {...input}
      />
    </React.Fragment>
  )
}
