import { isEqualExceptFalsy, searchByTag } from "../utilities/utils";

const defaultState = {
  keywordGroups: [],
  searchedKeywordGroups: [],
  search: "",
  originalKeywordGroup: null,
  editingKeywordGroup: null,
  editedId: null,
  synonymList: [],
  originalMorphemeList: [],
  morphemeList: [],
  isRefresh: false,
  synonymTime: 0,
  morphemeTime: 0,
};

function defaultKeywordGroup() {
  return {
    keywordGroup: "",
    title: "",
    keywords: [], // 本来は音声認識キーワードの基本形で構成された配列
    isEditingUpdate: false,
  };
}

function searchList(list, search) {
  let searchedList = [ ...list ];
  if (search) {
    searchedList = searchByTag(searchedList, 'title', search);
  }
  return searchedList;
}

const keywordGroups = (state = defaultState, action) => {
  let editingKeywordGroup, keywordGroup;
  switch(action.type) {
    case 'KEYWORD_GROUPS':
      return {
        ...state,
        keywordGroups: action.payload.keywordGroups,
        searchedKeywordGroups: searchList(action.payload.keywordGroups, state.search),
        editingKeywordGroup: action.payload.isInit ? null : state.editingKeywordGroup,
        isRefresh: false,
      };
    case 'SEARCH_KEYWORD_GROUP':
      return {
        ...state,
        search: action.payload.search,
        searchedKeywordGroups: searchList(state.keywordGroups, action.payload.search),
      };
    case 'BEGIN_EDIT_KEYWORD_GROUP':
      keywordGroup = state.keywordGroups.find(item => {
        return item.keywordGroup === action.payload.keywordGroup;
      });
      if (keywordGroup) {
        editingKeywordGroup = {...keywordGroup};
        const keywords = [];
        Object.keys(editingKeywordGroup.keywords).forEach(key => {
          keywords.push(editingKeywordGroup.keywords[key]);
        });
        editingKeywordGroup.keywords = keywords;
        editingKeywordGroup.isEditingUpdate = false;
      } else {
        editingKeywordGroup = defaultKeywordGroup();
      }
      return {
        ...state,
        originalKeywordGroup: editingKeywordGroup,
        editingKeywordGroup: editingKeywordGroup,
        editedId: null,
        synonymList: [],
        morphemeList: [],
      };
    case "CLOSE_EDIT_KEYWORD_GROUP":
      return {
        ...state,
        editingKeywordGroup: null,
      };
    case "CANCEL_EDIT_KEYWORD_GROUP":
      editingKeywordGroup = state.keywordGroups.find(item => {
        return item.keywordGroup === state.editingKeywordGroup.keywordGroup;
      });
      if (editingKeywordGroup) {
        editingKeywordGroup = { ...editingKeywordGroup };
        const keywords = [];
        Object.keys(editingKeywordGroup.keywords).forEach(key => {
          keywords.push(editingKeywordGroup.keywords[key]);
        });
        editingKeywordGroup.keywords = keywords;
        editingKeywordGroup.isEditingUpdate = false;
      } else {
        editingKeywordGroup = defaultKeywordGroup();
      }
      editingKeywordGroup.test = new Date().getTime();
      return {
        ...state,
        originalKeywordGroup: editingKeywordGroup,
        editingKeywordGroup: editingKeywordGroup,
        editedId: null,
        synonymList: [],
        morphemeList: [],
      };
    case "CHANGE_EDITING_KEYWORD_GROUP":
      editingKeywordGroup = action.payload.editingKeywordGroup;
      // originalKeywordGroupと比較するためいったんfalseにする
      editingKeywordGroup.isEditingUpdate = false;
      if (action.payload.isEditingUpdate) {

        // 入力されたキーワードには不要データが混ざっているので、フィルタ
        let originalKeywords = [];
        let editingKeywords = [];
        if (state.originalKeywordGroup.keywords) {
          originalKeywords = [ ...state.originalKeywordGroup.keywords ];
          originalKeywords.forEach((keyword, i) => {
            originalKeywords[i] = keyword.map(word => ({
              word: word.word,
              pos: word.pos,
              pos1: word.pos1,
              pos2: word.pos2,
              pos3: word.pos3
            }));
          });
        }
        if (editingKeywordGroup.keywords) {
          editingKeywords = [ ...editingKeywordGroup.keywords ];
          editingKeywords.forEach((keyword, i) => {
            editingKeywords[i] = keyword.map(word => ({
              word: word.word,
              pos: word.pos,
              pos1: word.pos1,
              pos2: word.pos2,
              pos3: word.pos3
            }));
          });
        }
        editingKeywordGroup.isEditingUpdate = isEqualExceptFalsy({ ...state.originalKeywordGroup, keywords: originalKeywords }, { ...editingKeywordGroup, keywords: editingKeywords }) ? false : true;
      }
      return {
        ...state,
        editingKeywordGroup: action.payload.editingKeywordGroup,
      };
    case "CHANGE_SEARCH_SYNONYM":
      // console.log(state.synonymTime, action.payload.time);
      if(state.synonymTime < action.payload.time){
        return {
          ...state,
          synonymList: action.payload.synonymList,
          synonymTime: action.payload.time,
        }
      }
      return {
        ...state,
      }
    case "CHANGE_SEARCH_MORPHEME":
      if(state.morphemeTime < action.payload.time){
        return {
          ...state,
          originalMorphemeList: action.payload.morphemeList,
          morphemeList: action.payload.morphemeList,
          morphemeTime: action.payload.time,
        }
      }
      return {
        ...state,
      }
    case "CHANGE_MORPHEME_SELECTED":
      editingKeywordGroup = state.editingKeywordGroup;
      // originalMorpheneListと比較するためいったんfalseにする
      editingKeywordGroup.isEditingUpdate = false;
      editingKeywordGroup.isEditingUpdate = isEqualExceptFalsy(state.originalMorphemeList, action.payload.morphemeList) ? false : true;
      return {
        ...state,
        morphemeList: action.payload.morphemeList,
      }
    case 'UPDATE_KEYWORD_GROUP':
      return {
        ...state,
        editingKeywordGroup: null,
        isRefresh: true,
      };
    case 'ADD_KEYWORD_UPDATE_KEYWORD_GROUP':
      return {
        ...state,
        originalKeywordGroup: {
          ...action.payload.editingKeywordGroup,
          isEditingUpdate: false,
        },
        editingKeywordGroup: {
          ...action.payload.editingKeywordGroup,
          isEditingUpdate: false,
        },
        editedId: action.payload.editingKeywordGroup.keywordGroup,
        isRefresh: true,
      }
    case 'DELETE_KEYWORD_GROUP':
      return {
        ...state,
        editingKeywordGroup: null,
        editedId: null,
        isRefresh: true,
      }
    case 'REFRESH_KEYWORD_GROUP':
      return {
        ...state,
        editingKeywordGroup: null,
        editedId: null,
        isRefresh: true,
      }
    case 'BEGIN_EDIT_KEYWORDS':
      return {
        ...state,
        editingKeywordGroup: action.payload.editingKeywordGroup,
      }
    default :
      return state;
  }
};

export default keywordGroups;
