const defaultState = {
  toolManagers: [],
  toolUsers: [],
};

function defaultAccounts() {
  return {
    uid: '',
    displayName: '',
    createdAt: '',
  }
}

const accounts = (state = defaultState, action) => {
  let toolManagers = null;
  let toolUsers = null;
  switch (action.type) {
    case 'ACCOUNTS':
      return {
        toolManagers: action.payload.toolManagers || defaultAccounts(),
        toolUsers: action.payload.toolUsers || defaultAccounts(),
      };
    case 'DELETE_MANAGER':
      toolManagers = state.toolManagers.concat();
      toolManagers.splice(toolManagers.findIndex(({ uid }) => uid === action.payload.uid), 1);
      return {
        ...state,
        toolManagers: toolManagers,
      };
    case 'DELETE_USER':
      toolUsers = state.toolUsers.concat();
      toolUsers.splice(toolUsers.findIndex(({ uid }) => uid === action.payload.uid), 1);
      return {
        ...state,
        toolUsers: toolUsers,
      };
    default:
      return state;
  }
};

export default accounts;