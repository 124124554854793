import 'firebase/firestore';
import functions, { isFunctionsSdkInternalError, isTokenRevokedError } from '../utilities/functions';
import db, { getDocsOrderBy, getModelsData } from '../utilities/db';
import { appErrorAction, libraryErrorAction, offLineErrorAction } from './appAction';
import storage from '../utilities/storage';
import { formatTag } from '../utilities/utils';
import { redirectUnauthorizeUser } from './login';


export async function listPhrases(dispatch, cid) {
  try {
    const companyDoc = db().collection("companies").doc(cid);
    const [items, models] = await Promise.all([
      getDocsOrderBy(companyDoc.collection("phrases"), "phrase", [{ column: "updatedAt", type: "desc" }]),
      getModelsData(companyDoc)
    ]);
    // console.log(items);
    items.forEach(item => {
      if(item.modelRef) item.modelRef = models.find(x => x.model === item.modelRef.id);
      let interlanguage = [];
      if(item.interlanguage){
        interlanguage = JSON.parse(item.interlanguage);
      }
      item.interlanguage = interlanguage;
    });
    dispatch(phrasesAction(items, models));
  } catch (e) {
    dispatch(appErrorAction(e));
  }
}

export function searchPhrase(dispatch, search, searchModel){
  dispatch({
    type: "SEARCH_PHRASE",
    payload: {
      search: search,
      searchModel: searchModel,
    }
  });
}

export async function getPhraseUrl(dispatch, phraseItem){
  const phraseStorage = storage().ref();
  if(phraseItem){
    const playPhraseUrl = await phraseStorage.child(phraseItem.phrasePath).getDownloadURL();
    dispatch({
      type: "PHRASE_PLAY_URL",
      playPhraseUrl: playPhraseUrl,
    });
  } else {
    dispatch({
      type: "PHRASE_PLAY_URL",
      playPhraseUrl: null,
    });
  }
}

export async function setSynthesizedPhrase(dispatch, item) {
  dispatch({
    type: "FACE_SYNTHESIZED_PHRASE",
    synthesizedPhrase: item,
  });
}

export function beginEditPhrase(dispatch, phrase, model) {
  dispatch(beginEditPhraseAction(phrase, model));
}

export function editPhrase(dispatch, editingPhrase, isChangeTitle=false) {
  dispatch(editPhraseAction(editingPhrase, isChangeTitle));
}

export async function getMorphRequest(dispatch, cid, path, method, responseParser, options, interlanguage, isFirstMorphRequest){
  let morphData = null;
   try{
    // console.log("getMorphRequest", interlanguage);
    const res = await (functions().httpsCallable('synthesize'))({
      cid: cid,
      method: method,
      path: path,
      payload: options.query
    });
    const data = typeof res.data === 'string' ? JSON.parse(res.data) : res.data;
    if(isFirstMorphRequest && interlanguage && interlanguage.length){
      morphData = interlanguage;
    } else {
      morphData = responseParser(data);
    }
    // console.log(morphData);
    dispatch({
      type: "PHRASE_GET_MORPH",
    })
  } catch(e){
    if (isTokenRevokedError(e)) {
      redirectUnauthorizeUser(dispatch, cid);
      return;
    }
    if (!navigator.onLine) {
      dispatch(offLineErrorAction("音声合成"));
      return;
    }
    if (isFunctionsSdkInternalError(e)) {
      dispatch(libraryErrorAction(e.code));
      return;
    }
    dispatch(appErrorAction(e));
  }
  return {
    response: morphData
  }
}

export async function synthesizeRequest(dispatch, cid, path, method, responseParser, payload, isTestPlayMode, editingPhrase){
  let audio = null;
  try{
    // console.log("synthesizeRequest");
    const res = await (functions().httpsCallable('synthesize'))({
      cid: cid,
      method: method,
      path: path,
      payload: payload
    });
    audio = responseParser(res.data);
    if(!isTestPlayMode){
      // テストモードでない場合は登録モード
      // console.log(audio);
      // console.log(editingPhrase);
      if(editingPhrase.phrase){
        const exists = await phraseExists(dispatch, cid, editingPhrase.phrase);
        if (!exists) return;
      }
      const data = await (functions().httpsCallable('createAudioFile'))({
        //この行がなければ新規音声、あれば上書き、非実在ID指定時はエラー
        phrase_id: editingPhrase.phrase,
        cid: cid,
        phrase_title: editingPhrase.phraseTitle,
        tag: formatTag(editingPhrase.tag),
        interlanguage: payload.morph || [],
        phrase_language: 'ja',
        model_ref: editingPhrase.modelRef,
        audio_content: audio
      });
      editingPhrase.phrase = data.phraseId;
      dispatch({
        type: "PHRASE_SYNTHESIZE",
        payload: {
          synthesizeAudio: audio,
          editingPhrase: editingPhrase,
        }
      });
    }
  } catch(e){
    if (isTokenRevokedError(e)) {
      redirectUnauthorizeUser(dispatch, cid);
      return;
    }
    if (!navigator.onLine) {
      dispatch(offLineErrorAction("音声作成"));
      return;
    }
    if (isFunctionsSdkInternalError(e)) {
      dispatch(libraryErrorAction(e.code));
      return;
    }
    // 削除されている可能性あり
    const exists = await phraseExists(dispatch, cid, editingPhrase.phrase);
    if (!exists) return;
    dispatch(appErrorAction("音声作成に失敗しました"));
    return;
  }
  return {
    response: audio
  };
}

export async function deletePhrase(dispatch, cid, phrase){
  try{
    // console.log(phrase);
    const phraseDoc = db().collection("companies").doc(cid).collection("phrases").doc(phrase);
    const exists = await phraseExists(dispatch, cid, phrase);
    if (!exists) return;
    let canDelete = true;
    for(let labelDoc of (await db().collection("companies").doc(cid).collection("labels").get()).docs){
      for(let characterDoc of (await labelDoc.ref.collection("characters").get()).docs){
        const [nearGreetings, midGreetings, manualCommands, contents, externalCommands] = await Promise.all([
          characterDoc.ref.collection("nearZoneGreetings").where("phraseRef", "==", phraseDoc).get(),
          characterDoc.ref.collection("midZoneGreetings").where("phraseRef", "==", phraseDoc).get(),
          characterDoc.ref.collection("manualCommands").where("phraseRef", "==", phraseDoc).get(),
          characterDoc.ref.collection("contents").where("phraseRef", "==", phraseDoc).get(),
          characterDoc.ref.collection("externalCommands").get(),
        ]);
        let containExternalCommand = false;
        for(let ec of externalCommands.docs){
          if(ec.data().phraseRef && ec.data().phraseRef.id === phraseDoc.id){
            containExternalCommand = true;
          } else if(ec.data().callSuccess && ec.data().callSuccess.phraseRef && ec.data().callSuccess.phraseRef.id === phraseDoc.id){
            containExternalCommand = true;
          } else if(ec.data().callFail && ec.data().callFail.phraseRef && ec.data().callFail.phraseRef.id === phraseDoc.id){
            containExternalCommand = true;
          } else if(ec.data().callReject && ec.data().callReject.phraseRef && ec.data().callReject.phraseRef.id === phraseDoc.id){
            containExternalCommand = true;
          } else if(ec.data().faceDetectionPhraseMaps){
            for(let map of ec.data().faceDetectionPhraseMaps){
              if(map.phraseRef && map.phraseRef.id === phraseDoc.id){
                containExternalCommand = true;
                break;
              }
            }
          }
          if(containExternalCommand) break;
        }
        if(!nearGreetings.empty || !midGreetings.empty || !manualCommands.empty || !contents.empty || containExternalCommand){
          canDelete = false;
          break;
        }
      }
      if(!canDelete) break;
    }
    // console.log(canDelete);
    if(canDelete){
      await phraseDoc.delete();
      const fileName = cid + "/phrases/" + phraseDoc.id + ".wav";
      const phraseStorage = storage().ref();
      await phraseStorage.child(fileName).delete();
      dispatch({
        type: "PHRASE_DELETED",
      })
    } else {
      dispatch(appErrorAction(["この音声フレーズは", "他から参照されているので削除できません。"]));
    }
  } catch(e){
    if (e && e.code === 'storage/object-not-found') {
      dispatch(phraseNotFoundErrorAction());
      dispatch(phraseRefreshAction());
      return;
    }
    dispatch(appErrorAction(e));
  }
}

export async function phraseExists(dispatch, cid, phraseId) {
  const phrase = await db().collection("companies").doc(cid).collection("phrases").doc(phraseId).get();
  if (!phrase.exists) {
    dispatch(phraseNotFoundErrorAction());
    dispatch(phraseRefreshAction());
    return false;
  }
  return true;
}

function phrasesAction(phrases, models) {
  return {
    type: 'PHRASES',
    payload: {
      phrases: phrases,
      models: models,
    }
  }
}

function beginEditPhraseAction(phrase, model) {
  return {
    type: 'BEGIN_EDIT_PHRASE',
    payload: {
      phrase: phrase,
      model: model,
    }
  }
}

function editPhraseAction(editingPhrase, isChangeTitle=false) {
  return {
    type: 'EDIT_PHRASE',
    payload: {
      phrases: editingPhrase,
      isChangeTitle: isChangeTitle,
    }
  }
}

function phraseRefreshAction() {
  return {
    type: 'PHRASE_REFRESH',
  };
}

function phraseNotFoundErrorAction() {
  return appErrorAction(["指定された音声フレーズは存在しません。","他のアカウントにより削除された可能性があります。"]);
}
