export const required = value => (value || typeof value === 'number' ? undefined : '必須入力');
const minLength = min => value => value && value.length < min ? (min + "文字以上") : undefined;
export const minLength2 = minLength(2);
export const minLength6 = minLength(6);
const maxLength = max => value => value && value.length > max ? (max + "文字以下") : undefined;
export const maxLength16 = maxLength(16);
export const emailRegexp = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
  'メールアドレス形式での入力が必要' : undefined;
export const isNumber = value => value && isNaN(Number(value)) ? '数値入力' : undefined;

export const toNumber = value => {
  try{
    if(isNaN(Number(value))){
      return 0;
    }
    return Number(value);
  }catch(e){
    return 0;
  }
}