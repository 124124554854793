const defaultState = {
  editingAccount: null,
  authorized: null,
  listener: null,
  approvalWaiting: false,
  autoLoginUser: null,
  isApprovalReject: false,
};

function defaultAccount(){
  return{
    displayName: "",
    email: "",
    password: "",
    passwordConfirm: "",
    role: "tool-manager",
    includeAutoApprovalMails: false,
  }
}

const addAccount = (state = defaultState, action) => {
  switch (action.type) {
    case 'BEGIN_EDIT_ACCOUNT':
      return {
        ...state,
        editingAccount: defaultAccount(),
        approvalWaiting: false,
        authorized: null,
        autoLoginUser: null,
        isApprovalReject: false,
      }
    case 'CHANGE_EDIT_ACCOUNT':
      return {
        ...state,
        editingAccount: action.payload.editingAccount,
      }
    case 'APPROVAL_ACCOUNT_ADDED':
      return {
        ...state,
        editingAccount: null,
        authorized: null,
        approvalWaiting: true,
      }
    case "AUTO_LOGIN_WAITING":
      return {
        ...state,
        autoLoginUser: {
          email: action.payload.email,
          password: action.payload.password,
        }
      }
    case "AUTO_LOGIN_EXECUTED":
      return {
        ...state,
        autoLoginUser: null
      }
    case "ADD_ACCOUNT_APPROVAL_REJECT":
      return {
        ...state,
        isApprovalReject: true
      }
    case "UNSUB_ACCOUNT_LISTENER":
      // ステート更新はREMOVE_ACCOUNT_LISTENERで行う
      if (state.listener) state.listener();
      return state;
    case "REMOVE_ACCOUNT_LISTENER":
      return {
        ...state,
        listener: null,
      }
    default:
      return state;
  }
};

export default addAccount;
