export function appError(dispatch, e) {
  dispatch(appErrorAction(e));
}

export function appRemoveError(dispatch) {
  dispatch({ type: 'APP_REMOVE_ERROR' });
}

export function appErrorAction(e, e2) {
  if(e) console.log(e);
  if(e2) console.log(e2);
  let error = e.message;
  if(!error) error = e;
  return{
    type: 'APP_ERROR',
    error: error,
  };
}

export function settingMenu(dispatch, isOpen){
  dispatch({ type: "APP_SETTING_MENU", isOpen: isOpen });
}

export function updateMessage(dispatch, isOpen, message) {
  dispatch({ type: "APP_UPDATE", isOpen: isOpen, message: message });
}

export function dbSetDataFailedErrorAction() {
  return appErrorAction(["データの作成・更新ができませんでした。", "時間を置いて再度作業を行ってください。"]);
}

export function libraryErrorAction(errorCode) {
  return appErrorAction(["ライブラリでエラーが発生しました。", "error code : " + errorCode]);
}

export function offLineErrorAction(messageTitle) {
  return appErrorAction([messageTitle + "中に監視ツールがオフラインになったため、", messageTitle + "に失敗した可能性があります。"]);
}
