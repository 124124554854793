const defaultState = {
  models: [],
};

const models = (state = defaultState, action) => {
  switch (action.type) {
    case 'MODELS_LIST':
      return {
        ...state,
        models: action.models,
      };
    default:
      return state;
  }
};

export default models;