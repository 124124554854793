import db from "../utilities/db";
import firebase from 'firebase/app';
import functions, { isTokenRevokedError } from '../utilities/functions';
import AgoraRTC from "agora-rtc-sdk";
import { appErrorAction } from "./appAction";
import $ from 'jquery';
import { mainCommandDelete, setVguardCallType } from "./main";
import { redirectUnauthorizeUser } from "./login";

const LOG_TYPE_MONITORING_SEND = "monitoringToolSendCallCommand";
const SEND_START_CALL_LOG = ["sendStartCall", "音声通話開始要求を送信"];
const SEND_STOP_CALL_LOG =["sendStopCall", "音声通話終了要求を送信"];
const SEND_START_BROADCAST_CALL_LOG = ["sendStartBroadcastCall", "一斉通話開始要求を送信"];
const SEND_STOP_BROADCAST_CALL_LOG = ["sendStopBroadcastCall", "一斉通話終了要求を送信"];
const SEND_REJECTED_CALL_LOG = ["sendRejectedCall", "音声通話拒否要求を送信" ];
const LOG_TYPE_CALL_FUNCTION = "callFunction";
const SUCCESS_START_CALL_LOG = ["successStartCall", "音声通話開始成功"];
const FAIL_START_CALL_LOG = ["failStartCall", "音声通話開始に失敗"];
const SUCCESS_STOP_CALL_LOG = ["successStopCall", "音声通話終了成功"];
const FAIL_STOP_CALL_LOG = ["failStopCall", "音声通話終了に失敗"];
const COMMAND_TYPE_CALL = "call";
const COMMAND_TYPE_BROADCAST = "broadcastCall";
const LOG_TYPE_STATUS_LOG = "statusLog";

const DEFAULT_MONITORING_UID = 4;

const DEBUG = true; // Agoraに接続できない環境でのデバッグフラグ

// 監視ツールから発信の音声通話開始
// 成功した場合：vguardに音声通話開始コマンドを送信、音声通話開始要求ログを送信（ステータス呼出中）（vguardから開始成功のログが来たら「ステータス通話中」になる）
// 失敗した場合：開始失敗ログを送信（ステータスデフォルト）、音声通話停止処理
export async function callStart(dispatch, user, label, vguardItem, vguardCommand, volume, isMute, vguardNumber) {
  const vguard = vguardItem.vguard;
  const cid = user.TenantId;

  // タイムアウトのコマンドが残っていた場合は消去
  const currentCommand = vguardCommand[vguard] && (vguardCommand[vguard]["call"] || vguardCommand[vguard]["emergency"]);
  if (currentCommand && currentCommand.commandMap.callMode === "fail_timeout") {
    await mainCommandDelete(dispatch, currentCommand.reporterRef.id, currentCommand.commandType);
  }

  const companyDoc = db().collection("companies").doc(cid);
  const labelDoc = companyDoc.collection("labels").doc(label);
  const userRef = user.isManager() ? companyDoc.collection("tool-managers").doc(user.uid) : companyDoc.collection("tool-users").doc(user.uid);
  let client = null, stream = null;
  try {
    // 音声通話のchannelNameは発信先のvguardID
    const channelName = vguard;
    const { token, appID, api, monitoringUid, vguardToken } = await getAgoraAccessToken(dispatch, cid, label, channelName, vguard, vguardNumber);
    if(api && api.data.channel_exist){
      dispatch(appErrorAction("他の端末が音声通話中のため、通話を開始できませんでした。"));
      return;
    }
    if (window.location.hostname !== "localhost" || !DEBUG) {
      client = await getCallClient(appID);
      stream = await callJoin(client, token, channelName, monitoringUid, isMute);
      addCallingListener(dispatch, client, stream, channelName, monitoringUid, volume, isMute, cid, label, vguard, vguardNumber);
      // monitoringUidのチェック
      const checkMonitoringUidResult = await checkMonitoringUid(dispatch, cid, monitoringUid, appID, channelName);
      console.log(checkMonitoringUidResult);
      if(!checkMonitoringUidResult){
        // 切断して終了
        await callLeave(client, stream);
        dispatch(appErrorAction("他の端末が音声通話中のため、通話を切断しました。"));
        return;
      }
    }
    // 音声通話(監視員発信)の開始
    dispatch({ type: "VOICE_CALLING_START_CALL", vguard: vguard });
    dispatch({
      type: "VOICE_CALLING_START_SUCCESS",
      payload: {
        mode: "call",
        client: client,
        stream: stream,
      }
    });
    // ログ：開始成功のログ
    await addCallEventLog(dispatch, user, userRef, LOG_TYPE_CALL_FUNCTION, SUCCESS_START_CALL_LOG, labelDoc, vguardItem);
    // ログ：音声通話開始要求を送信
    await addCallEventLog(dispatch, user, userRef, LOG_TYPE_MONITORING_SEND, SEND_START_CALL_LOG, labelDoc, vguardItem);
    // ステータスログを送る
    await addCallEventLog(dispatch, user, userRef, LOG_TYPE_STATUS_LOG, ["orange", `警備員0${vguardNumber}呼出中`], labelDoc, vguardItem);
    // vguardに音声通話開始要求を送る
    const commandMap = { callMode: "start", agoraAccessToken: vguardToken, vguardNumber: vguardNumber };
    await addCallVguardCommand(dispatch, companyDoc, label, vguard, commandMap, userRef, COMMAND_TYPE_CALL);
  } catch(e){
    // vguardの音声通話状態をstopにする
    dispatch({ type: "VOICE_CALLING_STOP_CALL", vguard: vguard });
    // ログ： 開始失敗のログ
    await addCallEventLog(dispatch, user, userRef, LOG_TYPE_CALL_FUNCTION, FAIL_START_CALL_LOG, labelDoc, vguardItem);
    console.error(e);
    dispatch(appErrorAction("音声通話要求の開始ができませんでした。"));
    // 念のため音声通話停止
    callLeave(client, stream);
    if (isTokenRevokedError(e)) {
      redirectUnauthorizeUser(dispatch, cid);
      return;
    }
  }
}

// vguard発信の音声通話開始要求の受諾（vguard送信の通話開始要求ログを受けると「ステータス警備員呼出中」）
// 成功した場合：音声通話開始成功ログを送信（ステータス通話中）
// 失敗した場合：vguardに音声通話失敗のコマンドを送信（vguard音声通話停止）、開始失敗ログを送信（ステータスデフォルト）、音声通話停止処理
export async function receiveCallStart(dispatch, user, label, vguardItem, commandType, vguardCommand, volume, isMute, vguardNumber) {
  const vguard = vguardItem.vguard;
  const cid = user.TenantId;
  const companyDoc = db().collection("companies").doc(cid);
  const labelDoc = companyDoc.collection("labels").doc(label);
  const userRef = user.isManager() ? companyDoc.collection("tool-managers").doc(user.uid) : companyDoc.collection("tool-users").doc(user.uid)
  const receiveCommandMap = vguardCommand[vguard] && vguardCommand[vguard][commandType];
  console.log(vguardCommand, receiveCommandMap);
  if(!receiveCommandMap || receiveCommandMap.commandMap.callMode !== "start"){
    // vguardに失敗の要求を送る
    // const commandMap = { callMode: "fail", agoraAccessToken: "" };
    // await addCallVguardCommand(dispatch, companyDoc, label, vguard, commandMap, userRef, COMMAND_TYPE_CALL);
    // ログ：開始失敗のログ
    // await addCallEventLog(dispatch, user, userRef, LOG_TYPE_CALL_FUNCTION, FAIL_START_CALL_LOG, labelDoc, vguardItem);
    dispatch(appErrorAction("音声通話開始要求が見つかりませんでした"));
    return;
  }
  let client = null, stream = null;
  try{
    // 音声通話のchannelNameは発信先のvguardID
    const channelName = vguard;
    const { token, appID, api, monitoringUid } = await getAgoraAccessToken(dispatch, cid, label, channelName, vguard, vguardNumber);
    if (api && api.data.channel_exist && api.data.users.filter(uid => uid >= DEFAULT_MONITORING_UID).length) {
      dispatch(appErrorAction("他の端末が受話したため、通話を開始できませんでした。"));
      return;
    }
    if (window.location.hostname !== "localhost" || !DEBUG) {
      client = await getCallClient(appID);
      stream = await callJoin(client, token, channelName, monitoringUid, isMute);
      addCallingListener(dispatch, client, stream, channelName, monitoringUid, volume, isMute, cid, label, vguard, vguardNumber);
      // monitoringUidのチェック
      const checkMonitoringUidResult = await checkMonitoringUid(dispatch, cid, monitoringUid, appID, channelName);
      console.log(checkMonitoringUidResult);
      if (!checkMonitoringUidResult) {
        // 切断して終了
        await callLeave(client, stream);
        dispatch(appErrorAction("他の端末が受話したため、通話を切断しました。"));
        return;
      }
    }
    dispatch({
      type: "VOICE_CALLING_RECEIVE_SUCCESS",
      vguard: vguard,
      payload: {
        mode: receiveCommandMap.commandType,
        client: client,
        stream: stream,
      }
    });
    // ログ：開始成功のログ
    await addCallEventLog(dispatch, user, userRef, LOG_TYPE_CALL_FUNCTION, SUCCESS_START_CALL_LOG, labelDoc, vguardItem);
    // vguardに開始成功の要求を送る
    const commandMap = { callMode: "monitoring_start", agoraAccessToken: "" };
    await addCallVguardCommand(dispatch, companyDoc, label, vguard, commandMap, userRef, COMMAND_TYPE_CALL);
  } catch(e){
    // vguardに失敗の要求を送る
    const commandMap = { callMode: "fail", agoraAccessToken: "" };
    await addCallVguardCommand(dispatch, companyDoc, label, vguard, commandMap, userRef, COMMAND_TYPE_CALL);
    // vguardの音声通話状態をstopにする
    dispatch({ type: "VOICE_CALLING_STOP_CALL", vguard: vguard });
    // ログ：開始失敗のログ
    await addCallEventLog(dispatch, user, userRef, LOG_TYPE_CALL_FUNCTION, FAIL_START_CALL_LOG, labelDoc, vguardItem);
    console.error(e);
    dispatch(appErrorAction("音声通話受信の開始ができませんでした。"));
    // 念のため音声通話停止
    callLeave(client, stream);
  }
}

// 監視ツールからの発信または通話中の音声通話の停止
// 成功した場合：vguardに音声通話終了コマンド送信、音声通話終了ログ送信（通話中でない場合はステータスデフォルト、通話中の場合スタータスは変更なし、vguardから送られてくるログでデフォルトに戻る）
// 失敗した場合：vguardに音声通話失敗のコマンドを送信（vguard音声通話停止）、終了失敗ログ送信（ステータスデフォルト）
export async function callStop(dispatch, user, label, vguardItem, callingData){
  const vguard = vguardItem.vguard;
  const cid = user.TenantId;
  const companyDoc = db().collection("companies").doc(cid);
  const labelDoc = companyDoc.collection("labels").doc(label);
  const userRef = user.isManager() ? companyDoc.collection("tool-managers").doc(user.uid) : companyDoc.collection("tool-users").doc(user.uid);
  try {
    console.log(callingData);
    await callLeave(callingData.client, callingData.stream, callingData.remoteStreams);
    // ログ：終了成功
    await addCallEventLog(dispatch, user, userRef, LOG_TYPE_CALL_FUNCTION, SUCCESS_STOP_CALL_LOG, labelDoc, vguardItem);
    // ログ：終了要求
    await addCallEventLog(dispatch, user, userRef, LOG_TYPE_MONITORING_SEND, SEND_STOP_CALL_LOG, labelDoc, vguardItem);
    // コマンド
    const commandMap = { callMode: "stop", agoraAccessToken: "" };
    await addCallVguardCommand(dispatch, companyDoc, label, vguard, commandMap, userRef, COMMAND_TYPE_CALL);
    dispatch({ type: "VOICE_CALLING_STOP_SUCCESS" });
    dispatch({ type: "VOICE_CALLING_STOP_CALL", vguard: vguard });
    // vguardのmonitoringUidのリセット
    // functions().httpsCallable("resetVguardMonitoringUid")({ tenantId: cid, label: label, vguard: vguard });
  } catch(e) {
    // vguardに失敗の要求を送る
    const commandMap = { callMode: "fail", agoraAccessToken: "" };
    await addCallVguardCommand(dispatch, companyDoc, label, vguard, commandMap, userRef, COMMAND_TYPE_CALL);
    // ログ：終了失敗
    await addCallEventLog(dispatch, user, userRef, LOG_TYPE_CALL_FUNCTION, FAIL_STOP_CALL_LOG, labelDoc, vguardItem);
    // vguardの音声通話状態をstopにする
    dispatch({ type: "VOICE_CALLING_STOP_CALL", vguard: vguard });
    console.error(e);
    dispatch(appErrorAction("音声通話の停止ができませんでした。"));
  }
}

export async function callStopByBeforeunload(dispatch, user, label, vguardItem){
  const vguard = vguardItem.vguard;
  const cid = user.TenantId;
  const uid = user.uid;
  const isManager = user.isManager();
  const vguardDisplayName = vguardItem.displayName;
  try{
    await functions().httpsCallable("executeCallStop")({
      cid: cid,
      label: label,
      uid: uid,
      isManager: isManager,
      vguard: vguard,
      vguardDisplayName: vguardDisplayName,
      commandType: "call",
    });
    // console.log(result);
  } catch(e){
    if (isTokenRevokedError(e)) {
      redirectUnauthorizeUser(dispatch, user.TenantId);
      return;
    }
    console.log(e);
  }
}

// 監視ツールからの通話要求を拒否
// 成功した場合：vguardに音声通話拒否コマンドを送信（vguard音声通話停止）、音声通話拒否ログを送信（ステータスデフォルト）
// 失敗した場合：vguardに音声通話失敗のコマンドを送信（vguard音声通話停止）、終了失敗ログ送信（ステータスデフォルト）
export async function rejectCall(dispatch, user, label, vguardItem, commandType, vguardCommand){
  const vguard = vguardItem.vguard;
  const cid = user.TenantId;
  const companyDoc = db().collection("companies").doc(cid);
  const labelDoc = companyDoc.collection("labels").doc(label);
  const userRef = user.isManager() ? companyDoc.collection("tool-managers").doc(user.uid) : companyDoc.collection("tool-users").doc(user.uid);
  console.log(vguardCommand, commandType);
  const receiveCommandMap = vguardCommand[vguard] && vguardCommand[vguard][commandType];
  console.log(receiveCommandMap);
  if(!receiveCommandMap || receiveCommandMap.commandMap.callMode !== "start"){
    // vguardに失敗の要求を送る
    // const commandMap = { callMode: "fail", agoraAccessToken: "" };
    // await addCallVguardCommand(dispatch, companyDoc, label, vguard, commandMap, userRef, COMMAND_TYPE_CALL);
    // ログ：終了失敗
    // await addCallEventLog(dispatch, user, userRef, LOG_TYPE_CALL_FUNCTION, FAIL_STOP_CALL_LOG, labelDoc, vguardItem);
    dispatch(appErrorAction("音声通話開始要求が見つからないため拒否できませんでした"));
    return;
  }
  try{
    // ログ：音声通話拒否
    await addCallEventLog(dispatch, user, userRef, LOG_TYPE_MONITORING_SEND, SEND_REJECTED_CALL_LOG, labelDoc, vguardItem);
    // 拒否時はステータスを更新
    setVguardCallType(dispatch, vguard, null);
    // vguardに音声通話拒否要求を送る
    const commandMap = { callMode: "reject", agoraAccessToken: "" };
    await addCallVguardCommand(dispatch, companyDoc, label, vguard, commandMap, userRef, COMMAND_TYPE_CALL);
  } catch(e){
    // vguardに失敗の要求を送る
    const commandMap = { callMode: "fail", agoraAccessToken: "" };
    await addCallVguardCommand(dispatch, companyDoc, label, vguard, commandMap, userRef, COMMAND_TYPE_CALL);
    // vguardの音声通話状態をstopにする
    dispatch({ type: "VOICE_CALLING_STOP_CALL", vguard: vguard });
    // ログ：終了失敗
    await addCallEventLog(dispatch, user, userRef, LOG_TYPE_CALL_FUNCTION, FAIL_STOP_CALL_LOG, labelDoc, vguardItem);
    console.error(e);
    dispatch(appErrorAction("音声通話拒否に失敗しました"));
  }
}

// 監視ツールからの一斉通話要求
// 成功した場合：それぞれのvguardに一斉通話開始コマンド送信、一斉通話開始要求ログをそれぞれに送信（ステータス呼出中）
// 失敗した場合：音声通話開始失敗ログを送信（ステータスデフォルト）、音声通話停止処理
export async function broadcastCallStart(dispatch, user, label, vguards, volume, isMute, vguardNumbers){
  const cid = user.TenantId;
  const companyDoc = db().collection("companies").doc(cid);
  const labelDoc = companyDoc.collection("labels").doc(label);
  const userRef = user.isManager() ? companyDoc.collection("tool-managers").doc(user.uid) : companyDoc.collection("tool-users").doc(user.uid);
  let client = null, stream = null;
  try {
    // 一斉通話のchannelNameは発信先のlabelID
    const channelName = label;
    const { token, appID, api, monitoringUid, vguardToken } = await getAgoraAccessToken(dispatch, cid, label, channelName, null, 0);
    if(api && api.data.channel_exist){
      dispatch(appErrorAction("他の端末で一斉通話中のため、通話を開始できませんでした。"));
      return;
    }
    if (window.location.hostname !== "localhost" || !DEBUG) {
      client = await getCallClient(appID);
      stream = await callJoin(client, token, channelName, monitoringUid, isMute);
      addCallingListener(dispatch, client, stream, channelName, monitoringUid, volume, isMute, cid, label, null, 0);
      // monitoringUidのチェック
      const checkMonitoringUidResult = await checkMonitoringUid(dispatch, cid, monitoringUid, appID, channelName);
      console.log(checkMonitoringUidResult);
      if(!checkMonitoringUidResult){
        // 切断して終了
        await callLeave(client, stream);
        dispatch(appErrorAction("他の端末で一斉通話中のため、通話を切断しました。"));
        return;
      }
    }
    // 音声通話(監視員発信)の開始
    dispatch({ type: "VOICE_CALLING_START_BROADCAST_CALL", vguards: vguards });
    dispatch({
      type: "VOICE_CALLING_START_SUCCESS",
      payload: {
        mode: "broadcast",
        client: client,
        stream: stream,
      }
    });
    await Promise.all(vguards.map(async vguardItem => {
      const vguard = vguardItem.vguard;
      // ログ：開始成功のログ
      await addCallEventLog(dispatch, user, userRef, LOG_TYPE_CALL_FUNCTION, SUCCESS_START_CALL_LOG, labelDoc, vguardItem);
      // ログ：一斉通話開始要求を送信
      await addCallEventLog(dispatch, user, userRef, LOG_TYPE_MONITORING_SEND, SEND_START_BROADCAST_CALL_LOG, labelDoc, vguardItem);
      // ステータスログを送る
      await addCallEventLog(dispatch, user, userRef, LOG_TYPE_STATUS_LOG, ["orange", `警備員0${vguardNumbers[vguard]}呼出中`], labelDoc, vguardItem);
      // それぞれのvguardに一斉通話開始要求を送る
      const commandMap = { callMode: "start", agoraAccessToken: vguardToken, vguardNumber: 0 }; // このコマンドマップでは一斉通話できない：vguardごとにトークンを発行する必要あり
      await addCallVguardCommand(dispatch, companyDoc, label, vguardItem.vguard, commandMap, userRef, COMMAND_TYPE_BROADCAST);
    }));
  } catch(e){
    dispatch({ type: "VOICE_CALLING_STOP_BROADCAST_CALL", vguards: vguards });
    // 開始失敗のログ
    await Promise.all(vguards.map(async vguardItem => {
      // vguardの音声通話状態をstopにする
      dispatch({ type: "VOICE_CALLING_STOP_CALL", vguard: vguardItem.vguard });
      // ログ：開始失敗
      await addCallEventLog(dispatch, user, userRef, LOG_TYPE_CALL_FUNCTION, FAIL_START_CALL_LOG, labelDoc, vguardItem);
    }));
    console.error(e);
    dispatch(appErrorAction("一斉通話の開始要求が開始できませんでした。"));
    // 念のため音声通話停止
    callLeave(client, stream);
    if (isTokenRevokedError(e)) {
      redirectUnauthorizeUser(dispatch, cid);
      return;
    }
  }
}

// 一斉通話の停止
// 成功した場合：それぞれのvguardに一斉通話終了コマンド送信、一斉通話終了ログ送信（通話中でない場合はステータスデフォルト、通話中の場合スタータスは変更なし、vguardから送られてくるログでデフォルトに戻る）
// 失敗した場合：それぞれのvguardに通話失敗のコマンドを送信、音声通話開始失敗ログを送信（ステータスデフォルト）
export async function broadcastCallStop(dispatch, user, label, vguards, callingData){
  const cid = user.TenantId;
  const companyDoc = db().collection("companies").doc(cid);
  const labelDoc = companyDoc.collection("labels").doc(label);
  const userRef = user.isManager() ? companyDoc.collection("tool-managers").doc(user.uid) : companyDoc.collection("tool-users").doc(user.uid);
  let commandMap;
  try {
    await callLeave(callingData.client, callingData.stream, callingData.remoteStreams);
    dispatch({ type: "VOICE_CALLING_STOP_SUCCESS" });
    commandMap = {
      callMode: "stop",
      agoraAccessToken: "",
    }
    await Promise.all(vguards.map(async vguardItem => {
      dispatch({ type: "VOICE_CALLING_STOP_CALL", vguard: vguardItem.vguard });
      // それぞれのvguardに一斉通話終了要求を送る
      await addCallVguardCommand(dispatch, companyDoc, label, vguardItem.vguard, commandMap, userRef, COMMAND_TYPE_BROADCAST);
      // ログ：終了成功
      await addCallEventLog(dispatch, user, userRef, LOG_TYPE_CALL_FUNCTION, SUCCESS_STOP_CALL_LOG, labelDoc, vguardItem);
      // ログ：一斉通話終了要求を送信
      await addCallEventLog(dispatch, user, userRef, LOG_TYPE_MONITORING_SEND, SEND_STOP_BROADCAST_CALL_LOG, labelDoc, vguardItem);
    }));
    // labelのmonitoringUidのリセット
    functions().httpsCallable("resetBroadcastMonitoringUid")({ tenantId: cid, label: label });
  } catch(e) {
    commandMap = { callMode: "fail", agoraAccessToken: "" };
    await Promise.all(vguards.map(async vguardItem => {
      // vguardの音声通話状態をstopにする
      dispatch({ type: "VOICE_CALLING_STOP_CALL", vguard: vguardItem.vguard });
      // vguardに失敗の要求を送る
      addCallVguardCommand(dispatch, companyDoc, label, vguardItem.vguard, commandMap, userRef, COMMAND_TYPE_BROADCAST);
      // 終了失敗
      await addCallEventLog(dispatch, user, userRef, LOG_TYPE_CALL_FUNCTION, FAIL_STOP_CALL_LOG, labelDoc, vguardItem);
    }));
    console.error(e);
    dispatch(appErrorAction("一斉通話の停止に失敗しました。"));
    if (isTokenRevokedError(e)) {
      redirectUnauthorizeUser(dispatch, user.TenantId);
      return;
    }
  }
}

export async function callStopFromCommand(dispatch, user, label, vguardItem, callingData, callMode, checkCommand){
  const vguard = vguardItem.vguard;
  const cid = user.TenantId;
  const companyDoc = db().collection("companies").doc(cid);
  const labelDoc = companyDoc.collection("labels").doc(label);
  const userRef = user.isManager() ? companyDoc.collection("tool-managers").doc(user.uid) : companyDoc.collection("tool-users").doc(user.uid);
  try{
    console.log(callingData, checkCommand);
    let flg = false;
    if(callingData.mode === checkCommand.commandType && callMode[vguard] && callMode[vguard].status === "start"){
      // 同じモードの停止・失敗命令の場合は音声通話停止処理を行う
      console.log("同じモードの停止・失敗命令の場合は音声通話停止処理を行う");
      flg = await callLeave(callingData.client, callingData.stream, callingData.remoteStreams);
      if(flg){
        console.log("停止に成功");
        dispatch({ type: "VOICE_CALLING_STOP_SUCCESS" });
        dispatch({ type: "VOICE_CALLING_STOP_CALL", vguard: vguard });
        // ログ：音声通話終了成功を送信
        await addCallEventLog(dispatch, user, userRef, LOG_TYPE_CALL_FUNCTION, SUCCESS_STOP_CALL_LOG, labelDoc, vguardItem);
      }
    }
    return flg;
  } catch(e){
    // ログ：終了失敗
    await addCallEventLog(dispatch, user, userRef, LOG_TYPE_CALL_FUNCTION, FAIL_STOP_CALL_LOG, labelDoc, vguardItem);
    dispatch(appErrorAction(e));
  }
}

export async function broadcastStopFromCommand(dispatch, user, label, vguards, callingData, callMode, checkCommand){
  vguards.forEach(vguardItem => {
    callStopFromCommand(dispatch, user, label, vguardItem, callingData, callMode, checkCommand)
  })
}


export function volumeChange(dispatch, volume, remoteStreams){
  console.log(volume, remoteStreams);
  if(remoteStreams){
    remoteStreams.forEach(stream => {
      volumeChangeExecute(volume, stream);
    });
  }
  dispatch({
    type: "VOICE_CALLING_VOLUME_CHANGE",
    payload: {
      volume: volume,
    }
  });
}

export function muteChange(dispatch, isMute, localStream){
  console.log(isMute, localStream);
  if(localStream){
    muteChangeExecute(isMute, localStream);
  }
  dispatch({
    type: "VOICE_CALLING__MUTE_CHANGE",
    payload: {
      isMute: isMute,
    }
  });
}

function volumeChangeExecute(volume, remoteStream){
  if(remoteStream){
    remoteStream.setAudioVolume(volume);
  }
}

function muteChangeExecute(isMute, localStream){
  if(localStream){
    if(isMute){
      localStream.muteAudio();
    } else {
      localStream.unmuteAudio();
    }
  }
}

async function checkMonitoringUid(dispatch, cid, monitoringUid, appID, channelName){
  try {
    const result = await functions().httpsCallable("getCallApi")({ tenantId: cid, appID: appID, channelName: channelName });
    console.log(result);
    const users = result.data.data && result.data.data.users;
    console.log(users);
    // usersがない場合はOK
    if(!users) return true;
    const minUid = users.filter(x => x >= DEFAULT_MONITORING_UID).reduce((a, b) => a < b ? a : b);
    if(minUid === monitoringUid) return true;
    return false;
  } catch(e) {
    if (isTokenRevokedError(e)) {
      redirectUnauthorizeUser(dispatch, cid);
      return;
    }
  }
}

async function getAgoraAccessToken(dispatch, cid, label, channelName, vguard, vguardNumber, defMonitoringUid=null) {
  const vguardUid = vguard;
  let monitoringUid = defMonitoringUid;
  if (!monitoringUid) {
    // monitoringUidの指定がない場合はfirestoreに問い合わせ
    const isBroadcast = vguardUid === null;
    const doc = isBroadcast ?
      db().collection('companies').doc(cid).collection('labels').doc(label).collection('callExclusiveControl').doc('callExclusiveControl') :
      db().collection('companies').doc(cid).collection('labels').doc(label).collection('vguards').doc(vguardUid).collection('callExclusiveControl').doc('callExclusiveControl');
    await firebase.firestore().runTransaction(async (transaction) => {
      // 最新データを取得のためトランザクション
      monitoringUid = DEFAULT_MONITORING_UID;
      const data = (await transaction.get(doc)).data();
      if (data && data.monitoringUid) {
        monitoringUid = data.monitoringUid + 1;
        transaction.update(doc, { monitoringUid: monitoringUid });
      } else {
        transaction.set(doc, { monitoringUid: monitoringUid });
      }
    });
  }
  try {
    const result = await functions().httpsCallable("getAgoraTokenFromMonitoring")({
      tenantId: cid,
      label: label,
      channelName: channelName,
      vguardUid: vguardUid,
      vguardNumber: vguardNumber,
      monitoringUid: monitoringUid,
    });
    // デバック用コンソール
    console.log("channel: " + channelName);
    console.log("token: " + result.data.monitoringToken);
    console.log("appID: " + result.data.appID);
    console.log("api: ", result.data.api);
    console.log("monitoringUid: ", monitoringUid);
    console.log("vguardToken: ", result.data.vguardToken);
    return {
      token: result.data.monitoringToken,
      appID: result.data.appID,
      api: result.data.api,
      monitoringUid: monitoringUid,
      vguardToken: result.data.vguardToken
    };
  } catch(e) {
    if (isTokenRevokedError(e)) {
      redirectUnauthorizeUser(dispatch, cid);
      return;
    }
  }
}

async function getCallClient(appID){
  let client = AgoraRTC.createClient({ mode: 'rtc', codec: 'h264' });
  await clientInitAsync(client, appID);
  console.log("agora client init!!!!!", client);
  return client;
}

async function callJoin(client, token, channelName, monitoringUid, isMute){
  console.log(`channelName: ${channelName} monitoringUid: ${monitoringUid} token: ${token}`);
  console.log(monitoringUid);
  const uid = await clientJoinAsync(client, token, channelName, monitoringUid);
  console.log("agora client join!!!!!", uid);
  const stream = AgoraRTC.createStream({
    streamID: uid,
    audio: true,
    video: false,
    screen: false,
  });
  // TODO: ローカルstreamを明示的に設定（Sample rate 32 kHz, mono, encoding rate 24 Kbps.）
  stream.setAudioProfile("speech_standard");
  await streamInitAsync(stream);
  console.log("agora stream init!!!!!", stream);
  stream.play("voice_calling_local_stream");
  client.publish(stream, (err) => console.log("publish error!!!!!", err));
  console.log("agora client publish!!!!!", client, stream);
  muteChangeExecute(isMute, stream);
  // client.getSystemStats((stats) => {
  //   console.log(stats);
  // });
  // stream.getStats((stats) => {
  //   console.log(stats);
  // });
  // setInterval(() => {
  //   client.getLocalAudioStats((localAudioStats) => {
  //     for(var uid in localAudioStats){
  //       console.log(localAudioStats[uid]);
  //       console.log(`Audio CodecType from ${uid}: ${localAudioStats[uid].CodecType}`);
  //       console.log(`Audio MuteState from ${uid}: ${localAudioStats[uid].MuteState}`);
  //       console.log(`Audio RecordingLevel from ${uid}: ${localAudioStats[uid].RecordingLevel}`);
  //       console.log(`Audio SamplingRate from ${uid}: ${localAudioStats[uid].SamplingRate}`);
  //       console.log(`Audio SendBitrate from ${uid}: ${localAudioStats[uid].SendBitrate}`);
  //       console.log(`Audio SendLevel from ${uid}: ${localAudioStats[uid].SendLevel}`);
  //     }
  //   });
  // }, 1000)
  return stream;
}

function addCallingListener(dispatch, client, stream, channelName, monitoringUid, volume, isMute, cid, label, vguard, vguardNumber) {
  client.on("stream-added", function(evt) {
    console.log("stream-added", evt);
    let remoteStream = evt.stream;
    let id = remoteStream.getId();
    if(id !== stream.getId()){
      client.subscribe(remoteStream, function(err) {
        console.log("stream subscribe failed", err);
      });
    }
    console.log("stream-added remote-uid: ", id);
  })
  client.on("stream-subscribed", function(evt) {
    console.log("stream-subscribed", evt);
    let remoteStream = evt.stream;
    let id = remoteStream.getId();
    addView(id);
    remoteStream.play("remote_stream_" + id);
    console.log("stream-subscribed remote-uid: ", id);
    // 音量とミュート
    volumeChangeExecute(volume, remoteStream);
    // muteChangeExecute(isMute, remoteStream);
    dispatch({
      type: "VOICE_CALLING_ADD_REMOTE_STREAM",
      payload: {
        remoteStream: remoteStream,
      }
    });
  });
  client.on("stream-removed", function(evt) {
    console.log("stream-removed", evt);
    let remoteStream = evt.stream;
    let id = remoteStream.getId();
    remoteStream.stop("remote_stream_" + id);
    removeView(id);
    console.log("stream-removed remote-uid: ", id);
    dispatch({
      type: "VOICE_CALLILNG_REMOVE_REMOTE_STREAM",
      payload: {
        remoteStream: remoteStream,
      }
    });
  });
  client.on("onTokenPrivilegeWillExpire", async function() {
    console.log("onTokenPrivilegeWillExpire");
    const result = await getAgoraAccessToken(dispatch, cid, label, channelName, vguard, vguardNumber, monitoringUid);
    const newToken = result.token;
    client.renewToken(newToken);
  });
  client.on("connection-state-change", function(evt) {
    console.log("agora connection-state-change!!!!!!!!!!!!");
    console.log(evt);
    if(evt.curState === "DISCONNECTED" && evt.prevState === "CONNECTED"){
      // コネクションが切断された(通常切断時はcurState="DISCONNECTED", prevState="DISCONNECTING")
      console.log("コネクションが切断された");
    }
  });
  client.on("reconnect", function() {
    console.log("reconnect");
    dispatch(appErrorAction("ネットワークが不安定なため、再接続を行っています。"));
  });
  client.on("rejoin", function() {
    console.log("rejoin");
    dispatch(appErrorAction("再接続に成功しました。"));
  });
  client.on("error", function(err) {
    console.log("Got error msg:", err);
    // Quote: https://docs.agora.io/en/faq/API%20Reference/web/interfaces/agorartc.client.html#agorartc.client.html#on
    //  |・When reason is "SOCKET_DISCONNECTED", the SDK disconnects from the Agora server due to network conditions and will automatically try reconnecting
    //  |・If this callback reports other reasons, it means that the error occurs during the reconnecting phase.
    if (err.reason === "SOCKET_DISCONNECTED") {
      // client.on("reconnect", callback) が呼ばれるはず
      console.log("ネットワークの状態により通話が切断されたので再接続します。");
    } else {
      console.log("再接続に失敗", err.reason);
    }
  });
  // stream.on("player-status-change", function(evt){
  //   console.log("agora player-status-change!!!!!!!!!!!!");
  //   console.log(evt);
  // });
}

async function callLeave(client, stream, remoteStreams){
  if(client){
    // await promisify(client.leave)();
    await clientLeaveAsync(client, stream);
    console.log("agora client leave!!!!!", client);
    if(stream){
      stream.stop();
      stream.close();
      console.log("agora stream stop and close!!!!!", stream);
      if(remoteStreams){
        remoteStreams.forEach(remoteStream => {
          const id = remoteStream.getId();
          remoteStream.stop();
          removeView(id);
          console.log("agora remote stream stop", id);
        });
      }
    } else {
      console.log("stream is null");
    }
    return true;
  } else {
    console.log("client is null");
  }
  return false;
}

function addView (id, show) {
  if (!$("#" + id)[0]) {
    $("<div/>", {
      id: "remote_video_panel_" + id,
      class: "video-view",
    }).appendTo("#video");

    $("<div/>", {
      id: "remote_video_" + id,
      class: "video-placeholder",
    }).appendTo("#remote_video_panel_" + id);

    $("<div/>", {
      id: "remote_video_info_" + id,
      class: "video-profile " + (show ? "" :  "hide"),
    }).appendTo("#remote_video_panel_" + id);

    $("<div/>", {
      id: "video_autoplay_"+ id,
      class: "autoplay-fallback hide",
    }).appendTo("#remote_video_panel_" + id);

    $("<div/>", {
      id: "remote_stream_"+ id,
      class: "remote_stream",
    }).appendTo("#remote_video_panel_" + id);
  }
}
function removeView (id) {
  if ($("#remote_video_panel_" + id)[0]) {
    $("#remote_video_panel_"+id).remove();
  }
}

function clientInitAsync(client, appID){
  return new Promise((resolve, reject) => {
    client.init(appID, () => {
      resolve();
    }, function(err) {
      console.log("client init!!!!!!!!!!!!!!!!!!!!!!");
      console.log(err);
      reject(err);
    });
  })
}

function clientJoinAsync(client, token, channelName, monitoringUid){
  return new Promise((resolve, reject) => {
    client.join(token, channelName, monitoringUid, (uid) => {
      resolve(uid);
    }, function(err) {
      console.log("client join error!!!!!!!!!!!!!!!!!!!!!!");
      console.log(err);
      reject(err);
    });
  })
}

function streamInitAsync(stream){
  return new Promise((resolve, reject) => {
    stream.init(() => {
      resolve();
    }, function(err) {
      console.log("stream init error!!!!!!!!!!!!!!!!!!!!!!");
      console.log(err);
      reject(err);
    });
  })
}

function clientLeaveAsync(client){
  return new Promise((resolve, reject) => {
    try{
      client.leave(() => {
        resolve();
      }, function(err) {
        console.log("client leave error!!!!!!!!!!!!!!!!!!!!!!");
        console.log(err);
        reject(err);
      });
    } catch(e){
      console.log(e);
      resolve();
    }
  })
}

async function addCallVguardCommand(dispatch, companyDoc, label, vguard, commandMap, userRef, commandType){
  try {
    // バーチャル警備員命令(音声通話)の作成
    const data = {
      toolRef: userRef,
      commandType: commandType,
      commandMap: commandMap,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    }
    await companyDoc.collection("labels").doc(label).collection("vguards").doc(vguard).collection("commands").doc().set(data);
  } catch(e){
    dispatch(appErrorAction(e));
  }
}

async function addCallEventLog(dispatch, user, userRef, primaryType, type, labelDoc, vguardItem) {
  try {
    // イベントログ(音声通話)の作成
    // console.log(vguardItem);
    let data = {
      executorRole: user.role,
      executorUserRef: userRef,
      executorUserDisplayName: user.displayName,
      executorProcess: "monitoringTool",
      relatedVguardRef: vguardItem ? vguardItem.ref : null,
      relatedVguardDisplayName: vguardItem ? vguardItem.displayName : null,
      level: "INFO",
      primaryType: primaryType,
      secondaryType: type[0],
      title: type[1],
      state: "done",
      motionMap: null,
      phraseMap: null,
      pictureMap: null,
      keywords: null,
      keywordFull: null,
      note: null,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    }
    // 音声通話要求受信時は、モーションIDに紐づく全ての情報を保持する
    // if(primaryType === receivedPrimaryType) {

    // }
    await labelDoc.collection("event-logs").doc().set(data);
  } catch(e){
    dispatch(appErrorAction(e));
  }
}
