const defaultState = {
  manualCommandsGroups: [],
  editingCommand: {},
  isRefresh: false,
};

function defaultCommandsGroups() {
  return {
    manualCommandsGroup: null,
    title: '',
  }
}

const commandsGroups = (state = defaultState, action) => {
  let editingCommand, manualCommandsGroups;
  switch (action.type) {
    case 'COMMANDS' :
      return {
        ...state,
        manualCommandsGroups: action.payload.manualCommandsGroups,
        editingCommand: null,
        isRefresh: false,
      };
    case 'BEGIN_EDIT_COMMAND' :
      editingCommand = state.manualCommandsGroups.find((cGroup) => (
        cGroup.manualCommandsGroup === action.payload.manualCommandsGroup
      ));
      return {
        ...state,
        editingCommand: editingCommand || defaultCommandsGroups(),
      };
    case 'EDIT_COMMAND':
      return {
        ...state,
        editingCommand: action.payload.editingCommand,
      };
    case 'UPDATE_COMMAND' :
      // editingCommand = action.payload.editingCommand;
      // index = state.manualCommandsGroups.find((cGroup) => (
      //   cGroup.manualCommandsGroup === action.payload.editingCommand.manualCommandsGroup
      // ));
      // manualCommandsGroups = state.manualCommandsGroups.concat();
      // if (index) {
      //   manualCommandsGroups[manualCommandsGroups.findIndex(({manualCommandsGroup}) => manualCommandsGroup === action.payload.editingCommand.manualCommandsGroup)].title = action.payload.editingCommand.title;
      // } else {
      //   manualCommandsGroups.push(editingCommand);
      // }
      return {
        ...state,
        editingCommand: null,
        isRefresh: true,
      };
    case 'DELETE_COMMAND' :
      manualCommandsGroups = state.manualCommandsGroups.concat();
      manualCommandsGroups.splice(manualCommandsGroups.findIndex(({manualCommandsGroup}) => manualCommandsGroup === action.payload.manualCommandsGroup),1);
      return {
        ...state,
        manualCommandsGroups: manualCommandsGroups,
        editingCommand: null,
      };
    case "COMMAND_GROUP_CLOSE_EDIT":
      return {
        ...state,
        editingCommand: null,
      }
    default:
      return state;
  }
};

export default commandsGroups;