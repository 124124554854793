import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { withStyles, MenuItem, Divider, ButtonBase, Slider } from "@material-ui/core";
import AppBar from '@material-ui/core/AppBar';
import { logout } from './actions/login';
import { selectedLabelChange } from './actions/main';
import { connect } from "react-redux";
import { onLoading, offLoading } from './actions/loading';
import { StyledSelect, CustomButton, StyledFormControlSm } from "./AppParts";
import { broadcastCallStart, broadcastCallStop, volumeChange, muteChange, callStopByBeforeunload } from "./actions/voiceCalling";

const styles = theme => ({
  appBar: {
    height: "56px",
    width: 1280,
    margin: "0 auto",
    position: 'relative',
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    backgroundColor: theme.palette.white.main,
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
    // borderBottom: `1px solid ${theme.palette.grey['100']}`,
  },
  icon: {
    position: "absolute",
    left: "1.88%",
    right: "95.63%",
    top: "21.43%",
    bottom: "absolute",
  },
  title: {
    position: "absolute",
    left: "4.84%",
    right: "88.83%",
    top: "25%",
    bottom: "35.71%",
    // fontFamily: "Helvetica",
    // fontStyle: "normal",
    // fontWeight: "bold",
    // fontSize: "24px",
    // lineHeight: "28px",
    // color: "#444444",
  },
  powered: {
    position: "absolute",
    left: "11.95%",
    right: "80.08%",
    top: "32.14%",
    bottom: "33.93%",
    // fontFamily: "DIN Condensed",
    // fontStyle: "normal",
    // fontWeight: 300,
    // fontSize: "16px",
    // lineHeight: "19px",
    // textAlign: "center",
    // color: "#444444",
  },
  userLeftDivide: {
    position: "absolute",
    left: "21.8%",
    color: theme.palette.gray.light,
  },
  userRightDivide: {
    position: "absolute",
    left: "40.55%",
    color: theme.palette.gray.light,
  },
  user: {
    position: "absolute",
    left: "23.75%",
    right: "61.33%",
    top: "28.57%",
    bottom: "28.57%",
    background: theme.palette.gray.light,
    borderRadius: "12px",
  },
  userId: {
    color: theme.palette.text.main,
    margin: "auto 12px",
    display: "block",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  selectLabel: {
    position: "absolute",
    left: "42.34%",
    right: "30.78%",
    top: "28.57%",
    bottom: "28.57%",
  },
  selectedLabel: {
    position: "absolute",
    left: "42.34%",
    right: "30.78%",
    top: "28.57%",
    bottom: "28.57%",
    background: theme.palette.gray.light,
    borderRadius: "12px",
    marginLeft: "-24px",
  },
  selectedLabelText: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "24px",
    color: "#AAAAAA",
    marginLeft: "12px",
  },
  labelRightDivide: {
    position: "absolute",
    left: "71.25%",
    right: "28.67%",
    top: "0%",
    bottom: "0%",
  },
  callAll: {
    position: "absolute",
    left: "73.2%",
    right: "19.3%",
    top: "28.57%",
    bottom: "28.57%",
  },
  soundOut: {
    position: "absolute",
    left: "81.33%",
    right: "16.8%",
    top: "28.57%",
    bottom: "28.57%",
  },
  soundIn: {
    position: "absolute",
    left: "83.83%",
    right: "14.3%",
    top: "28.57%",
    bottom: "28.57%",
  },

  logoutReftDivide: {
    position: "absolute",
    left: "87.58%",
    right: "12.34%",
    top: "0%",
    bottom: "0%",
  },
  logout: {
    position: "absolute",
    left: "89.53%",
    right: "6.95%",
    top: "35.71%",
    bottom: "33.93%",
  },
  logoutOnly: {
    position: "absolute",
    left: "94.61%",
    right: "1.88%",
    top: "35.71%",
    bottom: "33.93%",
  },
  setting: {
    position: "absolute",
    left: "94.53%",
    right: "1.8%",
    top: "35.71%",
    bottom: "33.93%",
  },
});

class Topbar extends Component {

  constructor(props){
    super(props);
    this.state = {
      isMain: props.isMain === undefined ? false : true,
      isCharacterEdit: props.isCharacterEdit === undefined ? false: true,
      isConfigOpen: false,
      isSoundSliderOpen: false,
    }
    // window.removeEventListener('beforeunload', this.handleBeforeUnload);
    // console.log("removeEventListener beforeunload8");
    // window.addEventListener('beforeunload', this.handleBeforeUnload);
    // console.log("addEventListener beforeunload8");

    window.removeEventListener('unload', this.handleBeforeUnload);
    // console.log("removeEventListener unload3");
    window.addEventListener('unload', this.handleBeforeUnload);
    // console.log("addEventListener unload3");
  }

  componentWillUnmount() {
    // 解除
    // window.removeEventListener('beforeunload', this.handleBeforeUnload, { capture: true });
    // console.log("removeEventListener beforeunload!!!!!!!!!!!!!");
  }

  handleBeforeUnload = async (e) => {
    // console.log(e);
    // console.log("handleBeforeUnload!!!!!!!!!!!!!");
    e.preventDefault();
    if(this.props.callingData && this.props.callingData.mode){
      // 仕様標準のメソッドを呼び出す
      // console.log("call!!!!!!!!!!!!!");
      // e.returnValue = '音声通話中です。';
      // returnValueに画面に表示したいメッセージを設定（Chromeはそれを無視してブラウザ定義のメッセージを表示）
      // e.returnValue = '音声通話中です。';
      // 呼出中または音声通話中
      if(this.props.callingData.mode === "broadcast"){
        // broadcastCallStop(this.propss.dispatch, this.props.user, this.props.selectedLabel.label, this.prrops.vguards, this.props.callingData);
      } else {
        for(let vguardItem of this.props.vguards){
          const callMode = this.props.callMode[vguardItem.vguard];
          if(callMode && callMode.status === "start" && callMode.mode === "call"){
            await callStopByBeforeunload(this.props.dispatch, this.props.user, this.props.selectedLabel.label, vguardItem)
            break;
          }
        }
      }
    }
    return e.returnValue = null;
  }

  onLogout = (e) => {
    e.preventDefault();
    if (!this.props.user) return;
    logout(this.props.dispatch, this.props.user);
  }

  onConfigOpen = (e) => {
    e.preventDefault();
    // settingMenu(this.props.dispatch, !this.props.isSettingMenuOpen);
    if(this.state.isCharacterEdit){
      this.props.history.push("/" + this.props.user.TenantId + "/characters");
    } else {
      this.props.history.push("/" + this.props.user.TenantId + '/vguards');
    }
  }

  onLabelChange = async (e) =>{
    await selectedLabelChange(this.props.dispatch, this.props.user.TenantId, e.target.value, this.props.listeners);
  }

  onBroadcastCallStart = async (e) => {
    e.preventDefault();
    onLoading(this.props.dispatch);
    await broadcastCallStart(this.props.dispatch, this.props.user, this.props.selectedLabel.label, this.props.vguards, this.props.volume, this.props.isMute, this.props.vguardNumbers);
    offLoading(this.props.dispatch);
  }

  onBroadcastCallStop = async (e) => {
    e.preventDefault();
    onLoading(this.props.dispatch);
    await broadcastCallStop(this.props.dispatch, this.props.user, this.props.selectedLabel.label, this.props.vguards, this.props.callingData);
    offLoading(this.props.dispatch);
  }

  onVolumeChange = (e, newValue) => {
    e.preventDefault();
    volumeChange(this.props.dispatch, newValue, this.props.callingData.remoteStreams);
  }

  onMuteChange = (e) => {
    e.preventDefault();
    muteChange(this.props.dispatch, !this.props.isMute, this.props.callingData.stream);
  }

  componentDidUpdate(){
    // console.log(this.props);
  }

  onDebug = () => {
    console.log(this.props.debaguState);
  }

  render() {
    const { classes } = this.props;
    const cid = this.props.match.params && this.props.match.params.cid;
    // 一斉通話の状態の制御
    let broadcastType = "start";
    let isOwnCalling = false;
    for(let vguardItem of this.props.vguards){
      const status = this.props.vguardStatus[vguardItem.vguard];
      if(status){
        if(status.colorStatus === "call" || status.colorStatus === "emergency"){
          broadcastType = "disabled";
          isOwnCalling = true;
          break;
        } else if (status.callStatus === "calling") {
          broadcastType = "disabled";
          if (this.props.callingData.mode) {
            isOwnCalling = true;
          }
          break;
        }
      }
    }
    if(this.props.callingData.mode === "call" || this.props.callingData.mode === "emergency" || this.props.callingData.mode === "broadcast"){
      for(let vguardItem of this.props.vguards){
        // TODO: 自身が音声通話をしている場合にステータスが変わってしまっても音声通話を切断できるようにしておく
        const callMode = this.props.callMode[vguardItem.vguard];
        // console.log(vguardItem, callMode);
        if(callMode){
          if(callMode.mode === "call"){
            // 音声通話をしている
            broadcastType = "disabled";
            isOwnCalling = true;
            break;
          } else if(callMode.mode === "broadcast"){
            broadcastType = "stop";
            isOwnCalling = true;
            break;
          }
        }
      }
    }
    // console.log(broadcastType);
    // 呼出音の制御
    let soundCallType = null;
    for(let vguardItem of this.props.vguards){
      const status = this.props.vguardStatus[vguardItem.vguard];
      if(status){
        if(status.colorStatus === "emergency" && status.callType === "callFromVguard" && status.callStatus !== "calling"){
          soundCallType = "emergency";
          break;
        } else if(status.colorStatus === "call" && status.callType === "callFromVguard" && status.callStatus !== "calling"){
          soundCallType = "call";
        }
      }
    }
    // 通話中のvguardがあったら音出さない
    for(let vguardItem of this.props.vguards){
      const status = this.props.vguardStatus[vguardItem.vguard];
      if(status && status.callStatus === "calling" && this.props.callingData && this.props.callingData.mode){
        soundCallType = null;
      }
    }
    return (
      <React.Fragment>
        <AppBar position="absolute" color="default" className={classes.appBar}>
          <Link to={"/" + (this.props.user ? this.props.user.TenantId : cid)} style={{ textDecoration: "none" }}>
            <img className={classes.icon} src="/images/logo.png" alt="logo" />
            <img className={classes.title} src="/images/vguard.png" alt="vguard" />
            <img className={classes.powered} src="/images/powered.png" alt="SECOM" />
          </Link>
          { this.props.user &&
            <React.Fragment>
              <Divider className={classes.userLeftDivide} orientation="vertical" />
              <div className={classes.user} onClick={() => this.onDebug()}>
                <span className={classes.userId}>
                  ID :{ this.props.user && this.props.user.displayName }
                </span>
              </div>
            </React.Fragment>
          }
          { this.state.isMain ?
            <React.Fragment>
              <Divider className={classes.userRightDivide} orientation="vertical" />
              <div className={classes.selectLabel}>
                <StyledFormControlSm variant="outlined" style={{ width: "100%" }}>
                  <StyledSelect value={this.props.selectedLabel && this.props.selectedLabel.label} onChange={this.onLabelChange} disabled={isOwnCalling} style={{ textAlign: "center" }}>
                  { this.props.labels.map(label => {
                    return (
                      <MenuItem key={label.label} value={label.label}>{ label.labelName }</MenuItem>
                    )
                  }) }
                  </StyledSelect>
                </StyledFormControlSm>
              </div>
              <Divider className={classes.labelRightDivide} orientation="vertical" />
              <div className={classes.callAll}>
                { (broadcastType === "start" && !this.props.loading) &&
                  <CustomButton themecolor="green" width={"100%"} height={24} onClick={this.onBroadcastCallStart} disabled={this.props.loading}>
                    <img src="/images/callAll.png" alt="一斉通話" />
                  </CustomButton>
                }
                { (broadcastType === "disabled" || this.props.loading) &&
                  <CustomButton width={"100%"} height={24} disabled={true} border="2px solid #58D16D" disablebackgroundcolor="white">
                    <img src="/images/callAll_green.png" alt="一斉通話" />
                  </CustomButton>
                }
                { (broadcastType === "stop" && !this.props.loading) &&
                  <CustomButton themecolor="red" width={"100%"} height={24} onClick={this.onBroadcastCallStop} disabled={this.props.loading}>
                    <img src="/images/callAll.png" alt="一斉通話" />
                  </CustomButton>
                }
                {/* { this.props.broadcastCallMode === "stop" &&
                <React.Fragment>
                  { this.props.callMode === "start" ?
                    <CustomButton width={"100%"} height={24} disabled={this.props.callMode === "start"} border="2px solid #58D16D" disablebackgroundcolor="white">
                      <img src="/images/callAll_green.png" alt="一斉通話" />
                    </CustomButton>
                  :
                    <CustomButton themecolor="green" width={"100%"} height={24} onClick={this.onBroadcastCallStart} disabled={this.props.callMode === "start"}>
                      <img src="/images/callAll.png" alt="一斉通話" />
                    </CustomButton>
                  }
                </React.Fragment>
                }
                { this.props.broadcastCallMode === "start" &&
                  <CustomButton themecolor="red" width={"100%"} height={24} onClick={this.onBroadcastCallStop}>
                    <img src="/images/callAll.png" alt="一斉通話" />
                  </CustomButton>
                } */}
              </div>
              <div className={classes.soundOut}>
                { !this.state.isSoundSliderOpen &&
                  <CustomButton themecolor="primary" width={24} height={24} onClick={() => this.setState({ isSoundSliderOpen: true })}>
                    <img src="/images/soundOut.png" alt="soundOut" />
                  </CustomButton>
                }
                { this.state.isSoundSliderOpen &&
                  <div style={{ width: 32, borderRadius:16, position: "absolute", top: -4,left: -4, backgroundColor: "white", boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.3)" }}>
                    <CustomButton themecolor="primary" width={24} height={24} onClick={() => this.setState({ isSoundSliderOpen: false })} style={{ marginTop: 4, marginLeft: 4 }}>
                      <img src="/images/soundOut.png" alt="soundOut" />
                    </CustomButton>
                    <Slider name="volume" orientation="vertical" value={this.props.volume} onChange={this.onVolumeChange}
                      style={{ height: 70, marginTop: 12, marginBottom: 12, marginLeft: 2 }} />
                  </div>
                }
              </div>
              <div className={classes.soundIn}>
                { !this.props.isMute &&
                  <CustomButton themecolor="primary" width={24} height={24} onClick={this.onMuteChange}>
                    <img src="/images/soundIn.png" alt="soundIn" />
                  </CustomButton>
                }
                { this.props.isMute &&
                  <CustomButton themecolor="gray" width={24} height={24} onClick={this.onMuteChange}>
                    <img src="/images/soundIn.png" alt="soundIn" />
                  </CustomButton>
                }
              </div>
            </React.Fragment>
          :
            <React.Fragment>
              { this.props.user &&
                <div className={classes.selectedLabel}>
                  <span className={classes.selectedLabelText}>
                    {this.props.selectedLabel && this.props.selectedLabel.labelName}
                  </span>
                </div>
              }
            </React.Fragment>
          }

          { this.state.isMain || this.state.isCharacterEdit ?
            <React.Fragment>
              { this.state.isMain &&
              <Divider className={classes.logoutReftDivide} orientation="vertical" />
              }
              <div className={classes.logout}>
                <ButtonBase onClick={this.onLogout} disabled={this.props.loading} disableRipple={this.props.loading} disableTouchRipple={this.props.loading}>
                  <img src="/images/logout.png" alt="ログアウト" />
                </ButtonBase>
              </div>
              <div className={classes.setting}>
                <ButtonBase onClick={this.onConfigOpen}>
                  <img src="/images/setting.png" alt="設定" />
                </ButtonBase>
              </div>
            </React.Fragment>
          :
            <React.Fragment>
              { this.props.user ?
                <div className={classes.logoutOnly}>
                <ButtonBase onClick={this.onLogout} disabled={this.props.loading} disableRipple={this.props.loading} disableTouchRipple={this.props.loading}>
                  <img src="/images/logout.png" alt="ログアウト" />
                </ButtonBase>
              </div>
              : null }
            </React.Fragment>
          }
        </AppBar>
        { this.props.user && soundCallType === "call" &&
          <audio src="/sounds/phone_call.mp3" autoPlay loop/>
        }
        { this.props.user && soundCallType === "emergency" &&
          <audio src="/sounds/phone_emergency.mp3" autoPlay loop/>
        }
      </React.Fragment>
    );
  }
}



export default withRouter(withStyles(styles)(
  connect(state => ({
    user: state.user && state.user.user,
    listeners: state.main && state.main.listeners,
    company: state.main && state.main.company,
    labels: (state.main && state.main.labels) || [],
    selectedLabel: state.main && state.main.selectedLabel,
    vguards: (state.main && state.main.vguards) || [],
    vguardStatus: (state.main && state.main.vguardStatus) || {},
    vguardNumbers: (state.main && state.main.vguardNumbers) || {},
    volume: state.voiceCalling && state.voiceCalling.volume,
    isMute: state.voiceCalling && state.voiceCalling.isMute,
    callMode: state.voiceCalling && state.voiceCalling.callMode,
    callingData: state.voiceCalling && state.voiceCalling.callingData,
    // broadcastCallMode: state.calling && state.calling.broadcastCallMode,
    loading: state.loading && state.loading.loading,
    isSettingMenuOpen: state.appState && state.appState.isSettingMenuOpen,
    debaguState: state,
  }))(Topbar)
));
