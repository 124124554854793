import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { login } from './actions/login';
import ErrorMessage from './errorMessage';
import { Field, getFormValues, reduxForm } from "redux-form";
import { renderSimpleTextField } from './formRenderer';
import { Link } from '@material-ui/core';
import { PrimaryButton } from './AppParts';
import { appError } from './actions/appAction';

export class Login extends Component {

  componentDidUpdate() {
    if (this.props.user) {
      const cid = this.props.match.params && this.props.match.params.cid;
      return this.props.history.replace('/' + cid);
    }
  }

  onLogin = (e) => {
    e.preventDefault();
    // console.log(this.props.formValues);
    const email = this.props.formValues.email;
    const password = this.props.formValues.password;
    const cid = this.props.match.params && this.props.match.params.cid;
    if (email && password) {
      login(this.props.dispatch, email, password, cid);
    } else {
      appError(this.props.dispatch, '入力内容が不足しています');
    }
  }

  onAddAccount = (e) =>{
    e.preventDefault();
    const cid = this.props.match.params && this.props.match.params.cid;
    this.props.history.push("/" + cid + "/addAccount");
  }

  render() {
    return (
      <div className="alignCenter verticalCenter" style={{ height: "100%", backgroundColor: "#fff" }}>
        <div>
          <ErrorMessage />
          <div className="alignCenter" style={{ margin: 20 }}>
            <img src="/images/logo_full.png" alt="vguard" />
          </div>
          <form>
            <div className="alignCenter mb20">
              <span className="f20">監視ツール</span>
            </div>
            <div className="alignCenter verticalCenter mb20">
              <span className="grayText f16 loginLabel">メールアドレス</span>
              <Field name="email" size="bg" component={renderSimpleTextField} style={{ width: 380 }} />
            </div>
            <div className="alignCenter verticalCenter mb30">
              <span className="grayText f16 loginLabel">パスワード</span>
              <Field name="password" type="password" size="bg" component={renderSimpleTextField} style={{ width: 380 }} />
            </div>
            <div className="alignCenter mb30">
              <Link className="f14" style={{ cursor: "pointer", textDecoration: "underline" }}>パスワードを忘れてしまった場合</Link>
            </div>
            <div className="alignCenter mb30">
              <Link onClick={this.onAddAccount} className="f14" style={{ cursor: "pointer", textDecoration: "underline" }}>監視ツールアカウント新規登録</Link>
            </div>
            <div className="alignCenter">
              <PrimaryButton onClick={this.onLogin} width={160} height={48}>ログイン</PrimaryButton>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

Login.contextTypes = {
  router: PropTypes.object
};

export default withRouter(
  connect(state => ({
    user: state.user && state.user.user,
    initialValues: {},
    formValues: getFormValues("loginForm")(state),
    company: state.main && state.main.company
  }))(reduxForm({ form: "loginForm", enableReinitialize: true })(Login))
);
