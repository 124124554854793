import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles, DialogContent, MenuItem } from "@material-ui/core";
import { connect } from "react-redux";
import Auth from "./auth";
import TopBar from "./TopBar";
import { Field, getFormValues, reduxForm } from "redux-form";
import { onLoading, offLoading } from "./actions/loading";
import { listContents, beginEditContent, beginSelectKeywordGroup, changeSelectKeywordGroup, changeEditingContent, MOTION_SHAGAMU, updateContentExec, CONTENT_TYPES, CONTENT_TYPE_SUCCESS, CONTENT_TYPE_SILENT, CONTENT_TYPE_REJECT, CONTENT_TYPE_RANGE_OUT, CONTENT_TYPE_RETRY_OVER, deleteContentExec, searchContent, debugContent, cancelEditContent, closeContent, CONTENT_TYPE_CALL_SUCCESS, CONTENT_TYPE_CALL_FAIL, CONTENT_TYPE_CALL_REJECT } from './actions/contents';
import { characterExists } from "./actions/characters";
import ErrorMessage from "./errorMessage";
import UpdateMessage from './updateMessage';
import { appRemoveError, appError, updateMessage } from "./actions/appAction";
import { renderSimpleSelectField, renderSimpleTextField, renderSimpleSwitch } from "./formRenderer";
import { required } from "./validator";
import muiStyles from "./MuiStyles";
import CharacterTopBar from "./CharacterTopBar";
import { EnhancedTable, GrayButton, PrimaryButton, BorderPrimaryButton, SecondaryButton, CloseButton, StyledDialog, KeywordDeleteButton, PlusButton, SelectedButton, NoSelectedButton, SearchTextField, BorderBackButton, Delete24Button, EditButtonSm, EditDialogButton } from "./AppParts";
import { formatDate, searchByTag } from "./utilities/utils";
import SelectDialog from './selectDialog';

// import { login } from "./actions/login";
// import { mainCompany, mainLablesListener } from "./actions/main";
// import { listCharacters } from "./actions/characters";


const headCells = [
  { id: 'stanbyFlag', label: '待受', isFlag: true, },
  { id: 'title', label: 'コンテンツ名' },
  { id: 'contentType', label: 'コンテンツ種別', modFunc: getContentTypeLabel },
  { id: 'keywordGroups', label: ['音声認識キーワード', 'グループ群'], isKeywordGroups: true, sortDisabled: true },
  { id: 'phraseRef.phraseTitle', label: 'フレーズ', isNest: true, },
  { id: 'updatedAt', label: '更新日時', modFunc: formatDate },
];

const editHeadCells = [
  { id: 'stanbyFlag', label: '待受', isFlag: true, },
  { id: 'title', label: 'コンテンツ名' },
  { id: 'keywordGroups', label: ['音声認識キーワード','グループ群'], isKeywordGroups: true, sortDisabled: true },
  { id: 'phraseRef.phraseTitle', label: 'フレーズ', isNest: true, },
];

const nextContentHeadCells = [
  { id: "", label: '選択', isSelectFlag: true, },
  { id: 'title', label: 'コンテンツ名' },
  { id: 'contentType', label: 'コンテンツ種別', modFunc: getContentTypeLabel },
  { id: 'keywordGroups', label: ['音声認識キーワード','グループ群'], isKeywordGroups: true, sortDisabled: true },
  { id: 'phraseRef.phraseTitle', label: 'フレーズ', isNest: true, },
];

function getContentTypeLabel(d){
  let result = d;
  for(let c of CONTENT_TYPES){
    if(d === c.key){
      result = c.value;
      break;
    }
  }
  return result;
}

export class Contents extends Component {
  state = {
    editingOriginalTitle: null,
    selectingKeywordGroup: false,
    selectingNextContent: false,
    nextContentSearch: null,
    nextContentSearchType: null,
    nextContentSearchSelected: false,
    searchedNextContentList: [],
    nextContentRefs: [],
    isDeleteConfirmDialog: false,
    deleteContent: null,
    deleteContentTitle: null,
    isDeleteKeywordGroupConfirmDialog: false,
    deleteKeywordGroupIndex: null,
    isDeleteNextContentConfirmDialog: false,
    deleteNextContent: null,
    message: null,
    add: false,
    editingPhrase: false,
    editingPicture: false,
  }

  async componentDidMount() {
    // TODO: デバッグ用
    // console.log("componentDidMount", this.props);
    // if(!this.props.user){
    //   const cid = this.props.match.params && this.props.match.params.cid;
    //   await login(this.props.dispatch, "test@test.com", "sskkotdotd", cid);
    // }
    // デバッグ用

    if(this.props.company && this.props.selectedLabel){
      const character = this.props.match.params && this.props.match.params.character;
      onLoading(this.props.dispatch);
      const exists = await characterExists(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, character);
      if (!exists) {
        this.backToCharacterPage();
        offLoading(this.props.dispatch);
        return;
      }
      await listContents(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, character, this.backToCharacterPage, true);
      offLoading(this.props.dispatch);
    }
  }

  async componentDidUpdate() {
    // TODO: デバッグ用
    // console.log("componentDidUpdate", this.props, this.state);
    // if(!this.props.company && this.props.user){
    //   await mainCompany(this.props.dispatch, this.props.user.TenantId);
    // }
    // if(this.props.company && !this.props.selectedLabel.label){
    //   await mainLablesListener(this.props.dispatch, this.props.user.TenantId, this.props.listeners);
    // }
    // if(this.props.company && this.props.selectedLabel.label && this.props.characters.length === 0){
    //   await listCharacters(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label);
    // }
    // if(this.props.company && this.props.selectedLabel && this.props.characters.length > 0 && !this.props.contents.length){
    //   const character = this.props.match.params && this.props.match.params.character;
    //   await listContents(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, character, this.backToCharacterPage);
    // }
    // デバッグ用

    if (this.props.isRefresh && !this.props.loading) {
      const character = this.props.match.params && this.props.match.params.character;
      onLoading(this.props.dispatch);
      await listContents(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, character, this.backToCharacterPage, false);
      offLoading(this.props.dispatch);
    }
    if (this.state.message) {
      updateMessage(this.props.dispatch, true, this.state.message);
      this.setState({ message: null });
    }
  }

  onSearch = (e) => {
    e.preventDefault();
    searchContent(this.props.dispatch, e.target.value, this.props.searchStanbyFlag, this.props.searchContentType);
  }

  onStanbyFilter = (searchStanbyFlag, searchContentType) => {
    searchContent(this.props.dispatch, this.props.search, searchStanbyFlag, searchContentType);
  }

  onBeginEdit = async (e, item) => {
    e.preventDefault();
    if (this.isFormChanged()) return;
    const character = this.props.match.params && this.props.match.params.character;
    const exists = await characterExists(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, character);
    if (!exists) {
      this.backToCharacterPage();
      return;
    }
    appRemoveError(this.props.dispatch);
    const newItem = Object.keys(item).length !== 0 ? false : true;
    beginEditContent(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, character, newItem ? null : item.content);
    this.setState({
      editingOriginalTitle: newItem ? null : item.title,
      add: newItem ? true : false,
    });
  }

  onBeginDialogEdit = (e, name) =>{
    e.preventDefault();
    this.setState({
      editingPhrase: name === "phrase",
      editingPicture: name === "picture",
    });
  }

  onCloseDialogEdit = (e) =>{
    e.preventDefault();
    this.setState({
      editingPhrase: false,
      editingPicture: false,
    });
  }

  onRegisterDialog = (name, value, change) => {
    const editingContent = { ...this.props.formValues };
    if (name === "phrase") {
      editingContent["phraseRef"] = value;
      if (change) {
        editingContent.isPhraseModelDiff = false;
      }
    } else if (name === "picture") {
      editingContent["pictureRef"] = value;
    }
    changeEditingContent(this.props.dispatch, editingContent);
  }

  onEditingChange = (e) =>{
    e.preventDefault();
    const editingContent = { ...this.props.formValues };
    editingContent[e.target.name] = e.target.value;
    if(e.target.name === "motionRef"){
      editingContent.isMotionModelDiff = false;
    } else if(e.target.name === "phraseRef"){
      editingContent.isPhraseModelDiff = false;
    }
    changeEditingContent(this.props.dispatch, editingContent);
  }

  onClose = () => {
    if (this.isFormChanged()) return;
    closeContent(this.props.dispatch);
    this.setState({
      editingOriginalTitle: null,
    });
  }

  onCancel = () => {
    const editingContent = { ...this.props.formValues };
    cancelEditContent(this.props.dispatch, editingContent.type);
  }

  onBeginKeywordGroupSelect = (e) => {
    e.preventDefault();
    appRemoveError(this.props.dispatch);
    const keywordGroups = this.props.editingContent.keywordGroups ? [...this.props.editingContent.keywordGroups] : [];
    beginSelectKeywordGroup(this.props.dispatch, this.props.user.TenantId, keywordGroups);
    this.setState({
      selectingKeywordGroup: true,
    });
  }

  onChange = (e) => {
    // console.log(e.target.value);
    const editingContent = {...this.props.formValues};
    if(e.target.name === "callFlag"){
      editingContent[e.target.name] = e.target.checked;
    } else {
      editingContent[e.target.name] = e.target.value;
    }
    if(editingContent.type !== CONTENT_TYPE_SUCCESS){
      editingContent.keywordGroupss = [];
      editingContent.stanbyFlag = false;
      if(editingContent.type !== CONTENT_TYPE_SILENT && editingContent.type !== CONTENT_TYPE_REJECT){
        editingContent.callFlag = false;
      }
    }
    if(!editingContent.callFlag){
      editingContent.emergencyFlag = false;
    }
    changeEditingContent(this.props.dispatch, editingContent);
  }

  onCloseNextContentSelect = (e) => {
    e.preventDefault();
    appRemoveError(this.props.dispatch);
    this.setState({
      selectingNextContent: false,
      nextContentSearchSelected: false,
    });
  }

  onCloseKeywordGroupSelect = (e) => {
    e.preventDefault();
    appRemoveError(this.props.dispatch);
    this.setState({
      selectingKeywordGroup: false,
    });
  }

  onKeywordGroupSelectedChange = (item) =>{
    const selectKeywordGroup = { ...this.props.selectKeywordGroup };
    selectKeywordGroup.selectedList = [...selectKeywordGroup.selectedList];
    if(selectKeywordGroup.selectedList.find(x => x.keywordGroup === item.keywordGroup)){
      // すでにある場合は削除
      selectKeywordGroup.selectedList = selectKeywordGroup.selectedList.filter(x => x.keywordGroup !== item.keywordGroup);
    } else {
      selectKeywordGroup.selectedList.push(item);
    }
    const editingContent = { ...this.props.formValues };
    editingContent.keywordGroups = [...selectKeywordGroup.keywordGroups];
    changeSelectKeywordGroup(this.props.dispatch, selectKeywordGroup, editingContent);
  }

  onSearchKeywordGroup = (e) => {
    const selectKeywordGroup = { ...this.props.selectKeywordGroup };
    const search = e.target.value;
    let list = selectKeywordGroup.originalList;
    if(search){
      list = selectKeywordGroup.originalList.filter(x => x.title.indexOf(search) >= 0);
    }
    selectKeywordGroup.searchList = [...list];
    const editingContent = { ...this.props.formValues };
    editingContent.keywordGroups = [...selectKeywordGroup.keywordGroups];
    changeSelectKeywordGroup(this.props.dispatch, selectKeywordGroup, editingContent);
  }

  onAddKeywordGroups = (e) => {
    e.preventDefault();
    if(!this.props.selectKeywordGroup.selectedList || !this.props.selectKeywordGroup.selectedList.length) return;
    const editingContent = { ...this.props.formValues };
    // console.log(editingContent);
    // console.log(editingContent.keywordGroups);
    // console.log(this.props.selectKeywordGroup.selectedList);
    for(let editingKeywordGroup of editingContent.keywordGroups){
      let containFlg = true;
      for(let item of this.props.selectKeywordGroup.selectedList){
        containFlg = editingKeywordGroup.some(x => x.keywordGroup === item.keywordGroup);
        if(!containFlg) break;
      }
      // console.log(editingKeywordGroup)
      // console.log(this.props.selectKeywordGroup.selectedList)
      if(containFlg && editingKeywordGroup.length === this.props.selectKeywordGroup.selectedList.length){
        // console.log(editingKeywordGroup)
        // console.log(this.props.selectKeywordGroup.selectedList)
        appError(this.props.dispatch, "同じ音声認識キーワードグループ群が既に設定されています。");
        return;
      }
    }
    editingContent.keywordGroups = [...editingContent.keywordGroups];
    editingContent.keywordGroups.push(this.props.selectKeywordGroup.selectedList);
    changeEditingContent(this.props.dispatch, editingContent);
    this.onCloseKeywordGroupSelect(e);
  }

  onChangeNextContent = (e, i) => {
    const editingContent = { ...this.props.formValues };
    editingContent.nextContentRefs = [...editingContent.nextContentRefs];
    editingContent.nextContentRefs[i] = e.target.value;
    editingContent[e.target.name] = e.target.value;
    changeEditingContent(this.props.dispatch, editingContent);
  }

  onOpenNextConent = (e) => {
    e.preventDefault();
    let list = [...this.props.contents];
    list = list.sort((a, b) => a.title < b.title ? -1 : 1)
    this.setState({
      selectingNextContent: true,
      nextContentSearch: "",
      nextContentSearchType: null,
      searchedNextContentList: list,
      nextContentRefs: [...this.props.editingContent.nextContentRefs],
    });
  }

  onSearchNextContent = (e) => {
    e.preventDefault();
    this.nextContentSearch(this.state.nextContentRefs, e.target.value, this.state.nextContentSearchType, this.state.nextContentSearchSelected);
  }

  onSearchNextContentType = (contentType) => {
    this.nextContentSearch(this.state.nextContentRefs, this.state.nextContentSearch, contentType, this.state.nextContentSearchSelected);
  }

  onSearchNextContentSelected = (e) => {
    e.preventDefault();
    this.nextContentSearch(this.state.nextContentRefs, this.state.nextContentSearch, this.state.nextContentSearchType, !this.state.nextContentSearchSelected);
  }

  nextContentSearch = (nextContentRefs, search, contentType, isSelected) => {
    let list = [...this.props.contents];
    if(search){
      list = searchByTag(list, "title", search);
    }
    if(contentType){
      list = list.filter(x => x.contentType === contentType);
    }
    if(isSelected){
      list = list.filter(x => nextContentRefs.indexOf(x.content) >= 0);
    }
    list = list.sort((a, b) => a.title < b.title ? -1 : 1)
    this.setState({
      nextContentSearch: search,
      nextContentSearchType: contentType,
      nextContentSearchSelected: isSelected,
      searchedNextContentList: list,
    });
  }

  onSelectNextContent = (e, item) => {
    e.preventDefault();
    let nextContentRefs = this.state.nextContentRefs;
    // console.log(nextContentRefs);
    if(nextContentRefs.indexOf(item.content) >= 0){
      nextContentRefs = nextContentRefs.filter(x => x !== item.content);
    } else {
      nextContentRefs.push(item.content);
    }
    this.setState({
      nextContentRefs: nextContentRefs
    });
  }

  onAddNextConent = (e) => {
    e.preventDefault();
    const editingContent = { ...this.props.formValues };
    editingContent.nextContentRefs = [...this.state.nextContentRefs];
    changeEditingContent(this.props.dispatch, editingContent);
    this.setState({
      selectingNextContent: false,
      nextContentSearchSelected: false,
    })
  }

  onUpdateExec = async (values) => {
    const editingContent = {...values}
    const character = this.props.match.params && this.props.match.params.character;
    const exists = await characterExists(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, character);
    if (!exists) {
      this.backToCharacterPage();
      return;
    }
    if(this.props.contents.find(x => x.content !== editingContent.content && x.title === editingContent.title)){
      appError(this.props.dispatch, "同じコンテンツ名がすでにあります。");
      return;
    }
    if(editingContent.type === CONTENT_TYPE_SUCCESS && !editingContent.keywordGroups.length){
      appError(this.props.dispatch, "音声認識キーワードグループ群が入力されていません。");
      return;
    }
    const c = editingContent.nextContentRefs.filter((x, i, self) => self.indexOf(x) !== self.lastIndexOf(x));
    if(c.filter(x => x !== "").length > 0){
      appError(this.props.dispatch, "次の遷移先に同じコンテンツが指定されています。");
      return;
    }
    if(editingContent.isMotionModelDiff){
      appError(this.props.dispatch, "モーションに異なるモデルのデータが指定されています。");
      return;
    }
    if(editingContent.isPhraseModelDiff){
      appError(this.props.dispatch, "フレーズに異なるモデルのデータが指定されています。");
      return;
    }
    appRemoveError(this.props.dispatch);
    onLoading(this.props.dispatch);
    const success = await updateContentExec(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, this.props.match.params.character, editingContent, this.backToCharacterPage);
    offLoading(this.props.dispatch);
    if (!success) return;
    this.setState({
      editingOriginalTitle: editingContent.title,
      message: this.state.add ? "register" : "update",
      add: false,
    });
  }

  onDeleteKeywordGroupConfirm = (e, index) => {
    e.preventDefault();
    this.setState({
      isDeleteKeywordGroupConfirmDialog: true,
      deleteKeywordGroupIndex: index,
    });
  }

  onDeleteKeywordGroups = (e) => {
    // console.log(index);
    e.preventDefault();
    if(this.state.deleteKeywordGroupIndex >= 0){
      const editingContent = { ...this.props.formValues };
      editingContent.keywordGroups = [...editingContent.keywordGroups];
      editingContent.keywordGroups = editingContent.keywordGroups.filter((x, i) => i !== this.state.deleteKeywordGroupIndex);
      changeEditingContent(this.props.dispatch, editingContent);
    }
    this.setState({
      isDeleteKeywordGroupConfirmDialog: false,
      deleteKeywordGroupIndex: null,
    });
  }

  onDeleteNextContentConfirm = (e, content) => {
    e.preventDefault();
    this.setState({
      isDeleteNextContentConfirmDialog: true,
      deleteNextContent: content,
    });
  }

  onDeleteNextContent = (e) => {
    // console.log(index);
    e.preventDefault();
    if(this.state.deleteNextContent){
      const editingContent = { ...this.props.formValues };
      editingContent.nextContentRefs = [...editingContent.nextContentRefs];
      editingContent.nextContentRefs = editingContent.nextContentRefs.filter(x => x !== this.state.deleteNextContent);
      changeEditingContent(this.props.dispatch, editingContent);
    }
    this.setState({
      isDeleteNextContentConfirmDialog: false,
      deleteNextContent: null,
    });
  }

  onDeleteConfirm = (e, item) => {
    e.preventDefault();
    this.setState({
      isDeleteConfirmDialog: true,
      deleteContent: item.content,
      deleteContentTitle: this.state.editingOriginalTitle || item.title,
    });
  }

  onDeleteExecute = async (e) =>{
    e.preventDefault();
    const character = this.props.match.params && this.props.match.params.character;
    const exists = await characterExists(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, character);
    if (!exists) {
      this.backToCharacterPage();
      return;
    }
    if(!this.state.deleteContent) return;
    appRemoveError(this.props.dispatch);
    onLoading(this.props.dispatch);
    await deleteContentExec(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, this.props.match.params.character, this.state.deleteContent);
    offLoading(this.props.dispatch);
    this.setState({
      isDeleteConfirmDialog: false,
      deleteContent: null,
      deleteContentTitle: null,
    });
  }

  onDebug = async(e) => {
    e.preventDefault();
    await debugContent(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, this.props.match.params.character);
  }

  getVariableArray = (key) =>{
    const a = this.props.editingContent[key] || [];
    return a.length ? a : [''];
  }

  isFormChanged = () => {
    if(this.props.editingContent && (this.props.pristine === false || this.props.editingContent.isEditingUpdate === true)){
      this.setState({ message: this.props.editingContent.content ? "changing_edit" : "changing_new" });
      return true;
    }
    return false;
  }

  backToCharacterPage = () => {
    this.props.history.push("/" + this.props.user.TenantId + "/characters");
  }

  onTagClick = (e, tag) => {
    e.preventDefault();
    const searchInput = document.querySelector('#searchContent');
    if (searchInput) {
      searchInput.value = tag;
    }
    searchContent(this.props.dispatch, tag, this.props.searchStanbyFlag, this.props.searchContentType);
  }

  onTagClickNextContent = (e, tag) => {
    e.preventDefault();
    const searchInput = document.querySelector('#searchNextContent');
    if (searchInput) {
      searchInput.value = tag;
    }
    this.nextContentSearch(this.state.nextContentRefs, tag, this.state.nextContentSearchType, this.state.nextContentSearchSelected);
  }

  render() {
    const { handleSubmit, submitting, classes } = this.props;
    let addBtnDisabled = true;
    if(this.props.selectKeywordGroup && this.props.selectKeywordGroup.selectedList && this.props.selectKeywordGroup.selectedList.length){
      addBtnDisabled = false;
    }
    let emergencyFlagDisabled = false, stanbyFlagDisalbed = false, keywordGroupsDisabled = false, callFlagDisabled = false, nextContentFlagDisabled = false;
    if(this.props.formValues){
      if(this.props.formValues.type !== CONTENT_TYPE_SUCCESS){
        keywordGroupsDisabled = true;
        stanbyFlagDisalbed = true;
        if(this.props.formValues.type !== CONTENT_TYPE_SILENT && this.props.formValues.type !== CONTENT_TYPE_REJECT && this.props.formValues.type !== CONTENT_TYPE_RETRY_OVER){
          callFlagDisabled = true;
        }
        if(this.props.formValues.type === CONTENT_TYPE_RANGE_OUT){
          nextContentFlagDisabled = true;
        }
      }
      if(!this.props.formValues.callFlag){
        emergencyFlagDisabled = true;
      }
    }
    const searchButtons = [
      [
        { label: "ALL", button: BorderPrimaryButton, stanbyFlag: false, contentType: null, width: 160 },
        { label: "待受", button: BorderPrimaryButton, stanbyFlag: true, contentType: null, width: 160 },
      ],
      [
        { label: "音声認識-成功", button: BorderPrimaryButton, stanbyFlag: true, contentType: CONTENT_TYPE_SUCCESS, width: 100 },
        { label: "音声認識-無発話", button: BorderPrimaryButton, stanbyFlag: true, contentType: CONTENT_TYPE_SILENT, width: 112 },
        { label: "音声認識-不認識", button: BorderPrimaryButton, stanbyFlag: true, contentType: CONTENT_TYPE_REJECT, width: 112 },
        { label: "退出時", button: BorderPrimaryButton, stanbyFlag: true, contentType: CONTENT_TYPE_RANGE_OUT, width: 64 },
        { label: "リトライオーバー時", button: BorderPrimaryButton, stanbyFlag: true, contentType: CONTENT_TYPE_RETRY_OVER, width: 130 },
        { label: "呼出成功時", button: BorderPrimaryButton, stanbyFlag: true, contentType: CONTENT_TYPE_CALL_SUCCESS, width: 86 },
        { label: "呼出失敗時", button: BorderPrimaryButton, stanbyFlag: true, contentType: CONTENT_TYPE_CALL_FAIL, width: 86 },
        { label: "呼出拒否時", button: BorderPrimaryButton, stanbyFlag: true, contentType: CONTENT_TYPE_CALL_REJECT, width: 86 },
      ]
    ];
    const searchNextContentButtons = [
      { label: "ALL", button: BorderPrimaryButton, stanbyFlag: false, contentType: null, width: 64 },
      ...searchButtons[1].map(s => {
        return {...s}
      })
    ];
    if(this.props.searchContentType){
      for(let searchButton of searchButtons[1]){
        if(this.props.searchContentType === searchButton.contentType){
          searchButton.button = PrimaryButton;
          break;
        }
      }
    } else {
      for(let searchButton of searchButtons[0]){
        if(this.props.searchStanbyFlag === searchButton.stanbyFlag){
          searchButton.button = PrimaryButton;
          break;
        }
      }
    }
    if(this.state.nextContentSearchType){
      for(let searchButton of searchNextContentButtons){
        if(this.state.nextContentSearchType === searchButton.contentType){
          searchButton.button = PrimaryButton;
          break;
        }
      }
    } else {
      searchNextContentButtons[0].button = PrimaryButton;
    }
    return (
      <React.Fragment>
        <Auth />
        <React.Fragment>
          <TopBar isCharacterEdit={true} />
          <div className={classes.content}>
            <div className={classes.characterMain}>
              <CharacterTopBar />
              <ErrorMessage />
              <UpdateMessage />
              <div className={classes.characerForm}>
                <div className="verticalCenter" style={{ justifyContent: "space-between", marginBottom: 8 }}>
                  <div>
                    { searchButtons[0].map((b, i) => {
                      const SearchButton = b.button;
                      let styles = { marginLeft: 0, width: b.width };
                      let count = this.props.contents.length;
                      if(i > 0){
                        styles.marginLeft = 8;
                        count = this.props.contents.filter(x => x.stanbyFlag).length;
                      }
                      return(
                        <SearchButton key={b.label + i} style={styles} onClick={() => this.onStanbyFilter(b.stanbyFlag, b.contentType)}><span className="f14">{ b.label } { count }件</span></SearchButton>
                      );
                    }) }
                  </div>
                  <div>
                    <SearchTextField id="searchContent" name="search" style={{ width: "280px" }} defaultValue={this.props.search} onChange={this.onSearch} />
                    {/* <SearchButton onClick={this.onSearch} style={{ marginLeft: 5 }} /> */}
                    {(this.props.user && this.props.user.isManager()) &&
                      <SecondaryButton onClick={(e) => this.onBeginEdit(e, {})} style={{ marginLeft: 5 }} disabled={this.props.loading}>新規登録</SecondaryButton>
                    }
                  </div>
                </div>
                <div style={{ marginBottom: 15 }}>
                  { searchButtons[1].map((b, i) => {
                    const SearchButton = b.button;
                    let styles = { marginLeft: 0, width: b.width };
                    if(i > 0){
                      styles.marginLeft = 8;
                    }
                    return(
                      <SearchButton key={b.label + i} style={styles} onClick={() => this.onStanbyFilter(b.stanbyFlag, b.contentType)}><span className="f12">{ b.label }</span></SearchButton>
                    );
                  }) }
                </div>
                { !this.props.editingContent &&
                <div className={classes.characterListContainer} style={{ height: "calc(100vh - 56px - 48px - 210px)" }}>
                  <EnhancedTable list={this.props.searchedContents} classes={classes} operationMinWidth={80}
                    headCells={headCells} editCallback={(this.props.user && this.props.user.isManager()) ? this.onBeginEdit : null} deleteCallback={(this.props.user && this.props.user.isManager()) ? this.onDeleteConfirm : null}
                    displayTag tagPosition={2} tagClickCallback={this.onTagClick} />
                </div>
                }
                <form onSubmit={handleSubmit(this.onUpdateExec)}>
                  { this.props.editingContent &&
                  <div className={classes.listEditContainer} style={{ height: "max(300px, calc(100vh - 56px - 48px - 210px))" }}>
                    <div className={classes.listEditList}>
                      <EnhancedTable list={this.props.searchedContents} classes={classes} selectCallback={(this.props.user && this.props.user.isManager()) ? this.onBeginEdit : null}
                        headCells={editHeadCells} selectItem={this.props.editingContent} selectKey="content" focusItem={this.props.editingContent} focusKey="content" isOperationHidden={true}
                        displayTag tagPosition={2} tagClickCallback={this.onTagClick} />
                    </div>
                    <div className={classes.listEditEdit}>
                      <div className={classes.arrowAndSideMenuContainer}>
                        <div className={classes.listEditSpace}>
                          <img src="/images/left_arrow.png" alt="<" />
                        </div>
                        <div className={classes.insideEditContainer} style={{ width: 431 }}>
                          <div className="verticalCenter" style={{ justifyContent: "space-between" }}>
                            <div className="verticalCenter">
                              <BorderBackButton onClick={this.onClose}/>
                              <span className="f16 subText ml8">音声対話シナリオ{this.state.add ? "新規登録" : "編集"}</span>
                            </div>
                            { (this.props.editingContent.content && this.props.user && this.props.user.isManager()) &&
                              <Delete24Button onClick={(e) => this.onDeleteConfirm(e, this.props.editingContent)} />
                            }
                          </div>
                          <div className="verticalCenter mb15" style={{ marginTop: 15 }}>
                            <span className="contentEditLabel">コンテンツ名</span>
                            <Field name="title" style={{ width: "100%" }} component={renderSimpleTextField} validate={[required]} />
                          </div>
                          <div className="verticalCenter mb15" style={{ marginTop: 15 }}>
                            <span className="contentEditLabel">タグ(任意)</span>
                            <Field name="tag" style={{ width: "100%" }} component={renderSimpleTextField} placeholder="#タグ1 #タグ2" />
                          </div>
                          <div className={classes.characterInsideEditScroll} style={{ maxHeight: "calc(100vh - 56px - 48px - 210px - 257px)" }}>
                            <div className="verticalCenter mb15">
                              <span className="contentEditLabel f12">待受</span>
                              <Field name="stanbyFlag" component={renderSimpleSwitch} disabled={stanbyFlagDisalbed}/>
                            </div>
                            <div className="verticalCenter mb15">
                              <span className="contentEditLabel f12">コンテンツ種別</span>
                              <Field name="type" component={renderSimpleSelectField} size={"sm"} style={{ marginRight: 10 }} disabled={this.props.editingContent.content ? true : false} onChange={this.onChange}>
                              { CONTENT_TYPES.map(item => (
                                <MenuItem key={item.key} value={item.key}>{ item.value }</MenuItem>
                              )) }
                              </Field>
                            </div>
                            <div className="verticalCenter mb15" style={{ alignItems: "baseline" }}>
                              <div className="contentEditLabel">
                                <div className="f12">音声認識</div>
                                <div className="f12">キーワード</div>
                                <div className="f12">グループ群</div>
                              </div>
                              <div style={{ width: "100%" }}>
                                { (this.props.editingContent.keywordGroups) && this.props.editingContent.keywordGroups.map((keywordGroup, i) => {
                                  return(
                                    <div key={i} className={classes.keywordListContainer}>
                                      <div style={{ margin: "2px 0" }}>
                                        <span className="keywordNumber" style={{ minWidth: 10 }}>{ i + 1 }.</span>
                                      </div>
                                      <div className={classes.flexKeywordList}>
                                        <div>
                                          { keywordGroup.map((item, j) => {
                                            return(
                                              <span key={j} className={classes.keyword}>{ item.title }</span>
                                            );
                                          }) }
                                        </div>
                                        <div style={{ minWidth: 60 }}>
                                          <KeywordDeleteButton onClick={(e) => this.onDeleteKeywordGroupConfirm(e, i)}><span className="primaryText f10">[ 削除 ]</span></KeywordDeleteButton>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                }) }
                                <div className="verticalCenter" style={{ justifyContent: "space-between" }}>
                                  <div>
                                  {/* { (this.props.editingContent.type === CONTENT_TYPE_SUCCESS && !this.props.editingContent.keywordGroups.length) &&
                                    <div style={{ color: "#e53935", margin: "0 14px", fontSize: "0.643rem" }}>必須入力</div>
                                  } */}
                                  </div>
                                  <div style={{ textAlign: "right", marginRight: 15 }}>
                                    <PlusButton onClick={this.onBeginKeywordGroupSelect} disabled={keywordGroupsDisabled}/>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="verticalCenter mb15">
                              <span className="contentEditLabel f12">モーション</span>
                              <Field name="motionRef" component={renderSimpleSelectField} size={"sm"} style={{ marginRight: 10 }} displayEmpty textcolor={this.props.editingContent.isMotionModelDiff && "red"} onChange={this.onEditingChange}>
                                <MenuItem value="">[状態維持]</MenuItem>
                                { this.props.motionMenuItems.map((item, i) => (
                                  <MenuItem key={i} value={item.motion} style={{display: item.disabled && "none"}}>{ item.title }</MenuItem>
                                )) }
                              </Field>
                            </div>
                            <div className="verticalCenter mb15">
                              <span className="contentEditLabel f12">音声フレーズ</span>
                              <Field name="phraseRef" component={renderSimpleSelectField} size={"phrase"} style={{ marginRight: 10 }} displayEmpty SelectDisplayProps={{ style: {paddingRight: 45} }}
                                textcolor={this.props.editingContent.isPhraseModelDiff ? "#F8431C" : "#444444"} disabled IconComponent={() => (
                                  <EditDialogButton onClick={(e) => this.onBeginDialogEdit(e, "phrase")}>[編集]</EditDialogButton>
                                  )}
                                >
                                <MenuItem value="">[再生しない]</MenuItem>
                                {this.props.phraseMenuItems.map((item, i) => (
                                  <MenuItem key={i} value={item.phrase}>{item.phraseTitle}</MenuItem>
                                ))}
                              </Field>
                              <SelectDialog
                                open={this.state.editingPhrase}
                                name="phrase"
                                type="table"
                                list={[{phrase: '', phraseTitle: '[再生しない]'}].concat(this.props.phraseMenuItems)}
                                initialSelected={this.props.editingContent.phraseRef}
                                isDiff={this.props.editingContent.isPhraseModelDiff}
                                onRegister={this.onRegisterDialog}
                                isCreate={!this.props.editingContent.createdAt}
                                onClose={this.onCloseDialogEdit} />
                            </div>
                            <div className="verticalCenter mb15">
                              <span className="contentEditLabel f12">再生タイプ</span>
                              <Field name="playType" component={renderSimpleSelectField} size={"sm"} style={{ marginRight: 10 }} >
                                <MenuItem value="SyncSoundAndMotion">音声・モーション同時再生</MenuItem>
                                <MenuItem value="SoundAfterMotion">音声再生のあとにモーション再生</MenuItem>
                                <MenuItem value="MotionAfterSound">モーション再生のあとに音声再生</MenuItem>
                              </Field>
                            </div>
                            <div className="verticalCenter mb15">
                              <span className="contentEditLabel f12">案内画像</span>
                              <Field name="pictureRef" component={renderSimpleSelectField} size={"sm"} style={{ marginRight: 10 }} displayEmpty SelectDisplayProps={{ style: {paddingRight: 45} }}
                                textcolor="#444444" disabled IconComponent={() => (
                                  <EditDialogButton onClick={(e) => this.onBeginDialogEdit(e, "picture")}>[編集]</EditDialogButton>)}
                                >
                                <MenuItem value="">[非表示]</MenuItem>
                                {this.props.pictures.map((item, i) => (
                                  <MenuItem key={i} value={item.picture}>{item.title}</MenuItem>
                                ))}
                              </Field>
                              <SelectDialog
                                open={this.state.editingPicture}
                                name="picture"
                                type="table"
                                list={[{picture: '', title: '[非表示]'}].concat(this.props.pictures)}
                                initialSelected={this.props.editingContent.pictureRef}
                                onRegister={this.onRegisterDialog}
                                isCreate={!this.props.editingContent.createdAt}
                                onClose={this.onCloseDialogEdit} />
                            </div>
                            <div className="verticalCenter mt5mb10">
                              <span className="contentEditLabel f12">低身長<br></br>モーション</span>
                              <Field name="childTallMotionRef" component={renderSimpleSelectField} size={"sm"} style={{ marginRight: 10 }} displayEmpty>
                                <MenuItem value="">[しない]</MenuItem>
                                <MenuItem value={MOTION_SHAGAMU}>しゃがみ</MenuItem>
                              </Field>
                            </div>
                            <div className="verticalCenter mb15" style={{ alignItems: "baseline" }}>
                              <span className="contentEditLabel f12">次の遷移先</span>
                              <div style={{ width: "100%" }}>
                                { this.props.editingContent.nextContentRefs.map((content, i) => {
                                  const d = this.props.contents.find(x => x.content === content);
                                  return(
                                    <div key={content} style={{ margin: "8px 0", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                      <div className={classes.grayFieldBack}>{ d.title }</div>
                                      <div style={{ minWidth: 60 }}>
                                        <KeywordDeleteButton onClick={(e) => this.onDeleteNextContentConfirm(e, content)}><span className="primaryText f10">[ 削除 ]</span></KeywordDeleteButton>
                                      </div>
                                    </div>
                                  )
                                }) }
                                <div style={{ textAlign: "right", marginRight: 15 }}>
                                  <EditButtonSm onClick={(e) => this.onOpenNextConent(e)} disabled={nextContentFlagDisabled} />
                                </div>
                              </div>
                            </div>
                            <div className="verticalCenter mb15">
                              <span className="f12" style={{ marginRight: 10 }}>音声通話</span>
                              <Field name="callFlag" component={renderSimpleSwitch} disabled={callFlagDisabled} onChange={this.onChange} />
                              <span className="f12" style={{ marginRight: 10, marginLeft: 20 }}>緊急</span>
                              <Field name="emergencyFlag" component={renderSimpleSwitch} disabled={emergencyFlagDisabled} style={{ marginLeft: 15 }} />
                            </div>
                            <div className="verticalCenter mb15">
                              <span className="contentEditLabel f12">作成日時</span>
                              <span className="f12">{ formatDate(this.props.editingContent.createdAt) }</span>
                            </div>
                          </div>
                          <div className="verticalCenter alignCenter" style={{ marginTop: 25 }}>
                            <GrayButton width={120} height={32} onClick={this.onCancel} style={{ marginRight: 20 }}>リセット</GrayButton>
                            <PrimaryButton type="submit" width={120} height={32} disabled={submitting}>{this.state.add ? "登録する" : "更新する"}</PrimaryButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  }
                </form>
              </div>
            </div>
          </div>
          <StyledDialog open={this.state.isDeleteConfirmDialog} onClose={() => this.setState({ isDeleteConfirmDialog: false, deleteContent: null })}>
            <DialogContent className={classes.dialogContent}>
              <div className={classes.closeButton}>
                <CloseButton onClick={() => this.setState({ isDeleteConfirmDialog: false, deleteContent: null })}/>
              </div>
              <div className="deletePopupContentText">
                <div>
                  <div className="wordKeepAll">「{this.state.deleteContentTitle}</div>
                  <div className="wordKeepAllClose">」</div>
                  を削除しますか？
                </div>
                <div style={{ marginTop: 36 }}>
                  <PrimaryButton width={160} height={48} onClick={(e) => this.onDeleteExecute(e)} disabled={this.props.loading}>削除する</PrimaryButton>
                  <GrayButton width={160} height={48} onClick={() => this.setState({ isDeleteConfirmDialog: false, deleteContent: null })} style={{ marginLeft: 20 }}>キャンセル</GrayButton>
                </div>
              </div>
            </DialogContent>
          </StyledDialog>
          <StyledDialog open={this.state.isDeleteKeywordGroupConfirmDialog} onClose={() => this.setState({ isDeleteKeywordGroupConfirmDialog: false, deleteKeywordGroupIndex: null })}>
            <DialogContent className={classes.dialogContent}>
              <div className={classes.closeButton}>
                <CloseButton onClick={() => this.setState({ isDeleteKeywordGroupConfirmDialog: false, deleteKeywordGroupIndex: null })}/>
              </div>
              <div className="deletePopupContentText">
                <div>削除しますか？</div>
                <div style={{ marginTop: 36 }}>
                  <PrimaryButton width={160} height={48} onClick={(e) => this.onDeleteKeywordGroups(e)} disabled={this.props.loading}>削除する</PrimaryButton>
                  <GrayButton width={160} height={48} onClick={() => this.setState({ isDeleteKeywordGroupConfirmDialog: false, deleteKeywordGroupIndex: null })} style={{ marginLeft: 20 }}>キャンセル</GrayButton>
                </div>
              </div>
            </DialogContent>
          </StyledDialog>
          <StyledDialog open={this.state.isDeleteNextContentConfirmDialog} onClose={() => this.setState({ isDeleteNextContentConfirmDialog: false, deleteNextContent: null })}>
            <DialogContent className={classes.dialogContent}>
              <div className={classes.closeButton}>
                <CloseButton onClick={() => this.setState({ isDeleteNextContentConfirmDialog: false, deleteNextContent: null })}/>
              </div>
              <div className="deletePopupContentText">
                <div>削除しますか？</div>
                <div style={{ marginTop: 36 }}>
                  <PrimaryButton width={160} height={48} onClick={(e) => this.onDeleteNextContent(e)} disabled={this.props.loading}>削除する</PrimaryButton>
                  <GrayButton width={160} height={48} onClick={() => this.setState({ isDeleteNextContentConfirmDialog: false, deleteNextContent: null })} style={{ marginLeft: 20 }}>キャンセル</GrayButton>
                </div>
              </div>
            </DialogContent>
          </StyledDialog>
        </React.Fragment>
        <StyledDialog open={this.state.selectingNextContent} onClose={this.onCloseNextContentSelect} PaperProps={{ style: { width: 1144, height: "auto", minHeight: 776 } }}>
          <DialogContent className={classes.dialogContent} style={{ padding: 32 }}>
            <div className={classes.closeButton}>
              <CloseButton onClick={this.onCloseNextContentSelect}/>
            </div>
            <div>
              <div style={{ width: "100%", textAlign: "center" }}>
                <span className="f28">次の遷移先 選択</span>
              </div>
              <div className={classes.insideEditContainer} style={{ width: 1056, padding: "32px 48px" }}>
                <div className="verticalCenter alignCenter mb30">
                  <SearchTextField id="searchNextContent" style={{ width: "100%", maxWidth: 496 }} defaultValue="" onChange={this.onSearchNextContent} />
                </div>
                <div className="mb16" style={{ textAlign: "center" }}>
                  { searchNextContentButtons.map((b, i) => {
                    const SearchButton = b.button;
                    let styles = { marginLeft: 0, marginTop: 8, width: b.width };
                    if(i > 0){
                      styles.marginLeft = 8;
                    }
                    return(
                      <SearchButton key={b.label + i} style={styles} onClick={() => this.onSearchNextContentType(b.contentType)}><span className="f12">{ b.label }</span></SearchButton>
                    );
                  }) }
                </div>
                <div className="verticalCenter mb16" style={{ justifyContent: "space-between", margin: "0 24px" }}>
                  <div className="verticalCenter">
                    { this.state.nextContentSearchSelected ?
                      <img src="/images/flag_true.png" alt="t" style={{ cursor: "pointer" }} onClick={this.onSearchNextContentSelected} />
                    :
                      <img src="/images/flag_false.png" alt="f"  style={{ cursor: "pointer" }} onClick={this.onSearchNextContentSelected}/>
                    }
                    <span className="f12" style={{ marginLeft: 4 }}>選択された項目のみ表示</span>
                  </div>
                  <div>
                    <span className="f16">
                      { this.state.nextContentRefs.length }/{ this.props.contents.filter(x => this.props.editingContent && x.content !== this.props.editingContent.content).length }
                    </span>
                  </div>
                </div>
                <div className={classes.selectListContainer + " mb25"} style={{ padding: "10px 20px 20px", height: 374 }}>
                  <div style={{ height: 336 }}>
                  <EnhancedTable list={this.state.searchedNextContentList.filter(x => this.props.editingContent && x.content !== this.props.editingContent.content)} classes={classes} headCells={nextContentHeadCells} isOperationHidden={true}
                    selectItems={this.state.nextContentRefs} selectKey="content"
                    selectCallback={this.onSelectNextContent} isCheckBoxSelect={true}
                    displayTag tagPosition={2} tagClickCallback={this.onTagClickNextContent}
                  />
                  </div>
                </div>
                <div className="alignCenter" >
                  <GrayButton width={120} height={32} onClick={this.onCloseNextContentSelect}>キャンセル</GrayButton>
                  <PrimaryButton width={120} height={32} onClick={this.onAddNextConent} style={{ marginLeft: 20 }}>更新する</PrimaryButton>
                </div>
              </div>
            </div>
          </DialogContent>
        </StyledDialog>
        <StyledDialog open={this.state.selectingKeywordGroup} onClose={this.onCloseKeywordGroupSelect} PaperProps={{ style: { width: 720, minHeight: 776 } }}>
          <DialogContent className={classes.dialogContent} style={{ alignItems: "baseline" }}>
            <div className={classes.closeButton}>
              <CloseButton onClick={this.onCloseKeywordGroupSelect}/>
            </div>
            <div>
              <div style={{ width: "100%", textAlign: "center" }}>
                <span className="f28">音声認識キーワードグループ群追加</span>
              </div>
              <div className={classes.insideEditContainer} style={{ width: 560 }}>
                <div className="verticalCenter mb15">
                  <span className="f16 subText">音声認識キーワードグループ群登録</span>
                </div>
                <div className="verticalCenter mb15" style={{ margin: "0 20px 15px 20px" }}>
                  <SearchTextField style={{ width: "100%" }} defaultValue="" onChange={this.onSearchKeywordGroup} />
                </div>
                <div className={classes.selectListContainer} style={{ padding: "15px 0 15px 15px" }}>
                  <div style={{ height: 300, maxHeight: 300, overflowY: "auto" }}>
                  { this.props.selectKeywordGroup.searchList.map((item, i) => {
                    let Component = SelectedButton;
                    if(!this.props.selectKeywordGroup.selectedList.find(x => x.keywordGroup === item.keywordGroup)){
                      Component = NoSelectedButton;
                    }
                    return(
                      <div key={i} style={{ marginBottom: 5 }}>
                        <Component onClick={() => this.onKeywordGroupSelectedChange(item)}>{ item.title }</Component>
                      </div>
                    );
                  }) }
                  </div>
                </div>
                <div className="mb15" style={{ padding: "15px 0 15px 15px", backgroundColor: "#eee", borderRadius: 6, maxHeight: 72, overflowY: "auto" }}>
                  { this.props.selectKeywordGroup.selectedList.map((item, i)=>{
                    return(
                      // <Button key={i} variant="outlined" className={classes.selectedBtn} onClick={() => this.onKeywordGroupSelectedChange(item)}>{ item.title }</Button>
                      <SelectedButton key={i} onClick={() => this.onKeywordGroupSelectedChange(item)} style={{ marginBottom: 5, marginRight: 5, paddingRight: 22 }}
                        endIcon={<img src={"/images/batu.png"} style={{ position: "absolute", right: 3, top: "50%", transform: "translateY(-50%)" }} alt="削除" />}>
                        { item.title }
                      </SelectedButton>
                    );
                  }) }
                </div>
                <div className="mb15 alignCenter" >
                  <span className="f14 grayText">上記結果を選択したものをAND条件で音声認識キーワードグループ群へ</span>
                </div>
                <div className="alignCenter" >
                  <GrayButton width={120} height={32} onClick={this.onCloseKeywordGroupSelect}>キャンセル</GrayButton>
                  <PrimaryButton width={120} height={32} onClick={this.onAddKeywordGroups} style={{ marginLeft: 20 }} disabled={addBtnDisabled}>追加する</PrimaryButton>
                </div>
              </div>
            </div>
          </DialogContent>
        </StyledDialog>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(muiStyles)(
  connect(state => ({
    user: state.user && state.user.user,
    listeners: state.main && state.main.listeners,
    company: state.main && state.main.company,
    selectedLabel: state.main && state.main.selectedLabel,
    characters: (state.characters && state.characters.characters) || [],
    contents: (state.contents && state.contents.contents) || [],
    paths: (state.contents && state.contents.paths) || [],
    searchedContents: (state.contents && state.contents.searchedContents) || [],
    search: state.contents && state.contents.search,
    searchStanbyFlag: state.contents && state.contents.searchStanbyFlag,
    searchContentType: state.contents && state.contents.searchContentType,
    editingContent: state.contents && state.contents.editingContent,
    selectKeywordGroup: (state.contents && state.contents.selectKeywordGroup) || {},
    nextContentRefs: (state.contents && state.contents.nextContentRefs) || [],
    phrases: (state.contents && state.contents.phrases) || [],
    motions: (state.contents && state.contents.motions) || [],
    phraseMenuItems: (state.contents && state.contents.phraseMenuItems) || [],
    motionMenuItems: (state.contents && state.contents.motionMenuItems) || [],
    contentMenuItems: (state.contents && state.contents.contentMenuItems) || [],
    pictures: (state.contents && state.contents.pictures) || [],
    initialValues: (state.contents && state.contents.editingContent) || {},
    formValues: getFormValues("contentForm")(state),
    isRefresh: state.contents && state.contents.isRefresh,
    loading: state.loading && state.loading.loading,
    pathNetwork: (state.contents && state.contents.pathNetwork) || [],
  }))(reduxForm({ form: "contentForm", enableReinitialize: true })(Contents))
));
