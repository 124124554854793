import db, { getDocData, getDocsOrderBy, getModelsData, getPhrasesData, getManualCommandGroupsData, getPicturesData, getMotionsDataExcludeNod } from "../utilities/db";
import firebase from 'firebase/app';
import { appErrorAction, dbSetDataFailedErrorAction } from './appAction';
import { checkModel } from "../utilities/utils";
import { characterExists, characterNotFoundErrorAction } from "./characters";

export async function listManualCommands(dispatch, cid, label, character, characterNotFoundCallback, isInit=false){
  try{
    // console.log(cid, label, character);
    const companyDoc = db().collection("companies").doc(cid);
    const characterDoc = companyDoc.collection("labels").doc(label).collection("characters").doc(character);
    let par = [
      getModelsData(companyDoc),
      getDocData(characterDoc, "character"),
      getDocsOrderBy(characterDoc.collection("manualCommands"), "manualCommand", [{ column: "updatedAt", type: "desc" }]),
      getManualCommandGroupsData(companyDoc),
      getPhrasesData(companyDoc),
      getMotionsDataExcludeNod(companyDoc),
      getPicturesData(companyDoc.collection("labels").doc(label))
    ];
    const [models, characterData, manualCommands, manualCommandsGroups, phrases, motions,pictures ] = await Promise.all(par);
    if (characterData === null) {
      dispatch(characterNotFoundErrorAction());
      characterNotFoundCallback();
      return;
    }
    // refの値の変更
    characterData.modelRef = characterData.modelRef && models.find(x => x.model === characterData.modelRef.id);
    phrases.forEach(item => { if(item.modelRef) item.modelRef = models.find(x => x.model === item.modelRef.id) });
    motions.forEach(item => { if(item.modelRef) item.modelRef = models.find(x => x.model === item.modelRef.id) });
    manualCommands.forEach(item => {
      // フレーズなどはDocumentReferenceが入ってくるので、それぞれの一覧用配列から値を参照できるようにデータを持ってくる
      if(item.manualCommandGroupRef) item.manualCommandGroupRef = manualCommandsGroups.find(x => x.manualCommandsGroup === item.manualCommandGroupRef.id);
      if(item.phraseRef) item.phraseRef = phrases.find(x => x.phrase === item.phraseRef.id);
      if(item.motionRef) item.motionRef = motions.find(x => x.motion === item.motionRef.id);
      if(item.pictureRef) item.pictureRef = pictures.find(x => x.picture === item.pictureRef.id);
    });

    // 手動操作コマンドグループの並び順を反映
    let manualCommandsGroupsOrder = [];
    if(characterData.manualCommandGroupsOrder){
      characterData.manualCommandGroupsOrder.forEach(doc => {
        const item = manualCommandsGroups.find(x => x.id === doc.id);
        if(item) manualCommandsGroupsOrder.push(item);
      })
    } else {
      manualCommandsGroupsOrder = manualCommandsGroups;
    }

    // モデルと設定されているフレーズやモーションが異なるか確認
    checkModel(manualCommands, characterData.modelRef.model);
    dispatch(listManualCommandsAction(characterData, manualCommands, manualCommandsGroupsOrder, phrases, motions, pictures, isInit));
  } catch (e) {
    dispatch(appErrorAction(e));
  }
}

export function searchManualCommand(dispatch, search){
  dispatch({
    type: "MANUAL_COMMAND_SEARCH",
    payload: {
      search: search,
    }
  });
}

export async function beginEditManualCommand(dispatch, cid, label, character, manualCommand){
  const companyDoc = db().collection("companies").doc(cid);
  const labelDoc = companyDoc.collection("labels").doc(label);
  const characterDoc = labelDoc.collection("characters").doc(character);
  let data = {
    manualCommandGroups: null,
    phrases: null,
    motions: null,
    pictures: null,
    character: null,
  }
  let par = [
    getManualCommandGroupsData(companyDoc),
    getPhrasesData(companyDoc),
    getMotionsDataExcludeNod(companyDoc),
    getPicturesData(labelDoc),
    getDocData(characterDoc, "character"),
  ];
  const items = await Promise.all(par);
  Object.keys(data).forEach((key, i) => data[key] = items[i]);
  dispatch(beginEditManualCommandAction(manualCommand, data));
}

export async function changeEditingManualCommand(dispatch, editingManualCommand){
  dispatch(changeEditingManualCommandAction(editingManualCommand));
}

export async function closeManualCommand(dispatch){
  dispatch({ type: "MANUAL_COMMAND_CLOSE" });
}

export function cancelEditManualCommand(dispatch){
  dispatch({
    type: "MANUAL_COMMAND_EDIT_CANCEL",
  })
}

export async function updateManualCommandExec(dispatch, cid, label, characterData, editingManualCommand, characterNotFoundCallback){
  // console.log(cid, label, characterData, editingManualCommand);
  let manualCommandDoc;
  try{
    const companyDoc = db().collection("companies").doc(cid)
    const characterDoc = companyDoc.collection("labels").doc(label).collection("characters").doc(characterData.character);
    const data = {};
    data.title = editingManualCommand.title;
    data.manualCommandGroupRef = editingManualCommand.manualCommandGroupRef ? companyDoc.collection("manualCommandsGroups").doc(editingManualCommand.manualCommandGroupRef) : null;
    data.motionRef = editingManualCommand.motionRef ? companyDoc.collection("motions").doc(editingManualCommand.motionRef) : null;
    data.phraseRef = editingManualCommand.phraseRef ? companyDoc.collection("phrases").doc(editingManualCommand.phraseRef): null;
    data.playType = editingManualCommand.playType;
    data.pictureRef = editingManualCommand.pictureRef ? companyDoc.collection("labels").doc(label).collection("guide-pictures").doc(editingManualCommand.pictureRef): null;
    data.digitalOut = editingManualCommand.digitalOut !== "" ? editingManualCommand.digitalOut : null;
    data.digitalOutOneShotTime = Number(editingManualCommand.digitalOutOneShotTime.toString().replace(/,/g, ""));
    data.timeoutType = editingManualCommand.timeoutType;
    data.timeoutSec = editingManualCommand.timeoutType === "Instantly" ? 0 : Number(editingManualCommand.timeoutSec.toString().replace(/,/g, ""));
    data.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
    if(!editingManualCommand.manualCommand){
      // 新規作成
      data.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      manualCommandDoc = characterDoc.collection("manualCommands").doc();
      await manualCommandDoc.set(data);
    } else {
      // 更新
      manualCommandDoc = characterDoc.collection("manualCommands").doc(editingManualCommand.manualCommand);
      const exists = await manualCommandExists(dispatch, manualCommandDoc);
      if (!exists) return false;
      await manualCommandDoc.update(data);
    }
    const updated = await manualCommandDoc.get();
    if (!updated.exists) {
      dispatch(manualCommandNotFoundErrorAction());
      dispatch(refreshManualCommandAction());
      return false;
    }
    editingManualCommand.createdAt = updated.data().createdAt;
    editingManualCommand.updatedAt = updated.data().updatedAt;
    editingManualCommand.manualCommand = manualCommandDoc.id;
    dispatch(updatedManualCommandAction(editingManualCommand));
    return true;
  } catch(e) {
    // セキュリティールールありだとpermission-denied、なしだとnot-foundエラーが返る
    if (e && e.name === 'FirebaseError' && (e.code === 'permission-denied' || e.code === 'not-found')) {
      const exists = await characterExists(dispatch, cid, label, characterData.character);
      if (!exists) {
        characterNotFoundCallback();
        return false;
      }
      if (editingManualCommand.manualCommand && manualCommandDoc) {
        // 更新の場合
        const editingCommandExists = await manualCommandExists(dispatch, manualCommandDoc);
        if (!editingCommandExists) return false;
      }
      dispatch(dbSetDataFailedErrorAction());
      return false;
    }
    dispatch(appErrorAction(e));
  }
  return false;
}

export async function deleteManualCommandExec(dispatch, cid, label, characterData, manualCommand){
  try{
    const manualCommandDoc = db().collection("companies").doc(cid).collection("labels").doc(label).collection("characters").doc(characterData.character).collection("manualCommands").doc(manualCommand);
    const exists = await manualCommandExists(dispatch, manualCommandDoc);
    if (!exists) return;
    await manualCommandDoc.delete();
    dispatch(deleteManualCommandAction(manualCommand));
  } catch(e){
    dispatch(appErrorAction(e));
  }
}

async function manualCommandExists(dispatch, manualCommandDoc) {
  const manualCommand = await manualCommandDoc.get();
  if (!manualCommand.exists) {
    dispatch(manualCommandNotFoundErrorAction());
    dispatch(refreshManualCommandAction());
    return false;
  }
  return true;
}

function listManualCommandsAction(characterData, manualCommands, manualCommandsGroups, phrases, motions, pictures, isInit){
  return {
    type: "LIST_MANUAL_COMMANDS",
    payload: {
      characterData: characterData,
      manualCommands: manualCommands,
      manualCommandsGroups: manualCommandsGroups,
      phrases: phrases,
      motions: motions,
      pictures: pictures,
      isInit: isInit,
    }
  }
}

function beginEditManualCommandAction(manualCommand, data){
  return {
    type: "BEGIN_EDIT_MANUAL_COMMAND",
    payload: {
      manualCommand: manualCommand,
      manualCommandGroups: data.manualCommandGroups,
      phrases: data.phrases,
      motions: data.motions,
      pictures: data.pictures,
      character: data.character,
    }
  }
}

function changeEditingManualCommandAction(editingManualCommand){
  return {
    type: "CHANGE_EDITING_MANUAL_COMMAND",
    payload: {
      editingManualCommand: editingManualCommand,
    }
  }
}

function updatedManualCommandAction(editingManualCommand){
  return {
    type: "UPDATED_MANUAL_COMMAND",
    editing: editingManualCommand,
  }
}

function deleteManualCommandAction(manualCommand){
  return {
    type: "DELETED_MANUAL_COMMAND",
    payload: {
      manualCommand: manualCommand,
    }
  }
}

function refreshManualCommandAction() {
  return { type: "REFRESH_MANUAL_COMMAND" };
}

function manualCommandNotFoundErrorAction() {
  return appErrorAction(["指定された手動操作コマンドは存在しません。","他のアカウントにより削除された可能性があります。"]);
}
