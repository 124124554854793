import db from '../utilities/db';
import firebase from 'firebase/app';
import {updateUser} from './login';
import authorizerFB from './authorizerFirebase';
import { appErrorAction } from './appAction';

export function beginEdit(dispatch){
  dispatch(beginEditAction());
}

export function changeEditing(dispatch, editingAccount){
  dispatch(changeEditAction(editingAccount));
}


export async function approvalAccountExec(dispatch, cid, editingAccount){
  // Auth作成とログイン
  let authorized;
  const authorizer = new authorizerFB(cid);
  try{
    authorized = await authorizer.createUser(editingAccount.email, editingAccount.password);
  } catch(e){
    if (e.code === "auth/invalid-email") {
      dispatch(appErrorAction("このメールアドレスは登録できない形式です。"));
      return;
    }
    authorized = await authorizer.authorize(editingAccount.email, editingAccount.password);
    if(!authorized){
      dispatch(appErrorAction("このメールアドレスは登録済みか承認リクエスト発行中です。"));
      return;
    }
    // すでにユーザが存在している場合は追加済みか確認
    const tm = await db().collection("companies").doc(cid).collection("tool-managers").doc(authorized.user.uid).get();
    if(tm.exists){
      dispatch(appErrorAction("このメールアドレスはすでに登録されています"));
      return;
    }
    const tu = await db().collection("companies").doc(cid).collection("tool-users").doc(authorized.user.uid).get();
    if(tu.exists){
      dispatch(appErrorAction("このメールアドレスはすでに登録されています"));
      return;
    }
    const requests = await db().collection("companies").doc(cid).collection("approval-requests").where("email", "==", editingAccount.email).get();
    console.log(requests);
    if(!requests.empty){
      dispatch(appErrorAction("このメールアドレスは承認リクエスト発行中です。"));
      return;
    }
  }
  // cidの存在チェック
  const companyDoc = db().collection("companies").doc(cid);
  let company = await companyDoc.get();
  if(!company.exists){
    dispatch(appErrorAction("会社情報がありません"));
    return;
  }
  // 自動承認に含まれるか
  if(company.data().automaticApprovalMails.indexOf(editingAccount.email) >= 0){
    editingAccount.includeAutoApprovalMails = true;
  }
  // 承認リクエストコレクション削除リスナー
  let added = false;
  const unsub = companyDoc.collection("approval-requests").doc(authorized.user.uid).onSnapshot({includeMetadataChanges: true }, async snapshot => {
    if(snapshot.exists){
      added = true;
      return;
    }
    if(!snapshot.exists && added){
      // 削除された場合
      unsub();
      dispatch({
        type: "AUTO_LOGIN_WAITING",
        payload: {
          email: editingAccount.email,
          password: editingAccount.password,
        }
      });
    }
  });
  // console.log(cid, editingAccount, authorized);
  // 承認リクエストへ追加（追加トリガーでfunctionsが起動）
  const approvalAccountDoc = companyDoc.collection("approval-requests").doc(authorized.user.uid);
  const approvalAccountData = await approvalAccountDoc.get();
  if(!approvalAccountData.exists){
    // 存在しない場合は追加
    await approvalAccountDoc.set({
      role: editingAccount.role,
      email: editingAccount.email,
      displayName: editingAccount.displayName,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
  } else {
    // 存在した場合は更新
    await approvalAccountDoc.update({
      role: editingAccount.role,
      email: editingAccount.email,
      displayName: editingAccount.displayName,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
  }
  dispatch(approvalAccountAddedAction());
}

export async function autoLoginExecute(dispatch, cid, autoLoginUser){
  const authorizer = new authorizerFB(cid);
  const authorized = await authorizer.authorize(autoLoginUser.email, autoLoginUser.password);
  const user = await authorizer.getUser(authorized);
  if (!user) {
    dispatch({ type: "ADD_ACCOUNT_APPROVAL_REJECT" });
    await authorizer.unauthorize();
    return;
  }
  if(user.role !== "tool-manager" && user.role !== "tool-user"){
    dispatch({ type: "ADD_ACCOUNT_APPROVAL_REJECT" });
    await authorizer.unauthorize();
    return;
  }
  dispatch({ type: "AUTO_LOGIN_EXECUTED" });
  sessionStorage.setItem('user' + cid, JSON.stringify(user));
  dispatch(updateUser(user));
}

export function unsubAccountListener(dispatch) {
  dispatch(unsubAccountListenerAction());
}

export function removeAccountListener(dispatch) {
  dispatch(removeAccountListenerAction());
}

function beginEditAction(){
  return {
    type: 'BEGIN_EDIT_ACCOUNT',
  }
}

function changeEditAction(editingAccount){
  return {
    type: 'CHANGE_EDIT_ACCOUNT',
    payload: {
      editingAccount: editingAccount
    }
  }
}

function approvalAccountAddedAction(){
  return {
    type: 'APPROVAL_ACCOUNT_ADDED',
  }
}

function unsubAccountListenerAction() {
  return {
    type: 'UNSUB_ACCOUNT_LISTENER',
  }
}

function removeAccountListenerAction() {
  return {
    type: 'REMOVE_ACCOUNT_LISTENER',
  }
}
