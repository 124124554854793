import React, { Component } from "react";
import { connect } from 'react-redux';
import Auth from "./auth";
import { withRouter } from "react-router-dom";
import TopBar from "./TopBar";
import { withStyles, Grid, Button, Tooltip, MenuItem } from '@material-ui/core';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { onLoading, offLoading } from "./actions/loading";
import { listVguards, beginEditVguard, updateVguardExec, hideUpdateToast } from "./actions/vguards";
import { renderTextField, renderSelectField, renderSimpleTextField, renderSwitch } from "./formRenderer";
import { CustomButton } from './AppParts';
import { required } from "./validator";
import ErrorMessage from './errorMessage';
import UpdateMessage from './updateMessage';
import { appRemoveError, updateMessage, appError } from './actions/appAction';
import muiStyles from "./MuiStyles";
import SettingMenu from './SettingMenu';
// import { login } from "./actions/login";
// import { mainCompany, mainLablesListener } from "./actions/main";

const validate = values => {
  const errors = {};
  if (!values.settings) return errors;
  errors.settings = {
    nearZone: {},
    midZone: {},
    srs: {},
  }
  if(values.settings.idlingEyesSpeed && Number(values.settings.idlingEyesSpeed.toString().replace(/,/g, "")) > 600000){
    errors.settings.idlingEyesSpeed = "600,000以下";
  }
  if(values.settings.idlingFaceRange && Number(values.settings.idlingFaceRange.toString().replace(/,/g, "")) > 180){
    errors.settings.idlingFaceRange = "180以下";
  }
  if(values.settings.childTallHeight && Number(values.settings.childTallHeight.toString().replace(/,/g, "")) > 10){
    errors.settings.childTallHeight = "10以下";
  }
  if (values.settings.nearZone.distance && Number(values.settings.nearZone.distance.toString().replace(/,/g, "")) > 600000){
    errors.settings.nearZone.distance = "600,000以下";
  }
  if (values.settings.nearZone.waitTime && Number(values.settings.nearZone.waitTime.toString().replace(/,/g, "")) > 600000){
    errors.settings.nearZone.waitTime = "600,000以下";
  }
  // speed
  if (values.settings.midZone.speed && Number(values.settings.midZone.speed.toString().replace(/,/g, "")) > 600000) {
    errors.settings.midZone.speed = "600,000以下";
  }
  if (values.settings.midZone.rerunWaitTime && Number(values.settings.midZone.rerunWaitTime.toString().replace(/,/g, "")) > 600000) {
    errors.settings.midZone.rerunWaitTime = "600,000以下";
  }
  ["1", "2", "3"].forEach(no => {
    ["X1", "X2", "Z1", "Z2"].forEach(n => {
      const name = "virtualwall0" + no + n;
      if(values.settings.midZone[name] && Number(values.settings.midZone[name].toString().replace(/,/g, "")) > 100){
        errors.settings.midZone[name] = "100以下";
      }
      if(values.settings.midZone[name] && Number(values.settings.midZone[name].toString().replace(/,/g, "")) < -100){
        errors.settings.midZone[name] = "-100以上";
      }
    });
  });
  if (values.settings.srs.silentDuration && Number(values.settings.srs.silentDuration.toString().replace(/,/g, "")) > 600000) {
    errors.settings.srs.silentDuration = "600,000以下";
  }
  if (values.settings.srs.rejectDuration && Number(values.settings.srs.rejectDuration.toString().replace(/,/g, "")) > 600000) {
    errors.settings.srs.rejectDuration = "600,000以下";
  }
  if (values.settings.srs.retryOverTimes && Number(values.settings.srs.retryOverTimes.toString().replace(/,/g, "")) > 100) {
    errors.settings.srs.retryOverTimes = "100以下";
  }
  if (values.settings.callTimeoutSec && Number(values.settings.callTimeoutSec.toString().replace(/,/g, "")) > 600) {
    errors.settings.callTimeoutSec = "600以下";
  }
  // const settings = values.settings;
  // const cameraSettings = settings.cameraSettings;
  // new Array(3).fill(null).forEach((_, i) => {
  //   const cameraName = "camera" + (i + 1);
  //   if(cameraSettings[cameraName + "IP1"] || cameraSettings[cameraName + "IP2"] || cameraSettings[cameraName + "IP3"] || cameraSettings[cameraName + "IP4"]){
  //     const ip = cameraSettings[cameraName + "IP1"] + "." + cameraSettings[cameraName + "IP2"] + "." + cameraSettings[cameraName + "IP3"] + "." + cameraSettings[cameraName + "IP4"];
  //     if(!ip.match('\\d{1,3}(\\.\\d{1,3}){3}(/\\d{1,2})?')){
  //       errors.settings.cameraSettings[cameraName + "IP1"] = "IP形式で入力";
  //       errors.settings.cameraSettings[cameraName + "IP2"] = " ";
  //       errors.settings.cameraSettings[cameraName + "IP3"] = " ";
  //       errors.settings.cameraSettings[cameraName + "IP4"] = " ";
  //     }
  //   }
  // });
  return errors;
}

export class Vguards extends Component {

  async componentDidMount() {
    // TODO: デバッグ用
    // console.log("componentDidMount", this.props);
    // if(!this.props.user){
    //   const cid = this.props.match.params && this.props.match.params.cid;
    //   await login(this.props.dispatch, "test@test.com", "sskkotdotd", cid);
    // }
    // デバッグ用

    if(this.props.company && this.props.selectedLabel){
      onLoading(this.props.dispatch);
      await listVguards(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, true);
      offLoading(this.props.dispatch);
    }
  }

  async componentDidUpdate() {
    // TODO: デバッグ用
    // console.log("componentDidUpdate", this.props);
    // if(!this.props.company && this.props.user){
    //   await mainCompany(this.props.dispatch, this.props.user.TenantId);
    // }
    // if(this.props.company && !this.props.selectedLabel.label){
    //   await mainLablesListener(this.props.dispatch, this.props.user.TenantId, this.props.listeners);
    // }
    // if(this.props.company && this.props.selectedLabel.label && !this.props.vguards.length){
    //   await listVguards(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, true);
    // }
    // デバッグ用

    if(this.props.isInit){
      if(this.props.vguards.length){
        const vguardNumber = this.props.editingVguard ? this.props.editingVguard.index : 0;
        const vguard = this.props.vguards[vguardNumber] ? this.props.vguards[vguardNumber].vguard : this.props.vguards[0].vguard;
        this.onBeginEdit(vguard);
      } else {
        this.onBeginEdit(null);
      }
    }

    if(this.props.showUpdateToast){
      setTimeout(()=>{
        hideUpdateToast(this.props.dispatch);
      }, 5000)
    }
  }

  onBeginEditChange = (e) => {
    e.preventDefault();
    appRemoveError(this.props.dispatch);
    this.onBeginEdit(e.target.value);
  }

  onBeginEdit = async (vguard) => {
    if(!this.props.loading){
      // 最新のデータを取得後、切替を行う
      onLoading(this.props.dispatch);
      await listVguards(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, false);
      offLoading(this.props.dispatch);
    }
    appRemoveError(this.props.dispatch);
    beginEditVguard(this.props.dispatch, vguard);
  }

  // onEditingChange = (e, isNumber=true) =>{
  //   // const editingVguard = { ...this.props.editingVguard };
  //   const editingVguard = this.props.editingVguard;
  //   editingVguard.settings[e.target.name] = isNumber ? Number(e.target.value) : e.target.value;
  //   changeEditingVguard(this.props.dispatch, editingVguard);
  // }

  // onEditingChangeCheck = (e) => {
  //   // const editingVguard = { ...this.props.editingVguard };
  //   const editingVguard = this.props.editingVguard;
  //   editingVguard.settings[e.target.name] = e.target.checked;
  //   changeEditingVguard(this.props.dispatch, editingVguard);
  // }

  // onEditingChangeNest = (e, nestName, isNumber=true) =>{
  //   // const editingVguard = { ...this.props.editingVguard };
  //   const editingVguard = this.props.editingVguard;
  //   editingVguard.settings[nestName][e.target.name] = isNumber ? Number(e.target.value) : e.target.value;
  //   changeEditingVguard(this.props.dispatch, editingVguard);
  // }

  // onEditingChangeNestCheck = (e, nestName) =>{
  //   // const editingVguard = { ...this.props.editingVguard };
  //   const editingVguard = this.props.editingVguard;
  //   editingVguard.settings[nestName][e.target.name] = e.target.checked;
  //   changeEditingVguard(this.props.dispatch, editingVguard);
  // }

  onUpdateExec = async (values) => {
    // const editingVguard = {...this.props.editingVguard};
    const editingVguard = {...values};
    const faceDetection = editingVguard.settings && editingVguard.settings.faceDetection;
    if (faceDetection) {
      if (faceDetection["faceDetectionServerIP1"] || faceDetection["faceDetectionServerIP2"] || faceDetection["faceDetectionServerIP3"] || faceDetection["faceDetectionServerIP4"]) {
        const ip = faceDetection["faceDetectionServerIP1"] + "." + faceDetection["faceDetectionServerIP2"] + "." + faceDetection["faceDetectionServerIP3"] + "." + faceDetection["faceDetectionServerIP4"];
        if (!ip.match('^(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])(\\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])){3}$')) {
          appError(this.props.dispatch, "IP アドレスは完全な形で入力してください。");
          return;
        }
      }
    }
    const cameraSettings = editingVguard.settings && editingVguard.settings.cameraSettings;
    if (cameraSettings) {
      const ipError = new Array(3).fill(null).some((_, i) => {
        const cameraName = "camera" + (i + 1);
        if (cameraSettings[cameraName + "IP1"] || cameraSettings[cameraName + "IP2"] || cameraSettings[cameraName + "IP3"] || cameraSettings[cameraName + "IP4"]) {
          const ip = cameraSettings[cameraName + "IP1"] + "." + cameraSettings[cameraName + "IP2"] + "." + cameraSettings[cameraName + "IP3"] + "." + cameraSettings[cameraName + "IP4"];
          if (!ip.match('^(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])(\\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])){3}$')) {
            appError(this.props.dispatch, "IP アドレスは完全な形で入力してください。");
            return true;
          }
        }
        return false;
      });
      if (ipError) return;
    }
    appRemoveError(this.props.dispatch);
    onLoading(this.props.dispatch);
    await updateVguardExec(this.props.dispatch, this.props.user.TenantId, this.props.selectedLabel.label, editingVguard);
    offLoading(this.props.dispatch);
    updateMessage(this.props.dispatch, true, "update");
  }

  render(){
    const { handleSubmit, submitting, classes } = this.props;
    const disabled = this.props.user && !this.props.user.isManager();
    const colorDisabled = { color: disabled && "#444444" };
    const textRight = { style: { textAlign: "right" }};
    return(
      <React.Fragment>
        <Auth />
        <React.Fragment>
          <TopBar />
          <div className={classes.content}>
            <SettingMenu />
            <div className={classes.main} style={{ width: 901 }}>
              <ErrorMessage />
              <UpdateMessage />
              { this.props.editingVguard &&
              <React.Fragment>
                <Grid container spacing={1}>
                  <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
                    <div className="titleText" style={{ minWidth: 420 }}>バーチャル警備員端末設定</div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around", width: "100%" }}>
                      { this.props.vguards.map((item, index) => {
                        let buttonStyle = "toggleNotSelected";
                        if(item.vguard === this.props.editingVguard.vguard){
                          buttonStyle = "toggleSelected";
                        }
                        return (
                          <Button key={index} className={classes[buttonStyle]} style={{ width: "100%" }} onClick={() => this.onBeginEdit(item.vguard)}>警備員0{index+1}</Button>
                        )
                      }) }
                    </div>
                  </Grid>
                </Grid>
                <form onSubmit={handleSubmit(this.onUpdateExec)}>
                  <div style={{ marginTop: 15 }}>
                    <span className="vguardNameText primaryText">警備員名称：{this.props.editingVguard.displayName}</span>
                  </div>
                  <Grid container justify="space-between" style={{ marginTop: "15px" }} spacing={2}>
                    <Grid item xs={6}>
                      <Grid container spacing={2} justify="center" alignItems="center">
                        <Grid item xs={12}>
                          <span className="vguardSettingTitle">・基本設定</span>
                        </Grid>
                        <Grid item xs={12}>
                          <Field name={"settings.idlingEyesEnabled"} component={renderSwitch} label={"目線の有効化"} labelGirdSize={6} tooltip="tooltip" disabled={disabled}/>
                        </Grid>
                        <Grid item xs={12}>
                          <Field name={"settings.idlingEyesSpeed"} component={renderTextField} validate={[required]} label={"目線の切替速度"} labelGirdSize={6} tooltip="目線の切替速度" size={"sm"}
                                 type="text" numberFormat={true} inputProps={{ style: { color: "#444444", ...textRight.style }}} suffixText="m/s" style={{ width: 56 }} disabled={disabled} />
                        </Grid>
                        <Grid item xs={12}>
                          <Field name={"settings.idlingFaceEnabled"} component={renderSwitch} label={"顔の向き変更の有効化"} labelGirdSize={6} tooltip="顔の向き変更の有効化" disabled={disabled} />
                        </Grid>
                        <Grid item xs={12}>
                          <Field name={"settings.idlingFaceRange"} component={renderTextField} validate={[required]} label={"顔の向きの可動域"} labelGirdSize={6} tooltip="顔の向きの可動域" size={"sm"}
                            type="text" numberFormat={true} inputProps={{ style: { color: "#444444", ...textRight.style }}} suffixText="°" style={{ width: 56 }} disabled={disabled} />
                        </Grid>
                        <Grid item xs={12}>
                          <Field name={"settings.childTallEnabled"} component={renderSwitch} label={"低身長対応の有効化"} labelGirdSize={6} tooltip="低身長対応の有効化" disabled={disabled} />
                        </Grid>
                        <Grid item xs={12}>
                          <Field name={"settings.childTallHeight"} component={renderTextField} validate={[required]} label={"低身長対応有効身長"} labelGirdSize={6} tooltip="低身長対応有効身長" size={"sm"}
                            type="text" numberFormat={true} inputProps={{ style: { color: "#444444", ...textRight.style }}}  suffixText="m以下" style={{ width: 56 }} disabled={disabled} />
                        </Grid>
                        <Grid item xs={12}>
                          <Field name={"settings.characterRef"} component={renderSelectField} validate={[required]} label={"キャラクター選択"} labelGirdSize={6} tooltip="キャラクター選択" style={colorDisabled} disabled={disabled} >
                            { this.props.characters.map(item => {
                              return(
                                <MenuItem key={item.character} value={item.character}>{ item.name }</MenuItem>
                              )
                            }) }
                          </Field>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} justify="center" alignItems="center" style={{ marginTop: 40 }}>
                        {/* 来訪者検知・近距離検知設定 */}
                        <Grid item xs={12}>
                          <span className="vguardSettingTitle">・来訪者検知 - 近距離検知設定</span>
                        </Grid>
                        <Grid item xs={12}>
                          <Field name={"settings.nearZone.distance"} component={renderTextField} validate={[required]} label={"検知距離設定"} labelGirdSize={6} tooltip="検知距離設定" size={"sm"}
                            type="text" numberFormat={true} inputProps={{ style: { color: "#444444", ...textRight.style }}} suffixText="mまで" style={{ width: 56 }} disabled={disabled} />
                        </Grid>
                        <Grid item xs={12}>
                          <Field name={"settings.nearZone.waitTime"} component={renderTextField} validate={[required]} label={"検知発動待機時間"} labelGirdSize={6} tooltip="検知発動待機時間" size={"sm"}
                            type="text" numberFormat={true} inputProps={{ style: { color: "#444444", ...textRight.style }}} suffixText="ms" style={{ width: 56 }} disabled={disabled} />
                        </Grid>
                        <Grid item xs={12}>
                          <Field name={"settings.nearZone.captureEnabled"} component={renderSwitch} label={"検知時キャプチャー有効化"} labelGirdSize={6} tooltip="検知時キャプチャー有効化" disabled={disabled} />
                        </Grid>
                        <Grid item xs={12}>
                          <Field name={"settings.nearZone.greetingEnabled"} component={renderSwitch} label={"挨拶設定有効化"} labelGirdSize={6} tooltip="挨拶設定有効化" disabled={disabled} />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} justify="center" alignItems="center" style={{ marginTop: 40 }}>
                        {/* 来訪者検知・中距離検知設定 */}
                        <Grid item xs={12}>
                          <span className="vguardSettingTitle">・来訪者検知 - 中距離検知設定</span>
                        </Grid>
                        <Grid item xs={12}>
                          <Field name={"settings.midZone.speed"} component={renderTextField} validate={[required]} label={"検知スピード"} labelGirdSize={6} tooltip="検知スピード" size={"sm"}
                            type="text" numberFormat={true} inputProps={{ style: { color: "#444444", ...textRight.style }}} suffixText="m/s以下" /* normalize={toNumber} */ style={{ width: 56 }} disabled={disabled} />
                        </Grid>
                        {/* バーチャルセンサー設定 */}
                        <Grid item xs={12}>
                          {new Array(3).fill(null).map((_, index) => {
                            return (
                              <RenderVirtualwallSetting key={index} parentProps={this.props} index={index} disabled={disabled} />
                            );
                          })}
                        </Grid>
                        <Grid item xs={12}>
                          <Field name={"settings.midZone.rerunWaitTime"} component={renderTextField} validate={[required]} label={"再実行待ち時間"} labelGirdSize={6} tooltip="再実行待ち時間" size={"sm"}
                            type="text" numberFormat={true} inputProps={{ style: { color: "#444444", ...textRight.style }}} suffixText="ms" /* normalize={toNumber} */ style={{ width: 56 }} disabled={disabled}/>
                        </Grid>
                        <Grid item xs={12}>
                          <Field name={"settings.midZone.captureEnabled"} component={renderSwitch} label={"検知時キャプチャー有効化"} labelGirdSize={6} tooltip="検知時キャプチャー有効化" disabled={disabled} />
                        </Grid>
                        <Grid item xs={12}>
                          <Field name={"settings.midZone.greetingEnabled"} component={renderSwitch} label={"挨拶設定有効化"} labelGirdSize={6} tooltip="挨拶設定有効化" disabled={disabled} />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} justify="center" alignItems="center" style={{ marginTop: 40 }}>
                        <Grid item xs={12}>
                          <span className="vguardSettingTitle">・音声認識設定</span>
                        </Grid>
                        <Grid item xs={12}>
                          <Field name={"settings.srs.bargeIn"} component={renderSwitch} label={"バージイン設定有効化"} labelGirdSize={6} tooltip="バージイン設定有効化" disabled={disabled} />
                        </Grid>
                        <Grid item xs={12}>
                          <Field name={"settings.srs.silentDuration"} component={renderTextField} validate={[required]} label={"無発話秒数指定"} labelGirdSize={6} tooltip="無発話秒数指定" size={"sm"}
                            type="text" numberFormat={true} inputProps={{ style: { color: "#444444", ...textRight.style }}} suffixText="ms" /* normalize={toNumber} */ disabled={disabled} />
                        </Grid>
                        <Grid item xs={12}>
                          <Field name={"settings.srs.rejectDuration"} component={renderTextField} validate={[required]} label={"不認識秒数指定"} labelGirdSize={6} tooltip="不認識秒数指定" size={"sm"}
                            type="text" numberFormat={true} inputProps={{ style: { color: "#444444", ...textRight.style }}} suffixText="ms" /* normalize={toNumber} */ disabled={disabled} />
                        </Grid>
                        <Grid item xs={12}>
                          <Field name={"settings.srs.retryOverTimes"} component={renderTextField} validate={[required]} label={"リトライオーバー回数"} labelGirdSize={6} tooltip="リトライオーバー回数" size={"sm"}
                            type="text" numberFormat={true} inputProps={{ style: { color: "#444444", ...textRight.style }}} suffixText="回" disabled={disabled}/>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} justify="center" alignItems="center" style={{ marginTop: 40 }}>
                        <Grid item xs={12}>
                          <span className="vguardSettingTitle">・発話検知表示設定</span>
                        </Grid>
                        <Grid item xs={12}>
                          <Field name={"settings.speechDetection.noddingMotionEnabled"} component={renderSwitch} label={"頷きモーション有効化"} labelGirdSize={6} tooltip="頷きモーション有効化" disabled={disabled} />
                        </Grid>
                        <Grid item xs={12}>
                          <Field name={"settings.speechDetection.textDisplayEnabled"} component={renderSwitch} label={"発話検知中表示"} labelGirdSize={6} tooltip="発話検知中表示" disabled={disabled} />
                        </Grid>
                        <Grid item xs={12}>
                          <Field name={"settings.speechDetection.srsResultDisplayEnabled"} component={renderSwitch} label={"音声認識結果表示"} labelGirdSize={6} tooltip="音声認識結果表示" disabled={disabled} />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} justify="center" alignItems="center" style={{ marginTop: 40 }}>
                        <Grid item xs={12}>
                          <span className="vguardSettingTitle">・音声通話設定</span>
                        </Grid>
                        <Grid item xs={12}>
                          <Field name={"settings.callTimeoutSec"} component={renderTextField} validate={[required]} label={"呼出タイムアウト秒数"} labelGirdSize={6} tooltip="呼出タイムアウト秒数" size={"sm"}
                            type="text" numberFormat={true} inputProps={{ style: { color: "#444444", ...textRight.style }}} suffixText="s" disabled={disabled} />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      {/* <Grid container spacing={2} justify="center" alignItems="center">
                        <Grid item xs={12}>
                          <span className="vguardSettingTitle">・ネットワーク死活監視設定</span>
                        </Grid>
                        <Grid item xs={12}>
                          <Field name={"heartbeat"} component={renderSwitch} label={"ネットワーク死活監視有効化"} labelGirdSize={7} tooltip="ネットワーク死活監視有効化" />
                        </Grid>
                      </Grid> */}
                      <Grid container spacing={2} justify="center" alignItems="center">
                        <Grid item xs={12}>
                          <span className="vguardSettingTitle">・顔認証設定</span>
                        </Grid>
                        <Grid item xs={12}>
                          <Field name={"settings.faceDetection.faceDetectionEnabled"} component={renderSwitch} label={"顔認証設定有効化"} labelGirdSize={7} tooltip="tooltip" disabled={disabled} />
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
                              <span className="labelText">顔認証サーバローカルIPアドレス</span>
                              <Tooltip placement="top" title="tooltip" classes={{ tooltip: "tooltip" }}>
                                <span className="primaryText">[ ? ]</span>
                              </Tooltip>
                            </Grid>
                            <Grid item xs={12} style={{ paddingLeft: "21px", paddingTop: 0, display: "flex", alignItems: "flex-end" }}>
                              <Field name={"settings.faceDetection.faceDetectionServerIP1"} component={renderSimpleTextField} type="text" numberFormat={true} style={{ width: "56px" }} inputProps={{ style: { color: "#444444" }}} size={"sm"} disabled={disabled} /><span>.</span>
                              <Field name={"settings.faceDetection.faceDetectionServerIP2"} component={renderSimpleTextField} type="text" numberFormat={true} style={{ width: "56px" }} inputProps={{ style: { color: "#444444" }}} size={"sm"} disabled={disabled} /><span>.</span>
                              <Field name={"settings.faceDetection.faceDetectionServerIP3"} component={renderSimpleTextField} type="text" numberFormat={true} style={{ width: "56px" }} inputProps={{ style: { color: "#444444" }}} size={"sm"} disabled={disabled} /><span>.</span>
                              <Field name={"settings.faceDetection.faceDetectionServerIP4"} component={renderSimpleTextField} type="text" numberFormat={true} style={{ width: "56px" }} inputProps={{ style: { color: "#444444" }}} size={"sm"} disabled={disabled} />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} justify="center" alignItems="center" style={{ marginTop: 40 }}>
                        <Grid item xs={12}>
                          <span className="vguardSettingTitle">・カメラIP設定</span>
                          <div style={{ marginLeft: "14px", marginTop: "10px" }}>
                            { new Array(3).fill(null).map((_, index) => {
                              return(
                                <RenderCameraSetting key={index} parentProps={this.props} index={index} colorDisabled={colorDisabled} disabled={disabled} />
                              );
                            }) }
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {(this.props.user && this.props.user.role === "tool-manager") &&
                    <div style={{ width: "center", textAlign: "center", marginTop: 30 }}>
                      <CustomButton variant="contained" themecolor="primary" type="submit" disabled={submitting} width={160} height={48}>更新する</CustomButton>
                    </div>
                  }
                </form>
              </React.Fragment>
              }
            </div>
          </div>
        </React.Fragment>
      </React.Fragment>
    )
  }
}

function RenderVirtualwallSetting(props) {
  const index = props.index;
  const no = index + 1;
  const virtualwallName = "settings.midZone.virtualwall0" + no;
  const disabled = props.disabled;
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Grid container spacing={2} style={{marginTop: index > 0 ? 10 : 0}}>
          <Grid item xs={7} className="formLabel">
            <span className="labelText">バーチャルセンサー{no}設定（m）</span>
            <Tooltip placement="top" title="tooltip" classes={{ tooltip: "tooltip" }}>
              <span className="primaryText">[ ? ]</span>
            </Tooltip>
          </Grid>
          <Grid item xs={5}></Grid>
          <Grid item xs={12} style={{ paddingTop: 0 }}>
            <div style={{ display: "flex", marginLeft: "14px" }}>
              <span>x1</span><Field name={virtualwallName + "X1"} component={renderSimpleTextField} type="text" numberFormat={true} allowNegative validate={[required]} style={{ width: "56px", marginLeft: 5 }} inputProps={{ style: { color: "#444444" }}} size={"sm"} disabled={disabled} />
              <span style={{ marginLeft: 10 }}>z1</span><Field name={virtualwallName + "Z1"} component={renderSimpleTextField} type="text" numberFormat={true} allowNegative validate={[required]} style={{ width: "56px", marginLeft: 5 }} inputProps={{ style: { color: "#444444" }}} size={"sm"} disabled={disabled} />
              <span style={{ marginLeft: 10 }}>x2</span><Field name={virtualwallName + "X2"} component={renderSimpleTextField} type="text" numberFormat={true} allowNegative validate={[required]} style={{ width: "56px", marginLeft: 5 }} inputProps={{ style: { color: "#444444" }}} size={"sm"} disabled={disabled} />
              <span style={{ marginLeft: 10 }}>z2</span><Field name={virtualwallName + "Z2"} component={renderSimpleTextField} type="text" numberFormat={true} allowNegative validate={[required]} style={{ width: "56px", marginLeft: 5 }} inputProps={{ style: { color: "#444444" }}} size={"sm"} disabled={disabled} />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export const CAMERA_STRING = {
  1: "正面",
  2: "背面",
  3: "顔認証",
}

function RenderCameraSetting(props){
  const index = props.index;
  const no = index + 1;
  const cameraString = CAMERA_STRING[no];
  const cameraName = "settings.cameraSettings.camera" + no;
  const disabled = props.disabled;
  const colorDisabled = props.colorDisabled;
  const { classes } = props.parentProps;
  return(
    <Grid container spacing={1} style={{ border: "1px solid #aaa", borderRadius: 4, boxSizing: "border-box", padding: "15px", marginTop: index > 0 ? 15 : 0 }}>
      <Grid item xs={12}>
        <div className={classes.cameraTitle}>
          <span className="cameraLabelText">カメラ0{no}（{cameraString}）</span>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
            <span className="labelText">ローカルIPアドレス</span>
            <Tooltip placement="top" title="tooltip" classes={{ tooltip: "tooltip" }}>
              <span className="primaryText">[ ? ]</span>
            </Tooltip>
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={12} style={{ paddingLeft: "21px", paddingTop: 0, display: "flex", alignItems: "flex-end" }}>
            <Field name={cameraName + "IP1"} component={renderSimpleTextField} type="text" numberFormat={true} style={{ width: "56px" }} inputProps={{ style: { color: "#444444" }}} size={"sm"} disabled={disabled} /><span>.</span>
            <Field name={cameraName + "IP2"} component={renderSimpleTextField} type="text" numberFormat={true} style={{ width: "56px" }} inputProps={{ style: { color: "#444444" }}} size={"sm"} disabled={disabled} /><span>.</span>
            <Field name={cameraName + "IP3"} component={renderSimpleTextField} type="text" numberFormat={true} style={{ width: "56px" }} inputProps={{ style: { color: "#444444" }}} size={"sm"} disabled={disabled} /><span>.</span>
            <Field name={cameraName + "IP4"} component={renderSimpleTextField} type="text" numberFormat={true} style={{ width: "56px" }} inputProps={{ style: { color: "#444444" }}} size={"sm"} disabled={disabled} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} style={{ marginTop: "10px" }}>
          <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
            <span className="labelText">ID</span>
            <Tooltip placement="top" title="tooltip" classes={{ tooltip: "tooltip" }}>
              <span className="primaryText">[ ? ]</span>
            </Tooltip>
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={12} style={{ paddingLeft: "21px", paddingTop: 0, display: "flex", alignItems: "flex-end" }}>
            <Field name={cameraName + "ID"} component={renderSimpleTextField} style={{ width: "100%" }} inputProps={{ style: colorDisabled}} size={"sm"} disabled={disabled} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} style={{ marginTop: "10px" }}>
          <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
            <span className="labelText">パスワード</span>
            <Tooltip placement="top" title="tooltip" classes={{ tooltip: "tooltip" }}>
              <span className="primaryText">[ ? ]</span>
            </Tooltip>
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={12} style={{ paddingLeft: "21px", paddingTop: 0, display: "flex", alignItems: "flex-end" }}>
            <Field name={cameraName + "Password"} component={renderSimpleTextField} type="password" style={{ width: "100%" }} inputProps={{ style: colorDisabled}} size={"sm"} disabled={disabled} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} style={{ marginTop: "10px" }}>
          <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
            <span className="labelText">クエリパラメータ</span>
            <Tooltip placement="top" title="tooltip" classes={{ tooltip: "tooltip" }}>
              <span className="primaryText">[ ? ]</span>
            </Tooltip>
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={12} style={{ paddingLeft: "21px", paddingTop: 0, display: "flex", alignItems: "flex-end" }}>
          <Field name={cameraName + "QueryString"} component={renderSimpleTextField} style={{ width: "100%" }} inputProps={{ style: colorDisabled}} size={"cameraQuery"} lineHeight={"21px"} newLine={2} disabled={disabled} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withRouter(withStyles(muiStyles)(
  connect(state => ({
    user: state.user && state.user.user,
    listeners: state.main && state.main.listeners,
    company: state.main && state.main.company,
    selectedLabel: state.main && state.main.selectedLabel,
    vguards: (state.vguards && state.vguards.vguards) || [],
    editingVguard: state.vguards && state.vguards.editingVguard,
    characters: (state.vguards && state.vguards.characters) || [],
    changed: (state.vguards && state.vguards.changed) || [],
    isInit: state.vguards && state.vguards.isInit,
    showUpdateToast: state.vguards && state.vguards.showUpdateToast,
    loading: state.loading && state.loading.loading,
    initialValues: state.vguards && state.vguards.editingVguard,
    formValues: getFormValues("vguardForm")(state),
  }))(reduxForm({ form: 'vguardForm', validate: validate, enableReinitialize: true })(Vguards))
));
